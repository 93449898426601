import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

/* Material UI */
import { Alert, Grid, IconButton, MenuItem, Stack, TextField, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

/* Project */
import color from 'utils/colors';
import { Delete } from '@mui/icons-material';

function StepTwo(props) {
  const {
    form,
    handleChange,
    handleFileChange,
    handleWordFileChange,
    handleConfidentialFileChange,
    file,
    wordFile,
    confidentialFile,
    objects,
    currentUser,
    setConfidentialFile,
  } = props;

  const { representatives, users } = objects;
  const currentUserModel = users[currentUser?.id];
  const currentEntityModel = currentUserModel?.entity();

  const avaliableRepresentatives = currentEntityModel
    ? Object.values(representatives).filter((r) => r.naturalEntityId === currentEntityModel.id)
    : [];

  const legalEntities = avaliableRepresentatives
    .map((representative) => representative.legalEntity())
    .filter((legalEntity) => legalEntity);

  const sortedlegalEntities = useMemo(
    () =>
      Object.values(legalEntities)
        .filter(Boolean)
        .sort((a, b) => {
          const x = a.entity().names.toLowerCase();
          const y = b.entity().names.toLowerCase();
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        }),
    [legalEntities],
  );

  const legalOptions = sortedlegalEntities.map((legalEntity) => (
    <MenuItem key={legalEntity.id} value={legalEntity.id}>
      {legalEntity.entity().names}
    </MenuItem>
  ));

  function validate(event) {
    if (event.key == '-') {
      event.preventDefault();
      return false;
    }
  }

  return (
    <>
      <Stack spacing={2}>
        <TextField
          size="small"
          id="outlined-select-legal-entities"
          select
          fullWidth
          label="En representación de"
          color="secondary"
          name="legalEntityId"
          value={form.legalEntityId}
          onChange={handleChange}>
          {legalOptions}
        </TextField>
        <TextField
          name="position"
          size="small"
          fullWidth
          label="Cargo dentro de la empresa"
          variant="outlined"
          onChange={handleChange}
          color="secondary"
          value={form.position}
        />
        <TextField
          multiline
          rows={6}
          fullWidth
          size="small"
          variant="outlined"
          label="Índice del documento"
          name="index"
          onChange={handleChange}
          color="secondary"
          value={form.index}
        />
        <TextField
          name="pageNumber"
          size="small"
          type="number"
          fullWidth
          label="Cantidad páginas de la presentación"
          variant="outlined"
          onChange={handleChange}
          color="secondary"
          value={form.pageNumber}
          onKeyDown={(event) => validate(event)}
        />
      </Stack>
      <Grid item xs={12} sx={{ mt: 2 }}>
        <Alert severity="info">
          {'¡Advertencia! Evita el uso de los siguientes caracteres especiales al nombrar archivos: / \\ : * ? < > \" | ()'}
        </Alert>
      </Grid>
      <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 2 }}>
        <Typography
          sx={{
            fontSize: 'h6.fontSize',
            fontWeight: 'bold',
            color: color.blue,
            textTransform: 'uppercase',
          }}>
          CARGAR
        </Typography>
        <Typography
          sx={{
            fontWeight: 'regular',
            color: color.blue,
            fontSize: 20,
          }}>
          Presentación PDF de discrepancia
        </Typography>
        <label htmlFor="presentation-file">
          <input
            accept=".pdf"
            id="presentation-file"
            name="icon-button-file"
            type="file"
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
          <IconButton color="primary" aria-label="upload picture" component="span">
            <AddIcon
              sx={{
                color: color.white,
                backgroundColor: color.blue,
                borderRadius: 3,
              }}
            />
          </IconButton>
        </label>
      </Stack>
      <Typography>{file?.name}</Typography>
      <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 2 }}>
        <Typography
          sx={{
            fontSize: 'h6.fontSize',
            fontWeight: 'bold',
            color: color.blue,
            textTransform: 'uppercase',
          }}>
          CARGAR
        </Typography>
        <Typography
          sx={{
            fontWeight: 'regular',
            color: color.blue,
            fontSize: 20,
          }}>
          Presentación word de discrepancia
        </Typography>
        <label htmlFor="presentation-word-file">
          <input
            accept=".doc, .docx"
            id="presentation-word-file"
            name="icon-button-file"
            type="file"
            style={{ display: 'none' }}
            onChange={handleWordFileChange}
          />
          <IconButton color="primary" aria-label="upload picture" component="span">
            <AddIcon
              sx={{
                color: color.white,
                backgroundColor: color.blue,
                borderRadius: 3,
              }}
            />
          </IconButton>
        </label>
      </Stack>
      <Typography>{wordFile?.name}</Typography>
      {/* INFO: Cliente requiere retirar la opción de agregar archivos confidenciales
      <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 2 }}>
        <Typography
          sx={{
            fontSize: 'h6.fontSize',
            fontWeight: 'bold',
            color: color.blue,
            textTransform: 'uppercase',
          }}>
          CARGAR
        </Typography>
        <Typography
          sx={{
            fontWeight: 'regular',
            color: color.blue,
            fontSize: 20,
          }}>
          Presentación confidencial en PDF
        </Typography>
        <label htmlFor="presentation-pdf-file">
          <input
            accept=".pdf"
            id="presentation-pdf-file"
            name="icon-button-file"
            type="file"
            style={{ display: 'none' }}
            onChange={handleConfidentialFileChange}
          />
          <IconButton color="primary" aria-label="upload file" component="span">
            <AddIcon
              sx={{
                color: color.white,
                backgroundColor: color.blue,
                borderRadius: 3,
              }}
            />
          </IconButton>
        </label>
        <IconButton aria-label="delete file">
          <Delete
            sx={{
              color: color.white,
              backgroundColor: color.red,
              borderRadius: 3,
            }}
            onClick={() => {
              setConfidentialFile(null);
            }}
          />
        </IconButton>
      </Stack>
      <Typography>{confidentialFile?.name}</Typography> */}
    </>
  );
}

StepTwo.propTypes = {
  form: PropTypes.shape({}).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleFileChange: PropTypes.func.isRequired,
  handleWordFileChange: PropTypes.func.isRequired,
  file: PropTypes.shape({}),
  wordFile: PropTypes.shape({}),
  currentUser: PropTypes.shape({}),
};

StepTwo.defaultProps = {
  file: null,
  wordFile: null,
  currentUser: null,
};

export default StepTwo;
