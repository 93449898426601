// @ts-nocheck
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import { Alert, Button, TextField, Typography } from '@mui/material';
import { Edit } from '@mui/icons-material';
import color from 'utils/colors';
import PrimaryButton from 'commons/components/PrimaryButton/PrimaryButton';
import SecondaryButton from 'commons/components/SecondaryButton/SecondaryButton';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import {
  changeLegalEntityControls,
  changeLegalEntityForm,
  changeLegalPowersControls,
  changeRepresentativesControls,
  clearLegalEntityForm,
  createLegalEntity,
  resetCaptcha,
  setLegalEntityErrorForms,
  showCaptchaError,
} from 'commons/commons.actions';
import { reselectObjects } from 'utils/helper';
import LegalPowersModal from 'commons/components/RegisterLegalEntity/components/LegalPowersModal';
import RepresentativesModal from 'commons/components/RegisterLegalEntity/components/RepresentativesModal';
import { validateEmail, validateRut } from 'utils/functions';
import { VALIDATE_CAPTCHA } from 'commons/commons.reducer';
import CaptchaDialog from '../CaptchaDialog/CaptchaDialog';

function RegisterLegalEntity() {
  const {
    registerLegalEntity,
    objects: objectsState,
    aprovedCaptcha,
  } = useSelector((state) => state.app);
  const objects = useMemo(() => reselectObjects(objectsState), [objectsState]);
  const dispatch = useDispatch();
  const { controls, form, loaders, formErrors } = registerLegalEntity;
  const [legalPowersArray, setLegalPowersArray] = useState([]);
  const [representativesArray, setRepresentativesArray] = useState([]);

  const legalEntitiesArr = Object.values(objects.legalEntities);
  const entities = Object.values(objects.entities);
  const skipEntitiesIds = legalEntitiesArr.map((le) => le.entityId);

  const naturalEntities = entities.filter((entity) => !skipEntitiesIds.includes(entity.id));

  const handleClose = () => {
    dispatch(changeLegalEntityControls({ name: 'openDialog', value: false }));
    dispatch(clearLegalEntityForm());
    dispatch(resetCaptcha());
  };

  const openLegalPowersModal = () => {
    dispatch(changeLegalPowersControls({ name: 'openDialog', value: true }));
  };

  const openRepresentativesModal = () => {
    dispatch(changeRepresentativesControls({ name: 'openDialog', value: true }));
  };

  const handleChange = ({ target: { name, value } }) => {
    dispatch(changeLegalEntityForm({ name, value }));
  };

  const handleRutChange = ({ target: { name, value } }) => {
    dispatch(changeLegalEntityForm({ name, value }));
    const validRut = validateRut(value);
    if (!validRut) {
      dispatch(
        setLegalEntityErrorForms({
          rut: {
            isValid: false,
            errorMessage: 'RUT inválido',
          },
        }),
      );
    } else {
      dispatch(
        setLegalEntityErrorForms({
          rut: {
            isValid: true,
            errorMessage: '',
          },
        }),
      );
    }
  };

  const handleEmailChange = ({ target: { name, value } }) => {
    dispatch(changeLegalEntityForm({ name, value }));
    const validEmail = validateEmail(value);
    if (!validEmail) {
      dispatch(
        setLegalEntityErrorForms({
          email: {
            isValid: false,
            errorMessage: 'Correo inválido',
          },
        }),
      );
    } else {
      dispatch(
        setLegalEntityErrorForms({
          email: {
            isValid: true,
            errorMessage: '',
          },
        }),
      );
    }
  };

  const submitCaptcha = () => {
    const siteKey = process.env.REACT_APP_CAPTCHA_SITE_KEY;
    const secretKey = process.env.REACT_APP_CAPTCHA_SECRET_KEY;
    try {
      grecaptcha.ready(function () {
        grecaptcha.execute(siteKey, { action: 'submit' }).then(function (token) {
          dispatch({ type: VALIDATE_CAPTCHA, payload: { secretKey, token } });
        });
      });
    } catch (error) {
      dispatch({ type: SET_ERROR, msg: 'Error al obtener captcha' });
    }
  };

  useEffect(() => {
    if (controls.openDialog) {
      submitCaptcha();
    }

    return () => {
      dispatch(resetCaptcha());
    };
    // eslint-disable-next-line
  }, [controls.openDialog]);

  const submitForm = () => {
    if (aprovedCaptcha) {
      return dispatch(
        createLegalEntity({
          name: form.name.trim(),
          rut: form.rut.trim(),
          email: form.email.trim(),
          phone: form.phone.trim(),
          address: form.address.trim(),
          password: form.password.trim(),
          confirmPassword: form.confirmPassword.trim(),
          legalPowersArray,
          representativesArray,
        }),
      );
    } else {
      return dispatch(showCaptchaError({ show: !aprovedCaptcha }));
    }
  };

  const title = (
    <Typography
      sx={{
        fontWeight: 'regular',
        color: color.cooper,
        fontSize: 20,
      }}>
      Registro nueva persona jurídica
    </Typography>
  );

  const nameTextField = (
    <TextField
      fullWidth
      size="small"
      variant="outlined"
      label="Nombre"
      name="name"
      onChange={handleChange}
      color="secondary"
      value={form.name}
      autoComplete="off"
    />
  );

  const rutTextField = (
    <TextField
      fullWidth
      size="small"
      variant="outlined"
      label="RUT"
      name="rut"
      onChange={handleRutChange}
      color="secondary"
      value={form.rut}
      error={!formErrors.rut.isValid}
      helperText={formErrors.rut.errorMessage}
      autoComplete="off"
    />
  );

  const emailTextField = (
    <TextField
      fullWidth
      size="small"
      variant="outlined"
      label="Correo"
      name="email"
      onChange={handleEmailChange}
      color="secondary"
      value={form.email}
      error={!formErrors.email.isValid}
      helperText={formErrors.email.errorMessage}
      autoComplete="off"
    />
  );

  const phoneTextField = (
    <TextField
      fullWidth
      size="small"
      variant="outlined"
      label="Teléfono"
      name="phone"
      onChange={handleChange}
      color="secondary"
      value={form.phone}
      autoComplete="off"
    />
  );

  const addressTextField = (
    <TextField
      fullWidth
      size="small"
      variant="outlined"
      label="Dirección"
      name="address"
      onChange={handleChange}
      color="secondary"
      value={form.address}
      autoComplete="off"
    />
  );

  const passwordTextField = (
    <TextField
      fullWidth
      size="small"
      type="password"
      variant="outlined"
      label="Contraseña"
      name="password"
      onChange={handleChange}
      color="secondary"
      value={form.password}
      autoComplete="off"
    />
  );

  const confirmPasswordTextField = (
    <TextField
      fullWidth
      size="small"
      type="password"
      variant="outlined"
      label="Confirmar contraseña"
      name="confirmPassword"
      onChange={handleChange}
      color="secondary"
      value={form.confirmPassword}
      autoComplete="off"
    />
  );

  const editLegalPowers = (
    <Button
      variant="text"
      onClick={openLegalPowersModal}
      sx={{ fontWeight: 'regular', color: color.blue }}
      endIcon={<Edit />}>
      Editar poderes
    </Button>
  );

  const editRepresentatives = (
    <Button
      variant="text"
      onClick={openRepresentativesModal}
      sx={{ fontWeight: 'regular', color: color.blue }}
      endIcon={<Edit />}>
      Editar representantes
    </Button>
  );

  const disableSave =
    !form.name.trim() ||
    !form.rut.trim() ||
    !form.email.trim() ||
    !form.phone.trim() ||
    !form.address.trim() ||
    !form.password.trim() ||
    !form.phone.trim() ||
    !form.confirmPassword.trim() ||
    !legalPowersArray.length ||
    !representativesArray.length ||
    !formErrors.rut.isValid ||
    !formErrors.email.isValid;

  return (
    <Dialog
      open={controls.openDialog}
      onClose={handleClose}
      PaperProps={{ sx: { minWidth: { lg: 700 }, minHeight: 200 } }}>
      <LegalPowersModal
        legalPowersArray={legalPowersArray}
        setLegalPowersArray={setLegalPowersArray}
        registerLegalEntity={registerLegalEntity}
      />
      <RepresentativesModal
        representativesArray={representativesArray}
        setRepresentativesArray={setRepresentativesArray}
        registerLegalEntity={registerLegalEntity}
        naturalEntities={naturalEntities}
      />
      <Box sx={{ m: 3 }}>
        <Grid container justifyContent="center" alignItems="center" sx={{ maxHeight: '80vh' }}>
          <Grid item container xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {title}
              </Grid>
              <Grid item xs={12}>
                <Alert severity="info">
                  Para crear una persona jurídica debe registrar previamente a la persona natural
                  que representa a la persona jurídica.
                </Alert>
              </Grid>
              <Grid item xs={12}>
                {nameTextField}
              </Grid>
              <Grid item xs={12}>
                {rutTextField}
              </Grid>
              <Grid item xs={12}>
                {emailTextField}
              </Grid>
              <Grid item xs={12}>
                {phoneTextField}
              </Grid>
              <Grid item xs={12}>
                {addressTextField}
              </Grid>
              <Grid item xs={6}>
                {passwordTextField}
              </Grid>
              <Grid item xs={6}>
                {confirmPasswordTextField}
              </Grid>
              <Grid item xs={12} alignItems="end">
                {!representativesArray.length || !legalPowersArray.length ? (
                  <Alert severity="error">
                    Debe agregar un poder de representación y un representante como mínimo.
                  </Alert>
                ) : null}
              </Grid>
              <Grid item xs={12} textAlign="end">
                {editLegalPowers}
              </Grid>
              <Grid item xs={12} textAlign="end">
                {editRepresentatives}
              </Grid>
              <Grid item xs={12}>
                <CaptchaDialog />
              </Grid>
              <Grid item xs={6} textAlign="start" sx={{ mt: 2 }}>
                <SecondaryButton text="Cancelar" onClick={handleClose} />
              </Grid>
              <Grid item xs={6} textAlign="end" sx={{ mt: 3 }}>
                <PrimaryButton
                  disabled={disableSave}
                  loading={loaders.create}
                  onClick={submitForm}
                  text="Aceptar"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
}

export default RegisterLegalEntity;
