import { newReducer } from 'utils/reducer';
import DiscrepancyDetailsTypes from 'screens/DiscrepancyDetail/DiscrepancyDetails.types';

const initialState = {
  controls: {
    loading: false,
    message: '',
    error: false,
    openEditDialog: false,
    openDeleteDialog: false,
    openDownloadDialog: false,
  },
  loaders: {
    discrepancyData: true,
    participantsFile: false,
    details: true,
    edition: false,
    delete: false,
    notification: false,
  },
  documentStepper: {
    form: {
      documentTypeId: '',
      title: '',
      index: '',
      position: '',
      legalEntityId: '',
      dateHourAudience: null,
      audienceUrl: '',
      dateHourRegistrationDeadline: null,
      dateHourPresentationInterestedParty: null,
      dateHourObservationsDeadline: null,
      copyToDiscrepancies: [],
    },
    controls: {
      activeStep: 0,
      openDialog: false,
    },
    modalControls: {
      openDialog: false,
      idx: -1,
    },
    loaders: {
      create: false,
    },
  },
  document: {
    controls: {
      openEditDialog: false,
      openDeleteDialog: false,
      editActiveStep: -1,
    },
    loaders: {
      edition: false,
      delete: false,
    },
    form: {
      documentTypeId: '1',
      title: '',
      index: '',
      id: -1,
      documentDate: null,
      legalEntityId: null,
      dateHourAudience: null,
      dateHourRegistrationDeadline: null,
      dateHourPresentationInterestedParty: null,
      dateHourObservationsDeadline: null,
    },
  },
  documentModal: false,
  view: 'main',
  viewDocument: false,
  documentFilters: {
    interested: [],
    documentType: [],
  },
  openDocumentBanner: false,
};

const changeDocumentDeleteLoader = (state, value) => ({
  ...state,
  document: {
    ...state.document,
    loaders: { ...state.document.loaders, delete: value },
  },
});

const changeLoaders = (state, { payload: { name, value } }) => ({
  ...state,
  loaders: {
    ...state.loaders,
    [name]: value,
  },
});

const setNotificationLoader = (state, value) =>
  changeLoaders(state, { payload: { name: 'notification', value } });

const discrepancyDetails = newReducer(initialState, {
  [`${DiscrepancyDetailsTypes.DISABLE_ERRORS}`]: (state) => ({
    ...state,
    error: false,
  }),
  [DiscrepancyDetailsTypes.GET_DISCREPANCY_SUCCESS]: (state) => ({
    ...state,
    loaders: {
      ...state.loaders,
      discrepancyData: false,
    },
  }),
  [DiscrepancyDetailsTypes.GET_DISCREPANCY]: (state, action) => ({
    ...state,
    loaders: {
      ...state.loaders,
      discrepancyData: true,
    },
    view: action.payload.view,
  }),
  [DiscrepancyDetailsTypes.GET_DISCREPANCY_FAIL]: (state) => ({
    ...state,
    discrepancies: null,
    loaders: {
      ...state.loaders,
      discrepancyData: true,
    },
  }),

  [DiscrepancyDetailsTypes.MAKE_HEARING_PARTICIPATIONS_FILE_SUCCESS]: (state) => ({
    ...state,
    loaders: {
      ...state.loaders,
      participantsFile: false,
    },
  }),
  [DiscrepancyDetailsTypes.MAKE_HEARING_PARTICIPATIONS_FILE]: (state) => ({
    ...state,
    loaders: {
      ...state.loaders,
      participantsFile: true,
    },
  }),
  [DiscrepancyDetailsTypes.MAKE_HEARING_PARTICIPATIONS_FILE_FAIL]: (state) => ({
    ...state,
    loaders: {
      ...state.loaders,
      participantsFile: false,
    },
  }),

  [DiscrepancyDetailsTypes.DOWNLOAD_ALL_FILES_REQUEST]: (state) => ({
    ...state,
    loaders: {
      ...state.loaders,
      participantsFile: false,
    },
  }),
  [DiscrepancyDetailsTypes.GET_HEARING_PARTICIPATIONS_FILE_SUCCESS]: (state) => ({
    ...state,
    loaders: {
      ...state.loaders,
      participantsFile: false,
    },
  }),
  [DiscrepancyDetailsTypes.GET_HEARING_PARTICIPATIONS_FILE]: (state) => ({
    ...state,
    loaders: {
      ...state.loaders,
      participantsFile: true,
    },
  }),
  [DiscrepancyDetailsTypes.GET_HEARING_PARTICIPATIONS_FILE_FAIL]: (state) => ({
    ...state,
    loaders: {
      ...state.loaders,
      participantsFile: false,
    },
  }),
  [DiscrepancyDetailsTypes.CONTROLS_CHANGED]: (state, action) => ({
    ...state.controls,
    ...action.controls,
  }),
  [DiscrepancyDetailsTypes.CHANGE_DOCUMENT_CREATION_CONTROLS]: (state, action) => ({
    ...state,
    documentStepper: {
      ...state.documentStepper,
      controls: {
        ...state.documentStepper.controls,
        [action.payload.name]: action.payload.value,
      },
    },
  }),
  [DiscrepancyDetailsTypes.RESET_DOCUMENT_CREATION_CONTROLS]: (state, action) => ({
    ...state,
    documentStepper: {
      ...state.documentStepper,
      form: {
        ...initialState.documentStepper.form,
      },
    },
  }),
  [DiscrepancyDetailsTypes.CHANGE_ATTACHMENT_MODAL_CONTROLS]: (state, action) => ({
    ...state,
    documentStepper: {
      ...state.documentStepper,
      modalControls: {
        ...state.documentStepper.modalControls,
        [action.payload.name]: action.payload.value,
      },
    },
  }),
  [DiscrepancyDetailsTypes.CHANGE_DOCUMENT_FORM]: (state, action) => ({
    ...state,
    documentStepper: {
      ...state.documentStepper,
      form: {
        ...state.documentStepper.form,
        [action.payload.name]: action.payload.value,
      },
    },
  }),
  [DiscrepancyDetailsTypes.TOGGLE_STEPPER]: (state) => ({
    ...state,
    documentModal: !state.documentModal,
  }),
  [DiscrepancyDetailsTypes.CREATE_DOCUMENT]: (state) => ({
    ...state,
    documentStepper: {
      ...state.documentStepper,
      loaders: {
        ...state.documentStepper.form,
        create: true,
      },
    },
  }),
  [DiscrepancyDetailsTypes.CREATE_DOCUMENT_FAIL]: (state) => ({
    ...state,
    documentStepper: {
      ...state.documentStepper,
      loaders: {
        ...state.documentStepper.form,
        create: false,
      },
    },
  }),
  [DiscrepancyDetailsTypes.CREATE_DOCUMENT_SUCCESS]: (state) => ({
    ...state,
    documentStepper: {
      ...initialState.documentStepper,
    },
  }),
  [DiscrepancyDetailsTypes.CREATE_AUDIENCE_DOCUMENT_SUCCESS]: (state) => ({
    ...state,
    documentStepper: {
      ...initialState.documentStepper,
    },
  }),
  [DiscrepancyDetailsTypes.UPDATE_DOCUMENTS_VALUES]: (state, action) => ({
    ...state,
    document: {
      ...state.document,
      loaders: {
        ...state.document.loaders,
        edition: true,
      },
      form: {
        ...state.document.form,
        ...action.payload,
      },
    },
  }),
  [DiscrepancyDetailsTypes.UPDATE_DOCUMENTS_VALUES_SUCCESS]: (state) => ({
    ...state,
    document: {
      ...state.document,
      controls: {
        ...state.document.controls,
        openEditDialog: false,
        activeStep: -1,
      },
      loaders: {
        ...state.document.loaders,
        edition: false,
      },
      form: {
        ...state.document.form,
        ...initialState.document.form,
      },
    },
  }),
  [DiscrepancyDetailsTypes.SET_DOCUMENT_FORM_VALUES]: (state, action) => ({
    ...state,
    document: {
      ...state.document,
      form: {
        ...state.document.form,
        ...action.payload,
      },
    },
  }),
  [DiscrepancyDetailsTypes.CHANGE_DOCUMENT_CONTROLS]: (state, action) => ({
    ...state,
    document: {
      ...state.document,
      controls: {
        ...state.document.controls,
        [action.payload.name]: action.payload.value,
      },
    },
  }),
  [DiscrepancyDetailsTypes.CHANGE_TWO_OR_MORE_DOCUMENT_CONTROLS]: (state, action) => ({
    ...state,
    document: {
      ...state.document,
      controls: {
        ...state.document.controls,
        ...action.payload,
      },
    },
  }),
  [DiscrepancyDetailsTypes.CHANGE_DISCREPANCY_CONTROLS]: (state, action) => ({
    ...state,
    discrepancy: {
      ...state.discrepancy,
      controls: {
        ...state.discrepancy.controls,
        [action.payload.name]: action.payload.value,
      },
    },
  }),
  [DiscrepancyDetailsTypes.DELETE_DOCUMENT]: (state) => changeDocumentDeleteLoader(state, true),
  [DiscrepancyDetailsTypes.DELETE_DOCUMENT_FAIL]: (state) =>
    changeDocumentDeleteLoader(state, false),
  [DiscrepancyDetailsTypes.DELETE_DOCUMENT_SUCCESS]: (state) => ({
    ...state,
    document: {
      ...state.document,
      controls: { ...state.document.controls, openDeleteDialog: false },
      loaders: { ...state.document.loaders, delete: false },
    },
  }),
  [DiscrepancyDetailsTypes.NOTIFICATE_DISCREPANCY]: (state) => setNotificationLoader(state, true),
  [DiscrepancyDetailsTypes.NOTIFICATE_DISCREPANCY_SUCCESS]: (state) =>
    setNotificationLoader(state, false),
  [DiscrepancyDetailsTypes.NOTIFICATE_DISCREPANCY_FAIL]: (state) =>
    setNotificationLoader(state, false),
  [DiscrepancyDetailsTypes.OPEN_UPDATE_DOCUMENT_DIALOG]: (state, action) => ({
    ...state,
    documentStepper: {
      ...state.documentStepper,
      modalControls: {
        ...state.documentStepper.modalControls,
        openDialog: true,
        idx: action.payload.idx,
      },
    },
  }),
  [DiscrepancyDetailsTypes.UPDATE_ATTACHMENT_FILES]: (state) => ({
    ...state,
    document: {
      ...state.document,
      loaders: {
        ...state.document.loaders,
        edition: true,
      },
    },
  }),
  [DiscrepancyDetailsTypes.UPDATE_ATTACHMENT_PART]: (state) => ({
    ...state,
    document: {
      ...state.document,
      loaders: {
        ...state.document.loaders,
        edition: true,
      },
    },
  }),
  [DiscrepancyDetailsTypes.UPDATE_ATTACHMENT_PART_SUCCESS]: (state) => ({
    ...state,
    document: {
      ...state.document,
      loaders: {
        ...state.document.loaders,
        edition: false,
      },
    },
  }),
  [DiscrepancyDetailsTypes.UPDATE_ATTACHMENT_PART_FAIL]: (state) => ({
    ...state,
    document: {
      ...state.document,
      loaders: {
        ...state.document.loaders,
        edition: false,
      },
    },
  }),
  [DiscrepancyDetailsTypes.UPDATE_ATTACHMENT_FILES_SUCCESS]: (state) => ({
    ...state,
    document: {
      ...state.document,
      loaders: {
        ...state.document.loaders,
        edition: false,
      },
      controls: {
        ...state.document.controls,
      },
    },
  }),
  [DiscrepancyDetailsTypes.UPDATE_ATTACHMENT_FILES_FAIL]: (state) => ({
    ...state,
    document: {
      ...state.document,
      loaders: {
        ...state.document.loaders,
        edition: false,
      },
    },
  }),
  [DiscrepancyDetailsTypes.CREATE_ATTACHMENT_PART]: (state) => ({
    ...state,
    document: {
      ...state.document,
      loaders: {
        ...state.document.loaders,
        edition: true,
      },
    },
  }),
  [DiscrepancyDetailsTypes.CREATE_ATTACHMENT_PART_SUCCESS]: (state) => ({
    ...state,
    document: {
      ...state.document,
      loaders: {
        ...state.document.loaders,
        edition: false,
      },
      controls: {
        ...state.document.controls,
      },
    },
  }),
  [DiscrepancyDetailsTypes.CREATE_ATTACHMENT_PART_FAIL]: (state) => ({
    ...state,
    document: {
      ...state.document,
      loaders: {
        ...state.document.loaders,
        edition: false,
      },
    },
  }),
  [DiscrepancyDetailsTypes.DELETE_ATTACHMENT_PART]: (state) => ({
    ...state,
    document: {
      ...state.document,
      loaders: {
        ...state.document.loaders,
        delete: true,
      },
    },
  }),
  [DiscrepancyDetailsTypes.DELETE_ATTACHMENT_PART_SUCCESS]: (state) => ({
    ...state,
    document: {
      ...state.document,
      loaders: {
        ...state.document.loaders,
        delete: false,
      },
      controls: {
        ...state.document.controls,
      },
    },
  }),
  [DiscrepancyDetailsTypes.CREATE_HEARING_PRESENTATION]: (state) => ({
    ...state,
    documentStepper: {
      ...state.documentStepper,
      loaders: {
        ...state.documentStepper.form,
        create: true,
      },
    },
  }),
  [DiscrepancyDetailsTypes.CREATE_HEARING_PRESENTATION_SUCCESS]: (state) => ({
    ...state,
    documentStepper: {
      ...state.documentStepper,
      ...initialState.documentStepper,
      loaders: {
        ...state.documentStepper.form,
        create: false,
      },
    },
  }),
  [DiscrepancyDetailsTypes.CREATE_HEARING_PRESENTATION_FAIL]: (state) => ({
    ...state,
    documentStepper: {
      ...state.documentStepper,
      loaders: {
        ...state.documentStepper.form,
        create: false,
      },
    },
  }),
  [DiscrepancyDetailsTypes.CHANGE_VIEW_TYPE]: (state, action) => ({
    ...state,
    view: action.payload.view,
  }),
  [DiscrepancyDetailsTypes.SELECT_VIEW_DOCUMENT]: (state, action) => ({
    ...state,
    viewDocument: action.payload.document,
  }),
  [DiscrepancyDetailsTypes.CONTROLS_CHANGED_DOCUMENT_FILTERS]: (state, action) => ({
    ...state,
    documentFilters: {
      ...state.documentFilters,
      [action.payload.name]: action.payload.value,
    },
  }),
  [DiscrepancyDetailsTypes.HANDLE_DOCUMENT_BANNER]: (state, action) => ({
    ...state,
    openDocumentBanner: action.payload.open,
  }),
  [DiscrepancyDetailsTypes.SELECT_DISCREPANCIES_CHANGE]: (state, action) => ({
    ...state,
    documentStepper: {
      ...state.documentStepper,
      form: {
        ...state.documentStepper.form,
        [action.payload.name]: [
          ...state.documentStepper.form.copyToDiscrepancies,
          action.payload.value,
        ],
      },
    },
  }),
  [DiscrepancyDetailsTypes.REMOVE_COPIED_DISCREPANCY]: (state, action) => ({
    ...state,
    documentStepper: {
      ...state.documentStepper,
      form: {
        ...state.documentStepper.form,
        [action.payload.name]: [...action.payload.value],
      },
    },
  }),
  [DiscrepancyDetailsTypes.HANDLE_DOWNLOAD_ZIP_DIALOG]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      openDownloadDialog: action.payload.open,
    },
  }),

  [DiscrepancyDetailsTypes.RESET_DOCUMENT_FILTERS]: (state, action) => ({
    ...state,
    documentFilters: {
      ...initialState.documentFilters,
    },
    //
  }),
});

export default discrepancyDetails;
