const BASE = '[DISCREPANCY_DETAILS]';

const DiscrepancyDetailsTypes = {
  GET_DISCREPANCY: `${BASE} GET_DISCREPANCY`,
  GET_DISCREPANCY_SUCCESS: `${BASE} GET_DISCREPANCY_SUCCESS`,
  GET_DISCREPANCY_FAIL: `${BASE} GET_DISCREPANCY_FAIL`,
  DISABLE_ERRORS: `${BASE} DISABLE_ERRORS`,
  CONTROLS_CHANGED: `${BASE} CONTROLS_CHANGED`,
  CHANGE_DOCUMENT_FORM: `${BASE} CHANGE_DOCUMENT_FORM`,
  CHANGE_DOCUMENT_CREATION_CONTROLS: `${BASE} CHANGE_DOCUMENT_CREATION_CONTROLS`,
  CREATE_DOCUMENT: `${BASE} CREATE_DOCUMENT`,
  CREATE_DOCUMENT_SUCCESS: `${BASE} CREATE_DOCUMENT_SUCCESS`,
  CREATE_DOCUMENT_FAIL: `${BASE} CREATE_DOCUMENT_FAIL`,
  TOGGLE_STEPPER: `${BASE} TOGGLE_STEPPER`,
  CHANGE_ATTACHMENT_MODAL_CONTROLS: `${BASE} CHANGE_ATTACHMENT_MODAL_CONTROLS`,
  CHANGE_DISCREPANCY_CONTROLS: `${BASE} CHANGE_DISCREPANCY_CONTROLS`,
  CHANGE_DOCUMENT_CONTROLS: `${BASE} CHANGE_DOCUMENT_CONTROLS`,
  CHANGE_TWO_OR_MORE_DOCUMENT_CONTROLS: `${BASE} CHANGE_TWO_OR_MORE_DOCUMENT_CONTROLS`,
  DELETE_DOCUMENT: `${BASE} DELETE_DOCUMENT`,
  DELETE_DOCUMENT_SUCCESS: `${BASE} DELETE_DOCUMENT_SUCCESS`,
  DELETE_DOCUMENT_FAIL: `${BASE} DELETE_DOCUMENT_FAIL`,
  NOTIFICATE_DISCREPANCY: `${BASE} NOTIFICATE_DISCREPANCY`,
  NOTIFICATE_DISCREPANCY_SUCCESS: `${BASE} NOTIFICATE_DISCREPANCY_SUCCESS`,
  NOTIFICATE_DISCREPANCY_FAIL: `${BASE} NOTIFICATE_DISCREPANCY_FAIL`,
  OPEN_UPDATE_DOCUMENT_DIALOG: `${BASE} OPEN_UPDATE_DOCUMENT_DIALOG`,
  UPDATE_DOCUMENTS_VALUES: `${BASE} UPDATE_DOCUMENTS_VALUES`,
  UPDATE_DOCUMENTS_VALUES_SUCCESS: `${BASE} UPDATE_DOCUMENTS_VALUES_SUCCESS`,
  UPDATE_DOCUMENTS_VALUES_FAIL: `${BASE} UPDATE_DOCUMENTS_VALUES_FAIL`,
  SET_DOCUMENT_FORM_VALUES: `${BASE} SET_DOCUMENT_FORM_VALUES`,
  UPDATE_ATTACHMENT_FILES: `${BASE} UPDATE_ATTACHMENT_FILES`,
  UPDATE_ATTACHMENT_FILES_SUCCESS: `${BASE} UPDATE_ATTACHMENT_FILES_SUCCESS`,
  UPDATE_ATTACHMENT_FILES_FAIL: `${BASE} UPDATE_ATTACHMENT_FILES_FAIL`,
  DELETE_ATTACHMENT_PART: `${BASE} DELETE_ATTACHMENT_PART`,
  DELETE_ATTACHMENT_PART_SUCCESS: `${BASE} DELETE_ATTACHMENT_PART_SUCCESS`,
  DELETE_ATTACHMENT_PART_FAIL: `${BASE} DELETE_ATTACHMENT_PART_FAIL`,
  CREATE_ATTACHMENT_PART: `${BASE} CREATE_ATTACHMENT_PART`,
  CREATE_ATTACHMENT_PART_SUCCESS: `${BASE} CREATE_ATTACHMENT_PART_SUCCESS`,
  CREATE_ATTACHMENT_PART_FAIL: `${BASE} CREATE_ATTACHMENT_PART_FAIL`,
  UPDATE_ATTACHMENT_PART: `${BASE} UPDATE_ATTACHMENT_PART`,
  UPDATE_ATTACHMENT_PART_SUCCESS: `${BASE} UPDATE_ATTACHMENT_PART_SUCCESS`,
  UPDATE_ATTACHMENT_PART_FAIL: `${BASE} UPDATE_ATTACHMENT_PART_FAIL`,
  REMOVE_DISCREPANCY: `${BASE} REMOVE_DISCREPANCY`,
  CREATE_AUDIENCE_DOCUMENT: `${BASE} CREATE_AUDIENCE_DOCUMENT`,
  CREATE_AUDIENCE_DOCUMENT_SUCCESS: `${BASE} CREATE_AUDIENCE_DOCUMENT_SUCCESS`,
  CREATE_AUDIENCE_DOCUMENT_FAIL: `${BASE} CREATE_AUDIENCE_DOCUMENT_FAIL`,
  CREATE_HEARING_PRESENTATION: `${BASE} CREATE_HEARING_PRESENTATION`,
  CREATE_HEARING_PRESENTATION_SUCCESS: `${BASE} CREATE_HEARING_PRESENTATION_SUCCESS`,
  CREATE_HEARING_PRESENTATION_FAIL: `${BASE} CREATE_HEARING_PRESENTATION_FAIL`,
  GET_HEARING_PARTICIPATIONS_FILE: `${BASE} GET_HEARING_PARTICIPATIONS_FILE`,
  GET_HEARING_PARTICIPATIONS_FILE_SUCCESS: `${BASE} GET_HEARING_PARTICIPATIONS_FILE_SUCCESS`,
  GET_HEARING_PARTICIPATIONS_FILE_FAIL: `${BASE} GET_HEARING_PARTICIPATIONS_FILE_FAIL`,
  MAKE_HEARING_PARTICIPATIONS_FILE: `${BASE} MAKE_HEARING_PARTICIPATIONS_FILE`,
  MAKE_HEARING_PARTICIPATIONS_FILE_SUCCESS: `${BASE} MAKE_HEARING_PARTICIPATIONS_FILE_SUCCESS`,
  MAKE_HEARING_PARTICIPATIONS_FILE_FAIL: `${BASE} MAKE_HEARING_PARTICIPATIONS_FILE_FAIL`,
  CHANGE_VIEW_TYPE: `${BASE} CHANGE_VIEW_TYPE`,
  SELECT_VIEW_DOCUMENT: `${BASE} SELECT_VIEW_DOCUMENT`,
  CONTROLS_CHANGED_DOCUMENT_FILTERS: `${BASE} CONTROLS_CHANGED_DOCUMENT_FILTERS`,
  HANDLE_DOCUMENT_BANNER: `${BASE} HANDLE_DOCUMENT_BANNER`,
  SELECT_DISCREPANCIES_CHANGE: `${BASE} SELECT_DISCREPANCIES_CHANGE`,
  REMOVE_COPIED_DISCREPANCY: `${BASE} REMOVE_COPIED_DISCREPANCY`,
  COPY_FILES_TO_DISCREPANCIES: `${BASE} COPY_FILES_TO_DISCREPANCIES`,
  COPY_FILES_TO_DISCREPANCIES_SUCCESS: `${BASE} COPY_FILES_TO_DISCREPANCIES_SUCCESS`,
  COPY_FILES_TO_DISCREPANCIES_FAIL: `${BASE} COPY_FILES_TO_DISCREPANCIES_FAIL`,
  HANDLE_DOWNLOAD_ZIP_DIALOG: `${BASE} HANDLE_DOWNLOAD_ZIP_DIALOG`,
  RESET_DOCUMENT_CREATION_CONTROLS: `${BASE} RESET_DOCUMENT_CREATION_CONTROLS`,
  RESET_DOCUMENT_FILTERS: `${BASE} RESET_DOCUMENT_FILTERS`,
  DOWNLOAD_ALL_FILES_REQUEST: `${BASE} DOWNLOAD_ALL_FILES_REQUEST`,
};

export default DiscrepancyDetailsTypes;
