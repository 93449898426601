import React, { useEffect, useMemo } from 'react';

/* Material */
import { CircularProgress, Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';

/* React Redux */
import { useDispatch, useSelector } from 'react-redux';

/* Project */
import DiscrepanciesList from 'commons/components/DiscrepanciesList/DiscrepanciesList';
import TitleField from 'commons/components/TitleField/TitleField';
import Filters from 'commons/components/Filters/Filters';
import { getDiscrepancies } from 'screens/Home/Home.actions';
import { reselectObjects } from 'utils/helper';
import { stateList, orderList } from './Home.helper';

/* Styles */
import homeStyles from './Home.styles';

const useStyles = makeStyles(homeStyles);

function Home() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { home: homeState, app: appState } = useSelector((state) => state);
  const { objects: objectsState } = appState;

  const objects = useMemo(() => reselectObjects(objectsState), [objectsState]);

  const { loaders, discrepanciesIds, filters, pagination } = homeState;

  const filteredDiscrepancies = Object.values(objects.discrepancies).filter((d) =>
    discrepanciesIds.includes(d.id),
  );

  useEffect(() => {
    dispatch(getDiscrepancies({ filters, pagination }));
  }, [pagination.page, appState.user]);

  const legalMatterList = Object.values(objects.legalMatters);
  const legalSubMatterList = !filters.legalMatter ? [] : Object.values(objects.legalSubMatters);
  return (
    <Container maxWidth={false} sx={{ m: 0 }}>
      <Typography variant="h6" color="initial" sx={{ fontSize: '16px', fontWeight: 500 }}>
        Buscador de discrepancias
      </Typography>
      <Filters
        legalMatterList={legalMatterList}
        stateList={stateList}
        orderList={orderList}
        legalSubMatterList={legalSubMatterList}
        filters={filters}
        pagination={pagination}
      />

      <Grid container direction="column">
        <Grid container rowSpacing={2} sx={{ overflow: 'auto', pb: 3, maxHeight: '65vh' }}>
          {loaders.list ? (
            <CircularProgress size={30} sx={{ margin: 'auto', marginTop: '1rem' }} />
          ) : (
            <DiscrepanciesList
              discrepanciesIds={discrepanciesIds}
              discrepanciesList={objects.discrepancies}
              filters={filters}
              loading={loaders.list}
              pagination={pagination}
              objects={objects}
            />
          )}
        </Grid>
      </Grid>
    </Container>
  );
}

export default Home;
