import camelize from 'camelcase-keys-deep';

export default class Entity {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  user() {
    return this.env().User.getById(this.userId);
  }

  legalEntity() {
    return this.env().LegalEntity.findBy('entityId', this.id);
  }
}
