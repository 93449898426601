import { createStore, compose } from 'redux';
import { persistReducer } from 'redux-persist';
import { createContext } from 'react';
import localForage from 'localforage';

import enhancers, { sagaMiddleware } from './middleware';
import mainSaga from '../main.saga';
import reducers from '../reducers';

export const ReactReduxContext = createContext({});

export default function configureStore(initialState = {}) {
  const persistConfig = {
    key: 'root',
    storage: localForage,
    whitelist: [],
  };

  const pReducer = persistReducer(persistConfig, reducers);

  const store = createStore(
    pReducer,
    initialState,
    compose(
      enhancers,
      /* eslint-disable no-underscore-dangle */
      typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : (f) => f,
      /* eslint-enable */
    ),
  );

  // Extensions
  sagaMiddleware.run(mainSaga);
  store.injectedSagas = {}; // Saga registry

  return store;
}
