import { newReducer } from 'utils/reducer';
import searchContentTypes from './SearchContent.types';

const initialState = {
  controls: {
    dateStart: null,
    dateEnd: null,
    keyword: [],
    exclude: [],
  },
  pagination: {
    count: 0,
    page: 1,
    size: 15,
    total: false,
  },
  loading: false,
  results: [],
  selectedFileDownloadLink: '',
  selectedFileName: '',
};

const searchContent = newReducer(initialState, {
  [searchContentTypes.HANDLE_CHANGE]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      [action.payload.name]: action.payload.value,
    },
  }),
  [searchContentTypes.RESET_CONTROLS]: (state, action) => ({
    ...state,
    controls: {
      ...initialState.controls,
    },
    pagination: {
      ...state.pagination,
      total: false,
    },
    selectedFileDownloadLink: '',
    selectedFileName: '',
  }),
  [searchContentTypes.GET_DOCUMENTS]: (state, action) => ({
    ...state,
    loading: true,
  }),
  [searchContentTypes.GET_DOCUMENTS_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    results: action.payload.values,
    pagination: {
      ...state.pagination,
      page: action.payload.page,
      count: action.payload.count,
      total: action.payload.total,
    },
  }),
  [searchContentTypes.CHANGE_PAGINATION]: (state, action) => ({
    ...state,
    pagination: {
      ...state.pagination,
      page: action.payload.value,
    },
  }),
  [searchContentTypes.GET_FILE_LINK]: (state, action) => ({
    ...state,
    selectedFileDownloadLink: '',
    selectedFileName: '',
  }),
  [searchContentTypes.GET_FILE_LINK_SUCCESS]: (state, action) => ({
    ...state,
    selectedFileDownloadLink: action.payload.downloadLink,
    selectedFileName: action.payload.fileName,
  }),
  [searchContentTypes.GET_FILE_LINK_FAIL]: (state, action) => ({
    ...state,
    selectedFileDownloadLink: '',
    selectedFileName: '',
  }),
});

export default searchContent;
