import React, { useState } from 'react';
import {
  Autocomplete,
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import PrimaryButton from 'commons/components/PrimaryButton/PrimaryButton';
import { useDispatch, useSelector } from 'react-redux';
import { getAll, handleChange, resetControls } from '../SearchContent.actions';
import SecondaryButton from 'commons/components/SecondaryButton/SecondaryButton';
import moment from 'moment';

const Filters = (props) => {
  const dispatch = useDispatch();
  const { controls, pagination, loading } = props;
  const { legalMatters, legalSubMatters } = useSelector((state) => state.app.objects);
  const { dateStart, dateEnd, keyword, exclude } = controls;
  const [dateErrorMessage, setDateErrorMessage] = useState({ startDate: '', endDate: '' });

  const handleOnChange = (name, value) => {
    if (
      (name === 'dateStart' && dateErrorMessage.startDate !== '') ||
      (name === 'dateEnd' && dateErrorMessage.endDate !== '')
    ) {
      setDateErrorMessage({ startDate: '', endDate: '' });
    }
    return dispatch(handleChange({ name, value }));
  };

  const getDocuments = () => {
    const startDate = dateStart ? moment(dateStart, 'YYYY-MM-DD HH:mm', true) : null;
    const endDate = dateEnd ? moment(dateEnd, 'YYYY-MM-DD HH:mm', true) : null;

    let startDateErrorMessage = '';
    let endDateErrorMessage = '';

    if (startDate && !startDate.isValid()) {
      startDateErrorMessage = 'Formato de fecha inválido';
    }

    if (endDate && !endDate.isValid()) {
      endDateErrorMessage = 'Formato de fecha inválido';
    }

    setDateErrorMessage({
      startDate: startDateErrorMessage,
      endDate: endDateErrorMessage,
    });

    if (!startDateErrorMessage && !endDateErrorMessage) {
      dispatch(getAll({ ...controls, ...pagination }));
    }
  };

  const cleanControl = () => {
    setDateErrorMessage({
      startDate: '',
      endDate: '',
    });
    dispatch(resetControls())
  };

  const searchValidation =
    !controls.dateEnd &&
    !controls.dateStart &&
    !controls.exclude.length &&
    !controls.keyword.length;

  return (
    <Grid container sx={{ mt: 2 }} spacing={3}>
      <Grid item xs={12} sm={6}>
        <DesktopDatePicker
          label="Desde"
          inputFormat="dd/MM/yyyy"
          maxDate={dateEnd}
          value={dateStart}
          onChange={(value) => handleOnChange('dateStart', value)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              fullWidth
              error={dateErrorMessage.startDate !== ''}
              helperText={dateErrorMessage.startDate}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DesktopDatePicker
          label="Hasta"
          inputFormat="dd/MM/yyyy"
          minDate={dateStart}
          value={dateEnd}
          onChange={(value) => handleOnChange('dateEnd', value)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              fullWidth
              error={dateErrorMessage.endDate !== ''}
              helperText={dateErrorMessage.endDate}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Autocomplete
          clearIcon={false}
          onChange={(event, value) => handleOnChange('keyword', value)}
          options={[]}
          autoSelect
          freeSolo
          multiple
          value={keyword}
          renderTags={(value, props) =>
            value.map((option, index) => (
              <Chip label={option} {...props({ index })} sx={{ maxWidth: '400px!important' }} />
            ))
          }
          renderInput={(params) => (
            <TextField label="Palabra clave" {...params} variant="standard" />
          )}
        />
        <FormHelperText>Oprimir enter después de tipear para agregar una palabra</FormHelperText>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Autocomplete
          clearIcon={false}
          onChange={(event, value) => handleOnChange('exclude', value)}
          options={[]}
          autoSelect
          freeSolo
          multiple
          value={exclude}
          renderTags={(value, props) =>
            value.map((option, index) => <Chip label={option} {...props({ index })} />)
          }
          renderInput={(params) => <TextField label="Excluye" {...params} variant="standard" />}
        />
        <FormHelperText>Oprimir enter después de tipear para agregar una palabra</FormHelperText>
      </Grid>
      <Grid
        item
        xs={12}
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
        spacing={3}>
        <Grid item>
          <SecondaryButton
            text="limpiar"
            secondaryStyles
            onClick={() => cleanControl()}
            disabled={searchValidation || loading}
          />
        </Grid>
        <Grid item>
          <PrimaryButton
            text="Buscar"
            secondaryStyles
            onClick={getDocuments}
            disabled={searchValidation || loading}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Filters;
