const base = '[COMMONS]';

const commonsTypes = {
  CHANGE_DISCREPANCY_FORM: `${base} CHANGE_DISCREPANCY_FORM`,
  CHANGE_DISCREPANCY_CREATION_CONTROLS: `${base} CHANGE_DISCREPANCY_CREATION_CONTROLS`,
  CREATE_DISCREPANCY: `${base} CREATE_DISCREPANCY`,
  CREATE_DISCREPANCY_SUCCESS: `${base} CREATE_DISCREPANCY_SUCCESS`,
  CREATE_DISCREPANCY_FAIL: `${base} CREATE_DISCREPANCY_FAIL`,

  CHANGE_LEGAL_ENTITY_FORM: `${base} CHANGE_LEGAL_ENTITY_FORM`,
  CLEAR_LEGAL_ENTITY_FORM: `${base} CLEAR_LEGAL_ENTITY_FORM`,
  CHANGE_LEGAL_ENTITY_CREATION_CONTROLS: `${base} CHANGE_LEGAL_ENTITY_CREATION_CONTROLS`,
  CREATE_LEGAL_ENTITY: `${base} CREATE_LEGAL_ENTITY`,
  CREATE_LEGAL_ENTITY_SUCCESS: `${base} CREATE_LEGAL_ENTITY_SUCCESS`,
  CREATE_LEGAL_ENTITY_FAIL: `${base} CREATE_LEGAL_ENTITY_FAIL`,

  CHANGE_NATURAL_ENTITY_FORM: `${base} CHANGE_NATURAL_ENTITY_FORM`,
  CHANGE_NATURAL_ENTITY_CREATION_CONTROLS: `${base} CHANGE_NATURAL_ENTITY_CREATION_CONTROLS`,
  CREATE_NATURAL_ENTITY: `${base} CREATE_NATURAL_ENTITY`,
  CREATE_NATURAL_ENTITY_SUCCESS: `${base} CREATE_NATURAL_ENTITY_SUCCESS`,
  CREATE_NATURAL_ENTITY_FAIL: `${base} CREATE_NATURAL_ENTITY_FAIL`,

  CHANGE_LEGAL_POWERS_MODAL_CONTROLS: `${base} CHANGE_LEGAL_POWERS_MODAL_CONTROLS`,
  CHANGE_REPRESENTATIVES_MODAL_CONTROLS: `${base} CHANGE_REPRESENTATIVES_MODAL_CONTROLS`,

  TOGGLE_UPDATE_DISCREPANCY_MODAL: `${base} TOGGLE_UPDATE_DISCREPANCY_MODAL`,
  UPDATE_DISCREPANCY: `${base} UPDATE_DISCREPANCY`,
  UPDATE_DISCREPANCY_SUCCESS: `${base} UPDATE_DISCREPANCY_SUCCESS`,
  UPDATE_DISCREPANCY_FAIL: `${base} UPDATE_DISCREPANCY_FAIL`,

  CHANGE_EDIT_NATURAL_ENTITY_FORM: `${base} CHANGE_EDIT_NATURAL_ENTITY_FORM`,
  CHANGE_EDIT_NATURAL_ENTITY_CONTROLS: `${base} CHANGE_EDIT_NATURAL_ENTITY_CONTROLS`,
  EDIT_NATURAL_ENTITY: `${base} EDIT_NATURAL_ENTITY`,
  EDIT_NATURAL_ENTITY_SUCCESS: `${base} EDIT_NATURAL_ENTITY_SUCCESS`,
  EDIT_NATURAL_ENTITY_FAIL: `${base} EDIT_NATURAL_ENTITY_FAIL`,

  CHANGE_EDIT_LEGAL_ENTITY_FORM: `${base} CHANGE_EDIT_LEGAL_ENTITY_FORM`,
  CHANGE_EDIT_LEGAL_ENTITY_CONTROLS: `${base} CHANGE_EDIT_LEGAL_ENTITY_CONTROLS`,
  EDIT_LEGAL_ENTITY: `${base} EDIT_LEGAL_ENTITY`,
  EDIT_LEGAL_ENTITY_SUCCESS: `${base} EDIT_LEGAL_ENTITY_SUCCESS`,
  EDIT_LEGAL_ENTITY_FAIL: `${base} EDIT_LEGAL_ENTITY_FAIL`,

  CHANGE_EDIT_LEGAL_POWERS_MODAL_CONTROLS: `${base} CHANGE_EDIT_LEGAL_POWERS_MODAL_CONTROLS`,
  CHANGE_EDIT_REPRESENTATIVES_MODAL_CONTROLS: `${base} CHANGE_EDIT_REPRESENTATIVES_MODAL_CONTROLS`,

  LOGOUT: `${base} LOGOUT`,

  SET_LEGAL_ENTITY_ERROR_FORMS: `${base} SET_LEGAL_ENTITY_ERROR_FORMS`,

  SET_NATURAL_ENTITY_ERROR_FORMS: `${base} SET_NATURAL_ENTITY_ERROR_FORMS`,

  DELETE_DISCREPANCY: `${base} DELETE_DISCREPANCY`,
  DELETE_DISCREPANCY_SUCCESS: `${base} DELETE_DISCREPANCY_SUCCESS`,
  DELETE_DISCREPANCY_FAIL: `${base} DELETE_DISCREPANCY_FAIL`,

  OPEN_CONFIRM_DELETE_LEGAL_POWER_DIALOG: `${base} OPEN_CONFIRM_DELETE_LEGAL_POWER_DIALOG`,
  CLOSE_CONFIRM_DELETE_LEGAL_POWER_DIALOG: `${base} CLOSE_CONFIRM_DELETE_LEGAL_POWER_DIALOG`,

  DELETE_LEGAL_POWER: `${base} DELETE_LEGAL_POWER`,
  DELETE_LEGAL_POWER_SUCCESS: `${base} DELETE_LEGAL_POWER_SUCCESS`,
  DELETE_LEGAL_POWER_FAIL: `${base} DELETE_LEGAL_POWER_FAIL`,

  CREATE_LEGAL_POWER: `${base} CREATE_LEGAL_POWER`,
  CREATE_LEGAL_POWER_SUCCESS: `${base} CREATE_LEGAL_POWER_SUCCESS`,
  CREATE_LEGAL_POWER_FAIL: `${base} CREATE_LEGAL_POWER_FAIL`,

  OPEN_GENERAL_ALERT: `${base} OPEN_GENERAL_ALERT`,
  CLOSE_GENERAL_ALERT: `${base} CLOSE_GENERAL_ALERT`,

  CHANGE_DISCREPANCY_MESSAGE_CONTROLS: `${base} CHANGE_DISCREPANCY_MESSAGE_CONTROLS`,
  OPEN_DISCREPANCY_MESSAGE_DIALOG: `${base} OPEN_DISCREPANCY_MESSAGE_DIALOG`,
  UPDATE_DISCREPANCY_MESSAGE: `${base} UPDATE_DISCREPANCY_MESSAGE`,
  UPDATE_DISCREPANCY_MESSAGE_SUCCESS: `${base} UPDATE_DISCREPANCY_MESSAGE_SUCCESS`,
  UPDATE_DISCREPANCY_MESSAGE_FAIL: `${base} UPDATE_DISCREPANCY_MESSAGE_FAIL`,
  LOAD_DISCREPANCY_MESSAGE: `${base} LOAD_DISCREPANCY_MESSAGE`,
};

export default commonsTypes;
