const PATH = '[LOGIN]';

const LoginTypes = {
  LOGIN_USER_SUCCESS: `${PATH} FETCH_LOGIN_USER_SUCCESS`,
  LOGIN_USER_FAIL: `${PATH} FETCH_LOGIN_USER_FAIL`,
  LOGIN_USER: `${PATH} FETCH_LOGIN_USER`,
  GET_USER_SUCCESS: `${PATH} GET_USER_SUCCESS`,
  GET_USER_FAIL: `${PATH} GET_USER_FAIL`,
  GET_USER: `${PATH} GET_USER`,
};

export default LoginTypes;
