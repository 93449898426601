import { takeLatest, spawn, put } from 'redux-saga/effects';

import apiRequest, { apiSuccess } from 'utils/api';
import * as APP from 'commons/commons.reducer';
import LoginTypes from 'screens/Login/Login.types';
import { appActions } from 'commons/commons.reducer';

function* login() {
  yield takeLatest(LoginTypes.LOGIN_USER, function* loginUserApi(action) {
    const body = JSON.stringify(action.controls);
    const response = yield apiRequest('api/v1/auth/login', { method: 'post', body }, true, true);

    if (!response.error) {
      localStorage.setItem('user', JSON.stringify({ token: response.token }));
      yield put(apiSuccess(LoginTypes.LOGIN_USER_SUCCESS, response));
      yield put(appActions.fetchInitialState());
    } else {
      yield put(apiSuccess(LoginTypes.LOGIN_USER_FAIL, response));
      yield put(apiSuccess(APP.SET_ERROR, { msg: response.errorMsg }));
      yield put(appActions.fetchInitialState());
    }
  });
}

export default function* LoginSaga() {
  yield spawn(login);
}
