import React, { useState } from 'react';

/* Material UI */
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import { Delete, FileUpload } from '@mui/icons-material';

/* React redux */
import { useDispatch } from 'react-redux';

/* React router */
import { useParams } from 'react-router-dom';

// PROJECT
import PrimaryButton from 'commons/components/PrimaryButton/PrimaryButton';
import SecondaryButton from 'commons/components/SecondaryButton/SecondaryButton';
import CustomTextField from 'commons/components/CustomTextField/CustomTextField';
import { changeDocumentControls, updateAttachmentFiles } from '../../../DiscrepancyDetails.actions';
import styles from './styles';
import { isZippedFile } from 'utils/functions';
import { appSetError } from 'commons/commons.actions';

function UpdateDocumentsFormStepTwo(props) {
  const { publicPdf, fileMainWordName, fileMainConfidentialName, loading } = props;

  const dispatch = useDispatch();
  const { documentId } = useParams();

  const publicPdfName = publicPdf?.content;
  const oldPageNumberMainPdf = publicPdf?.pageNumber;

  const [fileMainPdf, setFileMainPdf] = useState({ name: publicPdf?.content });
  const [pageNumberMainpdf, setPageNumberMainPdf] = useState(publicPdf?.pageNumber);
  const [fileMainWord, setFileMainWord] = useState({ name: fileMainWordName });
  const [fileMainConfidential, setFileMainConfidential] = useState({
    file: { name: fileMainConfidentialName },
    deleteConfidential: false,
  });

  const closeDialog = () => {
    dispatch(changeDocumentControls({ name: 'openEditDialog', value: false }));
  };

  const handleUpdateDocument = () => {
    // TODO DISPATCH ACTIONS
    dispatch(
      updateAttachmentFiles({
        fileMainPdf,
        fileMainWord,
        fileMainConfidential,
        documentId,
        pageNumberMainpdf,
        publicPdfName,
        fileMainWordName,
        fileMainConfidentialName,
        oldPageNumberMainPdf,
      }),
    );
  };

  const handleFileChange = ({ target: { name, files } }) => {
    if (name === 'mainPdf') {
      if (files[0] && !isZippedFile(files[0].name) && files[0].type === 'application/pdf') {
        return setFileMainPdf(files[0]);
      } else {
        dispatch(appSetError({ msg: 'El archivo debe ser de tipo PDF' }));
      }
    }
    if (name === 'mainWord') {
      if (
        files[0] &&
        !isZippedFile(files[0].name) &&
        (files[0].type ===
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
          files[0].type === 'application/msword')
      ) {
        setFileMainWord(files[0]);
      } else {
        dispatch(appSetError({ msg: 'El archivo debe ser un documento .doc o .docx' }));
      }
    }
    if (name === 'mainConfidential') {
      if (files[0] && !isZippedFile(files[0].name)) {
        setFileMainConfidential((prevState) => ({
          ...prevState,
          file: files[0],
          deleteConfidential: false,
        }));
      } else {
        dispatch(appSetError({ msg: 'El archivo no puede ser una carpeta comprimida' }));
      }
    }
  };

  return (
    <>
      <DialogTitle sx={{ ...styles.title }}>Edición de archivos principales</DialogTitle>
      <DialogContent sx={{ ...styles.dialogContent }}>
        <Box className="formControlsContainer">
          <CustomTextField
            name="mainPdf"
            label="Archivo principal PDF"
            value={fileMainPdf?.name?.split('_').pop()}
            inputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <label htmlFor="mainPdf">
                    <input
                      accept="application/pdf"
                      id="mainPdf"
                      name="mainPdf"
                      type="file"
                      style={{ display: 'none' }}
                      onChange={handleFileChange}
                    />
                    <IconButton sx={{ ...styles.uploadIcon }} aria-label="mainPdf" component="span">
                      <FileUpload />
                    </IconButton>
                  </label>
                </InputAdornment>
              ),
            }}
          />
          <CustomTextField
            name="mainWord"
            label="Archivo principal WORD"
            value={fileMainWord?.name?.split('_').pop()}
            disabled={!Boolean(fileMainWord.name)}
            inputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <label htmlFor="mainWord">
                    <input
                      accept=".docx"
                      id="mainWord"
                      name="mainWord"
                      type="file"
                      style={{ display: 'none' }}
                      onChange={handleFileChange}
                    />
                    <IconButton
                      sx={{ ...styles.uploadIcon }}
                      aria-label="mainWord"
                      component="span">
                      <FileUpload />
                    </IconButton>
                  </label>
                </InputAdornment>
              ),
            }}
          />
          {/* INFO: Cliente requiere retirar la opción de agregar archivos confidenciales
          <CustomTextField
            name="mainConfidential"
            label="Archivo confidencial PDF"
            value={fileMainConfidential.file.name || ''}
            inputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <label htmlFor="mainConfidential">
                    <input
                      accept="application/pdf"
                      id="mainConfidential"
                      name="mainConfidential"
                      type="file"
                      style={{ display: 'none' }}
                      onChange={handleFileChange}
                    />
                    <IconButton
                      sx={{ ...styles.uploadIcon }}
                      aria-label="mainConfidential"
                      component="span">
                      <FileUpload />
                    </IconButton>
                  </label>
                  <IconButton
                    sx={{ ...styles.uploadIcon }}
                    aria-label="mainConfidential"
                    component="span"
                    onClick={() =>
                      setFileMainConfidential({ file: { name: '' }, deleteConfidential: true })
                    }>
                    <Delete />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          /> */}
          <TextField
            name="pageNumberMainPdf"
            label="Número de páginas del documento"
            size="small"
            fullWidth
            value={pageNumberMainpdf}
            onChange={({ target }) => setPageNumberMainPdf(target.value)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <SecondaryButton onClick={closeDialog}>Cerrar</SecondaryButton>
        <PrimaryButton onClick={handleUpdateDocument} loading={loading}>
          Modificar
        </PrimaryButton>
      </DialogActions>
    </>
  );
}

export default UpdateDocumentsFormStepTwo;
