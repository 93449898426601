import React from 'react';
import { Typography, Grid, Card, CardContent, CardMedia, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import styles from './styles';

function ErrorPage() {
  const useStyles = makeStyles(styles());
  const classes = useStyles();

  const history = useHistory();

  return (
    <div>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ margin: 0, height: '100vh' }}>
        <Grid item md={4} lg={4} xs={12}>
          <Card>
            <CardContent>
              <CardMedia
                component="img"
                className={classes.image}
                sx={{ objectFit: 'none', background: 'black' }}
                src="https://drive.google.com/uc?export=view&id=1epryMkQtpO-rQzUGhLh8tpN7UHwdUU7X"
                title="TCIT"
              />
              <Grid container justifyContent="center">
                <Typography variant="h4" sx={{ padding: '1rem' }}>
                  ERROR 404
                </Typography>
                <Typography variant="p">El recurso al que se desea acceder no existe</Typography>
              </Grid>
              <IconButton edge="start" onClick={() => history.goBack()}>
                <ArrowBackIcon />
              </IconButton>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default ErrorPage;
