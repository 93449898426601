import React, { useEffect, useMemo } from 'react';

/* Material */
import { Container, Typography, Grid } from '@mui/material';

/* React Redux */
import { useDispatch, useSelector } from 'react-redux';

/* React Router */
import { useHistory, useParams } from 'react-router-dom';

/* Project */
import {
  getDiscrepancy,
  removeDiscrepancy,
} from 'screens/DiscrepancyDetail/DiscrepancyDetails.actions';

/* Styles */
import DiscrepancyTop from 'commons/components/DiscrepancyTop/DiscrepancyTop';
import Title from 'screens/DiscrepancyDetail/components/Title';
import PartyCard from 'screens/InterestedParties/components/PartyCard';
import { reselectObjects } from 'utils/helper';
import PageLoader from 'commons/components/PageLoader';
import DiscrepancyTabs from 'screens/DiscrepancyDetail/components/DiscrepancyTabs';

function DiscrepancyEntities() {
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDiscrepancy({ id, history, view: 'secondary' }));

    return function cleanup() {
      dispatch(removeDiscrepancy());
    };
  }, []);

  const { discrepancyDetails: discrepancyDetailsState, app: appState } = useSelector(
    (state) => state,
  );
  const { objects: objectsState, user: currentUser } = appState;
  const { loaders } = discrepancyDetailsState;

  const objects = useMemo(() => reselectObjects(objectsState), [objectsState]);

  const { discrepancies, entities } = objects;
  const discrepancy = discrepancies[id];

  if (!discrepancy) return <PageLoader />;

  const discrepancyInterestedParties = discrepancy.interestedParties();

  const parsedInterestedParties = {};

  discrepancyInterestedParties.forEach((dip) => {
    const naturalEntity = dip.naturalEntity();
    const legalEntity = dip.legalEntity();

    if (naturalEntity || legalEntity) {
      if (!parsedInterestedParties[legalEntity.id]) parsedInterestedParties[legalEntity.id] = [];

      const duplicatedNaturalEntity = Object.values(parsedInterestedParties[legalEntity?.id]).filter(
        (pip) => [naturalEntity?.id].includes(pip.entity?.id),
      );

      if (!duplicatedNaturalEntity.length) {
        parsedInterestedParties[legalEntity.id].push({
          entity: naturalEntity,
          interestedParty: dip,
        });
      }
    }
  });

  return loaders.discrepancyData ? null : (
    <Container maxWidth={false}>
      <Title discrepancy={discrepancies[id]} currentUser={currentUser} history={history} />
      <Grid container rowSpacing={1}>
        <DiscrepancyTop
          discrepancy={discrepancies[id]}
          loaders={loaders}
          objects={objects}
          currentUser={currentUser}
        />
        <DiscrepancyTabs discrepancy={discrepancies[id]} />
        <Grid item xs={12}>
          <Typography sx={{ fontWeight: 'medium' }}>PARTES E INTERESADOS</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          {Object.entries(parsedInterestedParties).map(([key, value]) => (
            <PartyCard
              key={`interested-party-${key}`}
              parsedInterestedParty={value}
              entities={entities}
            />
          ))}
        </Grid>
      </Grid>
    </Container>
  );
}

export default DiscrepancyEntities;
