const navBarStyles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  printStatus: {
    marginRight: theme.spacing(2),
    fontFamily: 'Roboto',
  },
});

export default navBarStyles;
