import React, { useEffect } from 'react';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import Filters from './components/Filters';
import ResultsTable from './components/ResultsTable';
import { useDispatch, useSelector } from 'react-redux';
import { getAll, resetControls } from './SearchContent.actions';

const SearchContent = () => {
  const dispatch = useDispatch();
  const { controls, results, loading, pagination, selectedFileDownloadLink, selectedFileName } =
    useSelector((state) => state.searchContent);

  useEffect(() => {
    return () => {
      dispatch(resetControls());
    };
  }, []);

  useEffect(() => {
    if (pagination.total) {
      dispatch(getAll({ ...controls, ...pagination }));
    }
  }, [pagination.page]);

  return (
    <Container maxWidth={false} sx={{ m: 0 }}>
      <Typography variant="h6" color="initial" sx={{ fontSize: '16px', fontWeight: 500 }}>
        Búsqueda de discrepancia
      </Typography>
      <Filters controls={controls} pagination={pagination} loading={loading} />
      <ResultsTable
        results={results}
        controls={controls}
        loading={loading}
        pagination={pagination}
        selectedFileDownloadLink={selectedFileDownloadLink}
        selectedFileName={selectedFileName}
      />
    </Container>
  );
};

export default SearchContent;
