import React from 'react';
import { useDispatch } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DownloadIcon from '@mui/icons-material/Download';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { makeHearingParticipationsFile } from 'screens/DiscrepancyDetail/DiscrepancyDetails.actions';
import color from 'utils/colors';

export default function AccountMenu({ discrepancyId, discrepancyReport, loading }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const makeFileHandler = () => {
    dispatch(makeHearingParticipationsFile({ id: discrepancyId }));
  };
  const { url } = discrepancyReport;
  const enableDownload = url !== 'no-url';
  const generateFileText = enableDownload ? 'Volver a generar' : 'Generar archivo';
  return (
    <>
      <Tooltip title="Descargar participantes de audiencia.">
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 0, pl: 0, paddingTop: '0px' }}
          aria-controls={open ? 'download-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}>
          <CloudDownloadIcon
            sx={{ color: color.purpleSecondary, fontSize: '30px', mt: '-5px', ml: '10px' }}
          />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="download-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            p: 1,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        <MenuItem onClick={makeFileHandler} mt={1}>
          {loading && <CircularProgress sx={{ fontSize: '18px', paddingRight: 2 }} />}
          {!loading && (
            <InsertDriveFileIcon sx={{ color: color.blue, fontSize: '24px', paddingRight: 2 }} />
          )}
          {loading ? 'Generando archivo' : generateFileText}
        </MenuItem>
        {enableDownload && !loading && (
          <>
            <Divider sx={{ marginY: 1 }} />
            <Button onClick={handleClose} element="a" href={url} target="_blank">
              <DownloadIcon sx={{ color: color.blue, fontSize: '24px', paddingRight: 2 }} />{' '}
              Descargar
            </Button>
          </>
        )}
      </Menu>
    </>
  );
}
