/* REACT */
import React from 'react';
import PropTypes from 'prop-types';

/* MATERIAL UI */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

/* PROJECT */

import { LoadingButton } from '@mui/lab';
import styles from './styles';

function GeneralConfirmDialog(props) {
  const { isOpen, title, message, closeGeneralDialog, confirmAction, isLoading } = props;
  return (
    <Dialog open={isOpen}>
      <DialogTitle>
        <Typography fontFamily="Roboto">{title}</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography fontFamily="Roboto">{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            closeGeneralDialog();
          }}
          sx={{ ...styles.button }}
          disabled={isLoading}>
          No
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          sx={{ ...styles.button }}
          onClick={confirmAction}
          disabled={isLoading}
          loading={isLoading}>
          Sí
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

GeneralConfirmDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  closeGeneralDialog: PropTypes.func.isRequired,
  confirmAction: PropTypes.func.isRequired,
  // isLoading: PropTypes.bool,
};

export default GeneralConfirmDialog;
