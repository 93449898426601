import {
  Box,
  Chip,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import React, { useState } from 'react';

const CustomSelectMultipleField = (props) => {
  const { label, list, name, value, onChange } = props;

  const options = list.map((option) => (
    <MenuItem
      key={option.id}
      value={option}
      sx={value.find((item) => item.id === option.id) ? { background: '#e0e0e0!important' } : null}>
      {(option.names && option.surnames && (`${option.names.trim()} ${option.surnames.trim()}`)) ||
        option.label}
    </MenuItem>
  ));

  return (
    <FormControl variant="standard" fullWidth>
      <InputLabel id="demo-multiple-chip-label">{label}</InputLabel>
      <Select
        labelId="demo-multiple-chip-label"
        id="demo-multiple-chip"
        multiple
        value={value}
        name={name}
        onChange={onChange}
        input={<Input id="standard-basic" />}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip
                key={value.id}
                label={(value.names && value.surnames && `${value.names.trim()} ${value.surnames.trim()}`) || value.label}
              />
            ))}
          </Box>
        )}>
        {options}
      </Select>
    </FormControl>
  );
};

export default CustomSelectMultipleField;
