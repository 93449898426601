import { TextField } from '@mui/material';
import { withStyles } from '@mui/styles';

const CustomField = withStyles({
  root: {
    // paddingBottom: "10px",
  },
})(TextField);

export default CustomField;
