import camelize from 'camelcase-keys-deep';

export default class LegalSubMatter {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  legalMatter() {
    return this.env().LegalMatter.getById(this.legalMatterId);
  }
}
