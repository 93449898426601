import React from 'react';

/* Material UI */
import { Dialog, Box } from '@mui/material';

/* React Redux */
import { useSelector } from 'react-redux';

/* Project */
import DocumentStepper from 'screens/DiscrepancyDetail/components/DocumentStepper/DocumentStepper';

function DocumentModal(props) {
  const { objects, interestedPartyId } = props;

  const { documentStepper } = useSelector((state) => state.discrepancyDetails);

  const { controls } = documentStepper;

  return (
    <Dialog
      open={controls.openDialog}
      PaperProps={{ sx: { minWidth: { lg: 700 }, minHeight: 200 } }}>
      <Box sx={{ m: 3 }}>
        <DocumentStepper objects={objects} interestedPartyId={interestedPartyId} />
      </Box>
    </Dialog>
  );
}

export default DocumentModal;
