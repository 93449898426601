import React, { useMemo } from 'react';
import {
  Alert,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import colors from 'utils/colors';
import { useDispatch, useSelector } from 'react-redux';
import { changeForm } from 'commons/commons.actions';
import {
  removeCopiedDiscrepancy,
  selectDiscrepanciesChange,
} from 'screens/DiscrepancyDetail/DiscrepancyDetails.actions';

const DocumentStepFour = (props) => {
  const dispatch = useDispatch();

  const { copyToDiscrepancies } = useSelector(
    (state) => state.discrepancyDetails.documentStepper.form,
  );
  const { objects, currentUser, currentDiscrepancyId, loading, selectedDocumentType } = props;
  const { discrepancies } = objects;

  const filteredDiscrepancies = useMemo(() => {
    return Object.values(discrepancies)
      .filter((item) => item.endedAt === null)
      .filter((item) => item.id !== Number(currentDiscrepancyId))
      .filter((discrepancy) => {
        if (currentUser.type === 'admin' || currentUser.type === 'internal') {
          return discrepancy;
        } else {
          const filteredDiscrepancies = [];
          const interestedParties = discrepancy.interestedParties();
          interestedParties.map((ip) => {
            const naturalEntity = ip.naturalEntity();
            const legalEntity = ip.legalEntity();

            if (
              naturalEntity?.userId === currentUser.id ||
              legalEntity?.userId === currentUser.id
            ) {
              filteredDiscrepancies.push(discrepancy);
            }
          });

          return filteredDiscrepancies[0];
        }
      })
      .sort((a, b) => b.number - a.number);
  }, [discrepancies, currentUser, currentDiscrepancyId]);

  const handleChange = ({ target: { name, value } }) => {
    const exist = copyToDiscrepancies.find((item) => item.id === value.id);
    if (!exist) {
      dispatch(selectDiscrepanciesChange({ name, value }));
    }
  };

  const removeDiscrepancy = (id) => {
    const newDiscrepancyList = copyToDiscrepancies.filter((item) => item.id !== id);
    dispatch(removeCopiedDiscrepancy({ name: 'copyToDiscrepancies', value: newDiscrepancyList }));
  };

  const discrepancyDateLimitsValidation = (discrepancy) => {
    const selectedDiscrepancy = objects.discrepancies[discrepancy.id];
    const selectedDiscrepancyDocuments = selectedDiscrepancy.documents();
    const guidelinesId = Object.values(objects.documentTypes).find(
      (item) => item.code === 'announcements_and_guidelines',
    );
    //ya tiene comunicado y pauta
    let guidelinesValidation;
    if (guidelinesId) {
      const hasGuidelines = selectedDiscrepancyDocuments.find(
        (item) => item.documentTypeId === guidelinesId.id,
      );
      guidelinesValidation =
        hasGuidelines && selectedDocumentType.code === 'announcements_and_guidelines';
    } else {
      guidelinesValidation = false;
    }

    return guidelinesValidation;
  };
  return (
    <Grid container>
      <Grid item xs={12}>
        <FormControl
          variant="standard"
          fullWidth
          disabled={!filteredDiscrepancies.length || loading}>
          <InputLabel id="discrepancy-item">Discrepancia en trámite</InputLabel>
          <Select
            labelId="discrepancy-item"
            id="discrepancy"
            value={0}
            name="copyToDiscrepancies"
            onChange={handleChange}
            label="Discrepancia en trámite">
            <MenuItem value={0}>Seleccionar discrepancia</MenuItem>
            {filteredDiscrepancies.length &&
              filteredDiscrepancies.map((item) => (
                <MenuItem value={item}>{`${item.number} ${item.cover}`}</MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sx={{ mt: 2 }}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow sx={{ background: colors.purpleSecondary }}>
                <TableCell align="center" sx={{ color: '#fff' }} colSpan={2}>
                  Discrepancias
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {copyToDiscrepancies.length ? (
                copyToDiscrepancies.map((discrepancy) => (
                  <TableRow
                    key={discrepancy.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell align="left">
                      {`${discrepancy.number} ${discrepancy.cover}`}{' '}
                      {discrepancyDateLimitsValidation(discrepancy) ? (
                        <span style={{ marginLeft: '5PX', color: 'red' }}>
                          - <b>No se copiará,</b> ya posee un comunicado y pauta.
                        </span>
                      ) : null}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        size="small"
                        onClick={() => removeDiscrepancy(discrepancy.id)}
                        disabled={loading}>
                        <RemoveCircleOutlineIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow key={0} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell align="center">Ninguna discrepancia seleccionada</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      {copyToDiscrepancies.length ? (
        <Alert severity="warning" sx={{ mt: 2, width: '100%' }}>
          Esto copiará los archivos a las distintas discrepancias seleccionadas, en caso de
          seleccionar una discrepancia errónea deberá eliminar el documento manualmente.
        </Alert>
      ) : null}
      {!filteredDiscrepancies.length && (
        <Alert severity="warning" sx={{ mt: 2, width: '100%' }}>
          No posee otras discrepancias, puede finalizar.
        </Alert>
      )}
    </Grid>
  );
};

export default DocumentStepFour;
