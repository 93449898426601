import React, { useState } from 'react';
import PropTypes from 'prop-types';

/* Material UI */
import {
  Box,
  Divider,
  Grid,
  IconButton,
  List,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { MoreVert, Delete, Edit } from '@mui/icons-material';

/* React redux */
import { useDispatch } from 'react-redux';

/* Project */
import AttachmentListItem from 'screens/DiscrepancyDetail/components/AttachmentListItem';
import PrimaryButton from 'commons/components/PrimaryButton/PrimaryButton';
import color from 'utils/colors';
import {
  changeDocumentControls,
  changeTwoOrMoreDocumentControls,
  setDocumentFormValues,
} from 'screens/DiscrepancyDetail/DiscrepancyDetails.actions';

function AttachmentsList(props) {
  const {
    attachmentParts,
    handleAttachmentItemClick,
    setOpenIndex,
    partOpen,
    setPartOpen,
    iframeDoc,
    currentDocument,
    currentUser,
    objects,
    discrepancyID,
    handleDocFile,
  } = props;

  const currentDiscrepancy = objects.discrepancies[discrepancyID];
  const presentation = currentDiscrepancy ? currentDiscrepancy.presentation() : null;

  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openDeleteDialog = () => {
    dispatch(changeDocumentControls({ name: 'openDeleteDialog', value: true }));
    handleClose();
  };

  const openEditDialog = (editActiveStep) => {
    dispatch(
      changeTwoOrMoreDocumentControls({
        openEditDialog: true,
        editActiveStep,
      }),
    );
    dispatch(
      setDocumentFormValues({
        id: currentDocument.id,
        documentTypeId: currentDocument.documentTypeId,
        title: currentDocument.title,
        index: currentDocument.index,
        documentDate: currentDocument.createdAt,
        legalEntityId: currentDocument.interestedParty().legalEntity().legalEntity().id,
      }),
    );
    handleClose();
  };

  const documentMenu = (
    <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
      <MenuItem onClick={() => openEditDialog(0)}>
        <ListItemIcon>
          <Edit fontSize="small" sx={{ color: color.cooper }} />
        </ListItemIcon>
        <ListItemText>Editar documento</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => openEditDialog(1)}>
        <ListItemIcon>
          <Edit fontSize="small" sx={{ color: color.cooper }} />
        </ListItemIcon>
        <ListItemText>Editar archivos principales</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => openEditDialog(2)}>
        <ListItemIcon>
          <Edit fontSize="small" sx={{ color: color.cooper }} />
        </ListItemIcon>
        <ListItemText>Editar adjuntos</ListItemText>
      </MenuItem>
      {presentation?.id === currentDocument.id ? null : (
        <>
          <Divider />
          <MenuItem onClick={openDeleteDialog}>
            <ListItemIcon>
              <Delete fontSize="small" sx={{ color: color.blue }} />
            </ListItemIcon>
            <ListItemText sx={{ color: color.blue }}>Eliminar documento</ListItemText>
          </MenuItem>
        </>
      )}
    </Menu>
  );

  return (
    <Grid item xs={12} md={8} lg={2}>
      <Box
        sx={{
          background: `linear-gradient(180deg, ${color.darkGreyRgba} 40%, ${color.whiteRgba} 1%)`,
        }}>
        <Grid container justifyContent="space-between">
          <Typography
            sx={{
              color: color.white,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxHeight: '24px',
              padding: '.7rem',
            }}>
            Archivos
          </Typography>
          {currentUser?.type === 'admin' && !currentDiscrepancy.endedAt ? (
            <IconButton aria-label="delete" size="small" onClick={handleClick}>
              <MoreVert fontSize="inherit" sx={{ color: color.white, padding: '.4rem' }} />
            </IconButton>
          ) : null}
        </Grid>
        <List sx={{ width: '100%', bgcolor: 'background.paper' }} component="nav">
          {Object.values(attachmentParts).map((attachmentPart) => (
            <AttachmentListItem
              key={`attachment-list-item-${attachmentPart.id}`}
              attachmentPart={attachmentPart}
              handleButtonClick={handleAttachmentItemClick}
              partOpen={partOpen}
              setPartOpen={setPartOpen}
              iframeDoc={iframeDoc}
              handleDocFile={handleDocFile}
            />
          ))}
        </List>
        <PrimaryButton text="ÍNDICE" fullWidth onClick={() => setOpenIndex(true)} />
      </Box>
      {currentUser?.type === 'admin' ? documentMenu : null}
    </Grid>
  );
}

AttachmentsList.propTypes = {
  attachmentParts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setOpenIndex: PropTypes.func.isRequired,
  handleAttachmentItemClick: PropTypes.func.isRequired,
  setPartOpen: PropTypes.func.isRequired,
  partOpen: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  iframeDoc: PropTypes.shape({}),
  currentUser: PropTypes.shape({}),
};

AttachmentsList.defaultProps = {
  partOpen: null,
  iframeDoc: null,
  currentUser: null,
};

export default AttachmentsList;
