import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import color from 'utils/colors';
import AddIcon from '@mui/icons-material/Add';
import { Delete } from '@mui/icons-material';
import { styled } from '@mui/styles';
import TableCell from '@mui/material/TableCell';
import { useDispatch } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import { appSetError, changeLegalPowersControls } from 'commons/commons.actions';
import PrimaryButton from 'commons/components/PrimaryButton/PrimaryButton';
import { isZippedFile } from 'utils/functions';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function LegalPowersModal(props) {
  const { legalPowersArray, setLegalPowersArray, registerLegalEntity } = props;
  const { legalPowersControls } = registerLegalEntity;
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(changeLegalPowersControls({ name: 'openDialog', value: false }));
  };

  const removeFileRow = (idx) => {
    const legalPowersRows = [...legalPowersArray];
    legalPowersRows.splice(idx, 1);
    setLegalPowersArray(legalPowersRows);
  };

  const newFileRow = ({ target }) => {
    if (
      target.files[0] &&
      !isZippedFile(target.files[0].name) &&
      target.files[0].type === 'application/pdf'
    ) {
      const theFileExists = legalPowersArray.find((file) => file.name === target.files[0].name);
      // TODO: show alert when a file with the same name has already been uploaded
      if (!theFileExists) setLegalPowersArray([...legalPowersArray, target.files[0]]);
    } else {
      dispatch(appSetError({ msg: 'El archivo debe ser un archivo PDF' }));
    }
  };

  const title = (
    <Typography
      sx={{
        fontWeight: 'regular',
        color: color.cooper,
        fontSize: 20,
      }}>
      Listado de poderes
    </Typography>
  );

  return (
    <Dialog
      open={legalPowersControls.openDialog}
      onClose={handleClose}
      PaperProps={{ sx: { minWidth: { lg: 700 }, minHeight: 200 } }}>
      <Box sx={{ m: 3 }}>
        {title}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            mb: 2,
          }}>
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: 'bold',
              color: color.blue,
              textTransform: 'uppercase',
              mr: 1,
            }}>
            AGREGAR
          </Typography>
          <Typography
            sx={{
              fontWeight: 'regular',
              color: color.blue,
              fontSize: 16,
            }}>
            Poder
          </Typography>
          <label htmlFor="legalPower">
            <input
              accept="application/pdf"
              id="legalPower"
              name="legalPower"
              type="file"
              style={{ display: 'none' }}
              onChange={newFileRow}
            />
            <IconButton sx={{ color: color.blue }} aria-label="legalPower" component="span">
              <AddIcon
                sx={{
                  color: color.white,
                  backgroundColor: color.blue,
                  borderRadius: 3,
                }}
              />
            </IconButton>
          </label>
        </Box>
        <TableContainer component={Paper}>
          <Table sx={{ maxWidth: 700 }} size="small" aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell />
                <StyledTableCell>Poder de representación</StyledTableCell>
                <StyledTableCell align="center">Acciones</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {legalPowersArray.map((a, idx) => (
                // eslint-disable-next-line react/no-array-index-key
                <StyledTableRow key={legalPowersArray[idx].name + idx}>
                  <StyledTableCell component="th" scope="row">
                    {`${idx + 1}.`}
                  </StyledTableCell>
                  <StyledTableCell>{legalPowersArray[idx].name}</StyledTableCell>
                  <StyledTableCell align="right">
                    <IconButton
                      sx={{ color: color.blue }}
                      aria-label="."
                      component="span"
                      onClick={() => removeFileRow(idx)}>
                      <Delete />
                    </IconButton>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            mt: 2,
          }}>
          <PrimaryButton onClick={handleClose} text="Cerrar" />
        </Box>
      </Box>
    </Dialog>
  );
}

LegalPowersModal.propTypes = {
  legalPowersArray: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setLegalPowersArray: PropTypes.func.isRequired,
};

export default LegalPowersModal;
