import { call, put } from 'redux-saga/effects';
import { appActions } from 'commons/commons.reducer';
import { truthty } from 'utils/functions';

export default function* controlledCall(
  toYield,
  path,
  body,
  succesFunction,
  failFunction = appActions.setError,
  isOpenFile = false,
) {
  const result = yield call(toYield, path, body || undefined);
  if (isOpenFile) {
    if (!result.ok) {
      let data = null;
      // eslint-disable-next-line no-return-assign
      yield result.json().then((responseBody) => (data = responseBody));
      yield put(failFunction(data));
    }
  } else {
    const nextAction = truthty(result.error) ? failFunction : succesFunction;
    yield put(nextAction(result));
  }

  return result;
}
