import React from 'react';
import PropTypes from 'prop-types';

/* Material UI */
import { Button, CircularProgress } from '@mui/material';

/* Project */
import color from '../../../utils/colors';

function PrimaryButton(props) {
  const {
    text,
    onClick,
    disabled,
    fullWidth,
    type,
    loading,
    startIcon,
    endIcon,
    children,
    sx = [],
    secondaryStyles,
  } = props;

  return (
    <Button
      startIcon={loading ? <CircularProgress size={18} /> : startIcon || null}
      endIcon={endIcon}
      disabled={loading || disabled}
      onClick={onClick}
      variant="contained"
      disableElevation
      fullWidth={fullWidth}
      size="small"
      type={type}
      sx={[
        {
          backgroundColor: secondaryStyles ? '#641C34' : color.blue,
          color: color.white,
          '&:hover': {
            backgroundColor: secondaryStyles ? '#4C1528' : color.cooper,
          },
          minWidth: '110px',
          fontSize: 16,
          textTransform: secondaryStyles ? 'capitalize' : 'uppercase',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}>
      {children || text}
    </Button>
  );
}

PrimaryButton.prototypes = {
  text: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  type: PropTypes.string,
  loading: PropTypes.bool,
  startIcon: PropTypes.element,
  endIcon: PropTypes.element,
  children: PropTypes.any,
  sx: PropTypes.arrayOf(PropTypes.shape({})),
};

PrimaryButton.defaultProps = {
  disabled: false,
  loading: false,
  startIcon: null,
  endIcon: null,
  sx: {},
};

export default PrimaryButton;
