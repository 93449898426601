/* eslint-disable no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import TextField from '@mui/material/TextField';
import { DateTimePicker } from '@mui/x-date-pickers';
import { IconButton, InputAdornment } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EventIcon from '@mui/icons-material/Event';
function CustomDateTimePicker(props) {
  const { value, onChange, sx, label, alternativeStyles, disabled, error, errorMsg } = props;
  // const [value, setValue] = React.useState(new Date());

  const CalendarIcon = () => {
    return <CalendarMonthIcon sx={{ color: '#A68095' }} />;
  };

  const DefaultIcon = () => {
    return <EventIcon sx={{ color: '#757575' }} />;
  };

  return (
    <DateTimePicker
      className="custom-date-time-picker"
      renderInput={(props) => (
        <TextField
          {...props}
          sx={{ ...sx }}
          size={alternativeStyles ? 'small' : null}
          variant={alternativeStyles ? 'standard' : 'outlined'}
          error={error}
          helperText={error ? errorMsg : ''}
        />
      )}
      label={label}
      value={value}
      inputFormat="dd/MM/yyyy HH:mm"
      onChange={onChange}
      components={{
        OpenPickerIcon: alternativeStyles ? CalendarIcon : DefaultIcon,
      }}
      disabled={disabled || false}
    />
  );
}

export default CustomDateTimePicker;
