import React from 'react';
import PropTypes from 'prop-types';

/* Material UI */
import { MenuItem, Stack, TextField } from '@mui/material';

/* React Redux */
import { useDispatch } from 'react-redux';

/* Project */
import { changeForm } from 'commons/commons.actions';
import DatePicker from '../DatePicker/DatePicker';

function StepOne(props) {
  const { form, handleChange, objects } = props;

  const dispatch = useDispatch();

  const legalMatterList = Object.values(objects.legalMatters);
  const legalSubMatterList = Object.values(objects.legalSubMatters);

  const legalMatterOptions = legalMatterList.map((legalMatter) => (
    <MenuItem key={legalMatter.id} value={legalMatter.id}>
      {legalMatter.name}
    </MenuItem>
  ));

  const filteredSubMatters = !form.matterId
    ? []
    : legalSubMatterList.filter((subMatter) => subMatter.legalMatterId === Number(form.matterId));

  const legalSubMatterOptions = filteredSubMatters.map((legalSubMatter) => (
    <MenuItem key={legalSubMatter.id} value={legalSubMatter.id}>
      {legalSubMatter.name}
    </MenuItem>
  ));

  const handleMatterChange = ({ target: { name, value } }) => {
    dispatch(changeForm({ name, value }));
    dispatch(changeForm({ name: 'subMatterId', value: '' }));
  };

  const coverTextField = (
    <TextField
      fullWidth
      size="small"
      variant="outlined"
      label="Nombre discrepancia"
      name="cover"
      onChange={handleChange}
      color="secondary"
      value={form.cover}
    />
  );

  const legalMatterField = (
    <TextField
      size="small"
      select
      fullWidth
      label="Materia"
      color="secondary"
      name="matterId"
      onChange={handleMatterChange}
      value={form.matterId}>
      {legalMatterOptions}
    </TextField>
  );

  const legalSubMatterField = (
    <TextField
      size="small"
      select
      fullWidth
      label="Submateria"
      color="secondary"
      name="subMatterId"
      onChange={handleChange}
      value={form.subMatterId}>
      {legalSubMatterOptions}
    </TextField>
  );

  const presentationLimit = (
    <DatePicker
      fullWidth
      label="Fecha limite de presentacion"
      color="secondary"
      name="presentationLimit"
      value={form.presentationLimit}
      onChange={(event) =>
        handleChange({ target: { name: 'presentationLimit', value: event.target.value } })
      }
    />
  );

  return (
    <Stack alignItems="center" spacing={2}>
      {coverTextField}
      {
        // TODO: Remove this conditional when the functionality is implemented
        false && presentationLimit
      }
      {legalMatterField}
      {legalSubMatterField}
    </Stack>
  );
}

StepOne.propTypes = {
  form: PropTypes.shape({}).isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default StepOne;
