import { Environment } from 'model-environment';
import Models from '../models';

const verifierDigit = (rut) => {
  let M = 0;
  let S = 1;

  // eslint-disable-next-line
  for (; rut; rut = Math.floor(rut / 10)) S = (S + (rut % 10) * (9 - (M++ % 6))) % 11;
  return S ? S - 1 : 'k';
};

export const validateTaxNumber = (rut) => {
  if (rut == null) {
    return false;
  }
  const rutCompleto = rut.toString()?.replace(/[-.]/g, '').trim();
  if (!/^[0-9]+[0-9kK]{1}$/.test(rutCompleto)) return false;
  let digv = rutCompleto.slice(-1);
  const rutSinDv = rutCompleto.slice(0, -1);
  if (digv === 'K') digv = 'k';
  return verifierDigit(rutSinDv).toString() === digv.toString();
};

export const onlyKeysWithValue = (obj) => {
  const result = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== undefined && obj[key] !== null && obj[key] !== '') {
      result[key] = obj[key];
    }
  });
  return result;
};

export const getExtension = (fileName) => fileName.split('.').pop().toLowerCase();

export const removeExtension = (fileName) =>
  fileName.split('.').slice(0, -1).join('.').toLowerCase();

/**
 * @param {Object} objects
 * @returns {Object}
 */
export const reselectObjects = (objects) => new Environment({ objects }, Models).parseDB().objects;

export const normalizeObj = (arr) =>
  arr.reduce((prev, current) => {
    // eslint-disable-next-line no-param-reassign
    prev[current.id] = current;
    return prev;
  }, {});

export const convertVideo = (input) => {
  let pattern = '';
  if (input.includes('youtube') || input.includes('youtu.be')) {
    pattern = /(?:http?s?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(\S+)/g;
    if (pattern.test(input)) {
      if (input.includes('/embed')) {
        return input;
      }
      const replacement = 'https://www.youtube.com/embed/$1';
      return input.replace(pattern, replacement);
    }
  } else if (input.includes('vimeo')) {
    pattern = /(?:http?s?:\/\/)?(?:www\.)?(?:vimeo\.com)\/?(\S+)/g;
    if (pattern.test(input)) {
      if (input.includes('https://player')) {
        return input;
      }
      const replacement = 'https://player.vimeo.com/video/$1';
      return input.replace(pattern, replacement);
    }
  }
  return null;
};

export const captureEnterEvent = (evt, callback) => {
  if (evt.key === 'Enter') {
    callback();
  }
};
