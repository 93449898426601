import React from 'react';
import PropTypes from 'prop-types';

/* Project */
import DocumentItem from 'screens/LastDocuments/components/DocumentItem';

const DocumentsList = (props) => {
  const { documentsList, entitiesList, interestedPartiesList, discrepanciesList, documentsIds } =
    props;

  const filteredDocuments = Object.values(documentsList).filter((doc) =>
    documentsIds.includes(doc.id),
  );

  return filteredDocuments.map((doc) => (
    <DocumentItem
      key={doc.id}
      document={doc}
      legalEntity={
        Object.values(entitiesList).filter(
          (e) =>
            e.id ===
            Object.values(interestedPartiesList).filter((ip) => ip.id === doc.interestedPartyId)[0]
              .legalEntityId,
        )[0]
      }
      naturalEntity={
        Object.values(entitiesList).filter(
          (e) =>
            e.id ===
            Object.values(interestedPartiesList).filter((ip) => ip.id === doc.interestedPartyId)[0]
              .naturalEntityId,
        )[0]
      }
      discrepancy={Object.values(discrepanciesList).filter((d) => d.id === doc.discrepancyId)[0]}
    />
  ));
};

DocumentsList.propTypes = {
  documentsList: PropTypes.shape({}).isRequired,
};

export default DocumentsList;
