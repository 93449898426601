const BASE = '[HOME]';

const HomeTypes = {
  GET_DISCREPANCIES: `${BASE} GET_DISCREPANCIES`,
  GET_DISCREPANCIES_SUCCESS: `${BASE} GET_DISCREPANCIES_SUCCESS`,
  GET_DISCREPANCIES_FAIL: `${BASE} GET_DISCREPANCIES_FAIL`,
  POST_DISCREPANCY: `${BASE} POST_DISCREPANCY`,
  POST_DISCREPANCY_SUCCESS: `${BASE} POST_DISCREPANCY_SUCCESS`,
  POST_DISCREPANCY_FAIL: `${BASE} POST_DISCREPANCY_FAIL`,
  SET_FILTERS: `${BASE} SET_FILTERS`,
  CLEAR_FILTERS: `${BASE} CLEAR_FILTERS`,
  TOGGLE_STEPPER: `${BASE} TOGGLE_STEPPER`,
  DISABLE_ERRORS: `${BASE} DISABLE_ERRORS`,
  CONTROLS_CHANGED: `${BASE} CONTROLS_CHANGED`,
  CHANGE_PAGINATION: `${BASE} CHANGE_PAGINATION`,
};

export default HomeTypes;
