import { newReducer } from 'utils/reducer';
import HomeTypes from 'screens/Home/Home.types';
import CommonsTypes from 'commons/commons.types';

const initialState = {
  filters: {
    legalMatter: '',
    legalSubMatter: '',
    number: '',
    order: '',
    state: '',
    // startDate: moment().subtract(1, 'M').format('YYYY-MM-DD'),
    // endDate: moment().format('YYYY-MM-DD'),
    startDate: '',
    endDate: '',
    keyword: '',
    onlyMyData: null,
  },
  pagination: {
    count: 0,
    page: 1,
    size: 10,
  },
  stepperIsOpen: false,
  errorMsg: '',
  error: false,
  successMsg: '',
  success: false,
  controls: {
    loading: false,
    message: '',
    error: false,
  },
  loaders: {
    list: false,
  },
  discrepanciesIds: [],
  chipsIds: {},
  discrepanciesLoading: true,
};

const home = newReducer(initialState, {
  [`${HomeTypes.DISABLE_ERRORS}`]: (state) => ({
    ...state,
    error: false,
  }),
  [HomeTypes.GET_DISCREPANCIES_SUCCESS]: (state, action) => ({
    ...state,
    chipsIds: action.payload.chipsIds,
    discrepanciesIds: action.payload.ids,
    discrepanciesLoading: false,
    loaders: {
      ...state.loaders,
      list: false,
    },
    pagination: {
      ...state.pagination,
      count: action.payload.totalPages,
      page: action.payload.currentPage,
    },
  }),
  [HomeTypes.GET_DISCREPANCIES]: (state) => ({
    ...state,
    loaders: {
      ...state.loaders,
      list: true,
    },
  }),
  [HomeTypes.GET_DISCREPANCIES_FAIL]: (state) => ({
    ...state,
    discrepancies: null,
    discrepanciesLoading: false,
  }),
  [HomeTypes.POST_DISCREPANCY]: (state) => ({
    ...state,
  }),
  [HomeTypes.POST_DISCREPANCY_SUCCESS]: (state) => ({
    ...state,
    stepperIsOpen: false,
  }),
  [HomeTypes.SET_FILTERS]: (state, action) => ({
    ...state,
    filters: { ...state.filters, [action.payload.name]: action.payload.value },
  }),
  [HomeTypes.CLEAR_FILTERS]: (state, action) => ({
    ...state,
    filters: { ...initialState.filters },
    pagination: { ...initialState.pagination, count: 1 },
  }),
  [HomeTypes.CONTROLS_CHANGED]: (state, action) => ({
    ...state.controls,
    ...action.controls,
  }),
  [HomeTypes.CHANGE_PAGINATION]: (state, action) => ({
    ...state,
    pagination: {
      ...state.pagination,
      page: action.payload.value,
    },
  }),
  [HomeTypes.TOGGLE_STEPPER]: (state) => ({
    ...state,
    stepperIsOpen: !state.stepperIsOpen,
  }),
  [CommonsTypes.CREATE_DISCREPANCY_SUCCESS]: (state, action) => ({
    ...state,
    discrepanciesIds: [...state.discrepanciesIds, ...action.payload.ids],
  }),
});

export default home;
