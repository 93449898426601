import { newReducer } from 'utils/reducer';
import LastDocumentsTypes from 'screens/LastDocuments/LastDocuments.types';

const initialState = {
  filters: {
    keyword: '',
    documentType: '',
    startDate: '',
    endDate: '',
  },
  loaders: {
    list: false,
  },
  chipsIds: {
    interestedParty: [],
    index: [],
    title: [],
    documentType: [],
    noChip: [],
  },
  documentsIds: [],
};

const lastDocuments = newReducer(initialState, {
  [LastDocumentsTypes.GET_DOCUMENTS_SUCCESS]: (state, action) => ({
    ...state,
    chipsIds: action.payload.chipsIds,
    documentsIds: action.payload.ids,
    loaders: {
      ...state.loaders,
      list: false,
    },
  }),
  [LastDocumentsTypes.GET_DOCUMENTS]: (state) => ({
    ...state,
    loaders: {
      ...state.loaders,
      list: true,
    },
  }),
  [LastDocumentsTypes.GET_DOCUMENTS_FAIL]: (state) => ({
    ...state,
    loaders: {
      ...state.loaders,
      list: true,
    },
  }),
  [LastDocumentsTypes.SET_FILTERS]: (state, action) => ({
    ...state,
    filters: { ...state.filters, [action.payload.name]: action.payload.value },
  }),
});

export default lastDocuments;
