import React, { useEffect, useMemo, useState } from 'react';

/* Material UI */
import { Box, Stepper, Step, StepLabel, Alert } from '@mui/material';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import CloseIcon from '@mui/icons-material/Close';
/* React Redux */
import { useDispatch, useSelector } from 'react-redux';

/* React Router */
import { useHistory, useParams } from 'react-router-dom';

/* Project */
import PrimaryButton from 'commons/components/PrimaryButton/PrimaryButton';
import SecondaryButton from 'commons/components/SecondaryButton/SecondaryButton';
import {
  changeControls,
  creatAudienceDocument,
  createDocument,
  createHearingPresentation,
  resetDocumentCreationControls,
} from 'screens/DiscrepancyDetail/DiscrepancyDetails.actions';
import DocumentStepOne from 'screens/DiscrepancyDetail/components/DocumentStepper/DocumentStepOne';
import DocumentStepTwo from 'screens/DiscrepancyDetail/components/DocumentStepper/DocumentStepTwo';
import DocumentTitleStepper from 'screens/DiscrepancyDetail/components/DocumentStepper/DocumentTitleStepper';
import DocumentStepThree from 'screens/DiscrepancyDetail/components/DocumentStepper/DocumentStepThree';
import moment from 'moment';
import DocumentStepFour from './DocumentStepFour';

const steps = [
  'Ingreso nuevo documento',
  'Agregar archivos principales',
  'Agregar archivos adjuntos',
  'Seleccionar otras discrepancias',
];

function DocumentStepper(props) {
  const { objects, interestedPartyId } = props;

  const history = useHistory();

  const dispatch = useDispatch();
  const { id: discrepancyID } = useParams();
  const { discrepancyDetails: discrepancyDetailsState, app: appState } = useSelector(
    (state) => state,
  );

  const [fileMainPdf, setFileMainPdf] = useState(null);
  const [fileMainWord, setFileMainWord] = useState(null);
  const [fileMainConfidential, setFileMainConfidential] = useState(null);
  const [attachmentsArray, setAttachmentsArray] = useState([]);

  const [hearingFile, setHearingFile] = useState(null);

  const { documentStepper } = discrepancyDetailsState;
  const { user: currentUser } = appState;

  const { representatives, users } = objects;
  const currentUserModel = users[currentUser?.id];

  const { controls, form, loaders } = documentStepper;
  const { copyToDiscrepancies } = form;
  const { documentTypes } = objects;

  const documentCode = objects.documentTypes[form.documentTypeId]?.code;
  const selectedDocumentType = objects.documentTypes[form.documentTypeId];

  const discrepancyHearing = objects.hearings[discrepancyID];

  const isAudienceType = documentCode === 'audiences';
  const isAnnouncement = documentCode === 'announcements';
  const isProceeding = documentCode === 'proceedings';
  const isOpinion = documentCode === 'opinions';
  const isReport = documentCode === 'reports';
  const isHearingPresentationType = documentCode === 'hearing_presentation';
  const isAnnouncementsAndGuidelines = documentCode === 'announcements_and_guidelines';
  const isObservations = documentCode === 'observations';

  const currentDiscrepancyInterestedParty = useMemo(
    () => objects.discrepancies[discrepancyID]?.interestedParties(),
    [discrepancyID, objects],
  );

  const stepFourValidation = useMemo(() => {
    const userIds = [];
    currentDiscrepancyInterestedParty.forEach((item) => {
      const naturalEntity = item.naturalEntity();
      const legalEntity = item.legalEntity();

      if (naturalEntity?.userId) {
        userIds.push(naturalEntity?.userId);
      }

      if (legalEntity?.userId) {
        userIds.push(legalEntity?.userId);
      }
    });

    const filteredUserIds = [...new Set(userIds)];

    return (
      currentUser.type === 'admin' ||
      currentUser.type === 'internal' ||
      !!filteredUserIds.find((item) => item === currentUser.id)
    );
  }, [currentDiscrepancyInterestedParty, currentUser]);

  const handleBack = () => {
    if (isHearingPresentationType) {
      return dispatch(
        changeControls({
          name: 'activeStep',
          value: 0,
        }),
      );
    } else {
      return dispatch(
        changeControls({
          name: 'activeStep',
          value: controls.activeStep - 1,
        }),
      );
    }
  };

  const copyToDiscrepanciesValidation = copyToDiscrepancies.filter((discrepancy) => {
    const selectedDiscrepancy = objects.discrepancies[discrepancy.id];

    const selectedDiscrepancyDocuments = selectedDiscrepancy.documents();
    //ya tiene comunicado y pauta
    const hasGuidelines = selectedDiscrepancyDocuments.find(
      (item) => item.documentTypeId === selectedDocumentType.id,
    );

    return hasGuidelines ? false : true;
  });

  const handleNext = () => {
    if (stepFourValidation ? controls.activeStep !== 3 : controls.activeStep !== 2) {
      return dispatch(
        changeControls({
          name: 'activeStep',
          value: controls.activeStep + 1,
        }),
      );
    }
    const files = [fileMainPdf, fileMainWord, fileMainConfidential, attachmentsArray];

    const copyToDiscrepanciesUniqueFilesValidation = copyToDiscrepancies.filter((discrepancy) => {
      const selectedDiscrepancy = objects.discrepancies[discrepancy.id];
      const selectedDiscrepancyDocuments = selectedDiscrepancy.documents();
      const guidelinesId = Object.values(objects.documentTypes).find(
        (item) => item.code === 'announcements_and_guidelines',
      );
      //ya tiene comunicado y pauta
      let guidelinesValidation;
      if (guidelinesId) {
        const hasGuidelines = selectedDiscrepancyDocuments.find(
          (item) => item.documentTypeId === guidelinesId.id,
        );
        guidelinesValidation =
          hasGuidelines && selectedDocumentType.code === 'announcements_and_guidelines';
      } else {
        guidelinesValidation = false;
      }

      return guidelinesValidation ? false : true;
    });

    return dispatch(
      createDocument({
        discrepancyId: discrepancyID,
        documentTypeId: form.documentTypeId,
        interestedPartyId,
        title: form.title,
        index: form.index,
        mainPdfFile: fileMainPdf,
        mainWordFile: fileMainWord,
        mainConfidential: fileMainConfidential,
        attachmentsArray,
        legalEntityId: form.legalEntityId,
        position: form.position,
        dateHourAudience: form.dateHourAudience,
        dateHourRegistrationDeadline: form.dateHourRegistrationDeadline,
        dateHourPresentationInterestedParty: form.dateHourPresentationInterestedParty,
        dateHourObservationsDeadline: form.dateHourObservationsDeadline,
        history,
        copyToDiscrepancies: copyToDiscrepanciesUniqueFilesValidation,
      }),
    );
  };

  const handleOnCreateOneStep = () => {
    if (isAudienceType) {
      return dispatch(
        creatAudienceDocument({
          audienceUrl: form.audienceUrl,
          documentTypeId: form.documentTypeId,
          interestedPartyId,
          legalEntityId: form.legalEntityId,
          discrepancyId: discrepancyID,
          history,
        }),
      );
    }
    if (isHearingPresentationType && controls.activeStep === 3) {
      return dispatch(
        createHearingPresentation({
          hearingFile,
          interestedPartyId,
          position: form.position,
          legalEntityId: form.legalEntityId,
          discrepancyId: discrepancyID,
          history,
          documentTypeId: form.documentTypeId,
          copyToDiscrepancies,
        }),
      );
    }

    if (isHearingPresentationType && controls.activeStep === 0) {
      dispatch(
        changeControls({
          name: 'activeStep',
          value: 3,
        }),
      );
    }

    return null;
  };
  const dateValidations = useMemo(() => {
    let error = false;
    let errorMsg = '';
    const documentType = Object.values(documentTypes).find(
      (item) => item.id === form.documentTypeId,
    );

    if (documentType?.code === 'announcements_and_guidelines') {
      error = moment(form.dateHourRegistrationDeadline).isAfter(form.dateHourAudience);
      errorMsg = 'La fecha no puede ser mayor a la fecha de audiencia';
    }

    return { error, errorMsg };
  }, [form.dateHourAudience, form.dateHourRegistrationDeadline, form.documentTypeId]);

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <DocumentStepOne
            form={form}
            objects={objects}
            interestedPartyId={interestedPartyId}
            currentUser={currentUser}
            hearingFile={hearingFile}
            setHearingFile={setHearingFile}
            documentCode={documentCode}
            discrepancyHearing={discrepancyHearing}
            dateValidations={dateValidations}
          />
        );
      case 1:
        return (
          <DocumentStepTwo
            fileMainConfidential={fileMainConfidential}
            setFileMainConfidential={setFileMainConfidential}
            fileMainPdf={fileMainPdf}
            fileMainWord={fileMainWord}
            setFileMainWord={setFileMainWord}
            setFileMainPdf={setFileMainPdf}
          />
        );
      case 2:
        return (
          <DocumentStepThree
            attachmentsArray={attachmentsArray}
            setAttachmentsArray={setAttachmentsArray}
          />
        );
      case 3:
        return (
          <DocumentStepFour
            objects={objects}
            currentUser={currentUser}
            currentDiscrepancyId={discrepancyID}
            loading={loaders.create}
            selectedDocumentType={selectedDocumentType}
          />
        );
      default:
        return 'Paso desconocido';
    }
  };

  const disabledStep0Next =
    !form.documentTypeId ||
    (isAnnouncementsAndGuidelines &&
      (!form.dateHourRegistrationDeadline ||
        !form.dateHourPresentationInterestedParty ||
        !form.dateHourObservationsDeadline ||
        dateValidations.error ||
        !form.dateHourAudience)) ||
    // !form.index ||
    (isHearingPresentationType &&
      (!form.documentTypeId || !form.legalEntityId || !hearingFile?.name)) ||
    (isObservations && !form.legalEntityId) ||
    (!isAnnouncementsAndGuidelines &&
      ((!form.position && !isHearingPresentationType) || !form.legalEntityId));

  const disabledStep1Next =
    !fileMainPdf ||
    (currentUser.type !== 'admin' &&
      (isReport || isProceeding || isOpinion || isAnnouncement ? false : !fileMainWord));

  const handleClose = () => {
    dispatch(
      changeControls({
        name: 'openDialog',
        value: false,
      }),
    );
    dispatch(resetDocumentCreationControls());
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper sx={{ ml: 0, mr: 0 }} activeStep={controls.activeStep}>
        {steps
          .filter((item) =>
            !stepFourValidation ? item !== 'Seleccionar otras discrepancias' : true,
          )
          .filter((item) =>
            isHearingPresentationType && stepFourValidation
              ? item === 'Seleccionar otras discrepancias' || item === 'Ingreso nuevo documento'
              : true,
          )
          .map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
      </Stepper>
      <>
        <DocumentTitleStepper steps={steps} controls={controls} />
        {/* {
          currentUserModel.entity().legalEntity() ?
            <Alert severity="error" sx={{ mt: 0.5 }}>
              Las discrepancias solo pueden ser ingresadas por personas naturales que representan a una persona jurídica
            </Alert> :
            null
        } */}
        <Box sx={{ mt: 2, mb: 1 }}>{getStepContent(controls.activeStep)}</Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          {controls.activeStep === 0 ? (
            <PrimaryButton
              onClick={handleClose}
              text="Cerrar"
              sx={{ mr: 1 }}
              secondaryStyles
              startIcon={<CloseIcon />}
            />
          ) : (
            <PrimaryButton
              onClick={handleBack}
              text="Anterior"
              sx={{ mr: 1 }}
              secondaryStyles
              startIcon={<CloseIcon />}
            />
          )}

          <Box sx={{ flex: '1 1 auto' }} />

          {isAudienceType || isHearingPresentationType ? (
            <PrimaryButton
              loading={loaders.create}
              onClick={handleOnCreateOneStep}
              text="Siguiente"
              secondaryStyles
              startIcon={<DoubleArrowIcon />}
              disabled={currentUserModel.entity().legalEntity() ? true : isHearingPresentationType ? disabledStep0Next : false}
            />
          ) : (
            <PrimaryButton
              disabled={controls.activeStep === 0 ? disabledStep0Next : disabledStep1Next}
              loading={loaders.create}
              onClick={handleNext}
              secondaryStyles
              text={
                stepFourValidation
                  ? controls.activeStep === 3
                    ? 'Finalizar'
                    : 'Siguiente'
                  : controls.activeStep === 2
                    ? 'Finalizar'
                    : 'Siguiente'
              }
              startIcon={<DoubleArrowIcon />}
            />
          )}
        </Box>
      </>
    </Box>
  );
}

export default DocumentStepper;
