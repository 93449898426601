import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

/* Material UI */
import { Grid, Divider, Switch, Typography, FormControlLabel, Stack, alpha } from '@mui/material';
import { Folder, Notifications, Person } from '@mui/icons-material';
import { styled } from '@mui/styles';

/* React Redux */
import { useDispatch } from 'react-redux';

/* Project */
import color from 'utils/colors';
import {
  notificateDiscrepancy,
  getHearingParticipationsFile,
} from 'screens/DiscrepancyDetail/DiscrepancyDetails.actions';
import { useHistory, useLocation } from 'react-router-dom';
import DiscrepancyParticipantsFile from 'commons/components/DiscrepancyParticipantsFile/DiscrepancyParticipantsFile';

const PurpleSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: color.purpleSecondary,
    '&:hover': {
      backgroundColor: alpha(color.purpleSecondary, theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: color.purpleSecondary,
  },
}));

function DiscrepancyTop(props) {
  const { discrepancy, loaders, currentUser, objects } = props;

  const { number, createdAt, endedAt, id: discrepancyId } = discrepancy;
  const { notification, participantsFile } = loaders;
  const { discrepancies, discrepancyReports } = objects;

  const discrepancyReport = discrepancyReports[discrepancyId];
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const onEntities = location.pathname.includes('entities');
  const currentDiscrepancy = discrepancies[discrepancyId];
  const [hearing] = currentDiscrepancy ? currentDiscrepancy.hearing() : [];

  const currentAlerts = discrepancy.alerts();
  const lastdocumentId = Math.max(...discrepancy.documents().map((d) => d.id));

  const changeNotification = (e, notificate) => {
    dispatch(notificateDiscrepancy({ notificate, discrepancyId }));
  };

  const currentAlert = currentAlerts.find((alert) => alert.userId === currentUser?.id);

  const notifyToCurrentUser = currentAlert?.notify ? currentAlert.notify : false;

  const handleRedirection = (evt) => {
    evt.preventDefault();
    if (onEntities) {
      return history.push(`/discrepancies/${discrepancy.id}/expedient/${lastdocumentId}`);
    }

    return history.push(`/discrepancies/${discrepancy.id}/entities`);
  };

  const typographyStyles = {
    fontWeight: '400',
    paddingTop: '0px',
    textAlign: 'center',
    fontSize: '14px',
  };

  const notificationSwitch = (
    <>
      <FormControlLabel
        sx={{ margin: 'auto', mt: '-7px' }}
        disabled={notification}
        control={
          <PurpleSwitch
            checked={notifyToCurrentUser}
            onChange={changeNotification}
            sx={{ color: 'red' }}
          />
        }
        label={
          <Grid component="span" sx={{ display: 'flex' }}>
            <Notifications sx={{ color: color.purpleSecondary }} />
            <span style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
              <Typography sx={typographyStyles}>Recibir actualizaciones</Typography>
            </span>
          </Grid>
        }
        labelPlacement="start"
      />
    </>
  );

  useEffect(() => {
    if (discrepancyId !== undefined) {
      dispatch(getHearingParticipationsFile({ id: discrepancyId }));
    }
  }, [discrepancyId]);

  return (
    <Grid
      container
      direction="row"
      justifyContent={'space-between'}
      sx={{ mt: '10px', mb: '10px' }}>
      <Grid xs={12} sm={6} md={4} lg={1} sx={{ mb: '10px', minWidth: '160px' }}>
        <Typography sx={typographyStyles}>Número discrepancia: {number}</Typography>
      </Grid>
      <Grid xs={12} sm={6} md={4} lg={1} sx={{ mb: '10px' }}>
        <Typography sx={typographyStyles}>
          Ingreso: {new Date(createdAt).toLocaleDateString()}
        </Typography>
      </Grid>
      <Grid xs={12} sm={6} md={4} lg={2} sx={{ mb: '10px' }}>
        <Typography sx={typographyStyles}>
          Materia: {discrepancy?.matter()?.name.toUpperCase()}
        </Typography>
      </Grid>
      <Grid xs={12} sm={6} md={4} lg={2} sx={{ mb: '10px' }}>
        <Typography sx={typographyStyles}>
          Submateria: {discrepancy.legalSubMatter().name.toUpperCase()}
        </Typography>
      </Grid>
      <Grid
        xs={12}
        sm={6}
        md={4}
        lg={2}
        sx={{
          mb: '10px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent: 'center',
          width: '100%',
        }}>
        <Typography sx={typographyStyles}>
          Audiencia pública:{' '}
          {discrepancy.hearing().length
            ? new Date(discrepancy.hearing()[0].date).toLocaleDateString()
            : 'Pendiente'}
        </Typography>
        {discrepancyReport &&
          (currentUser?.type === 'admin' || currentUser?.email.includes('@panelexpertos.cl')) && (
            <DiscrepancyParticipantsFile
              discrepancyId={discrepancyId}
              discrepancyReport={discrepancyReport}
              loading={participantsFile}
            />
          )}
      </Grid>
      <Grid
        xs={12}
        sm={6}
        md={4}
        lg={2}
        sx={{
          mb: '10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          width: '100%',
        }}>
        {notificationSwitch}
      </Grid>
      <Grid xs={12} sm={6} md={4} lg={1} sx={{ mb: '10px' }}>
        <Typography sx={typographyStyles}>
          Estado: {discrepancy.endedAt ? 'Terminada' : 'En Tramitación'}
        </Typography>
      </Grid>
    </Grid>
  );
}

DiscrepancyTop.propTypes = {
  currentUser: PropTypes.shape({}),
  objects: PropTypes.shape({}).isRequired,
  discrepancy: PropTypes.shape({}).isRequired,
  loaders: PropTypes.shape({}).isRequired,
};

DiscrepancyTop.defaultProps = {
  currentUser: null,
};

export default DiscrepancyTop;
