import searchContentTypes from './SearchContent.types';

export const handleChange = ({ name, value }) => ({
  type: searchContentTypes.HANDLE_CHANGE,
  payload: { name, value },
});

export const resetControls = () => ({
  type: searchContentTypes.RESET_CONTROLS,
});

export const getAll = (payload) => ({
  type: searchContentTypes.GET_DOCUMENTS,
  payload: payload,
});

export const changePagination = ({ value }) => ({
  type: searchContentTypes.CHANGE_PAGINATION,
  payload: { value },
});

export const getFileLink = ({ driveId, itemId }) => ({
  type: searchContentTypes.GET_FILE_LINK,
  payload: { driveId, itemId },
});
