/* Redux */
import { combineReducers } from 'redux';

/* Reducers */
import home from 'screens/Home/Home.reducer';
import login from 'screens/Login/Login.reducer';
import app from 'commons/commons.reducer';
import files from 'screens/Upload/reducer/uploadFile.reducer';
import recoveryPassword from 'screens/RecoveryPassword/RecoveryPassword.reducer';
import discrepancyDetails from 'screens/DiscrepancyDetail/DiscrepancyDetails.reducer';
import audiencesCalendar from 'screens/AudiencesCalendar/AudiencesCalendar.reducer';
import lastDocuments from 'screens/LastDocuments/LastDocuments.reducer';
import searchContent from 'screens/SearchContent/SearchContent.reducer';

const reducers = combineReducers({
  home,
  login,
  app,
  files,
  recoveryPassword,
  discrepancyDetails,
  audiencesCalendar,
  lastDocuments,
  searchContent,
});

export default reducers;
