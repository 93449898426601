// @ts-nocheck
import React from 'react';
// material
import { TextField, MenuItem } from '@mui/material';

function CustomSelectField(props) {
  const {
    label,
    name,
    list,
    disabled,
    onChange,
    value,
    mapKey,
    sx = [],
    endAdornment,
    variant,
  } = props;

  const options = list.map((option) => (
    <MenuItem key={option.id} value={option.id}>
      {option[mapKey || 'label']}
    </MenuItem>
  ));

  const shrinkCondition = variant === 'standard' ? true : false;

  return (
    <TextField
      disabled={disabled}
      size="small"
      id={`outlined-select- ${label}`}
      select
      fullWidth
      label={label}
      color="secondary"
      variant={variant || 'outlined'}
      name={name}
      sx={[...(Array.isArray(sx) ? sx : [sx])]}
      value={value}
      onChange={onChange}
      InputLabelProps={{
        shrink: shrinkCondition,
      }}
      InputProps={{ endAdornment: endAdornment || null }}>
      {options}
    </TextField>
  );
}

export default CustomSelectField;
