/* eslint-disable func-names */
// @ts-nocheck
import { spawn, takeLatest, put } from 'redux-saga/effects';
import { fileActions, FILE_TYPES } from '../reducer/uploadFile.reducer';
import apiRequest from '../../../utils/api';

function* UploadFile() {
  yield takeLatest(FILE_TYPES.START_FILE_UPLOAD, function* (action) {
    const { payload } = action;

    if (!payload) {
      // TODO: CREAR MENSAJE DE ERROR.
      // eslint-disable-next-line no-console
      console.log('TEST No existe el archivo ...');
    }

    const data = new FormData();
    data.append('file', payload);

    const result = yield apiRequest(
      'api/v1/files/file',
      { method: 'post', body: data, headers: { Accept: 'application/json' } },
      false,
    );

    // eslint-disable-next-line no-console
    console.log('TEST RESP BACKEND', result);
    yield put(fileActions.endFileUpload());
  });
}

export default function* auctionManagerSaga() {
  yield spawn(UploadFile);
}
