import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';
import { handleDocumentBanner } from '../DiscrepancyDetails.actions';

const DiscrepancyMessageBanner = () => {
  const dispatch = useDispatch();
  const { objects } = useSelector((state) => state.app);
  const { openDocumentBanner } = useSelector((state) => state.discrepancyDetails);
  const { discrepancyMessage } = objects;

  const discrepancyMessageValue = useMemo(
    () => Object.values(discrepancyMessage).pop(),
    [discrepancyMessage],
  );

  const handleClose = () => {
    dispatch(handleDocumentBanner({ open: false }));
  };

  return (
    <>
      {discrepancyMessageValue?.enabled && (
        <Dialog
          open={openDocumentBanner}
          onClose={handleClose}
          PaperProps={{ sx: { width: '100%', minHeight: 200 } }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          {discrepancyMessageValue?.title && (
            <DialogTitle id="alert-dialog-title">
              {discrepancyMessageValue?.title?.toUpperCase()}
            </DialogTitle>
          )}
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div
                dangerouslySetInnerHTML={{
                  __html: `${discrepancyMessageValue?.message.replace(
                    /href/g,
                    "target='_blank' rel='noopener noreferrer' href",
                  )}`,
                }}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} autoFocus>
              cerrar
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default DiscrepancyMessageBanner;
