const colors = {
  cooper: '#B15410',
  grey: '#76777B',
  white: '#FFFFFF',
  blue: '#007BFF',
  red: '#E53935',
  darkGrey: '#343A40',
  cooperRgba: 'rgba(177, 84, 16, 1)',
  greyRgba: 'rgba(118, 119, 123, 1)',
  whiteRgba: 'rgba(255, 255, 255, 0)',
  shadowRgba: 'rgba(0,0,0,0.32)',
  darkGreyRgba: 'rgba(52, 58, 64, 1)',
  purple: '#260D1D',
  purpleSecondary: '#641C34',
  secondaryMain: '#87767F',
  secondaryBackground: '#E0DCDC',
  secondaryGrey: '#303740',
};

export default colors;
