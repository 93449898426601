import React from 'react';

/* material UI */
import { Typography } from '@mui/material';
import color from '../../../utils/colors';

function TitleField(props) {
  const { title } = props;

  return (
    <Typography
      variant="h6"
      sx={{
        fontSize: 'h6.fontSize',
        fontWeight: '500',
        color: color.purple,
        textTransform: 'uppercase',
        paddingTop: '.4rem',
        paddingBottom: '.4rem',
        lineHeight: '150%',
      }}>
      {title}
    </Typography>
  );
}

export default TitleField;
