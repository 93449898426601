import React, { Fragment } from 'react';

/* Material UI */
import { Collapse, Icon, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Article, ExpandLess, ExpandMore, Folder, FolderOpen } from '@mui/icons-material';

/* Project */
import pdfIcon from 'commons/assets/images/pdf-file.svg';
import wordIcon from 'commons/assets/images/word-file.svg';
import confidentialIcon from 'commons/assets/images/confidential-file.svg';

function AttachmentListItem(props) {
  const { attachmentPart, handleButtonClick, partOpen, setPartOpen, iframeDoc, handleDocFile } =
    props;

  const isOpen = partOpen === attachmentPart.id;

  const handleClick = () => {
    if (isOpen) {
      setPartOpen(null);
    } else {
      setPartOpen(attachmentPart.id);
    }
  };

  const specificPdfIcon = (attachment) => (attachment.confidential ? confidentialIcon : pdfIcon);

  return (
    <Fragment key={`attachment-part-${attachmentPart.id}`}>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>{isOpen ? <FolderOpen /> : <Folder />}</ListItemIcon>
        <ListItemText primary={attachmentPart.type === 'principal' ? 'Principal' : 'Adjunto'} />
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {attachmentPart.attachments().map((attachment) => (
            <ListItemButton
              selected={iframeDoc?.id === attachment.id}
              key={attachment.id}
              sx={{ pl: 4 }}
              onClick={() =>
                attachment.contentType === 'pdf'
                  ? handleButtonClick(attachment)
                  : handleDocFile(attachment)
              }>
              <ListItemIcon>
                {/* eslint-disable-next-line no-nested-ternary */}
                {attachment.contentType === 'pdf' ? (
                  <Icon sx={{ textAlign: 'center' }}>
                    <img
                      style={{
                        display: 'flex',
                        height: 'inherit',
                        width: 'inherit',
                      }}
                      src={specificPdfIcon(attachment)}
                      alt="pdfIcon"
                    />
                  </Icon>
                ) : attachment.contentType === 'doc' ||
                  attachment.contentType === 'docx' ||
                  attachment.contentType === 'word' ? (
                  <Icon sx={{ textAlign: 'center' }}>
                    <img
                      style={{
                        display: 'flex',
                        height: 'inherit',
                        width: 'inherit',
                      }}
                      src={wordIcon}
                      alt="wordIcon"
                    />
                  </Icon>
                ) : (
                  <Article />
                )}
              </ListItemIcon>
              <ListItemText
                primary={attachment.content
                  .split('_')
                  .pop()
                  .replace(/\.[^/\\.]+$/, '')
                  .substring(0, 10)}
              />
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </Fragment>
  );
}

export default AttachmentListItem;
