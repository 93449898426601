import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Alert, Box, Grid, IconButton, InputAdornment, Stack, Typography } from '@mui/material';
import color from 'utils/colors';
import CustomTextField from 'commons/components/CustomTextField/CustomTextField';
import { Delete, FileUpload } from '@mui/icons-material';
import { appSetError } from 'commons/commons.actions';
import { isZippedFile } from 'utils/functions';

function DocumentStepTwo(props) {
  const {
    fileMainPdf,
    fileMainWord,
    fileMainConfidential,
    setFileMainConfidential,
    setFileMainPdf,
    setFileMainWord,
  } = props;

  const dispatch = useDispatch();

  const handleFileChange = ({ target: { name, files } }) => {
    if (name === 'mainPdf') {
      if (files[0] &&
        !isZippedFile(files[0].name) &&
        files[0].type === 'application/pdf') {
        return setFileMainPdf(files[0]);
      }
      setFileMainPdf(null);
      dispatch(appSetError({ msg: 'El archivo debe ser de tipo PDF' }));
    }
    if (name === 'mainWord') {
      if (files[0] &&
        !isZippedFile(files[0].name) &&
        (files[0].type ===
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
          files[0].type === 'application/msword')
      ) {
        return setFileMainWord(files[0]);
      }
      setFileMainWord(null);
      dispatch(appSetError({ msg: 'El archivo debe ser un documento .doc o .docx' }));
    }
    if (name === 'mainConfidential') {
      if (files[0] && !isZippedFile(files[0].name)) {
        return setFileMainConfidential(files[0]);
      }
      setFileMainConfidential(null);
      dispatch(appSetError({ msg: 'El archivo no puede ser una carpeta comprimida' }));
    }
    return null;
  };

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row', mb: 2 }}>
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: 'bold',
            color: color.blue,
            textTransform: 'uppercase',
            mr: 1,
          }}>
          SUBIR
        </Typography>
        <Typography
          sx={{
            fontWeight: 'regular',
            color: color.blue,
            fontSize: 16,
          }}>
          Archivos principales
        </Typography>
      </Box>
      <Grid item xs={12} sx={{ mb: 2 }}>
        <Alert severity="info">
          {'¡Advertencia! Evita el uso de los siguientes caracteres especiales al nombrar archivos: / \\ : * ? < > \" | ()'}
        </Alert>
      </Grid>
      <Stack spacing={2}>
        <CustomTextField
          name="mainPdf"
          label="Archivo principal PDF"
          value={fileMainPdf?.name || ''}
          inputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <label htmlFor="mainPdf">
                  <input
                    accept="application/pdf"
                    id="mainPdf"
                    name="mainPdf"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                  />
                  <IconButton sx={{ color: color.blue }} aria-label="mainPdf" component="span">
                    <FileUpload />
                  </IconButton>
                </label>
              </InputAdornment>
            ),
          }}
        />
        <CustomTextField
          name="mainWord"
          label="Archivo principal WORD"
          value={fileMainWord?.name || ''}
          inputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <label htmlFor="mainWord">
                  <input
                    accept=".doc, .docx"
                    id="mainWord"
                    name="mainWord"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                  />
                  <IconButton sx={{ color: color.blue }} aria-label="mainWord" component="span">
                    <FileUpload />
                  </IconButton>
                </label>
              </InputAdornment>
            ),
          }}
        />
        {/* INFO: Cliente requiere retirar la opción de agregar archivos confidenciales
        <CustomTextField
          name="mainConfidential"
          label="Archivo confidencial PDF"
          value={fileMainConfidential?.name || ''}
          inputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <label htmlFor="mainConfidential">
                  <input
                    accept="application/pdf"
                    id="mainConfidential"
                    name="mainConfidential"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                  />
                  <IconButton
                    sx={{ color: color.blue }}
                    aria-label="mainConfidential"
                    component="span">
                    <FileUpload />
                  </IconButton>
                </label>
                <IconButton
                  sx={{ color: color.blue }}
                  aria-label="mainConfidential"
                  component="span"
                  onClick={() => setFileMainConfidential(null)}>
                  <Delete />
                </IconButton>
              </InputAdornment>
            ),
          }}
        /> */}
      </Stack>
    </>
  );
}

DocumentStepTwo.propTypes = {
  fileMainPdf: PropTypes.shape({}),
  fileMainWord: PropTypes.shape({}),
  fileMainConfidential: PropTypes.shape({}),
  setFileMainConfidential: PropTypes.func.isRequired,
  setFileMainPdf: PropTypes.func.isRequired,
  setFileMainWord: PropTypes.func.isRequired,
};

DocumentStepTwo.defaultProps = {
  fileMainPdf: null,
  fileMainWord: null,
  fileMainConfidential: null,
};

export default DocumentStepTwo;
