import camelize from 'camelcase-keys-deep';

export default class DiscrepancyReport {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  discrepancy() {
    return this.env().Discrepancy.getById(this.id);
  }
}
