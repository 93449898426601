import React from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid,
} from '@mui/material';

import color from 'utils/colors';
import PrimaryButton from 'commons/components/PrimaryButton/PrimaryButton';
import SecondaryButton from 'commons/components/SecondaryButton/SecondaryButton';

function DownloadAttachmentModal(props) {
  const { openModal, setOpenModal, attachment } = props;

  const handleDownload = (atch) => {
    window.open(atch?.url, '_blank');
    setOpenModal(false);
  };

  const downloadDocument = (filePath, fileName = 'File.pdf') => {
    fetch(filePath, {
      method: 'GET',
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));

        const link = document.createElement('a');
        link.href = url;
        link.download = fileName.split('_')[1];

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
        setOpenModal(false);
      });
  };

  return (
    <Dialog fullWidth open={openModal}>
      <DialogTitle>
        <Typography
          sx={{
            fontWeight: 'regular',
            color: color.cooper,
            fontSize: 20,
          }}>
          Confirmar descarga de archivo
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>{attachment?.content.split('_').pop()}</Typography>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-between">
          <SecondaryButton onClick={() => setOpenModal(!openModal)}>Cancelar</SecondaryButton>
          <PrimaryButton
            color="primary"
            onClick={() => downloadDocument(attachment.url, attachment.content)}>
            Aceptar
          </PrimaryButton>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default DownloadAttachmentModal;
