import React, { useState, useEffect } from 'react';

/* Material UI */
import { Grid, Box, Typography, CardActionArea, useMediaQuery, Button } from '@mui/material';

/* React router dom */
import { useParams } from 'react-router-dom';

/* Project */
import color from 'utils/colors';
import AttachmentsList from 'screens/DiscrepancyDetail/components/AttachmentsList';
import DocumentList from 'screens/DiscrepancyDetail/components/DocumentList';
import DownloadAttachmentModal from 'screens/DiscrepancyDetail/components/DownloadAttachmentModal';
import VideoFrame from 'commons/components/VideoFrame';
import { useTheme } from '@mui/styles';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PrimaryButton from 'commons/components/PrimaryButton/PrimaryButton';
import DocumentsView from './DocumentsView';

function ExpedientDetail(props) {
  const { objects, currentUser, setOpenIndex, attachmentId } = props;
  const { documents, discrepancies } = objects;

  const [iframeDoc, setIframeDoc] = useState(null);
  const [partOpen, setPartOpen] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [currentAttachment, setCurrentAttachment] = useState(null);

  const theme = useTheme();
  const isMediumPage = useMediaQuery(theme.breakpoints.down('lg'));
  const isSmallPage = useMediaQuery(theme.breakpoints.down('md'));
  const { id: discrepancyId, documentId } = useParams();
  const currentDocument = documents[documentId];
  const checksum = discrepancies[discrepancyId]?.checksum;

  const attachmentParts = currentDocument?.attachmentParts();
  const documentType = currentDocument.documentType();
  const attachments = currentDocument?.attachments();

  const pdfAttachments = attachments?.filter((attachment) => attachment.contentType === 'pdf');
  const initialAttachment = pdfAttachments[0];
  const selectedAttachment = pdfAttachments.filter((obj) => obj.id == attachmentId);
  const initialSelectedAttachment = selectedAttachment[0];

  const [hearing] = currentDocument ? currentDocument.hearing() : [];
  const pageNum = initialAttachment?.pageNumber || 0;

  useEffect(() => {
    if (initialSelectedAttachment) {
      setIframeDoc(initialSelectedAttachment);
      setPartOpen(initialSelectedAttachment.attachmentPart().id);
    } else if (initialAttachment) {
      setIframeDoc(initialAttachment);
      setPartOpen(initialAttachment.attachmentPart().id);
    } else {
      setIframeDoc(null);
    }
  }, [currentDocument]);

  const handleButtonClick = (doc) => {
    setIframeDoc(doc);
  };

  const handleDocFile = (doc) => {
    setIsOpen(true);
    setCurrentAttachment(doc);
  };

  const updateHearingUrl = () => {
    if (hearing) {
      // openEditUrlDialog();
    }
  };

  const downloadPdf = (filePath, fileName = 'Example-PDF-file.pdf') => {
    fetch(filePath, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));

        const link = document.createElement('a');
        link.href = url;
        link.download = fileName.split('_')[1];

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
      });
  };

  const detail = (
    <Grid
      item
      xs={12}
      md={9}
      lg={9}
      sx={{
        marginTop: isMediumPage ? '1rem' : 0,
        marginBottom: isSmallPage ? '1rem' : 0,
      }}>
      <Box
        sx={{
          background: `linear-gradient(180deg, ${color.darkGreyRgba} 40%, ${color.whiteRgba} 1%)`,
        }}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ padding: '15px 10px' }}>
          <Typography sx={{ color: color.white, pl: 1 }}>
            {iframeDoc?.content.split('_').pop()}
          </Typography>
          <PrimaryButton
            endIcon={<FileDownloadIcon />}
            onClick={() => downloadPdf(iframeDoc?.url, iframeDoc?.content)}>
            descargar documento
          </PrimaryButton>
        </Grid>

        <div
          style={{
            position: 'relative',
            paddingBottom: '53%',
            paddingTop: '25px',
            height: 0,
            width: '99.8%',
          }}>
          <iframe
            src={iframeDoc?.url}
            title={iframeDoc?.content}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
          />
        </div>
        {pageNum > 0 && iframeDoc?.id === initialAttachment?.id && (
          <Typography
            fontFamily="Roboto"
            sx={{
              fontSize: '0.7rem',
              fontWeight: 'medium',
              color: color.grey,
              pt: 1,
            }}>
            Total: {pageNum} paginas
          </Typography>
        )}
      </Box>
    </Grid>
  );

  return (
    <Grid container columnSpacing={4} sx={{ marginTop: '1rem' }}>
      <DocumentList
        objects={objects}
        discrepancyID={discrepancyId}
        currentUser={currentUser}
        hearing={hearing}
        updateHearingUrl={updateHearingUrl}
        isSmallPage={isSmallPage}
        checksum={checksum}
      />
      {documentType.code === 'audiences' && (
        <Grid
          item
          lg={10}
          md={6}
          xs={12}
          sx={{
            padding: '50px',
          }}>
          <CardActionArea
            sx={{
              height: '100%',
              width: '100%',
            }}>
            <VideoFrame url={hearing?.url} />
          </CardActionArea>
        </Grid>
      )}

      {attachmentParts.length > 0 && (
        <>
          {isMediumPage ? null : (
            <DocumentsView
              attachmentParts={attachmentParts}
              currentUser={currentUser}
              objects={objects}
              discrepancyID={discrepancyId}
              currentDocument={currentDocument}
            />
          )}
          {isMediumPage ? (
            <DocumentsView
              attachmentParts={attachmentParts}
              currentUser={currentUser}
              objects={objects}
              discrepancyID={discrepancyId}
              currentDocument={currentDocument}
            />
          ) : null}
        </>
      )}
      <DownloadAttachmentModal
        openModal={isOpen}
        setOpenModal={setIsOpen}
        attachment={currentAttachment}
      />
    </Grid>
  );
}

export default ExpedientDetail;
