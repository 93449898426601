import commonsTypes from 'commons/commons.types';
import {
  RESET_CAPTCHA,
  SET_ERROR,
  SHOW_CAPTCHA_ERROR,
  VALIDATE_CAPTCHA,
} from 'commons/commons.reducer';
/**
 * @param {{ name: String, value: String|Number|Boolean|Array }} payload
 * @returns {{payload, type: string}}
 */
export const changeForm = ({ name, value }) => ({
  type: commonsTypes.CHANGE_DISCREPANCY_FORM,
  payload: { name, value },
});

/**
 * Change controls in discrepancy creation modal
 * @param {{ name: String, value: String|Number|Boolean }} payload
 * @returns {{payload: Object, type: string}}
 */
export const changeControls = ({ name, value }) => ({
  type: commonsTypes.CHANGE_DISCREPANCY_CREATION_CONTROLS,
  payload: { name, value },
});

/**
 * @param {{ name: String, value: String|Number|Boolean }} payload
 * @returns {{payload, type: string}}
 */
export const changeLegalEntityForm = ({ name, value }) => ({
  type: commonsTypes.CHANGE_LEGAL_ENTITY_FORM,
  payload: { name, value },
});

/**
 * @param {{ name: String, value: String|Number|Boolean }} payload
 * @returns {{payload, type: string}}
 */
export const clearLegalEntityForm = () => ({
  type: commonsTypes.CLEAR_LEGAL_ENTITY_FORM,
  payload: {},
});

/**
 * Change controls in legal entity creation modal
 * @param {{ name: String, value: String|Number|Boolean }} payload
 * @returns {{payload: Object, type: string}}
 */
export const changeLegalEntityControls = ({ name, value }) => ({
  type: commonsTypes.CHANGE_LEGAL_ENTITY_CREATION_CONTROLS,
  payload: { name, value },
});

/**
 * @param {{ name: String, value: String|Number|Boolean }} payload
 * @returns {{payload, type: string}}
 */
export const changeNaturalEntityForm = ({ name, value }) => ({
  type: commonsTypes.CHANGE_NATURAL_ENTITY_FORM,
  payload: { name, value },
});

/**
 * Change controls in natural entity creation modal
 * @param {{ name: String, value: String|Number|Boolean }} payload
 * @returns {{payload: Object, type: string}}
 */
export const changeNaturalEntityControls = ({ name, value }) => ({
  type: commonsTypes.CHANGE_NATURAL_ENTITY_CREATION_CONTROLS,
  payload: { name, value },
});

/**
 * Change controls in natural entity creation modal
 * @param {{ name: String, value: String|Number|Boolean }} payload
 * @returns {{payload: Object, type: string}}
 */
export const changeDiscrepancyMessage = ({ name, value }) => ({
  type: commonsTypes.CHANGE_DISCREPANCY_MESSAGE_CONTROLS,
  payload: { name, value },
});

/**
 * Change controls in natural entity creation modal
 * @param {{ name: String, value: String|Number|Boolean }} payload
 * @returns {{payload: Object, type: string}}
 */
export const openDiscrepancyMessageDialog = ({ name, value }) => ({
  type: commonsTypes.OPEN_DISCREPANCY_MESSAGE_DIALOG,
  payload: { name, value },
});

/**
 * @param {{ id: String, title: String, message: String, enabled: Boolean }} payload
 * @returns {{payload: {id, title, message, enabled}, type: string}}
 */
export const createDiscrepancyMessage = ({ id, title, message, enabled }) => ({
  type: commonsTypes.UPDATE_DISCREPANCY_MESSAGE,
  payload: {
    id,
    title,
    message,
    enabled,
  },
});

/**
 * @param {{ title: String, message: String, noTitle: Boolean, enabled: Boolean }} payload
 * @returns {{payload: { title, message, noTitle, enabled}, type: string}}
 */
export const loadDiscrepancyMessage = ({ title, message, noTitle, enabled }) => ({
  type: commonsTypes.LOAD_DISCREPANCY_MESSAGE,
  payload: {
    title,
    message,
    noTitle,
    enabled,
  },
});

/**
 * Change controls in legal powers modal
 * @param {{ name: String, value: String|Number|Boolean }} payload
 * @returns {{payload: Object, type: string}}
 */
export const changeLegalPowersControls = ({ name, value }) => ({
  type: commonsTypes.CHANGE_LEGAL_POWERS_MODAL_CONTROLS,
  payload: { name, value },
});

/**
 * Change controls in representatives modal
 * @param {{ name: String, value: String|Number|Boolean }} payload
 * @returns {{payload: Object, type: string}}
 */
export const changeRepresentativesControls = ({ name, value }) => ({
  type: commonsTypes.CHANGE_REPRESENTATIVES_MODAL_CONTROLS,
  payload: { name, value },
});

/**
 * @param {{ subMatterId: Number, cover: String, legalEntityId:
 *   Number,position: String,index: String, pageNumber: number, representationFile: Object,
 *   representationWordFile: Object, representationConfidential: Object, attachmentsArray: Array }} payload
 * @returns {{payload: {cover, subMatterId, legalEntityId, pageNumber, index, position, files}, type: string}}
 */
export const createDiscrepancy = ({
  subMatterId,
  cover,
  legalEntityId,
  position,
  index,
  pageNumber,
  representationFile,
  representationWordFile,
  representationConfidential,
  attachmentsArray,
  // presentationLimit,
}) => ({
  type: commonsTypes.CREATE_DISCREPANCY,
  payload: {
    subMatterId,
    cover,
    legalEntityId,
    index,
    // presentationLimit,
    position,
    pageNumber,
    files: [representationFile, representationWordFile, representationConfidential],
    attachments: attachmentsArray,
  },
});

/**
 * @param name
 * @param rut
 * @param email
 * @param phone
 * @param address
 * @param password
 * @param confirmPassword
 * @param legalPowersArray
 * @param representativesArray
 * @returns {{payload: {name, rut, legalPowersArray: *[], representativesArray: *[],
 *   email, phone, address, password, confirmPassword}, type: string}}
 */
export const createLegalEntity = ({
  name,
  rut,
  email,
  phone,
  address,
  password,
  confirmPassword,
  legalPowersArray,
  representativesArray,
}) => ({
  type: commonsTypes.CREATE_LEGAL_ENTITY,
  payload: {
    name,
    rut,
    email,
    phone,
    address,
    password,
    confirmPassword,
    legalPowersArray,
    representativesArray,
  },
});

/**
 * @param {{ names: String, surnames: String, rut:
 *   String,email: String, phone: String,
 *   password: String, confirmPassword: String }} payload
 * @returns {{payload: {names, surnames, rut, email, phone, password, confirmPassword}, type: string}}
 */
export const createNaturalEntity = ({
  names,
  surnames,
  rut,
  email,
  phone,
  password,
  confirmPassword,
}) => ({
  type: commonsTypes.CREATE_NATURAL_ENTITY,
  payload: {
    names,
    surnames,
    rut,
    email,
    phone,
    password,
    confirmPassword,
  },
});

/**
 * @returns {{type: string}}
 */
export const logout = (payload) => ({
  type: commonsTypes.LOGOUT,
  payload: { ...payload },
});

/**
 * @param {{ open: Boolean }} payload
 * @returns {{payload: {open}, type: string}}
 */
export const toggleUpdateDiscrepancyModal = ({ open }) => ({
  type: commonsTypes.TOGGLE_UPDATE_DISCREPANCY_MODAL,
  payload: { open },
});

/**
 *
 * @param {{
 *  discrepancyId, cover, subMatterId, presentation, wordPresentation, endedAt
 * }} payload
 * @returns {{payload: {cover, presentation, subMatterId, discrepancyId,
 *   wordPresentation, endedAt, }, type: string}}
 */
export const updateDiscrepancy = ({
  discrepancyId,
  cover,
  subMatterId,
  presentation,
  wordPresentation,
  endedAt,
  currentPdfPresentation,
  currentWordPresentation,
  oldValues,
}) => ({
  type: commonsTypes.UPDATE_DISCREPANCY,
  payload: {
    discrepancyId,
    cover,
    subMatterId,
    presentation,
    wordPresentation,
    endedAt,
    currentPdfPresentation,
    currentWordPresentation,
    oldValues,
  },
});

/**
 *
 * @param { discrepancyId, history } payload
 * @returns { { type: string, payload: { discrepancyId, history } } }
 */
export const deleteDiscrepancyAction = ({ discrepancyId, history }) => ({
  type: commonsTypes.DELETE_DISCREPANCY,
  payload: { discrepancyId, history },
});

/**
 * @param {{ name: String, value: String|Number|Boolean }} payload
 * @returns {{payload, type: string}}
 */
export const changeEditNaturalEntityForm = ({ name, value }) => ({
  type: commonsTypes.CHANGE_EDIT_NATURAL_ENTITY_FORM,
  payload: { name, value },
});

/**
 * Change controls in natural entity creation modal
 * @param {{ name: String, value: String|Number|Boolean }} payload
 * @returns {{payload: Object, type: string}}
 */
export const changeEditNaturalEntityControls = ({ name, value }) => ({
  type: commonsTypes.CHANGE_EDIT_NATURAL_ENTITY_CONTROLS,
  payload: { name, value },
});

/**
 * @param {{ names: String, surnames: String, rut:
 *   String,email: String, phone: String,
 *   password: String, confirmPassword: String, userId: Number }} payload
 * @returns {{payload: {names, surnames, rut, email, phone, password, confirmPassword, userId}, type: string}}
 */
export const updateNaturalEntity = ({
  names,
  surnames,
  rut,
  email,
  phone,
  password,
  confirmPassword,
  userId,
}) => ({
  type: commonsTypes.EDIT_NATURAL_ENTITY,
  payload: {
    names,
    surnames,
    rut,
    email,
    phone,
    password,
    confirmPassword,
    userId,
  },
});

/**
 * @param {{ name: String, surnames: String, rut:
 *   String, email: String, phone: String, address: String,
 *   password: String, confirmPassword: String, userId: Number, representativesArray: *[] }} payload
 * @returns {{payload: {names, surnames, rut, email, phone, password, confirmPassword, userId}, type: string}}
 */
export const updateLegalEntity = ({
  name,
  rut,
  email,
  phone,
  address,
  password,
  confirmPassword,
  userId,
  representativesArray,
}) => ({
  type: commonsTypes.EDIT_LEGAL_ENTITY,
  payload: {
    name,
    rut,
    email,
    phone,
    address,
    password,
    confirmPassword,
    userId,
    representativesArray,
  },
});

/**
 * @param {{ name: String, value: String|Number|Boolean }} payload
 * @returns {{payload, type: string}}
 */
export const changeEditLegalEntityForm = ({ name, value }) => ({
  type: commonsTypes.CHANGE_EDIT_LEGAL_ENTITY_FORM,
  payload: { name, value },
});

/**
 * Change controls in natural entity creation modal
 * @param {{ name: String, value: String|Number|Boolean }} payload
 * @returns {{payload: Object, type: string}}
 */
export const changeEditLegalEntityControls = ({ name, value }) => ({
  type: commonsTypes.CHANGE_EDIT_LEGAL_ENTITY_CONTROLS,
  payload: { name, value },
});

/**
 * Change controls in legal powers modal
 * @param {{ name: String, value: String|Number|Boolean }} payload
 * @returns {{payload: Object, type: string}}
 */
export const changeEditLegalPowersControls = ({ name, value }) => ({
  type: commonsTypes.CHANGE_EDIT_LEGAL_POWERS_MODAL_CONTROLS,
  payload: { name, value },
});

/**
 * Change controls in representatives modal
 * @param {{ name: String, value: String|Number|Boolean }} payload
 * @returns {{payload: Object, type: string}}
 */
export const changeEditRepresentativesControls = ({ name, value }) => ({
  type: commonsTypes.CHANGE_EDIT_REPRESENTATIVES_MODAL_CONTROLS,
  payload: { name, value },
});

export const setLegalEntityErrorForms = (payload) => ({
  type: commonsTypes.SET_LEGAL_ENTITY_ERROR_FORMS,
  payload,
});

export const setNaturalEntityErrorForms = (payload) => ({
  type: commonsTypes.SET_NATURAL_ENTITY_ERROR_FORMS,
  payload,
});

export const openConfirmDeleteLegalPowerAction = ({ powerLegalFileId }) => ({
  type: commonsTypes.OPEN_CONFIRM_DELETE_LEGAL_POWER_DIALOG,
  payload: { powerLegalFileId },
});

export const closeConfirmDeleteLegalPowerAction = () => ({
  type: commonsTypes.CLOSE_CONFIRM_DELETE_LEGAL_POWER_DIALOG,
});

export const deleteLegalPower = ({ powerLegalFileId }) => ({
  type: commonsTypes.DELETE_LEGAL_POWER,
  payload: { powerLegalFileId },
});

export const createLegalPower = ({ legalPowerFile, userId }) => ({
  type: commonsTypes.CREATE_LEGAL_POWER,
  payload: { legalPowerFile, userId },
});

/**
 *
 * @param {{ title: string, message: string, status: string }} params
 * @returns {{payload: { title: string, message: string, status: string }, type: string}}
 *
 */
export const openGeneralAlert = ({ title, message, status }) => ({
  type: commonsTypes.OPEN_GENERAL_ALERT,
  payload: { title, message, status },
});

export const closeGeneralAlert = (payload) => ({
  type: commonsTypes.CLOSE_GENERAL_ALERT,
  payload: payload,
});

export const appSetError = (payload) => ({
  type: SET_ERROR,
  payload: payload,
});

export const validateCaptcha = ({ secretKey, token }) => ({
  type: VALIDATE_CAPTCHA,
  payload: { secretKey, token },
});

export const resetCaptcha = () => ({
  type: RESET_CAPTCHA,
});

export const showCaptchaError = ({ show }) => ({
  type: SHOW_CAPTCHA_ERROR,
  payload: show,
});
