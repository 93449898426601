import React, { useState, useEffect, useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

/* Material */
import { Container, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';

/* React Redux */
import { useDispatch, useSelector } from 'react-redux';

/* Project */
import {
  getDiscrepancy,
  removeDiscrepancy,
} from 'screens/DiscrepancyDetail/DiscrepancyDetails.actions';
import DiscrepancyTop from 'commons/components/DiscrepancyTop/DiscrepancyTop';
import ExpedientDetail from 'screens/DiscrepancyDetail/components/ExpedientDetail';
import Title from 'screens/DiscrepancyDetail/components/Title';
import { reselectObjects } from 'utils/helper';
import DeleteDocumentDialog from 'screens/DiscrepancyDetail/DeleteDocumentDialog';
import PageLoader from 'commons/components/PageLoader';
import UpdateDocumentDialog from './UpdateDocumentDialog';
import { openGeneralAlert } from 'commons/commons.actions';
import DiscrepancyTabs from './components/DiscrepancyTabs';
import DiscrepancyEntities from 'screens/InterestedParties/DiscrepancyEntities';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}
function DiscrepancyDetails() {
  const { id, documentId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [openIndex, setOpenIndex] = useState(false);
  const query = useQuery();
  const { discrepancyDetails: discrepancyDetailsState, app: appState } = useSelector(
    (state) => state,
  );
  const { objects: objectsState, user: currentUser } = appState;

  const objects = useMemo(() => reselectObjects(objectsState), [objectsState]);

  const { loaders, document: documentState, view } = discrepancyDetailsState;
  const { discrepancies, documents } = objects;
  const currentDiscrepancy = discrepancies[id];
  const currentDocument = documents[documentId];
  const currentDiscrepancyDocuments = currentDiscrepancy?.documents();
  const attachmentId = query.get('attachmentId');

  useEffect(() => {
    dispatch(getDiscrepancy({ id, history, view: 'main' }));

    return function cleanup() {
      dispatch(removeDiscrepancy());
    };
  }, [id]);

  useEffect(() => {
    if (currentDiscrepancy) {
      if (!currentDocument || currentDocument?.discrepancyId !== Number(id)) {
        dispatch(
          openGeneralAlert({
            title: 'Documento no encontrado',
            message: 'El documento no existe o ha sido eliminado',
            status: 'error',
          }),
        );
        const discrepancyDocuments = currentDiscrepancy?.documents();
        const firstDocument = discrepancyDocuments ? discrepancyDocuments[0] : null;
        history.replace(`/discrepancies/${id}/expedient/${firstDocument.id}`);
      }
    }
  }, [currentDiscrepancy, currentDocument]);

  if (!currentDiscrepancy || loaders.discrepancyData) return <PageLoader />;

  const indexDialog = currentDocument ? (
    <Dialog open={openIndex} onClose={() => setOpenIndex(false)} maxWidth="md" fullWidth>
      <DialogTitle>Índice del documento</DialogTitle>
      <DialogContent>
        <Typography
          dangerouslySetInnerHTML={{
            __html: currentDocument.index.replace(/\n/gi, '<br />'),
          }}
        />
      </DialogContent>
    </Dialog>
  ) : null;

  const deleteDocumentDialog = currentDocument ? (
    <DeleteDocumentDialog
      objects={objects}
      documentState={documentState}
      history={history}
      currentDiscrepancy={currentDiscrepancy}
      currentDiscrepancyDocuments={currentDiscrepancyDocuments}
    />
  ) : null;

  const updateDocumentDialog = currentDocument ? (
    <UpdateDocumentDialog
      objects={objects}
      documentState={documentState}
      currentDocument={currentDocument}
      currentDiscrepancy={currentDiscrepancy}
      documentTypes={objects.documentTypes}
      attachmentsParts={currentDocument.attachmentParts()}
    />
  ) : null;

  return loaders.discrepancyData ? null : (
    <Container maxWidth={false}>
      <Title discrepancy={currentDiscrepancy} currentUser={currentUser} history={history} />
      <Grid container spacing={1}>
        <DiscrepancyTop
          currentUser={currentUser}
          discrepancy={currentDiscrepancy}
          loaders={loaders}
          objects={objects}
        />
        <DiscrepancyTabs discrepancy={currentDiscrepancy} />
        {currentDocument ? (
          <ExpedientDetail
            objects={objects}
            currentUser={currentUser}
            attachmentId={attachmentId}
            setOpenIndex={setOpenIndex}
          />
        ) : null}
        {indexDialog}
        {deleteDocumentDialog}
        {updateDocumentDialog}
      </Grid>
    </Container>
  );
}

export default DiscrepancyDetails;
