import React from 'react';

/* Material UI */
import { TextField } from '@mui/material';

function CustomTextField(props) {
  const { label, sx = [], disabled, name = '', onChange, inputProps, value, type, variant } = props;

  const valueCondition = value ? true : false;
  const shrinkCondition = variant === 'standard' ? true : valueCondition;

  return (
    <TextField
      name={name}
      disabled={disabled}
      size="small"
      InputProps={inputProps}
      fullWidth
      label={label}
      variant={variant || 'outlined'}
      onChange={onChange}
      color="secondary"
      value={value}
      type={type}
      InputLabelProps={{
        shrink: shrinkCondition,
      }}
      sx={[...(Array.isArray(sx) ? sx : [sx])]}
    />
  );
}

export default CustomTextField;
