import React, { useMemo } from 'react';

/* Material UI */
import { Snackbar, Grid, Alert } from '@mui/material';

/* React Redux */
import { useDispatch, useSelector } from 'react-redux';

/* React Router */
import { useParams } from 'react-router-dom';

/* Project */
import { composedComponent } from 'utils/functions';
import DiscrepancyUpdateDialog from 'commons/components/DiscrepancyUpdateDialog';
import { reselectObjects } from 'utils/helper';
import GeneralAlert from 'commons/components/GeneralAlert/GeneralAlert';
import saga from '../../commons.saga';
import NavBar from '../../components/NavBar/NavBar';
import { appActions } from '../../commons.reducer';
import { closeGeneralAlert } from '../../commons.actions';

function Layout(props) {
  const { actions, children } = props;
  const { id: discrepancyId } = useParams();
  const { app: appState, discrepancyDetails } = useSelector((state) => state);
  const dispatch = useDispatch();

  const {
    loaders: { discrepancyData: loadingDiscrepancy },
  } = discrepancyDetails;

  const {
    errorMsg,
    error,
    successMsg,
    success,
    user,
    openUpdateDiscrepancyDialog,
    updateDiscrepancyLoader,
    objects: objectsState,
    loaders,
    generalAlert,
  } = appState;

  const objects = useMemo(() => reselectObjects(objectsState), [objectsState]);

  const currentDiscrepancy = discrepancyId ? objects.discrepancies[discrepancyId] : null;

  const logOut = () => {
    localStorage.clear();
    actions.setUser(null);
  };

  const toggleError = () => {
    actions.toggleError();
  };

  const toggleSuccess = () => {
    actions.toggleSuccess();
  };

  const onCloseAlert = () => {
    dispatch(
      closeGeneralAlert({
        title: generalAlert.title,
        message: generalAlert.message,
        status: generalAlert.status,
        open: false,
      }),
    );
  };

  const loadDiscrepancyDialog =
    discrepancyId && currentDiscrepancy && !loadingDiscrepancy && user?.type === 'admin';

  return (
    <>
      <NavBar logOut={logOut} user={user} objects={objects} />
      <div className="content-container">
        <Grid container justifyContent="center">
          <Grid container item xs={12}>
            {children}
          </Grid>
        </Grid>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={error}
        autoHideDuration={3000}
        onClose={toggleError}>
        <Alert onClose={toggleError} severity="error">
          {errorMsg}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={success}
        autoHideDuration={3000}
        onClose={toggleSuccess}>
        <Alert elevation={6} variant="filled" onClose={toggleSuccess} severity="success">
          {successMsg}
        </Alert>
      </Snackbar>
      <GeneralAlert
        isOpen={generalAlert.open}
        message={generalAlert.message}
        title={generalAlert.title}
        status={generalAlert.status}
        handleOnClose={onCloseAlert}
      />

      {loadDiscrepancyDialog ? (
        <DiscrepancyUpdateDialog
          openUpdateDiscrepancyDialog={openUpdateDiscrepancyDialog}
          updateDiscrepancyLoader={updateDiscrepancyLoader}
          objects={objects}
          deleteDiscrepancyLoader={loaders.deleteDiscrepancy}
        />
      ) : null}
    </>
  );
}

export default composedComponent(Layout, saga, {
  saga: 'sagaLayout',
  actions: [appActions],
});
