import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import axios from 'axios';
import {
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadIcon from '@mui/icons-material/Download';
import moment from 'moment';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { selectViewDocument, downloadAllFilesRequest } from '../DiscrepancyDetails.actions';
import { call, delay } from 'redux-saga/effects';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const DocumentsViewList = (props) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const { formatedData, currentDocument, currentUser } = props;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const initialPaginationState = formatedData.reduce((acc, _, index) => {
    acc[index] = { page: 0, rowsPerPage: 5 };
    return acc;
  }, {});

  const [pagination, setPagination] = useState({
    0: { page: 0, rowsPerPage: 5 },
    1: { page: 0, rowsPerPage: 5 }
  });

  useEffect(() => {
    setPagination({
      0: { page: 0, rowsPerPage: 5 },
      1: { page: 0, rowsPerPage: 5 }
    })
  }, [currentDocument])


  const handleChangePage = (tabIndex, newPage) => {
    setPagination((prev) => ({
      ...prev,
      [tabIndex]: { ...prev[tabIndex], page: newPage },
    }));
  };

  const handleChangeRowsPerPage = (tabIndex, event) => {
    setPagination((prev) => ({
      ...prev,
      [tabIndex]: { page: 0, rowsPerPage: parseInt(event.target.value, 10) },
    }));
  };

  useEffect(() => {
    return () => {
      setValue(0);
    };
  }, [currentDocument]);

  const selectedData = useMemo(() => formatedData[value], [value, formatedData]);

  const colorIconValidation = (type) => {
    switch (type) {
      case 'pdf':
        return '#F40F02';
      case 'doc':
        return '#103F91';
      case 'docx':
        return '#103F91';
      case 'xlsx':
        return '#1D6F42';
      default:
        return '#9e9e9e';
    }
  };

  const openDocument = (attachment) => {
    dispatch(selectViewDocument({ document: attachment }));
  };

  const downloadPdf = (filePath, fileName = 'Example-PDF-file.pdf') => {
    axios(
      filePath,
      {
        method: 'GET',
        responseType: 'blob', // important
      },
    ).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;

      const fileNameFormated = fileName?.includes('_') ? fileName.split('_').slice(1).join('_') : fileName;
      link.setAttribute('download', fileNameFormated);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });

    // const link = document.createElement('a');
    // link.href = filePath;
    // link.target = '_blank';
    // link.download = fileName.split('_')[1];
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);

    // fetch(filePath, {
    //   method: 'GET',
    //   headers: {
    //     'Content-Type': 'application/pdf',
    //     'Access-Control-Allow-Origin': '*',
    //   },
    // })
    //   .then((response) => response.blob())
    //   .then((blob) => {
    //     const url = window.URL.createObjectURL(new Blob([blob]));

    //     const link = document.createElement('a');
    //     link.href = url;
    //     link.download = fileName.split('_')[1];

    //     document.body.appendChild(link);

    //     link.click();

    //     link.parentNode.removeChild(link);
    //   });
  };

  // const downloadAllFiles = ({ data }) => {
  //   // console.log(data);
  //   data.forEach(file => {
  //     console.log('eo', file)
  //     delay(10000);
  //     downloadPdf(file.url, file.content)
  //   });
    
  // }

  // const downloadAllFiles = function* ({ data }) {
  //   for (const file of data) {
  //     console.log('eo', file);
  //     yield delay(10000); // Usar yield para pausar la ejecución
  //     yield call(downloadPdf, file.url, file.content);
  //   }
  // };

  const handleClick = () => {
    // Puedes pasar los datos necesarios como argumento a tu acción
    dispatch(downloadAllFilesRequest(selectedData));
  };

  const documentsData = (tabIndex) => {
    const page = pagination[tabIndex]?.page;
    const rowsPerPage = pagination[tabIndex]?.rowsPerPage;

    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return (
      <TableContainer>
        <Table size="small" aria-label="a dense table">
          {selectedData?.data?.slice(startIndex, endIndex).map((item) => {
            const fileName = item?.content?.includes('_') ? item?.content.split('_').slice(1).join('_') : item?.content;

            return (
            <TableRow hover>
              <TableCell align="left">{fileName}</TableCell>
              <TableCell align="left" sx={{ width: '5px' }}>
                <Grid
                  sx={{
                    background: colorIconValidation(item.contentType),
                    textAlign: 'center',
                    paddingX: '3px',
                    borderRadius: '5px',
                    fontWeight: '500',
                    fontSize: '13px',
                    color: '#fff',
                  }}>
                  {item.contentType.toUpperCase()}
                </Grid>
              </TableCell>
              <TableCell align="left" sx={{ width: '120px' }}>
                {moment(item?.uploadDate).format('DD-MM-YYYY HH:mm')}
              </TableCell>
              <TableCell align="right" sx={{ width: '5px' }}>
                {item.contentType === 'pdf' && (
                  <IconButton
                    aria-label="delete"
                    sx={{ padding: '1px', fontSize: '20px' }}
                    onClick={(e) => openDocument(item)}>
                    <VisibilityIcon fontSize="inherit" />
                  </IconButton>
                )}
              </TableCell>
              <TableCell align="right" sx={{ width: '5px' }}>
                <IconButton
                  aria-label="delete"
                  sx={{ padding: '1px', fontSize: '20px' }}
                  onClick={() => downloadPdf(item.url, item.content)}>
                  <DownloadIcon fontSize="inherit" />
                </IconButton>
              </TableCell>
            </TableRow>
          )})}
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={selectedData?.data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(_, newPage) => handleChangePage(tabIndex, newPage)}
          onRowsPerPageChange={(event) => handleChangeRowsPerPage(tabIndex, event)}
          labelRowsPerPage={'Filas por página:'}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}–${to} de ${count !== -1 ? count : `más que ${to}`}`
          }
        />
      </TableContainer>
    );
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', position: 'relative' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          {formatedData.map((attachmentPart) => (
            <Tab label={attachmentPart?.title} {...a11yProps(0)} />
          ))}
        </Tabs>
        {currentUser && (
          <IconButton
            aria-label="download"
            sx={{
              padding: '1px',
              fontSize: '20px',
              position: 'absolute',
              right: '24px',
              top: '50%',
              transform: 'translateY(-50%)'
            }}
            // onClick={() => downloadAllFiles(selectedData)}>
            onClick={handleClick}
          >
            <DownloadIcon fontSize="inherit" />
          </IconButton>
        )}
      </Box>
      {formatedData.map((_, index) => (
        <CustomTabPanel key={index} value={value} index={index}>
          {documentsData(index)}
        </CustomTabPanel>
      ))}
    </Box>
  );
};

export default DocumentsViewList;
