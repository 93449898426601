import camelize from 'camelcase-keys-deep';

export default class Attachment {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  document() {
    return this.env().Document.getById(this.documentId);
  }

  attachmentPart() {
    return this.env().AttachmentPart.getById(this.attachmentPartId);
  }
}
