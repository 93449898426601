import React, { useEffect, useCallback } from 'react';

/* Material UI */
import { CardContent, Snackbar, CardMedia, Card, Grid, Alert } from '@mui/material';
import { withStyles } from '@mui/styles';

/* React Router */
import { Route, Switch, useHistory } from 'react-router-dom';

/* Project */
import { getParameterByName, composedComponent } from 'utils/functions';
import { appActions } from 'commons/commons.reducer';
import Paths from 'utils/paths';
import styles from './styles';
import { recoveryPasswordActions } from './RecoveryPassword.reducer';
import saga from './RecoveryPassword.saga';
import RecoveryForm from './components/RecoveryForm';
import UpdatePasswordForm from './components/UpdatePasswordForm';

function RecoveryPassword(props) {
  const { classes, controls, loading, location, actions, error, message, successChangePassword } =
    props;

  const history = useHistory();
  const pathLogin = Paths.shared[0].path;

  useEffect(() => {
    const token = getParameterByName('token', location.search) || '';
    const email = getParameterByName('email', location.search) || '';
    actions.setControls({ token, email });
    return () => {
      actions.resetState();
    };
  }, [actions, location.seaprops, location.search]);

  const onChangeText = useCallback((name, value) => {
    actions.setControls({ [name]: value });
  }, []);

  const toggleError = () => {
    actions.toggleError();
  };

  const toggleSuccess = () => {
    actions.toggleSuccess();
  };

  return (
    <div>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ margin: 0, height: '100vh' }}>
        <Grid item md={4} lg={4} xs={12}>
          <Card>
            <CardContent>
              <CardMedia
                component="img"
                className={classes.image}
                sx={{ objectFit: 'none' }}
                src="https://drive.google.com/uc?export=view&id=1epryMkQtpO-rQzUGhLh8tpN7UHwdUU7X"
                title="TCIT"
              />
              <Switch>
                <Route
                  exact
                  path="/recovery-password"
                  render={() => (
                    <RecoveryForm
                      onChangeText={onChangeText}
                      email={controls.email}
                      classes={classes}
                      loading={loading}
                      controls={controls}
                      actions={actions}
                      history={history}
                      successChangePassword={successChangePassword}
                    />
                  )}
                />
                <Route
                  exact
                  path="/update-password"
                  render={() => (
                    <UpdatePasswordForm
                      onChangeText={onChangeText}
                      password={controls.password}
                      verifyPassword={controls.verifyPassword}
                      classes={classes}
                      loading={loading}
                      controls={controls}
                      actions={actions}
                    />
                  )}
                />
              </Switch>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={error}
        autoHideDuration={3000}
        onClose={toggleError}>
        <Alert onClose={toggleError} severity="error">
          {message}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={successChangePassword}
        autoHideDuration={3000}
        onClose={() => {
          if (successChangePassword) {
            history.push(pathLogin);
          }
        }}>
        <Alert onClose={toggleSuccess} severity="success">
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default composedComponent(RecoveryPassword, saga, {
  saga: 'sagaRecoveryPassword',
  middlewares: [withStyles(styles)],
  states: [
    'recoveryPassword.controls',
    'recoveryPassword.message',
    'recoveryPassword.error',
    'recoveryPassword.successChangePassword',
    'recoveryPassword.loading',
  ],
  actions: [recoveryPasswordActions, appActions],
});
