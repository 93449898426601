import { takeLatest, spawn, put, select } from 'redux-saga/effects';

/* Project */
import apiRequest, { apiSuccess } from 'utils/api';
import HomeTypes from 'screens/Home/Home.types';
import { onlyKeysWithValue } from 'utils/helper';
import CommonsTypes from 'commons/commons.types';
import { getDiscrepancies } from 'screens/Home/Home.actions';
import { appActions } from 'commons/commons.reducer';
import { useSelector } from 'react-redux';

function* fetchDiscrepancies() {
  yield takeLatest(HomeTypes.GET_DISCREPANCIES, function* getDiscrepanciesApi(action) {
    const urlParams = new URLSearchParams(onlyKeysWithValue({ ...action.payload.filters }));
    urlParams.append('page', action.payload.pagination.page.toString());
    urlParams.append('size', action.payload.pagination.size.toString());

    const response = yield apiRequest(
      `api/v1/discrepancies?${urlParams}`,
      { method: 'get' },
      true,
      true,
    );
    if (response && !response.error) {
      yield put(apiSuccess(HomeTypes.GET_DISCREPANCIES_SUCCESS, response));
    } else {
      yield put(apiSuccess(HomeTypes.GET_DISCREPANCIES_FAIL));
    }
  });
}

function* logout() {
  yield takeLatest(CommonsTypes.LOGOUT, function* clearState(action) {
    localStorage.removeItem('user');
    const { filters, pagination } = action.payload;
    yield put(getDiscrepancies({ filters, pagination }));
    yield put(appActions.fetchInitialState());
  });
}

export default function* HomeSaga() {
  yield spawn(fetchDiscrepancies);
  yield spawn(logout);
}
