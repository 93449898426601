import React from 'react';

/* Material UI */
import { Grid, IconButton, InputAdornment } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

/* React Redux */
import { useDispatch } from 'react-redux';

/* Project */
import PrimaryButton from 'commons/components/PrimaryButton/PrimaryButton';
import DatePicker from 'commons/components/DatePicker/DatePicker';
import CustomTextField from 'commons/components/CustomTextField/CustomTextField';
import CustomSelectField from 'commons/components/CustomSelectField/CustomSelectField';

import { getDocuments, setFilters } from 'screens/LastDocuments/LastDocuments.actions';
import filtersStyles from './Filters.style';

function AddClearButton(props) {
  const { name, handleChange } = props;
  return (
    <ClearIcon
      className="clear-icon"
      onClick={() => {
        handleChange({ target: { name, value: '' } });
      }}
    />
  );
}

function LastDocFilters(props) {
  const { filters, docTypes } = props;
  const dispatch = useDispatch();

  const handleChange = ({ target: { name, value } }) => {
    dispatch(setFilters({ name, value }));
  };

  const handleSearch = () => {
    dispatch(getDocuments(filters));
  };

  const handleChangeDate = (date, name) => {
    handleChange({ target: { name, value: date } });
  };

  const handleClearDate = (name) => {
    handleChangeDate(null, name);
  };

  const handleMouseDown = (event) => {
    event.preventDefault();
  };

  const endAdornment = (name) => (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={() => handleClearDate(name)}
        onMouseDown={handleMouseDown}>
        <ClearIcon />
      </IconButton>
    </InputAdornment>
  );

  return (
    <Grid container rowSpacing={2} columnSpacing={6} sx={{ pb: 3, pt: 2 }}>
      <Grid item container xs={12} rowSpacing={2} columnSpacing={6}>
        <Grid item xs={12} md={6} lg={3}>
          <DatePicker
            value={filters.startDate}
            onChange={(e, date) => {
              handleChangeDate(date, 'startDate');
            }}
            fullWidth
            label="Fecha Desde"
            InputProps={{
              endAdornment: endAdornment('startDate'),
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <DatePicker
            value={filters.endDate}
            onChange={(e, date) => {
              handleChangeDate(date, 'endDate');
            }}
            fullWidth
            label="Fecha Hasta"
            InputProps={{
              endAdornment: endAdornment('endDate'),
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <CustomSelectField
            label="Tipo documento"
            name="documentType"
            list={docTypes}
            onChange={handleChange}
            value={filters.documentType}
            mapKey="name"
            endAdornment={
              <AddClearButton
                name="documentType"
                value={filters.documentType}
                handleChange={handleChange}
              />
            }
            sx={{ ...filtersStyles.selectButton }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <CustomTextField label="Palabra clave" name="keyword" onChange={handleChange} />
        </Grid>
      </Grid>
      <Grid item container xs={12} justifyContent="flex-end">
        <Grid item xs={2}>
          <PrimaryButton fullWidth text="Filtrar" onClick={handleSearch} />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default LastDocFilters;
