import React from 'react';

/* React Router */
import { Route, Switch } from 'react-router-dom';

/* Project */
import Paths from 'utils/paths';
import Auth from './Auth';
import PublicLayout from './PublicLayout';

function Routes({ user, loading }) {
  const { shared, publics, privates } = Paths;
  // const userRoute = Paths[user?.type];

  return (
    <Switch>
      {privates.map(({ path, component: Component }) => (
        <Route key={path} exact path={path}>
          <Auth key={path} user={user} loading={loading}>
            <Component />
          </Auth>
        </Route>
      ))}

      {publics.map(({ path, component: Component }) => (
        <Route key={path} exact path={path}>
          <PublicLayout key={path} user={user} loading={loading}>
            <Component />
          </PublicLayout>
        </Route>
      ))}

      {shared.map(({ path, component }) => (
        <Route key={path} exact path={path} component={component} />
      ))}
    </Switch>
  );
}

export default Routes;
