import React, { useMemo } from 'react';

/* Material UI */
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';

/* React redux */
import { useDispatch, useSelector } from 'react-redux';

/* Project */
import { changeControls } from 'commons/commons.actions';
import { reselectObjects } from 'utils/helper';
import DiscrepancyStepper from '../DiscrepancyStepper/DiscrepancyStepper';

function DiscrepancyModal() {
  const dispatch = useDispatch();
  const { discrepancyStepper, objects: objectsState } = useSelector((state) => state.app);

  const objects = useMemo(() => reselectObjects(objectsState), [objectsState]);

  const { controls } = discrepancyStepper;

  const handleClose = () => {
    dispatch(changeControls({ name: 'openDialog', value: false }));
  };

  return (
    <Dialog
      open={controls.openDialog}
      onClose={handleClose}
      PaperProps={{ sx: { minWidth: { lg: 700 }, minHeight: 200 } }}>
      <Box sx={{ m: 3 }}>
        <DiscrepancyStepper objects={objects} />
      </Box>
    </Dialog>
  );
}

export default DiscrepancyModal;
