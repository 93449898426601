import React from 'react';
import PropTypes from 'prop-types';

/* Material UI */
import { Button, CircularProgress } from '@mui/material';

/* Project */
import color from '../../../utils/colors';

function SecondaryButton(props) {
  const { text, onClick, sx, disabled, loading, children, startIcon, fullWidth, secondaryStyles } =
    props;

  const colorValidation = secondaryStyles ? color.purpleSecondary : color.blue;
  const hoverValidation = secondaryStyles ? color.secondaryMain : color.cooper;

  const sxStyles = {
    backgroundColor: color.white,
    color: colorValidation,
    borderColor: colorValidation,
    '&:hover': {
      color: hoverValidation,
      borderColor: hoverValidation,
    },
    minWidth: '110px',
    fontSize: 16,
    textTransform: secondaryStyles ? 'capitalize' : 'uppercase',
    ...sx,
  };

  return (
    <Button
      fullWidth={secondaryStyles ? false : fullWidth}
      endIcon={
        loading ? <CircularProgress size={15} sx={{ marginTop: '-3px' }} /> : startIcon || null
      }
      disabled={loading || disabled}
      onClick={onClick}
      variant="outlined"
      size="small"
      sx={sxStyles}>
      {children || text}
    </Button>
  );
}

SecondaryButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string,
  startIcon: PropTypes.element,
  sx: PropTypes.shape({}),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};

SecondaryButton.defaultProps = {
  text: null,
  startIcon: null,
  sx: {},
  disabled: false,
  loading: false,
};
export default SecondaryButton;
