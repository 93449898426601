import * as React from 'react';
import { useState } from 'react';

/* Material UI */
import { Box, Menu, MenuItem, Divider, IconButton, Tooltip } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

/* React Redux */
import { useDispatch, useSelector } from 'react-redux';

/* Project */
import {
  changeControls,
  changeLegalEntityControls,
  changeNaturalEntityControls,
  openDiscrepancyMessageDialog,
} from 'commons/commons.actions';
import { useHistory } from 'react-router-dom';
import color from '../../../utils/colors';

function SideMenu(props) {
  const dispatch = useDispatch();

  const { user, tutorialLink } = useSelector((state) => state.app);

  const { users } = props.objects;
  const currentUserModel = users[user?.id];

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const history = useHistory();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleStepper = () => dispatch(changeControls({ name: 'openDialog', value: true }));

  const toggleRegisterLegalEntity = () =>
    dispatch(changeLegalEntityControls({ name: 'openDialog', value: true }));

  const toggleRegisterNaturalEntity = () =>
    dispatch(changeNaturalEntityControls({ name: 'openDialog', value: true }));

  const menuButton = (
    <Tooltip title="Menú">
      <IconButton
        onClick={handleClick}
        size="small"
        aria-controls={open ? 'side-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}>
        <MenuIcon sx={{ width: 32, height: 32, color: color.white }} />
      </IconButton>
    </Tooltip>
  );

  const paperProps = {
    elevation: 0,
    sx: {
      pl: 2,
      pr: 2,
      overflow: 'visible',
      // TODO: move color to colors util
      filter: `drop-shadow(0px 2px 8px ${color.shadowRgba})`,
      mt: 1.5,
      '& .MuiAvatar-root': {
        width: 32,
        height: 32,
        ml: -0.5,
        mr: 1,
      },
      '&:before': {
        left: '50%',
        transform: 'translateX(-77%) translateY(32%)',
      },
    },
  };

  const redirectToHearings = () => history.push('/hearings');
  const toggleDiscrepancyMessage = () =>
    dispatch(openDiscrepancyMessageDialog({ name: 'openDialog', value: true }));

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>{menuButton}</Box>
      <Menu
        anchorEl={anchorEl}
        id="side-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={paperProps}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}>
        {
          !currentUserModel?.entity()?.legalEntity()
            ? <MenuItem sx={{ color: color.blue, fontWeight: 'medium' }} onClick={toggleStepper}>
                Nueva Discrepancia
              </MenuItem>
            : null
        }
        { !currentUserModel?.entity()?.legalEntity() ? <Divider variant="middle" /> : null }
        <MenuItem
          sx={{ color: color.cooper, fontWeight: 'medium' }}
          onClick={() => {
            history.push('/');
          }}>
          Discrepancias
        </MenuItem>
        <Divider variant="middle" />
        <MenuItem sx={{ fontWeight: 'medium' }} onClick={redirectToHearings}>
          Calendario audiencias
        </MenuItem>
        <Divider variant="middle" />
        <MenuItem
          sx={{ fontWeight: 'medium' }}
          onClick={() => {
            window.open('https://www.panelexpertos.cl/', '_blank');
          }}>
          Página web oficial
        </MenuItem>
        {user && (
        <>
          <Divider key="side-divider-1" variant="middle" />
          <MenuItem
            key="side-menu-1"
            sx={{ color: color.blue, fontWeight: 'medium' }}
            onClick={toggleRegisterLegalEntity}>
            Registrar persona jurídica
          </MenuItem>
        </>
        )}
        <Divider key="side-divider-2" variant="middle" />
        <MenuItem
          key="side-menu-2"
          sx={{ color: color.blue, fontWeight: 'medium' }}
          onClick={toggleRegisterNaturalEntity}>
          Registrar persona natural
        </MenuItem>
        {user && user?.type === 'admin' && (
          <>
            <Divider variant="middle" />
            <MenuItem
              sx={{ color: color.darkGrey, fontWeight: 'medium' }}
              onClick={toggleDiscrepancyMessage}>
              Modal discrepancia
            </MenuItem>
          </>
        )}
        <>
          <Divider variant="middle" />
          <MenuItem
            sx={{ color: color.darkGrey, fontWeight: 'medium' }}
            onClick={() => {
              history.push('/search-content');
            }}>
            Búsqueda de discrepancia
          </MenuItem>
        </>
        <Divider variant="middle" />
        <MenuItem
          sx={{ color: color.darkGrey, fontWeight: 'medium' }}
          onClick={() => {
            window.open(tutorialLink, '_blank');
          }}>
          Manual de usuario
        </MenuItem>
      </Menu>
    </>
  );
}

export default SideMenu;
