import Client from './Client';

import Attachment from './Attachment';
import Document from './Document';
import Discrepancy from './Discrepancy';
import DocumentType from './DocumentType';
import Entity from './Entity';
import InterestedParty from './InterestedParty';
import LegalMatter from './LegalMatter';
import LegalSubMatter from './LegalSubMatter';
import AttachmentPart from './AttachmentPart';
import User from './User';
import Alert from './Alert';
import LegalEntity from './LegalEntity';
import LegalEntityFile from './LegalEntityFile';
import Representative from './Representative';
import Hearing from './Hearing';
import HearingParticipation from './HearingParticipation';
import DiscrepancyReport from './DiscrepancyReport';
import DiscrepancyMessage from './DiscrepancyMessage';

export default {
  Client,
  Attachment,
  Document,
  Discrepancy,
  DocumentType,
  Entity,
  InterestedParty,
  LegalMatter,
  LegalSubMatter,
  AttachmentPart,
  User,
  Alert,
  LegalEntity,
  LegalEntityFile,
  Representative,
  Hearing,
  HearingParticipation,
  DiscrepancyReport,
  DiscrepancyMessage,
};
