import { takeLatest, spawn, put, call } from 'redux-saga/effects';

/* Project */
import apiRequest, { apiSuccess, post, update } from 'utils/api';
import * as APP from 'commons/commons.reducer';
import commonsTypes from 'commons/commons.types';
import controlledCall from 'utils/services/controlledSaga';
import { v4 as uuidv4 } from 'uuid';
import {
  appActions,
  FETCH_INITIAL_STATE,
  FETCH_INITIAL_STATE_SUCCESS,
  FETCH_INITIAL_STATE_FAIL,
} from 'commons/commons.reducer';
import { pairFiles } from 'utils/functions';

function* postDiscrepancy() {
  yield takeLatest(commonsTypes.CREATE_DISCREPANCY, function* postDiscrepancyApi(action) {
    const payloadCopy = { ...action.payload };

    const body = new FormData();

    const { files, attachments } = payloadCopy;

    delete payloadCopy.files;
    delete payloadCopy.attachments;

    const stringifyPayload = JSON.stringify(payloadCopy);

    const mainRandom = uuidv4().split('-')[0];
    const mainRandomWord = uuidv4().split('-')[0];
    const mainRandomConfidential = uuidv4().split('-')[0];

    const [publicFile, wordFile, confidentialFile] = files;
    const totalFiles = [publicFile, wordFile, confidentialFile];

    body.append('files[]', publicFile, `${mainRandom}_${publicFile?.name?.replace(/[\/\\:*?<>|()\[\]{}"]/g, '')}`);

    body.append(
      'files[]',
      wordFile || new Blob(),
      wordFile ? `${mainRandomWord}_${wordFile.name?.replace(/[\/\\:*?<>|()\[\]{}"]/g, '')}` : undefined,
    );

    body.append(
      'files[]',
      confidentialFile || new Blob(),
      confidentialFile ? `${mainRandomConfidential}_${confidentialFile.name?.replace(/[\/\\:*?<>|()\[\]{}"]/g, '')}` : undefined,
    );

    attachments.forEach((attachment) => {
      if (attachment) {
        const random = uuidv4().split('-')[0];
        const randomPdf = uuidv4().split('-')[0];
        // const randomWord = uuidv4().split('-')[0];
        const randomConfidential = uuidv4().split('-')[0];
        const { privateAtt, publicAtt } = attachment;

        if (privateAtt) {
          totalFiles.push(attachment.privateAtt);
        }
        if (publicAtt) {
          totalFiles.push(attachment.publicAtt);
        }

        body.append(
          `attachments[${random}]`,
          publicAtt,
          publicAtt ? `${randomPdf}_${publicAtt.name?.replace(/[\/\\:*?<>|()\[\]{}"]/g, '')}` : undefined,
        );
        // El modelo espera un pdf, word y pdf conf.
        // Sin embargo, en attachments solo se requiere pdf y pdf conf.
        // Se envía por eso blob vacío.
        body.append(`attachments[${random}]`, new Blob(), null);
        body.append(
          `attachments[${random}]`,
          privateAtt || new Blob(),
          privateAtt ? `${randomConfidential}_${privateAtt.name?.replace(/[\/\\:*?<>|()\[\]{}"]/g, '')}` : undefined,
        );
      }
    });

    body.append('jsonBody', stringifyPayload);

    const response = yield apiRequest(
      'api/v1/discrepancies',
      {
        method: 'post',
        body,
      },
      false,
    );

    if (!response.error) {
      const attachmentsSaved = Object.values(response.objects.attachments).filter(
        (attachment) => attachment.id,
      );
      const pairedFiles = pairFiles(totalFiles, attachmentsSaved);
      for (const singleFile of pairedFiles) {
        const { contentType, id, content } = singleFile.savedInDb;
        const { file } = singleFile;
        let body = JSON.stringify({
          fileName: content,
          attachment: true,
          fileType: contentType,
          id,
        });
        try {
          const signedUrlResponse = yield call(
            apiRequest,
            `api/v1/s3/get_presigned_url`,
            { method: 'post', body },
            true,
            true,
          );
          if (signedUrlResponse?.error) {
            throw new Error('Error obteniendo signedUrl para guardar el archivo');
          }
          yield fetch(decodeURI(signedUrlResponse?.signedUrl), {
            method: 'put',
            headers: {
              'Content-Type': contentType,
              'Access-Control-Allow-Headers': 'Content-Type',
              'Access-Control-Allow-Methods': 'OPTIONS,POST,GET',
              'Access-Control-Allow-Origin': '*',
            },
            body: file,
          });
        } catch (error) {
          console.log(`ERROR SUBIENDO DOCUMENTO`, error);
          yield apiRequest(
            `api/v1/discrepancies/${Object.keys(response.objects.discrepancies)[0]}`,
            { method: 'delete' },
            false,
          );
          yield put(apiSuccess(commonsTypes.CREATE_DISCREPANCY_FAIL, response));
          yield put(
            apiSuccess(APP.SET_ERROR, {
              msg: 'Hubo un error subiendo los documentos a la plataforma',
            }),
          );
          return;
        }
      }
      yield put(apiSuccess(commonsTypes.CREATE_DISCREPANCY_SUCCESS, response));
      yield put(
        apiSuccess(commonsTypes.OPEN_GENERAL_ALERT, {
          title: 'Aviso',
          message: 'Creación de discrepancia exitosa',
          status: 'success',
        }),
      );
    } else {
      yield put(apiSuccess(commonsTypes.CREATE_DISCREPANCY_FAIL, response));
      yield put(apiSuccess(APP.SET_ERROR, { msg: response.errorMsg }));
    }
  });
}

function* postNaturalEntity() {
  yield takeLatest(commonsTypes.CREATE_NATURAL_ENTITY, function* postNaturalEntityApi(action) {
    const body = { ...action.payload };

    const successFunction = (result) => ({
      type: commonsTypes.CREATE_NATURAL_ENTITY_SUCCESS,
      payload: result,
    });

    const failFunction = () => ({
      type: commonsTypes.CREATE_NATURAL_ENTITY_FAIL,
    });

    const response = yield controlledCall(
      post,
      'api/v1/entities/naturalEntity',
      body,
      successFunction,
      failFunction,
    );

    if (response.error) {
      yield put(apiSuccess(APP.SET_ERROR, { msg: response.errorMsg }));
    } else {
      yield put(
        apiSuccess(commonsTypes.OPEN_GENERAL_ALERT, {
          title: 'Aviso',
          message: 'Creación de persona natural exitosa',
          status: 'success',
        }),
      );
    }
  });
}

function* postLegalEntity() {
  yield takeLatest(commonsTypes.CREATE_LEGAL_ENTITY, function* postLegalEntityApi(action) {
    const payloadCopy = { ...action.payload };
    const body = new FormData();

    const { legalPowersArray } = payloadCopy;

    delete payloadCopy.legalPowersArray;

    const stringifyPayload = JSON.stringify(payloadCopy);

    legalPowersArray.forEach((legalPower) => {
      if (legalPower) {
        body.append('file', legalPower || new Blob(), legalPower.name);
      }
    });

    body.append('jsonBody', stringifyPayload);

    const response = yield apiRequest(
      'api/v1/entities/legalEntity',
      { method: 'post', body },
      false,
    );

    if (!response.error) {
      const { legalEntityFiles, legalEntities } = response.objects;
      const legalEntityCreated = Object.values(legalEntities)[0];
      for (const legalPower of legalPowersArray) {
        const legaEntityFileInfo = Object.values(legalEntityFiles).filter(
          (legalEntityFile) => legalPower.name === legalEntityFile.content,
        )[0];
        try {
          let body = JSON.stringify({
            fileName: legalPower.name,
            attachment: false,
            fileType: 'pdf',
            id: legaEntityFileInfo.id,
          });

          const signedUrlResponse = yield apiRequest(
            `api/v1/s3/get_presigned_url`,
            { method: 'post', body },
            true,
            true,
          );
          if (signedUrlResponse?.error) {
            throw new Error('Error obteniendo signedUrl para guardar el archivo');
          }
          yield fetch(decodeURI(signedUrlResponse?.signedUrl), {
            method: 'put',
            headers: {
              'Content-Type': 'application/pdf',
              'Access-Control-Allow-Headers': 'Content-Type',
              'Access-Control-Allow-Methods': 'OPTIONS,POST,GET',
              'Access-Control-Allow-Origin': '*',
            },
            body: legalPower,
          });
        } catch (error) {
          console.log('ERROR AL INTENTAR SUBIR ARCHIVO');
          yield apiRequest(`api/v1/entities/legalEntity/recentlyCreated/${legalEntityCreated.id}`, {
            method: 'delete',
          });
          yield put(apiSuccess(commonsTypes.CREATE_LEGAL_ENTITY_FAIL, response));
          yield put(
            apiSuccess(APP.SET_ERROR, {
              msg: 'Hubo un error al subir los archivos de la persona jurídica',
            }),
          );
          return;
        }
      }
      yield put(apiSuccess(commonsTypes.CREATE_LEGAL_ENTITY_SUCCESS, response));
      yield put(
        apiSuccess(commonsTypes.OPEN_GENERAL_ALERT, {
          title: 'Aviso',
          message: 'Creación de persona juridica exitosa',
          status: 'success',
        }),
      );
    } else {
      yield put(apiSuccess(commonsTypes.CREATE_LEGAL_ENTITY_FAIL, response));
      yield put(apiSuccess(APP.SET_ERROR, { msg: response.errorMsg }));
    }
  });
}

function* fetchInitialState() {
  yield takeLatest(FETCH_INITIAL_STATE, function* getUserApi() {
    const response = yield apiRequest('api/v1/home', { method: 'get' }, true, true);
    if (response && !response.error) {
      yield put(apiSuccess(FETCH_INITIAL_STATE_SUCCESS, response));
    } else {
      localStorage.removeItem('user');
      yield put(apiSuccess(FETCH_INITIAL_STATE_FAIL));
    }
  });
}

function* deleteDiscrepancy() {
  yield takeLatest(commonsTypes.DELETE_DISCREPANCY, function* deleteDiscrepancyGenerator(action) {
    const response = yield apiRequest(
      `api/v1/discrepancies/${action.payload.discrepancyId}`,
      { method: 'delete' },
      false,
    );

    if (!response.error) {
      yield put(apiSuccess(commonsTypes.DELETE_DISCREPANCY_SUCCESS, response));
      yield put(apiSuccess(APP.SET_SUCCESS, { msg: 'Eliminación de discrepancia exitosa' }));
      action.payload.history.push('/');
    } else {
      yield put(apiSuccess(commonsTypes.DELETE_DISCREPANCY_FAIL, response));
      yield put(apiSuccess(APP.SET_ERROR, { msg: 'Falló al eliminar la discrepancia' }));
    }
  });
}

function* updateDiscrepancy() {
  yield takeLatest(commonsTypes.UPDATE_DISCREPANCY, function* updateDiscrepancyData(action) {
    const payloadCopy = { ...action.payload };

    const body = new FormData();

    const {
      presentation,
      wordPresentation,
      discrepancyId,
      currentPdfPresentation,
      currentWordPresentation,
      oldValues,
    } = payloadCopy;
    delete payloadCopy.presentation;
    delete payloadCopy.wordPresentation;
    delete payloadCopy.currentPdfPresentation;
    delete payloadCopy.currentWordPresentation;
    delete payloadCopy.oldValues;

    const files = [presentation, wordPresentation];

    const oldFiles = [currentPdfPresentation, currentWordPresentation];

    const stringifyPayload = JSON.stringify(payloadCopy);

    files.forEach((file) => {
      if (file) {
        body.append('files[]', file, `${file.name?.replace(/[\/\\:*?<>|()\[\]{}"]/g, '')}`);
      }
    });

    body.append('jsonBody', stringifyPayload);

    const response = yield apiRequest(
      `api/v1/discrepancies/${discrepancyId}`,
      { method: 'put', body },
      false,
    );

    if (!response.error) {
      const filesUpdated = Object.values(response.objects.attachments);
      for (const fileTest of filesUpdated) {
        if (fileTest) {
          const { contentType, id, content } = fileTest;
          const file = files.filter((file) => file.name?.replace(/[\/\\:*?<>|()\[\]{}"]/g, '') === fileTest.content);
          let body = JSON.stringify({
            fileName: content?.replace(/[\/\\:*?<>|()\[\]{}"]/g, ''),
            attachment: true,
            fileType: contentType,
            id,
          });
          try {
            const signedUrlResponse = yield call(
              apiRequest,
              `api/v1/s3/get_presigned_url`,
              { method: 'post', body },
              true,
              true,
            );
            if (signedUrlResponse?.error) {
              throw new Error('Error obteniendo signedUrl para guardar el archivo');
            }
            yield fetch(decodeURI(signedUrlResponse?.signedUrl), {
              method: 'put',
              headers: {
                'Content-Type': contentType,
                'Access-Control-Allow-Headers': 'Content-Type',
                'Access-Control-Allow-Methods': 'OPTIONS,POST,GET',
                'Access-Control-Allow-Origin': '*',
              },
              body: file[0],
            });
            body = JSON.stringify({
              id,
              fileName: content?.replace(/[\/\\:*?<>|()\[\]{}"]/g, ''),
              contentType,
              attachment: true,
            });
            const viewFileSignedUrlResponse = yield apiRequest(
              `api/v1/s3/get_presigned_url_view`,
              {
                method: 'post',
                body,
              },
              true,
              true,
            );
            if (viewFileSignedUrlResponse?.error) {
              throw new Error('Error obteniendo signedUrl para guardar el archivo');
            }
            response.objects.attachments[id].url = viewFileSignedUrlResponse.signedUrl;
          } catch (error) {
            const body = new FormData();
            oldFiles.forEach((oldFile) => {
              if (oldFile) {
                body.append('files[]', new Blob(), `${oldFile.content?.replace(/[\/\\:*?<>|()\[\]{}"]/g, '')}`);
              }
            });
            body.append('jsonBody', JSON.stringify(oldValues));
            const response = yield apiRequest(
              `api/v1/discrepancies/${discrepancyId}`,
              { method: 'put', body },
              false,
            );
            yield put(apiSuccess(commonsTypes.UPDATE_DISCREPANCY_FAIL));
            yield put(
              apiSuccess(APP.SET_ERROR, { msg: 'Error al intentar subir archivo a la plataforma' }),
            );

            console.log('ERROR AL INTENTAR SUBIR EL ARCHIVO');
            return;
          }
        }
      }
      yield put(apiSuccess(commonsTypes.UPDATE_DISCREPANCY_SUCCESS, response));
      yield put(apiSuccess(APP.SET_SUCCESS, { msg: 'Discrepancia actualizada correctamente.' }));
    } else {
      yield put(apiSuccess(commonsTypes.UPDATE_DISCREPANCY_FAIL, response));
      yield put(apiSuccess(APP.SET_ERROR, { msg: response.errorMsg }));
    }
  });
}

function* updateNaturalEntity() {
  yield takeLatest(commonsTypes.EDIT_NATURAL_ENTITY, function* updateNaturalEntityApi(action) {
    const body = { ...action.payload };
    const successFunction = (result) => ({
      type: commonsTypes.EDIT_NATURAL_ENTITY_SUCCESS,
      payload: result,
    });

    const failFunction = () => ({
      type: commonsTypes.EDIT_NATURAL_ENTITY_FAIL,
    });

    const response = yield controlledCall(
      update,
      `api/v1/entities/naturalEntity/${body.userId}`,
      body,
      successFunction,
      failFunction,
    );

    if (response.error) {
      yield put(apiSuccess(APP.SET_ERROR, { msg: response.errorMsg }));
    } else {
      yield put(appActions.fetchInitialState());
    }
  });
}

function* updateLegalEntity() {
  yield takeLatest(commonsTypes.EDIT_LEGAL_ENTITY, function* updateNaturalEntityApi(action) {
    const body = { ...action.payload };
    const successFunction = (result) => ({
      type: commonsTypes.EDIT_LEGAL_ENTITY_SUCCESS,
      payload: result,
    });

    const failFunction = () => ({
      type: commonsTypes.EDIT_LEGAL_ENTITY_FAIL,
    });

    const response = yield controlledCall(
      update,
      `api/v1/entities/legalEntity/${body.userId}`,
      body,
      successFunction,
      failFunction,
    );

    if (response.error) {
      yield put(apiSuccess(APP.SET_ERROR, { msg: response.errorMsg }));
    } else {
      yield put(appActions.fetchInitialState());
    }
  });
}

function* deleteLegalPower() {
  yield takeLatest(commonsTypes.DELETE_LEGAL_POWER, function* deleteLegalPowerApi(action) {
    const { powerLegalFileId } = action.payload;

    const response = yield apiRequest(
      `api/v1/legalEntities/${powerLegalFileId}`,
      { method: 'delete' },
      false,
    );

    if (!response.error) {
      yield put(apiSuccess(commonsTypes.DELETE_LEGAL_POWER_SUCCESS, response));
      yield put(
        apiSuccess(APP.SET_SUCCESS, { msg: 'Eliminación de poder de representación exitosa' }),
      );
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { msg: response.errorMsg }));
    }
  });
}

function* createLegalPower() {
  yield takeLatest(commonsTypes.CREATE_LEGAL_POWER, function* createLegalPowerApi(action) {
    const { legalPowerFile, userId } = action.payload;
    const formData = new FormData();
    if (legalPowerFile?.type !== 'application/pdf') {
      yield put(apiSuccess(commonsTypes.CREATE_LEGAL_ENTITY_FAIL));
      yield put(
        apiSuccess(APP.SET_ERROR, {
          msg: 'Los archivos de persona jurídica deben ser archivos PDF',
        }),
      );
      return;
    }

    formData.append('file', legalPowerFile, legalPowerFile.name);

    const response = yield apiRequest(
      `api/v1/legalEntities/${userId}`,
      { method: 'post', body: formData },
      false,
    );

    if (!response.error) {
      const legalEntityFileCreated = Object.values(response.objects.legalEntityFiles)[0];
      try {
        let body = JSON.stringify({
          fileName: legalPowerFile.name,
          attachment: false,
          fileType: 'pdf',
          id: legalEntityFileCreated.id,
        });

        const signedUrlResponse = yield apiRequest(
          `api/v1/s3/get_presigned_url`,
          { method: 'post', body },
          true,
          true,
        );
        if (signedUrlResponse?.error) {
          throw new Error('Error obteniendo signedUrl para guardar el archivo');
        }
        yield fetch(decodeURI(signedUrlResponse?.signedUrl), {
          method: 'put',
          headers: {
            'Content-Type': 'application/pdf',
            'Access-Control-Allow-Headers': 'Content-Type',
            'Access-Control-Allow-Methods': 'OPTIONS,POST,GET',
            'Access-Control-Allow-Origin': '*',
          },
          body: legalPowerFile,
        });
        body = JSON.stringify({
          id: legalEntityFileCreated.id,
          fileName: legalPowerFile.name,
          contentType: 'pdf',
          attachment: false,
        });
        const viewFileSignedUrlResponse = yield apiRequest(
          `api/v1/s3/get_presigned_url_view`,
          {
            method: 'post',
            body,
          },
          true,
          true,
        );
        if (viewFileSignedUrlResponse?.error) {
          throw new Error('Error obteniendo signedUrl para guardar el archivo');
        }
        response.objects.legalEntityFiles[legalEntityFileCreated.id].url =
          viewFileSignedUrlResponse.signedUrl;
        response.objects.legalEntityFiles[legalEntityFileCreated.id].size = legalPowerFile.size;
      } catch (error) {
        console.log('ERROR AL SUBIR ARCHIVO');
        const response = yield apiRequest(
          `api/v1/legalEntities/${legalEntityFileCreated.id}`,
          { method: 'delete' },
          true,
          true,
        );
        yield put(apiSuccess(commonsTypes.CREATE_LEGAL_ENTITY_FAIL, response));
        yield put(
          apiSuccess(APP.SET_ERROR, { msg: 'Hubo un error al subir el archivo a la plataforma' }),
        );
        return;
      }

      yield put(apiSuccess(commonsTypes.CREATE_LEGAL_POWER_SUCCESS, response));
      yield put(
        apiSuccess(APP.SET_SUCCESS, { msg: 'Creación de poder de representación exitoso' }),
      );
    } else {
      yield put(apiSuccess(commonsTypes.CREATE_LEGAL_ENTITY_FAIL, response));
      yield put(apiSuccess(APP.SET_ERROR, { msg: response.errorMsg }));
    }

    // yield put({ type: commonsTypes.DELETE_LEGAL_POWER_SUCCESS });
  });
}

function* updateDiscrepancyMessage() {
  yield takeLatest(
    commonsTypes.UPDATE_DISCREPANCY_MESSAGE,
    function* updateDiscrepancyMessageApi(action) {
      const body = { ...action.payload };
      const successFunction = (result) => ({
        type: commonsTypes.UPDATE_DISCREPANCY_MESSAGE_SUCCESS,
        payload: result,
      });

      const failFunction = () => ({
        type: commonsTypes.UPDATE_DISCREPANCY_MESSAGE_FAIL,
      });

      const response = yield controlledCall(
        post,
        `api/v1/discrepancies/discrepancy-message`,
        body,
        successFunction,
        failFunction,
      );

      if (response.error) {
        yield put(apiSuccess(APP.SET_ERROR, { msg: response.errorMsg }));
      }
    },
  );
}

function* validateCaptcha() {
  yield takeLatest(APP.VALIDATE_CAPTCHA, function* validateCaptchaApi(action) {
    const { token, secretKey } = action.payload;

    let body = JSON.stringify({
      token,
      secretKey,
    });
    const response = yield apiRequest(
      'api/v1/home/validate-captcha',
      { method: 'post', body },
      true,
      true,
    );

    if (!response.error) {
      yield put(apiSuccess(APP.VALIDATE_CAPTCHA_SUCCESS, response));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { msg: response.errorMsg }));
    }
  });
}

export default function* CommonsSaga() {
  // yield spawn(logout);
  yield spawn(fetchInitialState);
  yield spawn(postDiscrepancy);
  yield spawn(postNaturalEntity);
  yield spawn(updateDiscrepancy);
  yield spawn(postLegalEntity);
  yield spawn(updateNaturalEntity);
  yield spawn(updateLegalEntity);
  yield spawn(deleteDiscrepancy);
  yield spawn(deleteLegalPower);
  yield spawn(createLegalPower);
  yield spawn(updateDiscrepancyMessage);
  yield spawn(validateCaptcha);
}
