const documentItemStyles = (theme) => ({
  root: {},
  listItemButton: {
    '&:hover': {
      color: '#007BFF',
      background: 'linear-gradient(to right, rgba(0, 123, 255, 1) 1%,rgba(255,255,255,0) 1%)',
    },
  },
  primaryTextStyle: {
    fontSize: '0.8rem !important',
    marginLeft: '0.7rem !important',
  },
  secondaryTextStyle: {
    fontSize: '1rem !important',
    marginLeft: '0.7rem !important',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxHeight: '23px',
    color: `${theme.palette.primary.main} !important`,
  },
  chip: { fontSize: '0.6rem', marginLeft: '0.1rem' },
});

export default documentItemStyles;
