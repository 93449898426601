import React from 'react';
import {
  Alert,
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import color from 'utils/colors';
import AddIcon from '@mui/icons-material/Add';
import { CloudDownload, Delete } from '@mui/icons-material';
import { styled } from '@mui/styles';
import TableCell from '@mui/material/TableCell';
import { useDispatch } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import {
  changeEditLegalPowersControls,
  closeConfirmDeleteLegalPowerAction,
  openConfirmDeleteLegalPowerAction,
  deleteLegalPower,
  createLegalPower,
  appSetError,
} from 'commons/commons.actions';
import PrimaryButton from 'commons/components/PrimaryButton/PrimaryButton';
import GeneralConfirmDialog from 'commons/components/GeneralConfirmDialog/GeneralConfirmDialog';
import { isZippedFile } from 'utils/functions';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

// modal de poderes
function EditLegalPowersModal(props) {
  const { legalEntityFiles, registerLegalEntity, userId } = props;
  const { legalPowersControls, loaders } = registerLegalEntity;
  const dispatch = useDispatch();

  const handleClose = () =>
    dispatch(changeEditLegalPowersControls({ name: 'openDialog', value: false }));

  const retrieveFileRow = (legalEntityFile) => {
    window.open(legalEntityFile.url, '_blank');
  };

  const removeFileRow = (idx) => {
    dispatch(openConfirmDeleteLegalPowerAction({ powerLegalFileId: legalEntityFiles[idx].id }));
  };

  const newFileRow = ({ target }) => {
    if(target.files[0] && !isZippedFile(target.files[0].name)){
      dispatch(createLegalPower({ legalPowerFile: target.files[0], userId }));
    } else {
      dispatch(appSetError({ msg: 'El archivo no puede ser una carpeta comprimida' }));
    }
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
  };

  const title = (
    <Typography
      sx={{
        fontWeight: 'regular',
        color: color.cooper,
        fontSize: 20,
      }}>
      Listado de poderes
    </Typography>
  );

  return (
    <Dialog
      open={legalPowersControls.openDialog}
      PaperProps={{ sx: { minWidth: { lg: 700 }, minHeight: 200 } }}>
      <Box sx={{ m: 3 }}>
        {title}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            mb: 2,
          }}>
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: 'bold',
              color: color.blue,
              textTransform: 'uppercase',
              mr: 1,
            }}>
            AGREGAR
          </Typography>
          <Typography
            sx={{
              fontWeight: 'regular',
              color: color.blue,
              fontSize: 16,
            }}>
            Poder
          </Typography>
          <label htmlFor="legalPower">
            <input
              accept="application/pdf"
              id="legalPower"
              name="legalPower"
              type="file"
              style={{ display: 'none' }}
              onChange={newFileRow}
            />
            <IconButton sx={{ color: color.blue }} aria-label="legalPower" component="span">
              <AddIcon
                sx={{
                  color: color.white,
                  backgroundColor: color.blue,
                  borderRadius: 3,
                }}
              />
            </IconButton>
          </label>
        </Box>
        <TableContainer component={Paper}>
          <Table sx={{ maxWidth: 700 }} size="small" aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell />
                <StyledTableCell>Poder de representación</StyledTableCell>
                <StyledTableCell>Tamaño</StyledTableCell>
                <StyledTableCell align="center">Acciones</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {legalEntityFiles.map((legalEntityFile, idx) => (
                <StyledTableRow key={legalEntityFile.id}>
                  <StyledTableCell component="th" scope="row">
                    {`${idx + 1}.`}
                  </StyledTableCell>
                  <StyledTableCell>{legalEntityFile.content}</StyledTableCell>
                  <StyledTableCell align="center">
                    {legalEntityFile.size === 0 ? (
                      <Alert sx={{ padding: '6px' }} severity="error">
                        Error en archivo
                      </Alert>
                    ) : (
                      formatBytes(legalEntityFile.size)
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {legalEntityFile.size > 0 ? (
                      <IconButton
                        sx={{ color: color.blue }}
                        aria-label="."
                        component="span"
                        onClick={() => retrieveFileRow(legalEntityFile)}>
                        <CloudDownload />
                      </IconButton>
                    ) : null}
                    <IconButton
                      sx={{ color: color.blue }}
                      aria-label="."
                      component="span"
                      onClick={() => removeFileRow(idx)}>
                      <Delete />
                    </IconButton>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            mt: 2,
          }}>
          <PrimaryButton
            onClick={handleClose}
            text="Cerrar"
            disabled={loaders?.legalPowers?.create}
            loading={loaders?.legalPowers?.create}
          />
        </Box>
      </Box>
      <GeneralConfirmDialog
        isOpen={legalPowersControls.openConfirmDialog.isOpen}
        title="Eliminar poder legar"
        message="¿Esta seguro que desea eliminar el poder de representación?"
        closeGeneralDialog={() => {
          dispatch(closeConfirmDeleteLegalPowerAction());
        }}
        confirmAction={() => {
          dispatch(
            deleteLegalPower({
              powerLegalFileId: legalPowersControls.openConfirmDialog.powerLegalFileId,
            }),
          );
        }}
        isLoading={loaders?.legalPowers?.delete}
      />
    </Dialog>
  );
}

EditLegalPowersModal.propTypes = {
  // legalPowersArray: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  // setLegalPowersArray: PropTypes.func.isRequired,
};

export default EditLegalPowersModal;
