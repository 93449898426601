import React, { useState } from 'react';

/* Material UI */
import { Typography, Button, Grid } from '@mui/material';

/* Project */
import { composedComponent } from 'utils/functions';
import UploadButton from './components/UploadButton';
import saga from './saga/file.saga';
import { fileActions } from './reducer/uploadFile.reducer';

function Upload(props) {
  const [file, setFile] = useState(null);
  const { actions } = props;

  // eslint-disable-next-line no-console
  console.log('TEST', props);

  const handleOnSubmit = (event) => {
    event.preventDefault();
    actions.startFileUpload(file);
  };

  const onChange = (File) => {
    setFile(File);
  };

  return (
    <Grid item sm={6} xs={12}>
      <Typography>
        <h1>Subir Archivos</h1>
      </Typography>
      <form onSubmit={handleOnSubmit}>
        <UploadButton actions={actions} onChange={onChange} />
        {file?.name && (
          <Typography variant="caption">
            <p>{file.name}</p>
          </Typography>
        )}
        <Button type="submit" variant="contained" color="primary" style={{ marginTop: 23 }}>
          Subir archivo
        </Button>
      </form>
    </Grid>
  );
}

export default composedComponent(Upload, saga, {
  saga: 'sagaFile',
  states: ['file'],
  actions: [fileActions],
});
