/* React */
import React, { useMemo, useState } from 'react';

/* React Redux */
import { useDispatch, useSelector } from 'react-redux';

/* Material UI */
import Grid from '@mui/material/Grid';
import { Button, TextField, Typography } from '@mui/material';
import { Edit } from '@mui/icons-material';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';

/* Project */
import color from 'utils/colors';
import PrimaryButton from 'commons/components/PrimaryButton/PrimaryButton';
import SecondaryButton from 'commons/components/SecondaryButton/SecondaryButton';
import {
  changeEditLegalEntityControls,
  changeEditLegalEntityForm,
  changeEditLegalPowersControls,
  changeEditRepresentativesControls,
  updateLegalEntity,
} from 'commons/commons.actions';
import { reselectObjects } from 'utils/helper';
import EditRepresentativesModal from 'commons/components/EditLegalEntity/components/EditRepresentativesModal';
import { formatRut } from 'utils/functions';
import EditLegalPowersModal from './components/EditLegalPowersModal';

function EditLegalEntity() {
  const { editLegalEntity, objects: objectsState, user } = useSelector((state) => state.app);

  const objects = useMemo(() => reselectObjects(objectsState), [objectsState]);
  const dispatch = useDispatch();
  const { controls, form, loaders, representatives } = editLegalEntity;

  const legalEntityFiles = Object.values(objects.legalEntityFiles);
  // const [legalPowersArray, setLegalPowersArray] = useState([...legalEntityFiles]);
  const [representativesArray, setRepresentativesArray] = useState([]);
  const legalEntitiesArr = Object.values(objects.legalEntities);
  const entities = Object.values(objects.entities);
  const skipEntitiesIds = legalEntitiesArr.map((le) => le.entityId);

  const naturalEntities = entities.filter((entity) => !skipEntitiesIds.includes(entity.id));

  const handleClose = () => {
    dispatch(changeEditLegalEntityControls({ name: 'openDialog', value: false }));
  };

  const openLegalPowersModal = () => {
    dispatch(changeEditLegalPowersControls({ name: 'openDialog', value: true }));
  };

  const openRepresentativesModal = () => {
    dispatch(
      changeEditRepresentativesControls({
        name: 'openDialog',
        value: true,
      }),
    );
  };

  const handleChange = ({ target: { name, value } }) => {
    dispatch(changeEditLegalEntityForm({ name, value }));
  };

  const submitForm = () => {
    dispatch(
      updateLegalEntity({
        name: form.name,
        rut: form.rut,
        email: form.email,
        phone: form.phone,
        address: form.address,
        password: form.password,
        confirmPassword: form.confirmPassword,
        userId: user.id,
        representativesArray,
      }),
    );
  };

  const title = (
    <Typography
      sx={{
        fontWeight: 'regular',
        color: color.cooper,
        fontSize: 20,
      }}>
      Edición persona jurídica
    </Typography>
  );

  const nameTextField = (
    <TextField
      fullWidth
      size="small"
      variant="outlined"
      label="Nombre"
      name="name"
      onChange={handleChange}
      color="secondary"
      value={form.name}
      autoComplete="off"
    />
  );

  const rutTextField = (
    <TextField
      fullWidth
      size="small"
      variant="outlined"
      label="RUT"
      name="rut"
      onChange={handleChange}
      color="secondary"
      value={formatRut(form.rut)}
      autoComplete="off"
    />
  );

  const emailTextField = (
    <TextField
      fullWidth
      size="small"
      variant="outlined"
      label="Correo"
      name="email"
      onChange={handleChange}
      color="secondary"
      value={form.email}
      autoComplete="off"
    />
  );

  const phoneTextField = (
    <TextField
      fullWidth
      size="small"
      variant="outlined"
      label="Teléfono"
      name="phone"
      onChange={handleChange}
      color="secondary"
      value={form.phone}
      autoComplete="off"
    />
  );

  const addressTextField = (
    <TextField
      fullWidth
      size="small"
      variant="outlined"
      label="Dirección"
      name="address"
      onChange={handleChange}
      color="secondary"
      value={form.address}
      autoComplete="off"
    />
  );

  const passwordTextField = (
    <TextField
      fullWidth
      size="small"
      type="password"
      variant="outlined"
      label="Contraseña"
      name="password"
      onChange={handleChange}
      color="secondary"
      value={form.password}
      autoComplete="off"
    />
  );

  const confirmPasswordTextField = (
    <TextField
      fullWidth
      size="small"
      type="password"
      variant="outlined"
      label="Confirmar contraseña"
      name="confirmPassword"
      onChange={handleChange}
      color="secondary"
      value={form.confirmPassword}
      autoComplete="off"
    />
  );

  const editLegalPowers = (
    <Button
      variant="text"
      onClick={openLegalPowersModal}
      sx={{ fontWeight: 'regular', color: color.blue }}
      endIcon={<Edit />}>
      Editar poderes
    </Button>
  );

  const editRepresentatives = (
    <Button
      variant="text"
      onClick={openRepresentativesModal}
      sx={{ fontWeight: 'regular', color: color.blue }}
      endIcon={<Edit />}>
      Editar representantes
    </Button>
  );

  const disableSave = !form.name || !form.rut || !form.email || !form.phone || !form.address;

  return (
    <Dialog
      open={controls.openDialog}
      onClose={handleClose}
      PaperProps={{ sx: { minWidth: { lg: 700 }, minHeight: 200 } }}>
      <EditLegalPowersModal
        legalEntityFiles={legalEntityFiles}
        registerLegalEntity={editLegalEntity}
        isEdditing
        userId={user.id}
      />
      <EditRepresentativesModal
        representativesArray={representativesArray}
        setRepresentativesArray={setRepresentativesArray}
        editLegalEntity={editLegalEntity}
        naturalEntities={naturalEntities}
        representatives={representatives}
      />
      <Box sx={{ m: 3 }}>
        <Grid container justifyContent="center" alignItems="center" sx={{ maxHeight: '80vh' }}>
          <Grid item container xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {title}
              </Grid>
              <Grid item xs={12}>
                {nameTextField}
              </Grid>
              <Grid item xs={12}>
                {rutTextField}
              </Grid>
              <Grid item xs={12}>
                {emailTextField}
              </Grid>
              <Grid item xs={12}>
                {phoneTextField}
              </Grid>
              <Grid item xs={12}>
                {addressTextField}
              </Grid>
              <Grid item xs={6}>
                {passwordTextField}
              </Grid>
              <Grid item xs={6}>
                {confirmPasswordTextField}
              </Grid>
              <Grid item xs={12} textAlign="end">
                {editLegalPowers}
              </Grid>
              <Grid item xs={12} textAlign="end">
                {editRepresentatives}
              </Grid>
              <Grid item xs={6} textAlign="start" sx={{ mt: 2 }}>
                <SecondaryButton text="Cancelar" onClick={handleClose} />
              </Grid>
              <Grid item xs={6} textAlign="end" sx={{ mt: 3 }}>
                <PrimaryButton
                  disabled={disableSave}
                  loading={loaders.create}
                  onClick={submitForm}
                  text="Aceptar"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
}

export default EditLegalEntity;
