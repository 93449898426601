import React, { useMemo, useState } from 'react';

/* Material UI */
import {
  Box,
  Menu,
  MenuItem,
  Divider,
  IconButton,
  Tooltip,
  Avatar,
  Grid,
  Button,
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DescriptionIcon from '@mui/icons-material/Description';
/* React Redux */
import { useDispatch, useSelector } from 'react-redux';

/* React Router */
import { useHistory } from 'react-router-dom';

/* Project */
import {
  changeEditLegalEntityControls,
  changeEditNaturalEntityControls,
  logout,
} from 'commons/commons.actions';
import styles from './styles';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

function AccountMenu(props) {
  const { user, objects } = props;
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const { entities, legalEntities } = objects;
  const { filters, pagination } = useSelector((state) => state.home);

  const history = useHistory();

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const currentEntity = useMemo(
    () => (user ? Object.values(entities).find((e) => e.userId === user.id) : null),
    [user],
  );

  const legalEntity = useMemo(
    () =>
      currentEntity
        ? Object.values(legalEntities).filter((le) => le.entityId === currentEntity.id)
        : null,
    [currentEntity],
  );

  const leters = currentEntity
    ? `${currentEntity.names[0] || ''}${currentEntity.surnames[0] || ''}`.toUpperCase()
    : null;

  const openEditModal = () => {
    if (legalEntity.length) {
      dispatch(
        changeEditLegalEntityControls({
          name: 'openDialog',
          value: true,
        }),
      );
    } else {
      dispatch(
        changeEditNaturalEntityControls({
          name: 'openDialog',
          value: true,
        }),
      );
    }
  };

  const handleLogout = () => {
    history.push('/');
    dispatch(logout({ filters, pagination }));
  };

  const sessionOption = (
    <MenuItem onClick={user ? handleLogout : () => history.push('/login')}>
      {user ? 'Cerrar sesión' : 'Iniciar sesión'}
    </MenuItem>
  );

  const recoverPasswordOption = user ? null : (
    <MenuItem onClick={() => history.push('/recovery-password')}>Recuperar contraseña</MenuItem>
  );

  const discrepancyUrl = location.pathname !== '/';

  return (
    <>
      <Grid>
        {discrepancyUrl && (
          <Button
            variant="text"
            sx={{ color: '#fff', textTransform: 'Capitalize' }}
            startIcon={<DescriptionIcon />}
            onClick={() => history.push('/')}>
            Discrepancias
          </Button>
        )}
        <Button
          variant="text"
          sx={{ color: '#fff' }}
          startIcon={<CalendarMonthIcon />}
          onClick={() => history.push('/hearings')}>
          Inscripción audiencias
        </Button>
      </Grid>
      <Box sx={{ ...styles.iconContainer }}>
        <Tooltip title="Cuenta">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}>
            {user ? (
              // <AccountCircleIcon fontSize="small" sx={{ ...styles.iconStyles }} />
              <Avatar fontSize="small" sx={{ ...styles.iconStyles }}>
                {leters}
              </Avatar>
            ) : (
              <LockIcon fontSize="small" sx={{ ...styles.iconStyles }} />
            )}
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          ...styles.paperStyles,
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        {user
          ? [
              <MenuItem key="profile-action" onClick={openEditModal}>
                Mi Perfil
              </MenuItem>,
              <Divider key="divider-action" variant="middle" />,
            ]
          : null}

        {sessionOption}
        {recoverPasswordOption}
      </Menu>
    </>
  );
}

export default AccountMenu;
