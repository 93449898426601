import {
  Alert,
  CircularProgress,
  Grid,
  IconButton,
  Link,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FolderIcon from '@mui/icons-material/Folder';
import moment from 'moment';
import colors from 'utils/colors';
import { changePagination, getFileLink } from '../SearchContent.actions';
import { useDispatch } from 'react-redux';

const ResultsTable = (props) => {
  const dispatch = useDispatch();
  const { results, loading, pagination, selectedFileDownloadLink, selectedFileName, controls } =
    props;
  const { page, count, total, size } = pagination;
  const pageSize = 15;
  // Estados para controlar la paginación
  const [currentPage, setCurrentPage] = useState(1);
  const [currentResults, setCurrentResults] = useState([]);
  // Efecto para actualizar los resultados mostrados cuando cambia la página o los resultados
  useEffect(() => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    setCurrentResults(results.slice(startIndex, endIndex));
  }, [currentPage, results]);

  useEffect(() => {
    setCurrentPage(1)
  }, [results])

  const onChangePagination = (event, value) => {
    setCurrentPage(value);
  };

  const extractPathFromUrl = (url) => {
    const segments = url.split('/');
    if (segments.length > 7) {
      const pathSegments = segments.slice(7, -1);
      return '/' + pathSegments.join('/');
    }
  };

  const getDownloadLink = (driveId, itemId) => {
    return dispatch(getFileLink({ driveId, itemId }));
  };

  const downloadPdf = (filePath, fileName) => {
    fetch(filePath, {
      method: 'GET',
      headers: {
        'Content-Type': '*',
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));

        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
      });
  };

  useEffect(() => {
    if (selectedFileDownloadLink !== '' && selectedFileName !== '') {
      return downloadPdf(selectedFileDownloadLink, selectedFileName);
    }
  }, [selectedFileDownloadLink, selectedFileName]);

  return (
    <Grid sx={{ mt: 5 }}>
      {loading ? (
        <Grid container sx={{ mt: 10 }} direction="row" justifyContent="center">
          <CircularProgress sx={{ color: colors.purpleSecondary }} size={90} thickness={3} />
        </Grid>
      ) : total !== false ? (
        <Grid container>
          <Grid container>
            <TableContainer sx={{ borderRadius: '4px', border: '1px solid #dee3ed' }}>
              <Table size="small" aria-label="a dense table">
                <TableHead sx={{ background: '#A6A6A6' }}>
                  <TableRow sx={{ color: '#fff' }}>
                    <TableCell sx={{ color: '#fff' }}>Archivo</TableCell>
                    <TableCell sx={{ color: '#fff' }}>Ruta</TableCell>
                    <TableCell sx={{ color: '#fff' }}>Fecha</TableCell>
                    <TableCell sx={{ color: '#fff' }} align="center">
                      Descargar
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentResults.length > 0 ? (
                    currentResults.map((item) => (
                      <TableRow
                        key={item.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        hover>
                        <TableCell align="left">{item.name}</TableCell>
                        <TableCell align="left">
                          <Tooltip arrow title={extractPathFromUrl(item.webUrl)} placement="right">
                            <IconButton
                              sx={{ padding: '1px', fontSize: '20px', justifyContent: 'left', cursor: 'initial' }}>
                              <FolderIcon fontSize="inherit" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        <TableCell align="left">
                          {moment(new Date(item.createdDateTime)).format('YYYY-MM-DD HH:mm')}
                        </TableCell>
                        <TableCell align="center">
                          <IconButton
                            sx={{ padding: '1px', fontSize: '20px' }}
                            onClick={() => getDownloadLink(item.driveId, item.id)}>
                            <DownloadIcon fontSize="inherit" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} hover>
                      <TableCell align="center" colSpan={4}>
                        Sin resultados
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid container sx={{ mt: 3, mb: 5 }} direction="row" justifyContent="center">
            <Pagination
              count={Math.ceil(results.length / pageSize)}
              page={currentPage}
              variant="outlined"
              shape="rounded"
              disabled={loading}
              onChange={onChangePagination}
            />
          </Grid>
        </Grid>
      ) : (
        <Alert severity="info">Filtre por fecha o palabras claves para obtener resultados.</Alert>
      )}
    </Grid>
  );
};

export default ResultsTable;
