import { IconButton, tableCellClasses, TableRow } from '@mui/material';
import PropTypes from 'prop-types';

import color from 'utils/colors';
import { Delete, Edit } from '@mui/icons-material';
import React from 'react';
import { styled } from '@mui/styles';
import TableCell from '@mui/material/TableCell';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function AttachmentPartItem(props) {
  const { attachmentPart, idx, handleUpdate, handleDelete } = props;
  const attachments = attachmentPart.attachments();

  const publicAttachment = attachments.find((a) => a.confidential === false);
  const privateAttachment = attachments.find((a) => a.confidential === true);

  return (
    <StyledTableRow>
      <StyledTableCell component="th" scope="row">
        {`${idx + 1}.`}
      </StyledTableCell>
      <StyledTableCell>{publicAttachment.content.split('_').pop()}</StyledTableCell>
      {/* INFO: Cliente requiere retirar la opción de agregar archivos confidenciales
       <StyledTableCell align="center">{privateAttachment?.content || ''}</StyledTableCell> */}
      <StyledTableCell align="right">
        <IconButton
          sx={{ color: color.blue }}
          aria-label="."
          component="span"
          onClick={() => handleUpdate(attachmentPart)}>
          <Edit />
        </IconButton>
        <IconButton
          sx={{ color: color.blue }}
          aria-label="."
          component="span"
          onClick={() => handleDelete(attachmentPart)}>
          <Delete />
        </IconButton>
      </StyledTableCell>
    </StyledTableRow>
  );
}

AttachmentPartItem.propTypes = {
  attachmentPart: PropTypes.shape({}).isRequired,
  idx: PropTypes.number.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default AttachmentPartItem;
