import camelize from 'camelcase-keys-deep';

export default class Document {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  documentType() {
    return this.env().DocumentType.getById(this.documentTypeId);
  }

  hearing() {
    return this.env().Hearing.findAllBy('documentId', this.id);
  }

  discrepancy() {
    return this.env().Discrepancy.getById(this.discrepancyId);
  }

  interestedParty() {
    return this.env().InterestedParty.getById(this.interestedPartyId);
  }

  attachmentParts() {
    return this.env().AttachmentPart.findAllBy('documentId', this.id);
  }

  attachments() {
    const atts = [];
    this.attachmentParts().forEach((attachmentPart) => {
      atts.push(...attachmentPart.attachments());
    });

    return atts;
  }

  principalAttachmentPart() {
    return this.attachmentParts().find((attachmentPart) => attachmentPart.type === 'principal');
  }
}
