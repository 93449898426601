import React from 'react';

/* Material UI */
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton, Stack } from '@mui/material';
import { Edit } from '@mui/icons-material';

/* React Redux */
import { useDispatch } from 'react-redux';

/* Project */
import TitleField from 'commons/components/TitleField/TitleField';
import { toggleUpdateDiscrepancyModal } from 'commons/commons.actions';

import { discrepancyFullTitle } from '../../../utils/functions';

function Title(props) {
  const { discrepancy, currentUser, history } = props;
  const { number, createdAt, cover } = discrepancy;

  const dispatch = useDispatch();

  const handleDiscrepancyEdition = () => {
    dispatch(toggleUpdateDiscrepancyModal({ open: true }));
  };

  const isAdmin = currentUser?.type === 'admin';

  return (
    <Stack direction="row" spacing={2} justifyContent={'center'}>
      <TitleField title={discrepancyFullTitle(number, cover, createdAt)} />
      {isAdmin ? (
        <IconButton onClick={handleDiscrepancyEdition}>
          <Edit />
        </IconButton>
      ) : null}
    </Stack>
  );
}

export default Title;
