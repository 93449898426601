import Login from 'screens/Login/Login';
import Home from 'screens/Home/Home';
import DiscrepancyDetail from 'screens/DiscrepancyDetail/DiscrepancyDetails';
import DiscrepancyEntities from 'screens/InterestedParties/DiscrepancyEntities';

import AudiencesCalendar from 'screens/AudiencesCalendar/AudiencesCalendar';
import LastDocuments from 'screens/LastDocuments/LastDocuments';
import RecoveryPassword from 'screens/RecoveryPassword/RecoveryPassword';
import Upload from '../screens/Upload/Upload';
import ErrorPage from '../screens/ErrorPage/ErrorPage';
import SearchContent from 'screens/SearchContent/SearchContent';

const Paths = {
  shared: [
    { path: '/login', component: Login },
    { path: '/recovery-password', component: RecoveryPassword },
    { path: '/update-password', component: RecoveryPassword },
    { path: '*', component: ErrorPage },
  ],
  publics: [
    { path: '/', component: Home },
    {
      path: '/discrepancies/:id/expedient/:documentId',
      component: DiscrepancyDetail,
    },
    {
      path: '/discrepancies/:id/entities',
      component: DiscrepancyEntities,
    },
    {
      path: '/hearings',
      component: AudiencesCalendar,
    },
    {
      path: '/lastDocuments',
      component: LastDocuments,
    },
    { path: '/search-content', component: SearchContent },
  ],
  privates: [{ path: '/upload', component: Upload }],
};

export default Paths;
