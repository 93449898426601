import HomeTypes from 'screens/Home/Home.types';

export const disableErrors = () => ({
  type: HomeTypes.DISABLE_ERRORS,
});

export const toggleStepper = () => ({
  type: HomeTypes.TOGGLE_STEPPER,
});

export const getDiscrepancies = (payload) => ({
  type: HomeTypes.GET_DISCREPANCIES,
  payload: { ...payload },
});

export const setFilters = ({ name, value }) => ({
  type: HomeTypes.SET_FILTERS,
  payload: { name, value },
});

export const clearFilters = () => ({
  type: HomeTypes.CLEAR_FILTERS,
});

export const controlsChanged = ({ name, value }) => ({
  type: HomeTypes.CONTROLS_CHANGED,
  payload: { name, value },
});

export const changePagination = ({ value }) => ({
  type: HomeTypes.CHANGE_PAGINATION,
  payload: { value },
});
