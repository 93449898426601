import React, { useEffect, useMemo } from 'react';

import { LinearProgress } from '@mui/material';

import AuthUserContext from 'commons/context/user';
import Layout from 'commons/containers/Layout/Layout';
import DiscrepancyModal from 'commons/components/DiscrepancyModal/DiscrepancyModal';
import { toggleStepper } from 'screens/Home/Home.actions';
import { useDispatch, useSelector } from 'react-redux';
import RegisterLegalEntity from 'commons/components/RegisterLegalEntity/RegisterLegalEntity';
import RegisterNaturalEntity from 'commons/components/RegisterNaturalEntity/RegisterNaturalEntity';
import EditNaturalEntity from 'commons/components/EditNaturalEntity/EditNaturalEntity';
import EditLegalEntity from 'commons/components/EditLegalEntity/EditLegalEntity';
import { reselectObjects } from 'utils/helper';
import RegisterDiscrepancyMessage from 'commons/components/RegisterDiscrepancyMessage/RegisterDiscrepancyMessage';

// import { useSelector } from 'react-redux';

function PublicLayout({ user, loading, children }) {
  // const fullState = useSelector((state) => state);
  const dispatch = useDispatch();

  if (loading) return <LinearProgress />;

  const { app: appState } = useSelector((state) => state);
  const { objects: objectsState } = appState;
  const objects = useMemo(() => reselectObjects(objectsState), [objectsState]);

  const { entities, legalEntities } = objects;

  const entity = user ? Object.values(entities).filter((e) => e.userId === user.id) : [];

  const legalEntity = entity.length
    ? Object.values(legalEntities).filter((le) => le.entityId === entity[0].id)
    : [];

  const handleToggle = () => dispatch(toggleStepper());

  const toggleCaptchaBadge = (show) => {
    const badge = document.getElementsByClassName('grecaptcha-badge')[0];
    if (badge && badge instanceof HTMLElement) {
      badge.style.visibility = show ? 'visible' : 'hidden';
    }
  };

  const { registerLegalEntity, registerNaturalEntity } = useSelector((state) => state.app);
  const { controls: legalEntityControls } = registerLegalEntity;
  const { controls: naturalEntityControls } = registerNaturalEntity;

  useEffect(() => {
    if (legalEntityControls.openDialog || naturalEntityControls.openDialog) {
      toggleCaptchaBadge(true);
    } else {
      toggleCaptchaBadge(false);
    }
    return () => {
      toggleCaptchaBadge(false);
    };
  }, [legalEntityControls.openDialog, naturalEntityControls.openDialog]);

  return (
    <Layout>
      <AuthUserContext.Provider value={user}>
        <DiscrepancyModal toggleStepper={handleToggle} />
        <RegisterLegalEntity />
        <RegisterNaturalEntity />
        {user?.type === 'admin' && <RegisterDiscrepancyMessage />}
        {legalEntity.length ? <EditLegalEntity /> : <EditNaturalEntity />}
        {children}
      </AuthUserContext.Provider>
    </Layout>
  );
}

export default PublicLayout;
