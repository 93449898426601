import {
  Button,
  Divider,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  SvgIcon,
  Typography,
} from '@mui/material';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import colors from 'utils/colors';
import {
  changeDocumentControls,
  changeTwoOrMoreDocumentControls,
  selectViewDocument,
  setDocumentFormValues,
} from '../DiscrepancyDetails.actions';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Delete, Edit } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import DocumentsViewList from './DocumentsViewList';
import DocumentsViewList2 from './DocumentsViewList2';
const DocumentsView = (props) => {
  const { attachmentParts, currentUser, objects, discrepancyID, currentDocument } = props;
  const dispatch = useDispatch();
  const { viewDocument } = useSelector((state) => state.discrepancyDetails);
  const { documentId } = useParams();
  const currentDiscrepancy = objects.discrepancies[discrepancyID];
  const presentation = currentDiscrepancy ? currentDiscrepancy.presentation() : null;

  useEffect(() => {
    return () => {
      dispatch(selectViewDocument({ document: false }));
    };
  }, []);

  const titleStyles = {
    textDecoration: 'underline',
    color: colors.purple,
  };

  const downloadPdf = (filePath, fileName = 'Example-PDF-file.pdf') => {
    fetch(filePath, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));

        const link = document.createElement('a');
        link.href = url;
        link.download = fileName.split('_')[1];

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
      });
  };

  const openDocument = (attachment) => {
    dispatch(selectViewDocument({ document: attachment }));
  };

  useEffect(() => {
    const principalDocument = Object.values(attachmentParts).find(
      (item) => item.type === 'principal',
    );
    const document = principalDocument.attachments();
    if (document.length) {
      const attachment = document.find((item) => item.contentType === 'pdf');
      dispatch(selectViewDocument({ document: attachment }));
    }
  }, [documentId]);

  const PdfIcon = (
    <SvgIcon sx={{ mr: '10px' }}>
      <svg
        width="14"
        height="18"
        viewBox="0 0 14 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.0018 3.44464L10.0527 0.495606C9.73638 0.179262 9.30756 0 8.86116 0H1.68717C0.755712 0.00351494 0 0.759227 0 1.69069V16.3128C0 17.2443 0.755712 18 1.68717 18H11.8102C12.7417 18 13.4974 17.2443 13.4974 16.3128V4.63972C13.4974 4.19332 13.3181 3.76098 13.0018 3.44464ZM11.6731 4.50264H8.99824V1.82777L11.6731 4.50264ZM1.68717 16.3128V1.69069H7.31107V5.34622C7.31107 5.81371 7.68717 6.18981 8.15466 6.18981H11.8102V16.3128H1.68717ZM10.4815 11.2619C10.0527 10.8401 8.82952 10.9561 8.21793 11.0334C7.61336 10.6643 7.20914 10.1547 6.92443 9.40598C7.06151 8.84007 7.27944 7.97891 7.11424 7.43761C6.96661 6.5167 5.78559 6.60809 5.61687 7.23023C5.46221 7.79613 5.60281 8.58348 5.86292 9.58875C5.51142 10.4288 4.9877 11.5571 4.61863 12.2039C3.91564 12.5659 2.96661 13.1248 2.82601 13.8278C2.71002 14.3831 3.73989 15.768 5.50088 12.7311C6.28822 12.471 7.14587 12.1511 7.9051 12.0246C8.56942 12.3831 9.34622 12.6221 9.86643 12.6221C10.7627 12.6221 10.8506 11.6309 10.4815 11.2619ZM3.51845 13.9965C3.69772 13.5149 4.37961 12.9596 4.58699 12.7663C3.91916 13.8313 3.51845 14.0211 3.51845 13.9965ZM6.38664 7.29701C6.64675 7.29701 6.62214 8.42531 6.44991 8.73111C6.29525 8.24253 6.29877 7.29701 6.38664 7.29701ZM5.529 12.0984C5.86995 11.5044 6.16169 10.7979 6.39719 10.1757C6.68893 10.7065 7.06151 11.1318 7.45518 11.4236C6.72408 11.5747 6.08787 11.884 5.529 12.0984ZM10.1547 11.9227C10.1547 11.9227 9.97891 12.1336 8.84359 11.6485C10.0773 11.5571 10.2812 11.8383 10.1547 11.9227Z"
          fill="#B21019"
        />
      </svg>
    </SvgIcon>
  );

  const WordIcon = (
    <SvgIcon sx={{ mr: '10px' }}>
      <svg
        width="14"
        height="18"
        viewBox="0 0 14 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.0018 3.44464L10.0527 0.495606C9.73638 0.179262 9.30756 0 8.86116 0H1.68717C0.755712 0.00351494 0 0.759227 0 1.69069V16.3128C0 17.2443 0.755712 18 1.68717 18H11.8102C12.7417 18 13.4974 17.2443 13.4974 16.3128V4.63972C13.4974 4.19332 13.3181 3.76098 13.0018 3.44464ZM11.6731 4.50264H8.99824V1.82777L11.6731 4.50264ZM1.68717 16.3128V1.69069H7.31107V5.34622C7.31107 5.81371 7.68717 6.18981 8.15466 6.18981H11.8102V16.3128H1.68717ZM9.42355 9.00176C9.2232 9.00176 9.05097 9.14236 9.0123 9.33568C8.28822 12.7698 8.29525 12.6889 8.27417 12.9736C8.26713 12.9315 8.26011 12.8823 8.24956 12.8225C8.22144 12.6432 8.26011 12.8295 7.42004 9.32513C7.37434 9.13533 7.20562 9.00176 7.00879 9.00176H6.5413C6.34798 9.00176 6.17926 9.13533 6.13005 9.32162C5.27241 12.8014 5.28647 12.703 5.25835 12.9666C5.25483 12.9279 5.25132 12.8787 5.24077 12.819C5.21617 12.6362 4.74517 10.2425 4.56942 9.33919C4.53076 9.14236 4.35852 8.99824 4.15466 8.99824H3.56415C3.28998 8.99824 3.08963 9.25483 3.1529 9.51845C3.43409 10.6643 4.09139 13.3673 4.31986 14.2988C4.36555 14.4886 4.53427 14.6186 4.73111 14.6186H5.61687C5.81019 14.6186 5.97891 14.4886 6.0246 14.2988L6.65378 11.7891C6.7065 11.5712 6.74165 11.3673 6.75923 11.181L6.86116 11.7891C6.86467 11.8032 7.30404 13.5641 7.49033 14.2988C7.53603 14.4851 7.70474 14.6186 7.89807 14.6186H8.76626C8.95958 14.6186 9.12829 14.4886 9.17399 14.2988C9.9051 11.42 10.2355 10.116 10.3866 9.51845C10.4534 9.25132 10.2531 8.99473 9.97891 8.99473H9.42355V9.00176Z"
          fill="#641C34"
        />
      </svg>
    </SvgIcon>
  );

  const DocumentIcon = (
    <SvgIcon sx={{ mr: '10px' }}>
      <svg
        width="14"
        height="18"
        viewBox="0 0 14 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.123 8.71875V9.70312C10.123 9.93516 9.93322 10.125 9.70123 10.125H3.79613C3.56415 10.125 3.37434 9.93516 3.37434 9.70312V8.71875C3.37434 8.48672 3.56415 8.29688 3.79613 8.29688H9.70123C9.93322 8.29688 10.123 8.48672 10.123 8.71875ZM9.70123 11.25H3.79613C3.56415 11.25 3.37434 11.4398 3.37434 11.6719V12.6562C3.37434 12.8883 3.56415 13.0781 3.79613 13.0781H9.70123C9.93322 13.0781 10.123 12.8883 10.123 12.6562V11.6719C10.123 11.4398 9.93322 11.25 9.70123 11.25ZM13.4974 4.63711V16.3125C13.4974 17.2441 12.7417 18 11.8102 18H1.68717C0.755712 18 0 17.2441 0 16.3125V1.6875C0 0.755859 0.755712 0 1.68717 0H8.86116C9.30756 0 9.73638 0.179297 10.0527 0.495703L13.0018 3.44531C13.3181 3.7582 13.4974 4.19062 13.4974 4.63711ZM8.99824 1.82461V4.5H11.6731L8.99824 1.82461ZM11.8102 16.3125V6.1875H8.15466C7.68717 6.1875 7.31107 5.81133 7.31107 5.34375V1.6875H1.68717V16.3125H11.8102Z"
          fill="#A6A6A6"
        />
      </svg>
    </SvgIcon>
  );

  const EyeIcon = (props) => {
    return (
      <SvgIcon
        sx={{
          mr: '10px',
          padding: '3px',
          cursor: 'pointer',
          borderRadius: '5px',
          '&:hover': { background: '#E5E5E5!important' },
        }}
        onClick={() => openDocument(props.attachment)}>
        <svg
          width="18"
          height="16"
          viewBox="0 0 18 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8.99742 3.625C8.66621 3.63147 8.33745 3.69724 8.02117 3.82031C8.16747 4.14192 8.24537 4.50504 8.24742 4.875C8.24742 5.16227 8.20215 5.44672 8.11421 5.71212C8.02626 5.97752 7.89736 6.21867 7.73486 6.4218C7.57235 6.62492 7.37943 6.78605 7.16711 6.89599C6.95479 7.00592 6.72723 7.0625 6.49742 7.0625C6.20145 7.05993 5.91095 6.96256 5.65367 6.77969C5.45068 7.65969 5.47434 8.59721 5.72129 9.45947C5.96825 10.3217 6.42598 11.065 7.02966 11.584C7.63333 12.103 8.35234 12.3714 9.08485 12.3513C9.81735 12.3311 10.5262 12.0233 11.111 11.4716C11.6958 10.9199 12.1269 10.1521 12.3432 9.2771C12.5596 8.40209 12.5502 7.46417 12.3164 6.5962C12.0826 5.72823 11.6362 4.97421 11.0406 4.44096C10.4449 3.9077 9.73009 3.62224 8.99742 3.625ZM17.8887 7.42969C16.194 3.29648 12.839 0.5 8.99742 0.5C5.15586 0.5 1.79992 3.29844 0.106169 7.43008C0.0346825 7.60684 -0.00256348 7.80213 -0.00256348 8.0002C-0.00256348 8.19826 0.0346825 8.39355 0.106169 8.57031C1.80086 12.7035 5.15586 15.5 8.99742 15.5C12.839 15.5 16.1949 12.7016 17.8887 8.56992C17.9602 8.39316 17.9974 8.19787 17.9974 7.9998C17.9974 7.80174 17.9602 7.60645 17.8887 7.42969ZM8.99742 13.625C5.91461 13.625 3.08836 11.4766 1.56211 8C3.08836 4.52344 5.91429 2.375 8.99742 2.375C12.0805 2.375 14.9065 4.52344 16.4327 8C14.9068 11.4766 12.0805 13.625 8.99742 13.625Z"
            fill="#A6A6A6"
          />
        </svg>
      </SvgIcon>
    );
  };

  const DownloadIcon = (props) => {
    return (
      <SvgIcon
        sx={{
          mr: '10px',
          padding: '3px',
          cursor: 'pointer',
          borderRadius: '5px',
          '&:hover': { background: '#E5E5E5!important' },
        }}
        onClick={() => downloadPdf(props.url, props.fileName)}>
        <svg
          width="15"
          height="16"
          viewBox="0 0 15 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.3255 0.5H8.66925C9.0589 0.5 9.37238 0.813477 9.37238 1.20312V6.125H11.9417C12.4632 6.125 12.7239 6.75488 12.3548 7.12402L7.89874 11.583C7.67902 11.8027 7.31866 11.8027 7.09894 11.583L2.63702 7.12402C2.26788 6.75488 2.52863 6.125 3.05011 6.125H5.62238V1.20312C5.62238 0.813477 5.93585 0.5 6.3255 0.5ZM14.9974 11.5156V14.7969C14.9974 15.1865 14.6839 15.5 14.2943 15.5H0.7005C0.310852 15.5 -0.00262451 15.1865 -0.00262451 14.7969V11.5156C-0.00262451 11.126 0.310852 10.8125 0.7005 10.8125H4.99835L6.4339 12.248C7.02277 12.8369 7.97198 12.8369 8.56085 12.248L9.9964 10.8125H14.2943C14.6839 10.8125 14.9974 11.126 14.9974 11.5156ZM11.3646 14.0938C11.3646 13.7715 11.1009 13.5078 10.7786 13.5078C10.4564 13.5078 10.1927 13.7715 10.1927 14.0938C10.1927 14.416 10.4564 14.6797 10.7786 14.6797C11.1009 14.6797 11.3646 14.416 11.3646 14.0938ZM13.2396 14.0938C13.2396 13.7715 12.9759 13.5078 12.6536 13.5078C12.3314 13.5078 12.0677 13.7715 12.0677 14.0938C12.0677 14.416 12.3314 14.6797 12.6536 14.6797C12.9759 14.6797 13.2396 14.416 13.2396 14.0938Z"
            fill="#A6A6A6"
          />
        </svg>
      </SvgIcon>
    );
  };

  const iconValidation = (type) => {
    switch (type) {
      case 'pdf':
        return PdfIcon;
      case 'doc':
        return WordIcon;
      case 'docx':
        return WordIcon;
      default:
        return DocumentIcon;
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const hearing = useMemo(() => {
    return currentDocument.hearing().find((item) => item.discrepancyId === currentDiscrepancy.id);
  }, [currentDiscrepancy, currentDocument]);

  const openEditDialog = (editActiveStep) => {
    dispatch(
      changeTwoOrMoreDocumentControls({
        openEditDialog: true,
        editActiveStep,
      }),
    );
    dispatch(
      setDocumentFormValues({
        id: currentDocument.id,
        documentTypeId: currentDocument.documentTypeId,
        title: currentDocument.title,
        index: currentDocument.index,
        documentDate: currentDocument.createdAt,
        legalEntityId: currentDocument.interestedParty().legalEntity().legalEntity().id,
        dateHourAudience: hearing?.date || null,
        dateHourRegistrationDeadline: hearing?.deadline || null,
        dateHourPresentationInterestedParty: currentDiscrepancy.presentationDate,
        dateHourObservationsDeadline: currentDiscrepancy.observationsDeadline,
      }),
    );
    handleClose();
  };

  const openDeleteDialog = () => {
    dispatch(changeDocumentControls({ name: 'openDeleteDialog', value: true }));
    handleClose();
  };

  const documentMenu = (
    <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
      <MenuItem onClick={() => openEditDialog(0)}>
        <ListItemIcon>
          <Edit fontSize="small" sx={{ color: colors.cooper }} />
        </ListItemIcon>
        <ListItemText>Editar documento</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => openEditDialog(1)}>
        <ListItemIcon>
          <Edit fontSize="small" sx={{ color: colors.cooper }} />
        </ListItemIcon>
        <ListItemText>Editar archivos principales</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => openEditDialog(2)}>
        <ListItemIcon>
          <Edit fontSize="small" sx={{ color: colors.cooper }} />
        </ListItemIcon>
        <ListItemText>Editar adjuntos</ListItemText>
      </MenuItem>
      {presentation?.id === currentDocument.id ? null : (
        <>
          <Divider />
          <MenuItem onClick={openDeleteDialog}>
            <ListItemIcon>
              <Delete fontSize="small" sx={{ color: colors.blue }} />
            </ListItemIcon>
            <ListItemText sx={{ color: colors.blue }}>Eliminar documento</ListItemText>
          </MenuItem>
        </>
      )}
    </Menu>
  );

  const formatedData = useMemo(() => {
    let principalData = [];
    let secondaryData = [];
    Object.values(attachmentParts).map((attachmentPart) => {
      if (attachmentPart?.type === 'principal') {
        principalData.push(...attachmentPart.attachments());
      } else {
        secondaryData.push(...attachmentPart.attachments());
      }
    });

    let data = [{ title: 'Principal', data: principalData }];

    if (secondaryData.length) {
      data.push({ title: 'Adjunto', data: secondaryData });
    }

    return data;
  }, [attachmentParts]);

  return (
    <Grid
      item
      xs={12}
      md={9}
      lg={9}
      spacing={4}
      sx={{
        fontFamily: 'Roboto',
        fontWeight: '400',
        color: colors.secondaryGrey,
        fontSize: '15px',
      }}>
      {currentUser?.type === 'admin' && !currentDiscrepancy.endedAt ? (
        <Grid container sx={{ mb: '5px', mt: 2 }} justifyContent={'flex-end'}>
          <Button
            sx={{ color: colors.purpleSecondary, height: '35px' }}
            variant="outlined"
            size="small"
            onClick={handleClick}
            startIcon={<EditIcon />}>
            Editar
          </Button>
        </Grid>
      ) : null}
      <DocumentsViewList formatedData={formatedData} currentDocument={currentDocument} currentUser={currentUser} />

      <Grid
        sx={{
          mt: '30px',
          background: `linear-gradient(180deg, ${colors.darkGreyRgba} 40%, ${colors.whiteRgba} 1%)`,
        }}>
        {viewDocument && (
          <>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ padding: '15px 10px' }}>
              <Typography sx={{ color: colors.white, pl: 1 }}>
                {viewDocument?.content.includes('_') ? viewDocument?.content.split('_').slice(1).join('_') : viewDocument?.content}
              </Typography>
            </Grid>
            <div
              style={{
                position: 'relative',
                paddingBottom: '53%',
                paddingTop: '25px',
                height: 0,
                width: '99.8%',
                marginBottom: '30px',
              }}>
              <iframe
                src={viewDocument?.url}
                title={'sadasdsda'}
                aria-label="ASDASD"
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                }}
              />
            </div>
          </>
        )}
      </Grid>
      {currentUser?.type === 'admin' ? documentMenu : null}
    </Grid>
  );
};

export default DocumentsView;
