import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import color from 'utils/colors';
import AddIcon from '@mui/icons-material/Add';
import AttachmentModal from 'screens/DiscrepancyDetail/components/AttachmentModal';
import { Delete, Edit } from '@mui/icons-material';
import { styled } from '@mui/styles';
import TableCell from '@mui/material/TableCell';
import {
  changeAttachmentControls,
  openUpdateDocumentDialog,
} from 'screens/DiscrepancyDetail/DiscrepancyDetails.actions';
import { useDispatch } from 'react-redux';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function StepThree(props) {
  const { attachmentsArray, setAttachmentsArray } = props;

  const dispatch = useDispatch();

  const openModal = () => {
    dispatch(changeAttachmentControls({ name: 'openDialog', value: true }));
  };

  const removeFileRow = (idx) => {
    const attachmentsRows = [...attachmentsArray];
    attachmentsRows.splice(idx, 1);
    setAttachmentsArray(attachmentsRows);
  };

  const setFileRow = (idx) => {
    dispatch(openUpdateDocumentDialog({ idx }));
  };

  const newFileRow = (attachments, idx) => {
    if (idx !== -1) {
      const filteredArray = attachmentsArray.map((item, index) =>
        index === idx ? attachments : item,
      );

      return setAttachmentsArray([...filteredArray]);
    }

    return setAttachmentsArray([...attachmentsArray, attachments]);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          mb: 2,
        }}>
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: 'bold',
            color: color.blue,
            textTransform: 'uppercase',
            mr: 1,
          }}>
          CARGAR
        </Typography>
        <Typography
          sx={{
            fontWeight: 'regular',
            color: color.blue,
            fontSize: 16,
          }}>
          Archivos adjuntos
        </Typography>
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="span"
          onClick={openModal}>
          <AddIcon
            sx={{
              color: color.white,
              backgroundColor: color.blue,
              borderRadius: 3,
            }}
          />
        </IconButton>
        <AttachmentModal newFileRow={newFileRow} attachmentsArray={attachmentsArray} />
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ maxWidth: 700 }} size="small" aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell />
              <StyledTableCell>Adjunto (versión pública)</StyledTableCell>
              {/* INFO: Cliente requiere retirar la opción de agregar archivos confidenciales
              <StyledTableCell align="right">Versión confidencial</StyledTableCell> */}
              <StyledTableCell align="center">Acciones</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {attachmentsArray.map((attachment, idx) => (
              // eslint-disable-next-line react/no-array-index-key
              <StyledTableRow key={`${attachment.publicAtt?.name} ${idx}`}>
                <StyledTableCell component="th" scope="row">
                  {`${idx + 1}.`}
                </StyledTableCell>
                <StyledTableCell>{attachmentsArray[idx].publicAtt?.name}</StyledTableCell>
                {/* INFO: Cliente requiere retirar la opción de agregar archivos confidenciales
                <StyledTableCell align="center">
                  {attachmentsArray[idx].privateAtt?.name || '-'}
                </StyledTableCell> */}
                <StyledTableCell align="right">
                  <IconButton
                    sx={{ color: color.blue }}
                    aria-label="."
                    component="span"
                    onClick={() => setFileRow(idx)}>
                    <Edit />
                  </IconButton>
                  <IconButton
                    sx={{ color: color.blue }}
                    aria-label="."
                    component="span"
                    onClick={() => removeFileRow(idx)}>
                    <Delete />
                  </IconButton>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

StepThree.propTypes = {
  attachmentsArray: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setAttachmentsArray: PropTypes.func.isRequired,
};

export default StepThree;
