// @ts-nocheck
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import { FormControlLabel, Switch, TextField, Typography, styled } from '@mui/material';
import color from 'utils/colors';
import PrimaryButton from 'commons/components/PrimaryButton/PrimaryButton';
import SecondaryButton from 'commons/components/SecondaryButton/SecondaryButton';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import {
  changeDiscrepancyMessage,
  createDiscrepancyMessage,
  loadDiscrepancyMessage,
  openDiscrepancyMessageDialog,
} from 'commons/commons.actions';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const CustomSwitch = styled(Switch)(({ theme }) => ({
  '& .Mui-checked': {
    color: '#641C34',
  },
  '& .MuiSwitch-track': {
    backgroundColor: '#641C34 !important',
  },
}));

function RegisterDiscrepancyMessage() {
  const { registerDiscrepancyMessage, objects } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const { controls, form, loaders } = registerDiscrepancyMessage;
  const { discrepancyMessage } = objects;

  const discrepancyMessageValue = useMemo(
    () => Object.values(discrepancyMessage).pop(),
    [discrepancyMessage],
  );

  useEffect(() => {
    if (discrepancyMessageValue) {
      return dispatch(
        loadDiscrepancyMessage({
          title: discrepancyMessageValue.title,
          message: discrepancyMessageValue.message,
          enabled: discrepancyMessageValue.enabled,
          noTitle: !discrepancyMessageValue?.title,
        }),
      );
    }
  }, [discrepancyMessageValue]);

  const handleClose = () => {
    return dispatch(openDiscrepancyMessageDialog({ name: 'openDialog', value: false }));
  };

  const handleChange = ({ target: { name, value } }) => {
    return dispatch(changeDiscrepancyMessage({ name, value }));
  };

  const textEditorChange = (data) => {
    return dispatch(changeDiscrepancyMessage({ name: 'message', value: data }));
  };

  const handleChangeSwitch = (e) => {
    if (e.target.name === 'noTitle') {
      dispatch(changeDiscrepancyMessage({ name: 'title', value: '' }));
    }
    dispatch(changeDiscrepancyMessage({ name: e.target.name, value: e.target.checked }));
  };

  const submitForm = () => {
    return dispatch(
      createDiscrepancyMessage({
        id: discrepancyMessageValue?.id,
        title: form.title,
        message: form.message,
        enabled: form.enabled,
      }),
    );
  };

  const title = (
    <Typography
      sx={{
        fontWeight: 'regular',
        color: color.purple,
        fontSize: 20,
      }}>
      Mensaje aviso modal discrepancia
    </Typography>
  );

  const titleTextField = (
    <TextField
      fullWidth
      size="small"
      variant="outlined"
      label="Título"
      name="title"
      onChange={handleChange}
      color="secondary"
      value={form.title}
      disabled={form.noTitle}
    />
  );

  const titleSwitch = (
    <FormControlLabel
      value="start"
      control={<CustomSwitch />}
      label="Sin título"
      labelPlacement="start"
      name="noTitle"
      sx={{ marginLeft: 0 }}
      checked={form.noTitle}
      onChange={handleChangeSwitch}
    />
  );

  const messageTextField = (
    <ReactQuill
      theme="snow"
      modules={{
        toolbar: [
          [{ header: [1, 2, false] }],
          ['bold', 'italic', 'underline'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['link'],
        ],
      }}
      formats={['header', 'bold', 'italic', 'underline', 'list', 'bullet', 'link']}
      value={form.message}
      onChange={textEditorChange}
    />
  );

  const enabledSwitch = (
    <FormControlLabel
      value="start"
      control={<CustomSwitch />}
      label="Habilitado"
      labelPlacement="start"
      name="enabled"
      sx={{ marginLeft: 0 }}
      checked={form.enabled}
      onChange={handleChangeSwitch}
    />
  );

  const disabledSave = !form.noTitle
    ? !form.title.trim() || !form.message.trim()
    : !form.message.trim();

  return (
    <Dialog
      open={controls.openDialog}
      onClose={handleClose}
      PaperProps={{ sx: { minWidth: { lg: 700 }, minHeight: 200 } }}>
      <Box sx={{ m: 3 }}>
        <Grid container justifyContent="center" alignItems="center" sx={{ maxHeight: '80vh' }}>
          <Grid item container xs={12}>
            <form onSubmit={() => {}}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {title}
                </Grid>
                <Grid item xs={12}>
                  {titleTextField}
                </Grid>
                <Grid item xs={12}>
                  {titleSwitch}
                </Grid>
                <Grid item xs={12}>
                  {messageTextField}
                </Grid>
                <Grid item xs={12}>
                  {enabledSwitch}
                </Grid>

                <Grid item xs={6} textAlign="start" sx={{ mt: 2 }}>
                  <SecondaryButton text="Cancelar" onClick={handleClose} secondaryStyles />
                </Grid>
                <Grid item xs={6} textAlign="end" sx={{ mt: 2 }}>
                  <PrimaryButton
                    loader={loaders.create}
                    onClick={submitForm}
                    text="Aceptar"
                    secondaryStyles
                    disabled={disabledSave}
                  />
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
}

export default RegisterDiscrepancyMessage;
