import React, { useState } from 'react';

/* Material UI */
import { Box, Stepper, Step, StepLabel, Typography, Alert, MenuItem } from '@mui/material';

/* React Redux */
import { useDispatch, useSelector } from 'react-redux';

/* Project */
import PrimaryButton from 'commons/components/PrimaryButton/PrimaryButton';
import SecondaryButton from 'commons/components/SecondaryButton/SecondaryButton';
import {
  appSetError,
  changeControls,
  changeForm,
  createDiscrepancy,
} from 'commons/commons.actions';
import StepOne from 'commons/components/DiscrepancyStepper/StepOne';
import StepTwo from 'commons/components/DiscrepancyStepper/StepTwo';
import StepThree from 'commons/components/DiscrepancyStepper/StepThree';
import { useHistory } from 'react-router-dom';
import StepperTitle from 'commons/components/DiscrepancyStepper/StepperTitle';
import { isZippedFile } from 'utils/functions';

const steps = ['Paso 1', 'Paso 2', 'Paso 3'];

function DiscrepancyStepper(props) {
  const { objects } = props;

  const dispatch = useDispatch();
  const [attachmentsArray, setAttachmentsArray] = useState([]);

  const { discrepancyStepper, user: currentUser } = useSelector((state) => state.app);
  const history = useHistory();

  const { controls, form, loaders } = discrepancyStepper;

  const [file, setFile] = useState(null);
  const [wordFile, setWordFile] = useState(null);
  const [confidentialFile, setConfidentialFile] = useState(null);

  const { representatives, users } = objects;
  const currentUserModel = users[currentUser?.id];
  const currentEntityModel = currentUserModel?.entity();
  const isAdmin = currentUserModel?.type === 'admin';

  const avaliableRepresentatives = currentEntityModel
    ? Object.values(representatives).filter((r) => r.naturalEntityId === currentEntityModel.id)
    : [];

  const legalEntities = avaliableRepresentatives
    .map((representative) => representative.legalEntity())
    .filter((legalEntity) => legalEntity);

  const legalOptions = legalEntities.map((legalEntity) => (
    <MenuItem key={legalEntity.id} value={legalEntity.id}>
      {legalEntity.entity().names}
    </MenuItem>
  ));

  const handleBack = () => {
    dispatch(
      changeControls({
        name: 'activeStep',
        value: controls.activeStep - 1,
      }),
    );
  };

  const handleNext = () => {
    if (controls.activeStep !== 2) {
      return dispatch(
        changeControls({
          name: 'activeStep',
          value: controls.activeStep + 1,
        }),
      );
    }

    return dispatch(
      createDiscrepancy({
        subMatterId: form.subMatterId,
        cover: form.cover.trim(),
        legalEntityId: form.legalEntityId,
        position: form.position,
        index: form.index.trim(),
        pageNumber: form.pageNumber.trim(),
        representationFile: file,
        representationWordFile: wordFile,
        representationConfidential: confidentialFile,
        attachmentsArray,
        // presentationLimit: form.presentationLimit,
      }),
    );
  };

  const handleChange = ({ target: { name, value } }) => {
    dispatch(changeForm({ name, value }));
  };

  const handleFileChange = ({ target }) => {
    if (
      target.files[0] &&
      !isZippedFile(target.files[0].name) &&
      target.files[0].type === 'application/pdf'
    ) {
      setFile(target.files[0]);
    } else {
      dispatch(appSetError({ msg: 'El archivo debe ser de tipo PDF' }));
    }
  };

  const handleWordFileChange = ({ target }) => {
    const currentFile = target.files[0];
    if (
      currentFile &&
      !isZippedFile(currentFile.name) &&
      (currentFile.type ===
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
        currentFile.type === 'application/msword')
    ) {
      setWordFile(target.files[0]);
    } else {
      dispatch(appSetError({ msg: 'El archivo debe ser un documento .doc o .docx' }));
    }
  };

  const handleConfidentialFileChange = ({ target }) => {
    const currentFile = target.files[0];
    if (currentFile && !isZippedFile(currentFile.name)) {
      setConfidentialFile(target.files[0]);
    } else {
      dispatch(appSetError({ msg: 'El archivo no puede ser una carpeta comprimida' }));
    }
  };

  const redirectToLogin = () => {
    history.push('/login');
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <StepOne form={form} handleChange={handleChange} objects={objects} />;
      case 1:
        return (
          <StepTwo
            objects={objects}
            form={form}
            handleChange={handleChange}
            handleFileChange={handleFileChange}
            handleConfidentialFileChange={handleConfidentialFileChange}
            file={file}
            handleWordFileChange={handleWordFileChange}
            setConfidentialFile={setConfidentialFile}
            wordFile={wordFile}
            confidentialFile={confidentialFile}
            currentUser={currentUser}
          />
        );
      case 2:
        return (
          <StepThree
            attachmentsArray={attachmentsArray}
            setAttachmentsArray={setAttachmentsArray}
          />
        );
      default:
        return <>Unknown Step</>;
    }
  };

  const disabledNext =
    (controls.activeStep === 0 && (!form.cover.trim() || !form.matterId || !form.subMatterId)) ||
    (controls.activeStep === 1 &&
      (!form.position.trim() || !form.legalEntityId || !file || (!isAdmin && !wordFile)));

  const content = (
    <>
      <Stepper sx={{ ml: 20, mr: 20 }} activeStep={controls.activeStep}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <>
        <StepperTitle controls={controls} />
        <Alert severity="info" sx={{ mt: 2 }}>
          Instrucciones para el ingreso de una nueva discrepancia: <br />
          <br />
          1. Registrar a la persona natural que realizará el ingreso. <br />
          2. Registrar a la persona jurídica que presenta la discrepancia. <br />
          3. Incorporar la documentación en la sección del Paso 3. <br />
          <br />
          Antes de ingresar una nueva discrepancia, revise si ya ha sido creada previamente, caso en
          el cual debe ingresar su presentación en dicho expediente, seleccionando la discrepancia
          en el inicio y luego presionar el botón nuevo documento.
        </Alert>
        {legalOptions.length ? null : (
          <Alert severity="error" sx={{ mt: 0.5 }}>
            Recuerde que debe estar asociado a una entidad jurídica para agregar nueva discrepancia
          </Alert>
        )}
        {/* {
          currentUserModel.entity().legalEntity() ?
            <Alert severity="error" sx={{ mt: 0.5 }}>
              Las discrepancias solo pueden ser ingresadas por personas naturales que representan a una persona jurídica
            </Alert> :
            legalOptions.length 
              ? null
              : (
                  <Alert severity="error" sx={{ mt: 0.5 }}>
                    Recuerde que debe estar asociado a una entidad jurídica para agregar nueva discrepancia
                  </Alert>
                )
        } */}

        <Box sx={{ mt: 2, mb: 1 }}>{getStepContent(controls.activeStep)}</Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <SecondaryButton
            disabled={controls.activeStep === 0}
            onClick={handleBack}
            text="Anterior"
            sx={{ mr: 1 }}
          />
          <Box sx={{ flex: '1 1 auto' }} />
          <PrimaryButton
            loading={loaders.create}
            disabled={currentUserModel?.entity()?.legalEntity() ? true : disabledNext}
            onClick={handleNext}
            text={controls.activeStep === 2 ? 'Finalizar' : 'Siguiente'}
          />
        </Box>
      </>
    </>
  );

  const loginMessage = (
    <>
      <Typography variant="h4">Importante</Typography>
      <Typography>Es necesario iniciar sesión para el ingreso de una nueva discrepancia</Typography>
      <PrimaryButton sx={{ marginTop: '1rem' }} onClick={redirectToLogin}>
        Iniciar sesión
      </PrimaryButton>
    </>
  );

  return <Box sx={{ width: '100%' }}>{currentUser ? content : loginMessage}</Box>;
}

export default DiscrepancyStepper;
