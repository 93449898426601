import camelize from 'camelcase-keys-deep';

export default class Representative {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  entity() {
    return this.env().Entity.getById(this.naturalEntityId);
  }

  naturalEntity() {
    return this.env().Entity.getById(this.naturalEntityId);
  }

  legalEntity() {
    const entityFromLegal = this.env().Entity.getById(this.legalEntityId);
    return entityFromLegal.legalEntity();
  }

  entityFromLegal() {
    return this.env().Entity.getById(this.legalEntityId);
  }
}
