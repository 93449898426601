const filtersStyles = {
  selectButton: {
    '& .MuiSelect-icon': {
      marginRight: '30px',
    },
    '& .MuiSvgIcon-root.clear-icon': {
      color: 'rgba(0, 0, 0, 0.54);',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },
};

export default filtersStyles;
