import React, { useState } from 'react';

/* Material UI */
import Grid from '@mui/material/Grid';
import {
  Collapse,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { Apartment, MoreVert } from '@mui/icons-material';

/* Project */
import color from 'utils/colors';

function PartyCard(props) {
  const { parsedInterestedParty, entities } = props;
  const [open, setOpen] = useState(false);

  const interestedArray = Object.values(parsedInterestedParty);
  const naturalEntities = interestedArray.filter((pip) => pip.entity).map((pip) => pip.entity);
  const { interestedParty } = interestedArray[0];
  const entityLegal = entities[interestedParty.legalEntityId];

  const interestedStyle = {
    boxShadow: 4,
    pt: 0,
    borderRadius: '4px',
    maxHeight: '10vh',
    maxWidth: '40vw',
    mb: 1,
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const handleEntityClick = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
  };

  return (
    <Grid item xs={6}>
      <List sx={{ width: '100%', maxWidth: '40vw' }} component="nav">
        <ListItemButton component="a" onClick={handleClick} sx={interestedStyle}>
          <ListItemIcon>
            <Apartment sx={{ color: color.cooper }} />
          </ListItemIcon>
          <ListItemText
            primary={`${entityLegal.names} ${entityLegal.surnames}`}
            secondary={
              naturalEntities.length === 1
                ? `${naturalEntities.length} persona`
                : `${naturalEntities.length} personas`
            }
            primaryTypographyProps={{
              fontSize: '0.9rem',
              fontWeight: 'medium',
            }}
            secondaryTypographyProps={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxHeight: '2vh',
              fontSize: '0.8rem',
            }}
          />
          <IconButton name="icon" onClick={handleEntityClick}>
            <MoreVert />
          </IconButton>
        </ListItemButton>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="a" disablePadding>
            {naturalEntities.map((entity) => (
              <ListItemButton key={`entity-item-button-${entity.id}`}>
                <ListItemText primary={`${entity.names} ${entity.surnames}`} />
              </ListItemButton>
            ))}
          </List>
        </Collapse>
      </List>
    </Grid>
  );
}

export default PartyCard;
