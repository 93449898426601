/* Redux Saga */
import { all } from 'redux-saga/effects';

/* Project */
import CommonsSaga from 'commons/commons.saga';
import LoginSaga from 'screens/Login/Login.saga';
import HomeSaga from 'screens/Home/Home.saga';
import DiscrepancyDetailsSaga from 'screens/DiscrepancyDetail/DiscrepancyDetails.saga';
import AudiencesCalendarSaga from 'screens/AudiencesCalendar/AudiencesCalendar.saga';
import LastDocumentsSaga from 'screens/LastDocuments/LastDocuments.saga';
import RecoveryPasswordSaga from 'screens/RecoveryPassword/RecoveryPassword.saga';
import SearchContentSaga from 'screens/SearchContent/SearchContent.saga';

export default function* mainSaga() {
  yield all([
    CommonsSaga(),
    LoginSaga(),
    HomeSaga(),
    DiscrepancyDetailsSaga(),
    AudiencesCalendarSaga(),
    LastDocumentsSaga(),
    RecoveryPasswordSaga(),
    SearchContentSaga(),
  ]);
}
