const BASE = '[LASTDOCUMENTS]';

const LastDocumentsTypes = {
  GET_DOCUMENTS: `${BASE} GET_DOCUMENTS`,
  GET_DOCUMENTS_SUCCESS: `${BASE} GET_DOCUMENTS_SUCCESS`,
  GET_DOCUMENTS_FAIL: `${BASE} GET_DOCUMENTS_FAIL`,
  SET_FILTERS: `${BASE} SET_FILTERS`,
};

export default LastDocumentsTypes;
