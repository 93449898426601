// @ts-nocheck
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import { TextField, Typography } from '@mui/material';
import color from 'utils/colors';
import PrimaryButton from 'commons/components/PrimaryButton/PrimaryButton';
import SecondaryButton from 'commons/components/SecondaryButton/SecondaryButton';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import {
  changeEditNaturalEntityControls,
  changeEditNaturalEntityForm,
  updateNaturalEntity,
} from 'commons/commons.actions';
import { formatRut } from 'utils/functions';

function EditNaturalEntity() {
  const { app: appState } = useSelector((state) => state);
  const dispatch = useDispatch();

  const { editNaturalEntity, user } = appState;
  const { controls, form, loaders } = editNaturalEntity;

  const handleClose = () => {
    dispatch(
      changeEditNaturalEntityControls({
        name: 'openDialog',
        value: false,
      }),
    );
  };

  const handleChange = ({ target: { name, value } }) => {
    dispatch(changeEditNaturalEntityForm({ name, value }));
  };

  const submitForm = () => {
    dispatch(
      updateNaturalEntity({
        names: form.names,
        surnames: form.surnames,
        rut: form.rut,
        email: form.email,
        phone: form.phone,
        password: form.password,
        confirmPassword: form.confirmPassword,
        userId: user.id,
      }),
    );
  };

  const title = (
    <Typography
      sx={{
        fontWeight: 'regular',
        color: color.cooper,
        fontSize: 20,
      }}>
      Editar persona natural
    </Typography>
  );
  const namesTextField = (
    <TextField
      fullWidth
      size="small"
      variant="outlined"
      label="Nombres"
      name="names"
      onChange={handleChange}
      color="secondary"
      value={form.names}
      autoComplete="off"
    />
  );
  const surnamesTextField = (
    <TextField
      fullWidth
      size="small"
      variant="outlined"
      label="Apellidos"
      name="surnames"
      onChange={handleChange}
      color="secondary"
      value={form.surnames}
      autoComplete="off"
    />
  );
  const rutTextField = (
    <TextField
      fullWidth
      disabled
      size="small"
      variant="outlined"
      label="Rut"
      name="rut"
      onChange={handleChange}
      color="secondary"
      value={formatRut(form.rut)}
      autoComplete="off"
    />
  );

  const emailTextField = (
    <TextField
      fullWidth
      size="small"
      variant="outlined"
      label="Correo"
      name="email"
      onChange={handleChange}
      color="secondary"
      value={form.email}
      autoComplete="off"
    />
  );
  const phoneTextField = (
    <TextField
      fullWidth
      size="small"
      variant="outlined"
      label="Teléfono"
      name="phone"
      onChange={handleChange}
      color="secondary"
      value={form.phone}
      autoComplete="off"
    />
  );
  const passwordTextField = (
    <TextField
      fullWidth
      size="small"
      type="password"
      variant="outlined"
      label="Contraseña"
      name="password"
      onChange={handleChange}
      color="secondary"
      value={form.password}
      autoComplete="off"
    />
  );
  const confirmPasswordTextField = (
    <TextField
      fullWidth
      size="small"
      type="password"
      variant="outlined"
      label="Confirmar contraseña"
      name="confirmPassword"
      onChange={handleChange}
      color="secondary"
      value={form.confirmPassword}
      autoComplete="off"
    />
  );

  const disableSave = !form.names || !form.surnames || !form.email || !form.phone;

  return (
    <Dialog
      open={controls.openDialog}
      onClose={handleClose}
      PaperProps={{ sx: { minWidth: { lg: 700 }, minHeight: 200 } }}>
      <Box sx={{ m: 3 }}>
        <Grid container justifyContent="center" alignItems="center" sx={{ maxHeight: '80vh' }}>
          <Grid item container xs={12}>
            <form onSubmit={() => {}}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {title}
                </Grid>
                <Grid item xs={12}>
                  {namesTextField}
                </Grid>
                <Grid item xs={12}>
                  {surnamesTextField}
                </Grid>
                <Grid item xs={12}>
                  {rutTextField}
                </Grid>
                <Grid item xs={12}>
                  {emailTextField}
                </Grid>
                <Grid item xs={12}>
                  {phoneTextField}
                </Grid>
                <Grid item xs={6}>
                  {passwordTextField}
                </Grid>
                <Grid item xs={6}>
                  {confirmPasswordTextField}
                </Grid>
                <Grid item xs={6} textAlign="start" sx={{ mt: 2 }}>
                  <SecondaryButton text="Cancelar" onClick={handleClose} />
                </Grid>
                <Grid item xs={6} textAlign="end" sx={{ mt: 2 }}>
                  <PrimaryButton
                    loader={loaders.create}
                    onClick={submitForm}
                    text="Aceptar"
                    disabled={disableSave}
                  />
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
}

export default EditNaturalEntity;
