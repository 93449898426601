const BASE = '[HEARINGS]';

const AudiencesCalendarTypes = {
  FETCH_AUCIENCES: `${BASE} GET_DISCREPANCY`,
  FETCH_AUCIENCES_SUCCESS: `${BASE} FETCH_AUCIENCES_SUCCESS`,
  FETCH_AUCIENCES_FAIL: `${BASE} FETCH_AUCIENCES_FAIL`,
  SUSCRIBE_IN_HEARING: `${BASE} SUSCRIBE_IN_HEARING`,
  SUSCRIBE_IN_HEARING_SUCCESS: `${BASE} SUSCRIBE_IN_HEARING_SUCCESS`,
  SUSCRIBE_IN_HEARING_FAIL: `${BASE} SUSCRIBE_IN_HEARING_FAIL`,
  UPDATE_SUSCRIBE_IN_HEARING: `${BASE} UPDATE_SUSCRIBE_IN_HEARING`,
  UPDATE_SUSCRIBE_IN_HEARING_SUCCESS: `${BASE} UPDATE_SUSCRIBE_IN_HEARING_SUCCESS`,
  UPDATE_SUSCRIBE_IN_HEARING_FAIL: `${BASE} UPDATE_SUSCRIBE_IN_HEARING_FAIL`,
  HANDLE_CHANGE: `${BASE} HANDLE_CHANGE`,
  FILL_HEARING: `${BASE} FILL_HEARING`,
  FETCH_USER_HEARING: `${BASE} FETCH_USER_HEARING`,
  FETCH_USER_HEARING_SUCCESS: `${BASE} FETCH_USER_HEARING_SUCCESS`,
  FETCH_USER_HEARING_FAIL: `${BASE} FETCH_USER_HEARING_FAIL`,
};

export default AudiencesCalendarTypes;
