/* REACT */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

/* MATERIAL UI */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';

/* PROJECTS */
import SecondaryButton from 'commons/components/SecondaryButton/SecondaryButton';
import styles from './styles';

function GeneralAlert(props) {
  const { isOpen, sx, title, message, handleOnClose, status, handleSubmit } = props;

  const StatusIcon = useCallback(() => {
    switch (status) {
      case 'error':
        return <ErrorOutlineIcon className="icon error" />;
      case 'success':
        return <CheckCircleOutlinedIcon className="icon success" />;
      case 'warning':
        return <ErrorOutlineIcon className="icon warning" />;
      default:
        return <ErrorOutlineIcon className="icon error" />;
    }
  }, [status]);

  return (
    <Dialog open={isOpen} sx={{ ...styles.dialogStyles, ...sx }} onClose={handleOnClose}>
      <Box className="modal-container">
        <Box className="icon-container">
          <StatusIcon />
        </Box>
        <Box className="dialog-title-container">
          <DialogTitle className="title-container">{title}</DialogTitle>
        </Box>
        <DialogContent className="dialog-content-container">
          <Typography fontFamily="Roboto" variant="body1" className="dialog-content">
            {message}
          </Typography>
        </DialogContent>
        <DialogActions className="dialog-actions-container">
          {handleSubmit ? (
            <SecondaryButton
              variant="contained"
              onClick={handleOnClose}
              className="dialog-actions-button">
              Cancelar
            </SecondaryButton>
          ) : null}
          <Button
            variant="contained"
            onClick={handleSubmit || handleOnClose}
            className="dialog-actions-button">
            Aceptar
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

GeneralAlert.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  sx: PropTypes.shape({}),
  title: PropTypes.string,
  message: PropTypes.string,
  handleOnClose: PropTypes.func.isRequired,
  status: PropTypes.string,
};

GeneralAlert.defaultProps = {
  sx: {},
  title: '',
  message: '',
  status: 'warning',
};

export default GeneralAlert;
