import React, { useState } from 'react';
import PropTypes from 'prop-types';

/* Material UI */
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

/* React redux */
import { useDispatch } from 'react-redux';

/* Project */
import styles from 'screens/DiscrepancyDetail/components/UpdateDocumentForms/UpdateDocumentsFormStepOne/styles';
import SecondaryButton from 'commons/components/SecondaryButton/SecondaryButton';
import PrimaryButton from 'commons/components/PrimaryButton/PrimaryButton';
import {
  changeDocumentControls,
  createAttachmentPart,
  deleteAttachmentPart,
  updateAttachmentPart,
} from 'screens/DiscrepancyDetail/DiscrepancyDetails.actions';
import color from 'utils/colors';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/styles';
import TableCell from '@mui/material/TableCell';
import UpdateAttachmentDialog from 'screens/DiscrepancyDetail/components/UpdateDocumentForms/UpdateDocumentsFormStepThree/components/UpdateAttachmentDialog';
import { useParams } from 'react-router-dom';
import AttachmentPartItem from 'screens/DiscrepancyDetail/components/UpdateDocumentForms/UpdateDocumentsFormStepThree/components/AttachmentPartItem';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function UpdateDocumentsFormStepThree(props) {
  const { loading, objects } = props;

  const { documentId } = useParams();
  const currentDocument = objects.documents[documentId];
  const attachmentParts = currentDocument.attachmentParts();
  const secondaryParts = attachmentParts.filter((ap) => ap.type === 'secondary');

  const [openAttachmentModal, setOpenAttachmentModal] = useState(false);
  const [currentAttachmentPart, setCurrentAttachmentPart] = useState(null);

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(changeDocumentControls({ name: 'openEditDialog', value: false }));
  };

  const handleAddAttachment = ({
    publicFile,
    privateFile,
    removeConfidential,
    attachmentPartId,
    defaultPublicAttachment,
    defaultPrivateAttachment,
  }) => {
    if (currentAttachmentPart?.id) {
      dispatch(
        updateAttachmentPart({
          publicFile,
          privateFile,
          removeConfidential,
          attachmentPartId,
          defaultPublicAttachment,
          defaultPrivateAttachment,
        }),
      );
    } else {
      dispatch(createAttachmentPart({ publicFile, privateFile, documentId }));
    }
  };

  const handleDeletePartItem = (attachmentPart) => {
    dispatch(deleteAttachmentPart({ id: attachmentPart.id }));
  };

  const handleUpdatePartItem = (attachmentPart) => {
    setCurrentAttachmentPart(attachmentPart);
    setOpenAttachmentModal(true);
  };

  const handleCloseAttachmentDialog = () => {
    setOpenAttachmentModal(false);
    setCurrentAttachmentPart(null);
  };

  const content = (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          mb: 2,
        }}>
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: 'bold',
            color: color.blue,
            textTransform: 'uppercase',
            mr: 1,
          }}>
          CARGAR
        </Typography>
        <Typography
          sx={{
            fontWeight: 'regular',
            color: color.blue,
            fontSize: 16,
          }}>
          Archivos adjuntos
        </Typography>
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="span"
          onClick={() => setOpenAttachmentModal(true)}>
          <AddIcon
            sx={{
              color: color.white,
              backgroundColor: color.blue,
              borderRadius: 3,
            }}
          />
        </IconButton>
        {openAttachmentModal ? (
          <UpdateAttachmentDialog
            defaultAttachmentPart={currentAttachmentPart}
            open={openAttachmentModal}
            handleSubmit={handleAddAttachment}
            handleClose={handleCloseAttachmentDialog}
            loading={loading}
          />
        ) : null}
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ maxWidth: 700 }} size="small" aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell />
              <StyledTableCell>Adjunto (versión pública)</StyledTableCell>
              {/* INFO: Cliente requiere retirar la opción de agregar archivos confidenciales
              <StyledTableCell align="right">Versión confidencial</StyledTableCell> */}
              <StyledTableCell align="center">Acciones</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {secondaryParts.map((attachmentPart, idx) => (
              <AttachmentPartItem
                handleDelete={handleDeletePartItem}
                handleUpdate={handleUpdatePartItem}
                key={`attachment-part-${attachmentPart.id}`}
                attachmentPart={attachmentPart}
                idx={idx}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );

  return (
    <>
      <DialogTitle sx={{ ...styles.title }}>Actualización de adjuntos</DialogTitle>
      <DialogContent sx={{ ...styles.dialogContent }}>{content}</DialogContent>
      <DialogActions>
        <SecondaryButton onClick={handleClose} loading={loading}>
          Cerrar
        </SecondaryButton>
      </DialogActions>
    </>
  );
}

UpdateDocumentsFormStepThree.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default UpdateDocumentsFormStepThree;
