import React from 'react';

/* Material UI */
import { Chip, ListItemButton, ListItemText } from '@mui/material';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';

/* React Router */
import { useHistory } from 'react-router-dom';

/* Project */
import { useSelector } from 'react-redux';
import discrepancyItemStyles from './DiscrepancyItem.styles';
import color from '../../../utils/colors';
import { discrepancyFullTitle } from '../../../utils/functions';

/* Extra */
const useStyles = makeStyles(discrepancyItemStyles);

function DiscrepancyItem({ discrepancy }) {
  const classes = useStyles();
  const history = useHistory();
  const { cover, number, createdAt, endedAt, id } = discrepancy;
  const { home: homeState } = useSelector((state) => state);
  const { chipsIds } = homeState;
  const { index, interestedParty, matter, name, subMatter, title } = chipsIds;

  const discrepancyStyle = {
    color: endedAt ? color.grey : color.cooper,
    background: `linear-gradient(to right, ${endedAt ? color.greyRgba : color.cooperRgba} 1%, ${
      color.whiteRgba
    } 1%)`,
    boxShadow: 4,
    borderRadius: '4px',
    height: '50px',
  };

  const lastdocumentId = Math.min(...discrepancy.documents().map((d) => d.id));

  const handleRedirection = (evt) => {
    evt.preventDefault();
    history.push(`discrepancies/${id}/expedient/${lastdocumentId}`);
  };

  const nameChip = name.filter((n) => id === n.id).length ? (
    <Chip label="Nombre" size="small" className={classes.chip} />
  ) : null;

  const interestedPartyChip = interestedParty.filter((ip) => id === ip.id).length ? (
    <Chip label="Parte e Interesada" size="small" className={classes.chip} />
  ) : null;

  const indexChip = index.filter((idx) => id === idx.id).length ? (
    <Chip label="Indice Documento" size="small" className={classes.chip} />
  ) : null;

  const titleChip = title.filter((t) => id === t.id).length ? (
    <Chip label="Título Documento" size="small" className={classes.chip} />
  ) : null;

  const matterChip = matter.filter((m) => id === m.id).length ? (
    <Chip label="Materia" size="small" className={classes.chip} />
  ) : null;

  const subMatterChip = subMatter.filter((sm) => id === sm.id).length ? (
    <Chip label="Sub-materia" size="small" className={classes.chip} />
  ) : null;

  return (
    <Grid item xs={12}>
      <ListItemButton
        id={id}
        className={`${classes.listItemButton} list-item-button`}
        component="a"
        onClick={handleRedirection}
        sx={discrepancyStyle}>
        <ListItemText
          secondary={discrepancyFullTitle(number, cover, createdAt)}
          primary={endedAt ? 'TERMINADA' : 'EN TRAMITACION'}
          primaryTypographyProps={{ className: classes.primaryTextStyle }}
          secondaryTypographyProps={{ className: classes.secondaryTextStyle }}
        />
        {nameChip}
        {interestedPartyChip}
        {indexChip}
        {titleChip}
        {matterChip}
        {subMatterChip}
      </ListItemButton>
    </Grid>
  );
}

export default DiscrepancyItem;
