import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Autocomplete,
  IconButton,
  Paper,
  Table,
  TableBody,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  TableCell,
  Dialog,
  Grid,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import color from 'utils/colors';
import { Delete, Add } from '@mui/icons-material';
import { styled } from '@mui/styles';
import { useDispatch } from 'react-redux';
import { changeRepresentativesControls } from 'commons/commons.actions';
import PrimaryButton from 'commons/components/PrimaryButton/PrimaryButton';
import { captureEnterEvent } from 'utils/helper';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function RepresentativesModal(props) {
  const { representativesArray, setRepresentativesArray, registerLegalEntity, naturalEntities } =
    props;

  const { representativesControls } = registerLegalEntity;

  const [selectedEntity, setSelectedEntity] = useState(null);

  const [naturalEntitiesOpt, setNaturalEntitiesOpt] = useState(
    Object.values(naturalEntities)
      .filter((naturalEntity) => naturalEntity.user()?.type !== 'admin')
      .map((en) => ({
        id: en.id,
        label: `${en.names} ${en.surnames}`,
      })),
  );

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(
      changeRepresentativesControls({
        name: 'openDialog',
        value: false,
      }),
    );
  };

  const handleAutocomplete = (event, newValue) => {
    setSelectedEntity(newValue);
  };

  const removeRepresentativeRow = (idx, option) => {
    const representativesRows = [...representativesArray];
    representativesRows.splice(idx, 1);
    setRepresentativesArray(representativesRows);
    const optionsArray = [...naturalEntitiesOpt];
    optionsArray.push(option);
    optionsArray.sort((a, b) => (a.id > b.id ? 1 : -1));
    setNaturalEntitiesOpt(optionsArray);
  };

  const newRepresentativeRow = () => {
    if (selectedEntity) {
      setRepresentativesArray([...representativesArray, selectedEntity]);
      setNaturalEntitiesOpt(
        naturalEntitiesOpt.filter((opt) => ![selectedEntity].find((se) => se.id === opt.id)),
      );
      setSelectedEntity(null);
    }
  };

  const title = (
    <Typography
      sx={{
        fontWeight: 'regular',
        color: color.cooper,
        fontSize: 20,
      }}>
      Listado de representantes
    </Typography>
  );

  const naturalEntitiesAutocomplete = (
    <Autocomplete
      fullWidth
      disablePortal
      id="autoComplete"
      value={selectedEntity}
      onChange={handleAutocomplete}
      options={naturalEntitiesOpt}
      renderInput={(params) => (
        // TODO: check this
        // eslint-disable-next-line react/jsx-props-no-spreading
        <TextField {...params} label="Representantes" color="secondary" size="small" />
      )}
      onKeyDown={(evt) => captureEnterEvent(evt, newRepresentativeRow)}
    />
  );

  return (
    <Dialog
      open={representativesControls.openDialog}
      onClose={handleClose}
      // PaperProps={{ sx: { minWidth: { lg: 700 }, minHeight: 200 } }}
      fullWidth
      // maxWidth="sm"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {/* <Box sx={{ m: 3 }}> */}

        <Grid container spacing={2} sx={{ marginBottom: '1rem' }}>
          <Grid item xs={10} sx={{ marginTop: '5px' }}>
            {naturalEntitiesAutocomplete}
          </Grid>
          <Grid item xs={2}>
            <IconButton
              sx={{ color: color.blue }}
              aria-label="representative"
              onClick={newRepresentativeRow}
              component="span">
              <Add
                sx={{
                  color: color.white,
                  backgroundColor: color.blue,
                  borderRadius: 3,
                }}
              />
            </IconButton>
          </Grid>
        </Grid>
        <TableContainer component={Paper}>
          <Table sx={{ maxWidth: 700 }} size="small" aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell />
                <StyledTableCell>Representante</StyledTableCell>
                <StyledTableCell align="center">Acciones</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {representativesArray.map((representative, idx) => (
                <StyledTableRow key={representative.id}>
                  <StyledTableCell component="th" scope="row">
                    {`${idx + 1}.`}
                  </StyledTableCell>
                  <StyledTableCell>{representative.label}</StyledTableCell>
                  <StyledTableCell align="right">
                    <IconButton
                      sx={{ color: color.blue }}
                      aria-label="."
                      component="span"
                      onClick={() =>
                        removeRepresentativeRow(idx, {
                          id: representative.id,
                          label: representative.label,
                        })
                      }>
                      <Delete />
                    </IconButton>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* </Box> */}
      </DialogContent>
      <DialogActions>
        {/* <Box */}
        {/*   sx={{ */}
        {/*     display: 'flex', */}
        {/*     flexDirection: 'row', */}
        {/*     justifyContent: 'flex-end', */}
        {/*     mt: 2, */}
        {/*   }}> */}
        <PrimaryButton onClick={handleClose} text="Cerrar" />
        {/* </Box> */}
      </DialogActions>
    </Dialog>
  );
}

RepresentativesModal.propTypes = {
  representativesArray: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setRepresentativesArray: PropTypes.func.isRequired,
};

export default RepresentativesModal;
