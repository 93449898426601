import LastDocumentsTypes from 'screens/LastDocuments/LastDocuments.types';

export const getDocuments = (payload) => ({
  type: LastDocumentsTypes.GET_DOCUMENTS,
  payload: { ...payload },
});

export const setFilters = ({ name, value }) => ({
  type: LastDocumentsTypes.SET_FILTERS,
  payload: { name, value },
});
