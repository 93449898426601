import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import { Box, IconButton, InputAdornment, Stack, Typography } from '@mui/material';
import color from 'utils/colors';
import CustomTextField from 'commons/components/CustomTextField/CustomTextField';
import { Delete, FileUpload } from '@mui/icons-material';
import SecondaryButton from 'commons/components/SecondaryButton/SecondaryButton';
import PrimaryButton from 'commons/components/PrimaryButton/PrimaryButton';
import { isZippedFile } from 'utils/functions';
import { useDispatch } from 'react-redux';
import { appSetError } from 'commons/commons.actions';

function UpdateAttachmentDialog(props) {
  const { defaultAttachmentPart, open, handleClose, handleSubmit, loading } = props;

  const defaultAttachments = defaultAttachmentPart ? defaultAttachmentPart.attachments() : [];

  const defaultPublicAttachment = defaultAttachments.find((a) => a.confidential === false);
  const defaultPrivateAttachment = defaultAttachments.find((a) => a.confidential === true);

  const [publicAttachment, setPublicAttachment] = useState(defaultPublicAttachment);
  const [privateAttachment, setPrivateAttachment] = useState(defaultPrivateAttachment);
  const [removeConfidential, setRemoveConfidential] = useState(false);

  const dispatch = useDispatch();

  const handleFileChange = ({ target }) => {
    const currentFile = target.files[0];

  //   const allowedFileTypes = [
  //   'application/pdf',
  //   'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
  //   'application/msword', // doc
  //   'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx
  //   'application/vnd.ms-excel', // xls
  //   'application/vnd.openxmlformats-officedocument.presentationml.presentation', // pptx
  //   'application/vnd.ms-powerpoint' // ppt
  // ];

    const isFileAllowed = !isZippedFile(currentFile.name);

    if (currentFile && isFileAllowed) {
      setPublicAttachment(currentFile);
    } else {
      setPublicAttachment(null);
      document.getElementById('attachmentPublic').value= null;
      dispatch(appSetError({ msg: 'El archivo no puede ser una carpeta comprimida' }));
      // dispatch(appSetError({ msg: 'Solo se permiten archivos tipo pdf, doc, docx, xls, xlsx, ppt y pptx. No está permitido subir imagenes, videos ni audios.' }));
    }
  };

  const handleprivateFileChange = ({ target }) => {
    const currentFile = target.files[0];
    if (currentFile && !isZippedFile(currentFile.name)) {
      setRemoveConfidential(false);
      setPrivateAttachment(target.files[0]);
    } else {
      dispatch(appSetError({ msg: 'El archivo no puede ser una carpeta comprimida' }));
    }
  };

  const handleSaveButton = () => {
    handleSubmit({
      publicFile: publicAttachment,
      privateFile: privateAttachment,
      removeConfidential,
      attachmentPartId: defaultAttachmentPart?.id,
      defaultPublicAttachment,
      defaultPrivateAttachment,
    });
    handleClose();
  };

  const handleDelete = () => {
    setRemoveConfidential(true);
    setPrivateAttachment(null);
  };

  const closeDialog = () => {
    setPublicAttachment(null);
    setPrivateAttachment(null);
    setRemoveConfidential(false);
    handleClose();
  };

  const disableSave = () => {
    if (defaultAttachmentPart?.id) {
      if (removeConfidential && !publicAttachment?.size) {
        return false;
      }

      if (!publicAttachment?.size && !privateAttachment?.size) {
        return true;
      }

      return false;
    }

    return !publicAttachment?.size;
  };

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      PaperProps={{ sx: { minWidth: { lg: 500 }, minHeight: 200 } }}>
      <Box sx={{ m: 3 }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', mb: 2 }}>
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: 'bold',
              color: color.blue,
              textTransform: 'uppercase',
              mr: 1,
            }}>
            CARGAR
          </Typography>
          <Typography
            sx={{
              fontWeight: 'regular',
              color: color.blue,
              fontSize: 16,
            }}>
            Archivo adjunto
          </Typography>
        </Box>
        <Stack spacing={2}>
          <CustomTextField
            name="attachmentPublic"
            label="Archivo público"
            value={publicAttachment?.name || publicAttachment?.content || ''}
            inputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <label htmlFor="attachmentPublic">
                    <input
                      accept="application/pdf"
                      id="attachmentPublic"
                      name="attachmentPublic"
                      type="file"
                      style={{ display: 'none' }}
                      onChange={handleFileChange}
                    />
                    <IconButton
                      sx={{ color: color.blue }}
                      aria-label="attachmentPublic"
                      component="span">
                      <FileUpload />
                    </IconButton>
                  </label>
                </InputAdornment>
              ),
            }}
          />
          {/* INFO: Cliente requiere retirar la opción de agregar archivos confidenciales
          <CustomTextField
            name="attachmentPrivate"
            label="Archivo confidencial"
            value={
              removeConfidential
                ? ''
                : privateAttachment?.name || defaultPrivateAttachment?.content || ''
            }
            inputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <label htmlFor="attachmentPrivate">
                    <input
                      accept="application/pdf"
                      id="attachmentPrivate"
                      name="attachmentPrivate"
                      type="file"
                      style={{ display: 'none' }}
                      onChange={handleprivateFileChange}
                    />
                    <IconButton
                      sx={{ color: color.blue }}
                      aria-label="attachmentPrivate"
                      component="span">
                      <FileUpload />
                    </IconButton>
                  </label>
                  <IconButton
                    sx={{ color: color.blue }}
                    aria-label="attachmentPrivate"
                    component="span"
                    onClick={handleDelete}>
                    <Delete />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          /> */}
        </Stack>
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <SecondaryButton onClick={closeDialog} text="Cancelar" sx={{ mr: 1 }} />
          <Box sx={{ flex: '1 1 auto' }} />
          <PrimaryButton
            disabled={disableSave()}
            onClick={handleSaveButton}
            text="Guardar"
            loading={loading}
          />
        </Box>
      </Box>
    </Dialog>
  );
}

UpdateAttachmentDialog.propTypes = {
  defaultAttachmentPart: PropTypes.shape({}),
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

UpdateAttachmentDialog.defaultProps = {
  defaultAttachmentPart: null,
};

export default UpdateAttachmentDialog;
