import React from 'react';
import PropTypes from 'prop-types';

/* MaterialUi */
import Grid from '@mui/material/Grid';
import { CircularProgress } from '@mui/material';

function PageLoader(props) {
  const { children } = props;

  return (
    <Grid container>
      <Grid item xs={12} sx={{ textAlign: 'center', alignSelf: 'center' }}>
        {children || <CircularProgress size={40} />}
      </Grid>
    </Grid>
  );
}

PageLoader.propTypes = {
  children: PropTypes.element,
};

PageLoader.defaultProps = {
  children: null,
};

export default PageLoader;
