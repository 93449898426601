import DiscrepancyDetailsTypes from 'screens/DiscrepancyDetail/DiscrepancyDetails.types';

export const disableErrors = () => ({
  type: DiscrepancyDetailsTypes.DISABLE_ERRORS,
});

export const getHearingParticipationsFile = ({ id }) => ({
  type: DiscrepancyDetailsTypes.GET_HEARING_PARTICIPATIONS_FILE,
  payload: { id },
});

export const makeHearingParticipationsFile = ({ id }) => ({
  type: DiscrepancyDetailsTypes.MAKE_HEARING_PARTICIPATIONS_FILE,
  payload: { id },
});

/**
 * @param {{ id, history, view }} payload
 * @returns {{payload: {id, history, view}, type: string}}
 */
export const getDiscrepancy = ({ id, history, view }) => ({
  type: DiscrepancyDetailsTypes.GET_DISCREPANCY,
  payload: { id, history, view },
});

export const toggleStepper = () => ({
  type: DiscrepancyDetailsTypes.TOGGLE_STEPPER,
});

/**
 * @param {{ name: String, value: String|Number|Boolean }} payload
 * @returns {{payload, type: string}}
 */
export const changeForm = ({ name, value }) => ({
  type: DiscrepancyDetailsTypes.CHANGE_DOCUMENT_FORM,
  payload: { name, value },
});

/**
 * Change controls in discrepancy creation modal
 * @param {{ name: String, value: String|Number|Boolean }} payload
 * @returns {{payload: Object, type: string}}
 */
export const changeControls = ({ name, value }) => ({
  type: DiscrepancyDetailsTypes.CHANGE_DOCUMENT_CREATION_CONTROLS,
  payload: { name, value },
});

/**
 * Change controls in discrepancy creation modal
 * @param {{ name: String, value: String|Number|Boolean }} payload
 * @returns {{payload: Object, type: string}}
 */
export const changeAttachmentControls = ({ name, value }) => ({
  type: DiscrepancyDetailsTypes.CHANGE_ATTACHMENT_MODAL_CONTROLS,
  payload: { name, value },
});

export const openUpdateDocumentDialog = ({ idx }) => ({
  type: DiscrepancyDetailsTypes.OPEN_UPDATE_DOCUMENT_DIALOG,
  payload: { idx },
});

/**
 * @param {{ discrepancyId,documentTypeId,interestedPartyId,position,legalEntityId,title,index,mainPdfFile,mainWordFile,mainConfidential,attachmentsArray, dateHourAudience, dateHourRegistrationDeadline, dateHourPresentationInterestedParty, dateHourObservationsDeadline, history, copyToDiscrepancies}}
 *   payload
 * @returns {{payload: {attachments, legalEntityId, documentTypeId, index,
 *   files: *[], interestedPartyId, position, title, discrepancyId, dateHourAudience, dateHourRegistrationDeadline, dateHourPresentationInterestedParty, dateHourObservationsDeadline, history, copyToDiscrepancies}, type:
 *   string}}
 */
export const createDocument = ({
  discrepancyId,
  documentTypeId,
  interestedPartyId,
  position,
  legalEntityId,
  title,
  index,
  mainPdfFile,
  mainWordFile,
  mainConfidential,
  attachmentsArray,
  dateHourAudience,
  dateHourRegistrationDeadline,
  dateHourPresentationInterestedParty,
  dateHourObservationsDeadline,
  history,
  copyToDiscrepancies,
}) => ({
  type: DiscrepancyDetailsTypes.CREATE_DOCUMENT,
  payload: {
    discrepancyId,
    documentTypeId,
    interestedPartyId,
    title,
    index,
    position,
    legalEntityId,
    files: [mainPdfFile, mainWordFile, mainConfidential],
    attachments: attachmentsArray,
    dateHourAudience,
    dateHourRegistrationDeadline,
    dateHourPresentationInterestedParty,
    dateHourObservationsDeadline,
    history,
    copyToDiscrepancies,
  },
});

export const creatAudienceDocument = ({
  audienceUrl,
  documentTypeId,
  interestedPartyId,
  legalEntityId,
  discrepancyId,
  history,
}) => ({
  type: DiscrepancyDetailsTypes.CREATE_AUDIENCE_DOCUMENT,
  payload: {
    audienceUrl,
    documentTypeId,
    interestedPartyId,
    legalEntityId,
    discrepancyId,
    history,
  },
});

/**
 *
 * @param {{ hearingFile, interestedPartyId, position, legalEntityId, discrepancyId, history, documentTypeId, copyToDiscrepancies  }} payload
 * @returns {{ type: string, payload: { hearingFile, interestedPartyId, position, legalEntityId, discrepancyId, history, documentTypeId, copyToDiscrepancies } }}
 */
export const createHearingPresentation = ({
  hearingFile,
  interestedPartyId,
  position,
  legalEntityId,
  discrepancyId,
  history,
  documentTypeId,
  copyToDiscrepancies,
}) => ({
  type: DiscrepancyDetailsTypes.CREATE_HEARING_PRESENTATION,
  payload: {
    hearingFile,
    interestedPartyId,
    position,
    legalEntityId,
    discrepancyId,
    history,
    documentTypeId,
    copyToDiscrepancies,
  },
});

/**
 * @param {{ name: String, value }} payload
 * @returns {{payload: {name, value}, type: string}}
 */
export const changeDiscrepancyControls = ({ name, value }) => ({
  type: DiscrepancyDetailsTypes.CHANGE_DISCREPANCY_CONTROLS,
  payload: { name, value },
});

/**
 * @param {{ name: String, value }} payload
 * @returns {{payload: {name, value}, type: string}}
 */
export const changeDocumentControls = ({ name, value }) => ({
  type: DiscrepancyDetailsTypes.CHANGE_DOCUMENT_CONTROLS,
  payload: { name, value },
});

/**
 * @param {object} payload
 * @returns {{ type: string, payload: object }}
 */
export const changeTwoOrMoreDocumentControls = (payload) => ({
  type: DiscrepancyDetailsTypes.CHANGE_TWO_OR_MORE_DOCUMENT_CONTROLS,
  payload,
});

export const updateDocumentsValues = ({
  id,
  documentTypeId,
  title,
  index,
  documentDate,
  legalEntityId,
  dateHourAudience,
  dateHourRegistrationDeadline,
  dateHourPresentationInterestedParty,
  dateHourObservationsDeadline,
}) => ({
  type: DiscrepancyDetailsTypes.UPDATE_DOCUMENTS_VALUES,
  payload: {
    id,
    documentTypeId,
    title,
    index,
    documentDate,
    legalEntityId,
    dateHourAudience,
    dateHourRegistrationDeadline,
    dateHourPresentationInterestedParty,
    dateHourObservationsDeadline,
  },
});

export const setDocumentFormValues = ({
  id,
  documentTypeId,
  title,
  index,
  documentDate,
  legalEntityId,
  dateHourAudience,
  dateHourRegistrationDeadline,
  dateHourPresentationInterestedParty,
  dateHourObservationsDeadline,
}) => ({
  type: DiscrepancyDetailsTypes.SET_DOCUMENT_FORM_VALUES,
  payload: {
    id,
    documentTypeId,
    title,
    index,
    documentDate,
    legalEntityId,
    dateHourAudience,
    dateHourRegistrationDeadline,
    dateHourPresentationInterestedParty,
    dateHourObservationsDeadline,
  },
});

/**
 * @param {{ documentId: Number, history: any, currentDiscrepancyId: Number, firstDocumentId: Number}} payload
 * @returns {{payload: {name, value}, type: string}}
 */
export const deleteDocument = ({ documentId, history, currentDiscrepancyId, firstDocumentId }) => ({
  type: DiscrepancyDetailsTypes.DELETE_DOCUMENT,
  payload: { documentId, history, currentDiscrepancyId, firstDocumentId },
});

/**
 * @param {{ notificate: Boolean, discrepancyId: Number }} payload
 * @returns {{payload: {notificate}, type: *}}
 */
export const notificateDiscrepancy = ({ notificate, discrepancyId }) => ({
  type: DiscrepancyDetailsTypes.NOTIFICATE_DISCREPANCY,
  payload: { name: 'notification', value: notificate, discrepancyId },
});

export const updateAttachmentFiles = ({
  fileMainPdf,
  fileMainWord,
  fileMainConfidential,
  documentId,
  pageNumberMainpdf,
  publicPdfName,
  fileMainWordName,
  fileMainConfidentialName,
  oldPageNumberMainPdf,
}) => ({
  type: DiscrepancyDetailsTypes.UPDATE_ATTACHMENT_FILES,
  payload: {
    files: [fileMainPdf, fileMainWord, fileMainConfidential],
    oldFileNames: [publicPdfName, fileMainWordName, fileMainConfidentialName],
    documentId,
    pageNumberMainpdf,
    oldPageNumberMainPdf,
  },
});

export const deleteAttachmentPart = ({ id }) => ({
  type: DiscrepancyDetailsTypes.DELETE_ATTACHMENT_PART,
  payload: { id },
});

export const updateAttachmentPart = ({
  publicFile,
  privateFile,
  removeConfidential,
  attachmentPartId,
  defaultPublicAttachment,
  defaultPrivateAttachment,
}) => ({
  type: DiscrepancyDetailsTypes.UPDATE_ATTACHMENT_PART,
  payload: {
    publicFile,
    privateFile,
    removeConfidential,
    attachmentPartId,
    defaultPublicAttachment,
    defaultPrivateAttachment,
  },
});

export const createAttachmentPart = ({ publicFile, privateFile, documentId }) => ({
  type: DiscrepancyDetailsTypes.CREATE_ATTACHMENT_PART,
  payload: { publicFile, privateFile, documentId },
});

export const removeDiscrepancy = () => ({
  type: DiscrepancyDetailsTypes.REMOVE_DISCREPANCY,
  payload: {},
});

export const changeViewType = ({ view }) => ({
  type: DiscrepancyDetailsTypes.CHANGE_VIEW_TYPE,
  payload: { view },
});

export const selectViewDocument = ({ document }) => ({
  type: DiscrepancyDetailsTypes.SELECT_VIEW_DOCUMENT,
  payload: { document },
});

export const downloadAllFilesRequest = (data) => {
  console.log('actionss')
  return ({
  type: DiscrepancyDetailsTypes.DOWNLOAD_ALL_FILES_REQUEST,
  payload: { data },
})};


export const controlsFiltersChanged = ({ name, value }) => ({
  type: DiscrepancyDetailsTypes.CONTROLS_CHANGED_DOCUMENT_FILTERS,
  payload: { name, value },
});

export const handleDocumentBanner = ({ open }) => ({
  type: DiscrepancyDetailsTypes.HANDLE_DOCUMENT_BANNER,
  payload: { open },
});

export const selectDiscrepanciesChange = ({ name, value }) => ({
  type: DiscrepancyDetailsTypes.SELECT_DISCREPANCIES_CHANGE,
  payload: { name, value },
});

export const removeCopiedDiscrepancy = ({ name, value }) => ({
  type: DiscrepancyDetailsTypes.REMOVE_COPIED_DISCREPANCY,
  payload: { name, value },
});

export const copyFilesToDiscrepancies = (payload) => ({
  type: DiscrepancyDetailsTypes.COPY_FILES_TO_DISCREPANCIES,
  payload: payload,
});

export const handleDownloadZipDialog = ({ open }) => ({
  type: DiscrepancyDetailsTypes.HANDLE_DOWNLOAD_ZIP_DIALOG,
  payload: { open },
});

export const resetDocumentCreationControls = () => ({
  type: DiscrepancyDetailsTypes.RESET_DOCUMENT_CREATION_CONTROLS,
});

export const resetDocumentFilters = () => ({
  type: DiscrepancyDetailsTypes.RESET_DOCUMENT_FILTERS,
});
