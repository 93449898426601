import React, { useState, useMemo } from 'react';

// REACT-REDUX
import { useDispatch } from 'react-redux';

// MATERIAL-UI
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Divider,
} from '@mui/material';

// PROJECT
import PrimaryButton from 'commons/components/PrimaryButton/PrimaryButton';
import SecondaryButton from 'commons/components/SecondaryButton/SecondaryButton';
import CustomDateTimePicker from 'commons/components/CustomDateTimePicker/CustomDateTimePicker';
import { changeDocumentControls, updateDocumentsValues } from '../../../DiscrepancyDetails.actions';
import styles from './styles';
import moment from 'moment';

function UpdateDocumentsFormStepOne(props) {
  const { initFormValues, documentTypes, loading, currentDocument, currentDiscrepancy, objects } =
    props;

  const { documents, representatives } = objects;

  const dispatch = useDispatch();

  const [formValues, setFormValues] = useState(initFormValues);

  const {
    id,
    documentTypeId,
    title,
    index,
    documentDate,
    legalEntityId,
    dateHourAudience,
    dateHourRegistrationDeadline,
    dateHourPresentationInterestedParty,
    dateHourObservationsDeadline,
  } = formValues;

  const presentation = currentDiscrepancy ? currentDiscrepancy.presentation() : null;

  const isPresentation = presentation?.id === currentDocument.id;

  const closeDialog = () => {
    dispatch(changeDocumentControls({ name: 'openEditDialog', value: false }));
  };

  const handleUpdateDocument = () => {
    dispatch(
      updateDocumentsValues({
        id,
        documentTypeId,
        title,
        index,
        documentDate,
        legalEntityId,
        dateHourAudience,
        dateHourRegistrationDeadline,
        dateHourPresentationInterestedParty,
        dateHourObservationsDeadline,
      }),
    );
  };

  const handleChange = ({ target: { name, value } }) => {
    setFormValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleHourChange = (dateVal) => {
    handleChange({
      target: {
        name: 'documentDate',
        value: dateVal,
      },
    });
  };

  const handleDateChange = (name, dateVal) => {
    handleChange({
      target: {
        name: name,
        value: moment(dateVal).toDate(),
      },
    });
  };

  const currentDocumentModel = documents[currentDocument.id];
  const currentIpModel = currentDocumentModel?.interestedParty();
  const currentNaturalEntity = currentIpModel?.naturalEntity();

  const avaliableRepresentatives = currentNaturalEntity
    ? Object.values(representatives).filter((r) => r.naturalEntityId === currentNaturalEntity.id)
    : [];

  const legalEntities = useMemo(
    () => avaliableRepresentatives.map((representative) => representative.legalEntity()),
    [avaliableRepresentatives],
  );

  const sortedlegalEntities = useMemo(
    () =>
      Object.values(legalEntities)
        .filter(Boolean)
        .sort((a, b) => {
          const x = a.entity().names.toLowerCase();
          const y = b.entity().names.toLowerCase();
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        }),
    [legalEntities],
  );

  const legalOptions = sortedlegalEntities.map((legalEntity) => (
    <MenuItem key={legalEntity.id} value={legalEntity.id}>
      {legalEntity.entity().names}
    </MenuItem>
  ));

  const currentDocumentType = documents[currentDocument.id].documentType();
  const isGuidelines = currentDocumentType?.code === 'announcements_and_guidelines';

  const dateAudienceField = (
    <CustomDateTimePicker
      label="Fecha audiencia"
      value={dateHourAudience}
      onChange={(value) => handleDateChange('dateHourAudience', value)}
      sx={{
        width: '100%',
      }}
    />
  );

  const dateRegistrationDeadline = (
    <CustomDateTimePicker
      label="Fecha Tope Inscripción"
      value={dateHourRegistrationDeadline}
      onChange={(value) => handleDateChange('dateHourRegistrationDeadline', value)}
      sx={{
        width: '100%',
      }}
    />
  );

  const datePresentationInterestedParty = (
    <CustomDateTimePicker
      label="Fecha Presentación Interesados"
      value={dateHourPresentationInterestedParty}
      onChange={(value) => handleDateChange('dateHourPresentationInterestedParty', value)}
      sx={{
        width: '100%',
      }}
    />
  );

  const dateObservationsDeadline = (
    <CustomDateTimePicker
      label="Fecha Tope Observaciones Complementarias"
      value={dateHourObservationsDeadline}
      onChange={(value) => handleDateChange('dateHourObservationsDeadline', value)}
      sx={{
        width: '100%',
      }}
    />
  );

  const documentTypeOptions = Object.values(documentTypes)
    .filter((item) => (!isGuidelines ? item.code !== 'announcements_and_guidelines' : true))
    .filter((item) => (!isPresentation ? item.code !== 'presentations' : true))
    .map((documentType) => (
      <MenuItem key={documentType.id} value={documentType.id}>
        {documentType.name}
      </MenuItem>
    ));

  return (
    <>
      <DialogTitle sx={{ ...styles.title }}>Actualización de documento</DialogTitle>
      <DialogContent sx={{ ...styles.dialogContent }}>
        <Box className="formControlsContainer">
          <FormControl fullWidth size="small" sx={{ mb: 2 }}>
            <InputLabel id="documentTypeId">Tipo Documento</InputLabel>
            <Select
              /* disabled={isPresentation || isGuidelines} */
              labelId="documentTypeId"
              name="documentTypeId"
              value={documentTypeId}
              label="Tipo documento"
              onChange={handleChange}>
              {documentTypeOptions.filter(dt => dt.props.children !== 'Grabación de audiencia pública')}
            </Select>
          </FormControl>
          {!isGuidelines && (
            <TextField
              size="small"
              id="outlined-select-legal-entities"
              select
              fullWidth
              label="En representación de"
              name="legalEntityId"
              onChange={handleChange}
              color="secondary"
              value={legalEntityId}
              sx={{ mb: 2 }}>
              {legalOptions}
            </TextField>
          )}
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            label="Título"
            name="title"
            onChange={handleChange}
            color="secondary"
            value={title}
            sx={{ mb: 2 }}
          />
          <TextField
            multiline
            rows={6}
            fullWidth
            size="small"
            variant="outlined"
            label="Índice del documento"
            name="index"
            onChange={handleChange}
            color="secondary"
            value={index}
            sx={{ mb: 2 }}
          />
          <CustomDateTimePicker
            label="Fecha creación"
            value={documentDate}
            onChange={handleHourChange}
            sx={{
              width: '100%',
              mb: 1,
            }}
          />
          {isGuidelines && (
            <Grid container spacing={2} sx={{ mt: '5px' }}>
              <Grid item xs={6} sx={{ mb: 2 }}>
                {dateAudienceField}
              </Grid>
              <Grid item xs={6} sx={{ mb: 2 }}>
                {dateRegistrationDeadline}
              </Grid>
              <Grid item xs={6} sx={{ mb: 2 }}>
                {datePresentationInterestedParty}
              </Grid>
              <Grid item xs={6} sx={{ mb: 2 }}>
                {dateObservationsDeadline}
              </Grid>
            </Grid>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <SecondaryButton onClick={closeDialog}>Cerrar</SecondaryButton>
        <PrimaryButton onClick={handleUpdateDocument} loading={loading}>
          Modificar
        </PrimaryButton>
      </DialogActions>
    </>
  );
}

export default UpdateDocumentsFormStepOne;
