import color from '../../../utils/colors';

const styles = {
  iconContainer: { display: 'flex', alignItems: 'center', textAlign: 'center' },
  iconStyles: {
    width: 25,
    height: 25,
    color: color.blue,
    backgroundColor: color.white,
    borderRadius: '50%',
    padding: '3px',
    fontWeight: 500,
  },
  paperStyles: {
    elevation: 0,
    sx: {
      overflow: 'visible',
      filter: `drop-shadow(0px 2px 8px ${color.shadowRgba})`,
      mt: 1.5,
      '& .MuiAvatar-root': {
        width: 32,
        height: 32,
        ml: -0.5,
        mr: 1,
      },
      '&:before': {
        left: '50%',
        transform: 'translateX(-77%) translateY(32%)',
      },
    },
  },
};

export default styles;
