import camelize from 'camelcase-keys-deep';

export default class User {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  entity() {
    return this.env().Entity.findBy('userId', this.id);
  }
}
