import colors from '../../../utils/colors';

const styles = {
  button: {
    backgroundColor: colors.blue,
    color: colors.white,
    '&:hover': {
      backgroundColor: colors.cooper,
    },
    minWidth: '110px',
    fontSize: 16,
  },
};
export default styles;
