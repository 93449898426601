/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
/* eslint-disable import/prefer-default-export */
/* eslint-disable prefer-destructuring */
import { useCallback, useState } from 'react';

function deconstructEvent(event, date) {
  event.persist();
  let value = null;
  if (event.target.type === 'file') {
    value = event.target.files[0];
  } else if (event.target.type === 'checkbox') {
    value = event.target.checked;
  } else {
    value =
      event.target.type === 'date' || event.target.type === 'time' ? date : event.target.value;
  }
  const id = event.target.type ? event.target.id : event.currentTarget.id;
  return { id, value };
}

export const useForm = (controls, actions, options) => {
  const [errors, setErrors] = useState({});
  if (actions?.controlsChanged == null && options?.onChange == null) {
    throw new Error('Parameters are missing change callback');
  }

  const isValid = useCallback(
    (id, value) =>
      options.validations[id] == null ||
      options.validations[id].every((validation) => validation.check(value)),
    [options],
  );

  const validate = useCallback(
    (id, value) => {
      let isValidValue = true;
      const newErrors = [];

      for (const validation of options.validations[id]) {
        if (validation.check(value)) {
          continue;
        }
        isValidValue = false;
        if (newErrors[id] == null) {
          newErrors[id] = [];
        }
        newErrors.push(validation.message ?? 'Campo inválido');
      }
      setErrors((state) => ({ ...state, [id]: newErrors.length === 0 ? undefined : newErrors }));

      return isValidValue;
    },
    [options.validations],
  );

  const validateAll = useCallback(() => {
    const ids = Object.keys(options.validations);
    let isValidValue = true;
    const newErrors = {};

    for (const id of ids) {
      const value = controls[id];
      for (const validation of options.validations[id]) {
        if (validation.check(value)) {
          continue;
        }
        isValidValue = false;
        if (newErrors[id] == null) {
          newErrors[id] = [];
        }
        newErrors[id].push(validation.message ?? 'Campo inválido');
      }
    }
    setErrors(newErrors);

    return isValidValue;
  }, [options.validations, controls]);

  const onChange = useCallback(
    (event) => {
      const hasControlsChanged = actions && 'controlsChanged' in actions;
      const { id, value } = deconstructEvent(event, null); // TODO: Pass date

      if (id == null) {
        throw new Error('Input is missing id');
      }

      // Either validate on change or clear errors if it is valid
      if (options?.validateOnChange) {
        validate(id, value);
      } else if (isValid(id, value)) {
        setErrors((newErrors) => ({ ...newErrors, [id]: undefined }));
      }

      if (hasControlsChanged) {
        actions.controlsChanged({ [id]: value });
      } else {
        options.onChange(id, value);
      }
    },
    [options, actions, isValid, validate],
  );

  const onSubmit = useCallback(
    (callback) => {
      const valid = validateAll();
      if (valid) {
        callback();
      }
    },
    [validateAll],
  );

  return { errors, onChange, onSubmit };
};
