import colors from 'utils/colors';

const styles = {
  dialogStyles: {
    '& .modal-container': {
      padding: '40px',
      minWidth: '400px',
      '& .icon-container': {
        padding: '10px',
        textAlign: 'center',
        '& .icon': {
          fontSize: '100px',
          '&.error': {
            color: 'red',
          },
          '&.warning': {
            color: 'gold',
          },
          '&.success': {
            color: 'green',
          },
        },
      },
      '& .dialog-title-container': {
        textAlign: 'center',
        '& .title-container': {
          fontFamily: 'Roboto',
          fontSize: '40px',
          paddingBottom: '0',
        },
      },
      '& .dialog-content-container': {
        textAlign: 'center',
        '& .dialog-content': {
          fontSize: '25px',
        },
      },
      '& .dialog-actions-container': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& .dialog-actions-button': {
          backgroundColor: colors.blue,
          color: colors.white,
        },
      },
    },
  },
};

export default styles;
