import React from 'react';
import PropTypes from 'prop-types';

/* Material UI */
import { Box, Divider, Typography } from '@mui/material';

/* Project */
import color from 'utils/colors';

function StepperTitle(props) {
  const { controls } = props;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', mt: 3 }}>
      <Typography
        sx={{
          fontSize: 'h6.fontSize',
          fontWeight: 'bold',
          color: color.cooper,
          textTransform: 'uppercase',
        }}>
        PASO {controls.activeStep + 1}
      </Typography>
      <Divider orientation="vertical" flexItem sx={{ ml: 1, mr: 1, background: color.cooper }} />
      <Typography
        sx={{
          fontWeight: 'regular',
          color: color.cooper,
          fontSize: 20,
        }}>
        Ingreso nueva discrepancia
      </Typography>
    </Box>
  );
}

StepperTitle.propTypes = {
  controls: PropTypes.shape({}).isRequired,
};

export default StepperTitle;
