import React, { useState, useEffect, useCallback } from 'react';

/* React Router */
import { useHistory } from 'react-router-dom';

/* Material UI */
import { CircularProgress, Typography, Snackbar } from '@mui/material';
import { withStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';

/* Immer */
import produce from 'immer';

/* Project */
import { SET_ERROR, VALIDATE_CAPTCHA, appActions } from 'commons/commons.reducer';
import PrimaryButton from 'commons/components/PrimaryButton/PrimaryButton';
import { getParameterByName, composedComponent } from 'utils/functions';
import { useForm } from 'utils/hooks/useForm';
import GeneralAlert from 'commons/components/GeneralAlert/GeneralAlert';
import styles from './styles';
import saga from './Login.saga';
import { loginActions } from './Login.reducer';
import color from '../../utils/colors';
import { useDispatch, useSelector } from 'react-redux';
import { resetCaptcha, showCaptchaError } from 'commons/commons.actions';
import CaptchaDialog from 'commons/components/CaptchaDialog/CaptchaDialog';

function Login(props) {
  const { classes, controls, user, location, actions, error, errorMsg, success, successMsg } =
    props;

  const history = useHistory();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    email: '',
    password: '',
  });

  useEffect(() => {
    if (user) {
      history.replace('/');
    }
  }, [user]);

  // #region Handlers ---------------------------------------
  const onChangeText = useCallback((id, value) => {
    setState(
      produce((draft) => {
        // eslint-disable-next-line no-param-reassign
        draft[id] = value?.trim();
      }),
    );
  }, []);

  const { onChange, onSubmit, errors } = useForm(state, null, {
    onChange: onChangeText,
    validations: {
      password: [
        {
          check: (value) => value != null && value.trim().length > 0,
          message: 'Contraseña obligatoria',
        },
      ],
      email: [
        {
          check: (value) => value != null && value.trim().length > 0,
          message: 'Email obligatorio',
        },
      ],
    },
  });

  const submitCaptcha = () => {
    const siteKey = process.env.REACT_APP_CAPTCHA_SITE_KEY;
    const secretKey = process.env.REACT_APP_CAPTCHA_SECRET_KEY;
    try {
      grecaptcha.ready(function () {
        grecaptcha.execute(siteKey, { action: 'submit' }).then(function (token) {
          dispatch({ type: VALIDATE_CAPTCHA, payload: { secretKey, token } });
        });
      });
    } catch (error) {
      dispatch({ type: SET_ERROR, msg: 'Error al obtener captcha' });
    }
  };

  const toggleCaptchaBadge = (show) => {
    const badge = document.getElementsByClassName('grecaptcha-badge')[0];
    if (badge && badge instanceof HTMLElement) {
      badge.style.visibility = show ? 'visible' : 'hidden';
    }
  };

  const { aprovedCaptcha, score } = useSelector((state) => state.app);

  useEffect(() => {
    toggleCaptchaBadge(true);
    submitCaptcha();
    return () => {
      toggleCaptchaBadge(false);
      dispatch(resetCaptcha());
    };
    // eslint-disable-next-line
  }, []);

  function onClickLogin(event) {
    event.preventDefault();
    if (aprovedCaptcha) {
      onSubmit(() => actions.login(state, controls.redirectUrl));
    } else {
      dispatch(showCaptchaError({ show: !aprovedCaptcha }));
    }
  }

  useEffect(() => {
    const token = getParameterByName('token', location.search);
    if (token) {
      actions.verify(token);
    }
  }, [actions, location.seaprops, location.search]);

  // #endregion Handlers ---------------------------------------

  // #region Components --------------------------------------
  const emailInput = (
    <TextField
      className={classes.field}
      autoFocus
      label="Correo electrónico o RUT"
      id="email"
      value={state.email}
      onChange={onChange}
      fullWidth
      error={!!errors.email}
      helperText={errors.email}
      color="secondary"
    />
  );

  const passwordInput = (
    <TextField
      className={classes.field}
      label="Contraseña"
      onChange={onChange}
      value={state.password}
      id="password"
      fullWidth
      type="password"
      error={!!errors.password}
      helperText={errors.password}
      color="secondary"
    />
  );

  const loginErrorMsg = controls.error && (
    <Grid item xs={10} textAlign="center">
      <Typography style={{ color: 'red' }} align="center" variant="caption">
        Credenciales inválidas
      </Typography>
    </Grid>
  );

  // const errorSnackbar = (
  //   <Snackbar
  //     anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
  //     open={error}
  //     autoHideDuration={3000}
  //     onClose={actions.toggleError}>
  //     <Alert onClose={actions.toggleError} severity="error">
  //       {errorMsg}
  //     </Alert>
  //   </Snackbar>
  // );

  const successSnackbar = (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={success}
      autoHideDuration={3000}
      onClose={actions.toggleSuccess}>
      <Alert onClose={actions.toggleSuccess} severity="success">
        {successMsg}
      </Alert>
    </Snackbar>
  );
  // #endregion Components --------------------------------------

  const errorAlert = (
    <GeneralAlert
      isOpen={error}
      handleOnClose={actions.toggleError}
      title="Error"
      message={errorMsg === 'Unauthorized' ? 'Credenciales incorrectas' : errorMsg || ''}
      status="error"
    />
  );

  return (
    <div>
      <div>
        <Grid container justifyContent="center" alignItems="center" sx={{ height: '80vh' }}>
          <Grid item container md={3} lg={3} xs={12}>
            <form onSubmit={onClickLogin}>
              <Grid container spacing={2} sx={{ m: 1 }}>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontWeight: 'regular',
                      color: color.cooper,
                      fontSize: 20,
                    }}>
                    Iniciar sesión
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {emailInput}
                </Grid>
                <Grid item xs={12}>
                  {passwordInput}
                </Grid>
                {loginErrorMsg}
                <Grid item xs={12}>
                  <Button
                    variant="text"
                    sx={{ color: color.cooper }}
                    onClick={() => history.push('/recovery-password')}>
                    Recuperar Contraseña
                  </Button>
                  <CaptchaDialog />
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={12}
                  container
                  justifyContent="space-between"
                  alignItems="center">
                  <IconButton edge="start" onClick={() => history.replace('/')}>
                    <ArrowBackIcon />
                  </IconButton>
                  <PrimaryButton
                    type="submit"
                    text={controls.loading ? <CircularProgress /> : 'Aceptar'}
                  />
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </div>
      {successSnackbar}
      {errorAlert}
    </div>
  );
}

export default composedComponent(Login, saga, {
  saga: 'sagaLogin',
  middlewares: [withStyles(styles)],
  states: [
    'login.controls',
    'app.user',
    'app.errorMsg',
    'app.error',
    'app.successMsg',
    'app.success',
  ],
  actions: [loginActions, appActions],
});
