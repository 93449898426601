import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import { changeAttachmentControls } from 'screens/DiscrepancyDetail/DiscrepancyDetails.actions';
import { IconButton, InputAdornment, Stack, Typography } from '@mui/material';
import CustomTextField from 'commons/components/CustomTextField/CustomTextField';
import color from 'utils/colors';
import { Delete, FileUpload } from '@mui/icons-material';
import SecondaryButton from 'commons/components/SecondaryButton/SecondaryButton';
import PrimaryButton from 'commons/components/PrimaryButton/PrimaryButton';
import { appSetError } from 'commons/commons.actions';
import { isZippedFile } from 'utils/functions';

function AttachmentModal(props) {
  const { newFileRow, attachmentsArray } = props;

  const dispatch = useDispatch();
  const { documentStepper } = useSelector((state) => state.discrepancyDetails);

  const { modalControls } = documentStepper;

  const [attachments, setAttachments] = useState({ publicAtt: null, privateAtt: null });

  useEffect(() => {
    if (attachmentsArray.length > 0) {
      // console.log('cambiar el estado');
      // console.log(attachmentsArray[modalControls.idx]);
      setAttachments((prevState) => ({ ...prevState, ...attachmentsArray[modalControls.idx] }));
    }
  }, [modalControls.idx]);

  const handleClose = () => {
    dispatch(changeAttachmentControls({ name: 'openDialog', value: false }));
    dispatch(changeAttachmentControls({ name: 'idx', value: -1 }));
    setAttachments({ publicAtt: null, privateAtt: null });
  };

  const handleSubmit = () => {
    newFileRow(attachments, modalControls.idx);
    handleClose();
  };

  const handleModalChange = ({ target: { name, files } }) => {
    const attachmentsCopy = { ...attachments };

    // const allowedFileTypes = [
    //   'application/pdf',
    //   'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
    //   'application/msword', // doc
    //   'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx
    //   'application/vnd.ms-excel', // xls
    //   'application/vnd.openxmlformats-officedocument.presentationml.presentation', // pptx
    //   'application/vnd.ms-powerpoint' // ppt
    // ];
    if (name === 'attachmentPublic') {
      // eslint-disable-next-line prefer-destructuring
      const currentFile = files[0];
      const isFileAllowed = !isZippedFile(currentFile.name) // && allowedFileTypes.includes(currentFile.type);
      if (currentFile && isFileAllowed) {
        attachmentsCopy.publicAtt = files[0];
        return setAttachments(attachmentsCopy);
      } else {
        attachmentsCopy.publicAtt = null;
        setAttachments(attachmentsCopy);
        document.getElementById('attachmentPublic').value= null;
        dispatch(appSetError({ msg: 'El documento no puede ser un archivo comprimido' }));
        // dispatch(appSetError({ msg: 'Solo se permiten archivos tipo pdf, doc, docx, xls, xlsx, ppt y pptx. No está permitido subir imagenes, videos ni audios.' }));
      }
    }
    if (name === 'attachmentPrivate') {
      // eslint-disable-next-line prefer-destructuring
      const currentFile = files[0];
      const isFileAllowed = !isZippedFile(currentFile.name) // && allowedFileTypes.includes(currentFile.type);
      if (currentFile && isFileAllowed) {
        attachmentsCopy.privateAtt = files[0];
        return setAttachments(attachmentsCopy);
      } else {
        attachmentsCopy.privateAtt = null;
        setAttachments(attachmentsCopy);
        dispatch(appSetError({ msg: 'El documento no puede ser un archivo comprimido' }));
        // dispatch(appSetError({ msg: 'Solo se permiten archivos tipo pdf, doc, docx, xls, xlsx, ppt y pptx. No está permitido subir imagenes, videos ni audios.' }));
      }
    }
    return setAttachments(attachmentsCopy);
  };

  const deletePrivateAttachment = () => {
    const attachmentsCopy = { ...attachments };
    attachmentsCopy.privateAtt = null;
    return setAttachments(attachmentsCopy);
  };

  const disabledNext = !attachments.publicAtt;

  return (
    <Dialog
      open={modalControls.openDialog}
      onClose={handleClose}
      PaperProps={{ sx: { minWidth: { lg: 500 }, minHeight: 200 } }}>
      <Box sx={{ m: 3 }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', mb: 2 }}>
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: 'bold',
              color: color.blue,
              textTransform: 'uppercase',
              mr: 1,
            }}>
            CARGAR
          </Typography>
          <Typography
            sx={{
              fontWeight: 'regular',
              color: color.blue,
              fontSize: 16,
            }}>
            Archivo adjunto
          </Typography>
        </Box>
        <Stack spacing={2}>
          <CustomTextField
            name="attachmentPublic"
            label="Archivo público"
            value={attachments.publicAtt?.name || ''}
            inputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <label htmlFor="attachmentPublic">
                    <input
                      accept="application/pdf"
                      id="attachmentPublic"
                      name="attachmentPublic"
                      type="file"
                      style={{ display: 'none' }}
                      onChange={handleModalChange}
                    />
                    <IconButton
                      sx={{ color: color.blue }}
                      aria-label="attachmentPublic"
                      component="span">
                      <FileUpload />
                    </IconButton>
                  </label>
                </InputAdornment>
              ),
            }}
          />
          {/* INFO: Cliente requiere retirar la opción de agregar archivos confidenciales
          <CustomTextField
            name="attachmentPrivate"
            label="Archivo confidencial"
            value={attachments.privateAtt?.name || ''}
            inputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <label htmlFor="attachmentPrivate">
                    <input
                      accept="application/pdf"
                      id="attachmentPrivate"
                      name="attachmentPrivate"
                      type="file"
                      style={{ display: 'none' }}
                      onChange={handleModalChange}
                    />
                    <IconButton
                      sx={{ color: color.blue }}
                      aria-label="attachmentPrivate"
                      component="span">
                      <FileUpload />
                    </IconButton>
                  </label>
                  <IconButton
                    sx={{ color: color.blue }}
                    aria-label="attachmentPrivate"
                    component="span"
                    onClick={deletePrivateAttachment}>
                    <Delete />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          /> */}
        </Stack>
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <SecondaryButton onClick={handleClose} text="Cancelar" sx={{ mr: 1 }} />
          <Box sx={{ flex: '1 1 auto' }} />
          <PrimaryButton disabled={disabledNext} onClick={handleSubmit} text="Aceptar" />
        </Box>
      </Box>
    </Dialog>
  );
}

export default AttachmentModal;
