import React from 'react';
import { useHistory } from 'react-router-dom';

// material
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { makeStyles } from '@mui/styles';

// Project
import navBarStyles from './NavBar.styles';
import PdeLogo from '../../assets/images/PdeLogo_123x42.png';
import AccountMenu from '../AccountMenu/AccountMenu';
import SideMenu from '../SideMenu/SideMenu';
import colors from 'utils/colors';

const useStyles = makeStyles(navBarStyles);

function NavBar(props) {
  const { user, objects } = props;
  const classes = useStyles();
  const history = useHistory();

  return (
    <AppBar
      position="static"
      color="primary"
      style={{ marginBottom: '10px', backgroundColor: colors.purpleSecondary }}>
      <Toolbar variant="dense">
        <SideMenu objects={objects} />
        <Tooltip title="Inicio">
          <IconButton onClick={() => history.push('/')}>
            <img style={{ height: '40px' }} src={PdeLogo} alt="" />
          </IconButton>
        </Tooltip>

        <Typography variant="h6" className={classes.title} />

        <AccountMenu user={user} objects={objects} />
      </Toolbar>
    </AppBar>
  );
}

export default NavBar;
