/* eslint-disable import/no-import-module-exports */
/* eslint-disable no-console */
import React from 'react';

/* React Router */
import { BrowserRouter } from 'react-router-dom';

/* React Redux */
import { Provider } from 'react-redux';

/* Rexus persist */
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

/* React Doom */
import { render } from 'react-dom';

/* Sentry */
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

/* Extra fonts */
import 'fontsource-roboto';

/* MATERIALUI */
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

/* Project */
import configureStore from './config/configureStore';
import App from './App';
import './commons/assets/styles.scss';

// init sentry
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_KEY,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: process.env.REACT_APP_TRACES_SAMPLE_RATE || 0.1,
});

const store = configureStore();
const persistor = persistStore(store);

const renderApp = () =>
  render(
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </LocalizationProvider>,
    document.querySelector('#root'),
  );

renderApp();

if (process.env.NODE_ENV !== 'production') {
  // @ts-ignore
  if (module.hot) {
    // Support hot reloading of components
    // and display an overlay for runtime errors
    module.hot.accept('./App', () => {
      renderApp();
    });
  }
}

if ('serviceWorker' in navigator && process.env.NODE_ENV === 'production') {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/sw.js')
      .then(() => console.log('Successful registration'))
      .catch(() => console.log('Failed registration.'));
  });
} else {
  console.log('serviceWorker not available');
}
