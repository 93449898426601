export const stateList = [
  {
    id: 1,
    label: 'En Tramitación',
  },
  {
    id: 2,
    label: 'Terminada',
  },
];

export const orderList = [
  {
    id: 1,
    label: 'Número Discrepancia - Ascendente',
  },
  {
    id: 2,
    label: 'Número Discrepancia - Descendente',
  },
  {
    id: 3,
    label: 'Fecha Inicio - Ascendente',
  },
  {
    id: 4,
    label: 'Fecha Inicio - Descendente',
  },
];
