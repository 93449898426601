import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

/* Material UI */
import { CardMedia } from '@mui/material';

/* Project */
import { convertVideo } from 'utils/helper';

function VideoFrame(props) {
  const { url } = props;
  const [fullUrl, setFullUrl] = useState('');

  useEffect(() => {
    setFullUrl(convertVideo(url) || url);
  }, [url]);

  return (
    <CardMedia
      sx={{
        height: '500px',
      }}>
      {fullUrl ? (
        <iframe
          title="maps-preview"
          height="auto"
          src={fullUrl}
          allow="autoplay; encrypted-media"
          allowFullScreen
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            border: 0,
          }}
          // sandbox="allow-scripts allow-same-origin"
        />
      ) : (
        <div />
      )}
    </CardMedia>
  );
}

VideoFrame.propTypes = {
  url: PropTypes.string.isRequired,
};

export default VideoFrame;
