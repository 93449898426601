import LoginTypes from 'screens/Login/Login.types';
import { createAction, newReducer } from 'utils/reducer';
import commonsTypes from 'commons/commons.types';
import HomeTypes from 'screens/Home/Home.types';
import DiscrepancyDetailsTypes from 'screens/DiscrepancyDetail/DiscrepancyDetails.types';
import { normalizeObj } from 'utils/helper';
import AudiencesCalendarTypes from 'screens/AudiencesCalendar/AudiencesCalendar.types';
import LastDocumentsTypes from 'screens/LastDocuments/LastDocuments.types';

// GLOBAL
export const FETCH_INITIAL_STATE = '[APP] FETCH_INITIAL_STATE';
export const FETCH_INITIAL_STATE_SUCCESS = '[APP] FETCH_INITIAL_STATE_SUCCESS';
export const FETCH_INITIAL_STATE_FAIL = '[APP] FETCH_INITIAL_STATE_FAIL';
// export const LOG_OUT = 'app/LOG_OUT';
export const TOGGLE_DRAWER = 'app/TOGGLE_DRAWER';
export const TOGGLE_ERROR = 'app/TOGGLE_ERROR';
export const TOGGLE_SUCCESS = 'app/TOGGLE_SUCCESS';
export const SET_ERROR = 'app/SET_ERROR';
export const SET_SUCCESS = 'app/SET_SUCCESS';
export const SET_LOADER = 'app/SET_LOADER';
export const GET_STATE_REPLACE_FROM_API_SUCCESS = 'app/GET_STATE_REPLACE_FROM_API_SUCCESS';
export const DELETE_DOCUMENT_SUCCESS = 'app/DiscrepancyDetailsTypes.DELETE_DOCUMENT_SUCCESS';
export const VALIDATE_CAPTCHA = 'app/VALIDATE_CAPTCHA';
export const VALIDATE_CAPTCHA_SUCCESS = 'app/VALIDATE_CAPTCHA_SUCCESS';
export const VALIDATE_CAPTCHA_ERROR = 'app/VALIDATE_CAPTCHA_ERROR';
export const RESET_CAPTCHA = 'app/RESET_CAPTCHA';
export const SHOW_CAPTCHA_ERROR = 'app/SHOW_CAPTCHA_ERROR';

export const appActions = {
  // api fetch
  // logOut: createAction(LOG_OUT),
  fetchInitialState: createAction(FETCH_INITIAL_STATE),
  setUser: createAction(FETCH_INITIAL_STATE_SUCCESS, 'payload'),
  // others
  toggleError: createAction(TOGGLE_ERROR),
  toggleSuccess: createAction(TOGGLE_SUCCESS),
  toggleDrawer: createAction(TOGGLE_DRAWER),
  setError: createAction(SET_ERROR, 'msg'),
  setSuccess: createAction(SET_SUCCESS, 'msg'),
  setLoader: createAction(SET_LOADER, 'id', 'state'),
  closeGeneralDialog: createAction(commonsTypes.CLOSE_GENERAL_DIALOG),
};

const mergeObjects = (state, action, mutation = (newState) => newState) => {
  const newObjects = {};
  Object.keys({ ...state.objects, ...action.payload.objects }).forEach((key) => {
    newObjects[key] = {
      ...state.objects[key],
      ...(action.payload.objects?.[key] || {}),
    };
  });

  const newState = {
    ...state,
    objects: newObjects,
  };

  return mutation(newState);
};

const replaceObjects = (state, action) => {
  const newObjects = { ...state.objects };
  Object.keys(action.payload.objects).forEach((key) => {
    newObjects[key] = {
      ...(action.payload.objects[key] || {}),
    };
  });
};

const deleteObjectAndMutateState = (state, action, mutation = (newState) => newState) => {
  const newState = { ...state };
  const newObjects = { ...state.objects };

  Object.keys(action.payload.objects).forEach((key) => {
    delete newObjects[key];
    newObjects[key] = normalizeObj(
      Object.values(state.objects[key]).filter((item) => !action.payload.objects[key][item.id]),
    );
  });
  // eslint-disable-next-line no-param-reassign
  newState.objects = newObjects;

  return mutation(newState);
};

const deleteObject = (state, action) => {
  const newState = { ...state };
  const newObjects = { ...state.objects };

  Object.keys(action.payload.objects).forEach((key) => {
    delete newObjects[key];
    newObjects[key] = normalizeObj(
      Object.values(state.objects[key]).filter((item) => !action.payload.objects[key][item.id]),
    );
  });
  // eslint-disable-next-line no-param-reassign
  newState.objects = newObjects;

  return newState;
};

const initialState = {
  openUpdateDiscrepancyDialog: false,
  updateDiscrepancyLoader: false,
  drawerIsOpen: false,
  loadingUser: true,
  errorMsg: '',
  error: false,
  successMsg: '',
  success: false,
  aprovedCaptcha: false,
  showCaptchaError: false,
  objects: {
    alerts: {},
    attachments: {},
    attachmentParts: {},
    clients: {},
    discrepancies: {},
    discrepancyFileUrl: {},
    documents: {},
    documentTypes: {},
    entities: {},
    interestedParties: {},
    users: {},
    legalMatters: {},
    legalSubMatters: {},
    legalEntities: {},
    representatives: {},
    hearings: {},
    legalEntityFiles: {},
    discrepancyMessage: {},
  },
  user: null,
  loaders: {
    deleteDiscrepancy: false,
  },
  discrepancyStepper: {
    form: {
      cover: '',
      matterId: '',
      subMatterId: '',
      legalEntityId: '',
      position: '',
      index: '',
      presentationLimit: '',
      pageNumber: '',
    },
    controls: {
      activeStep: 0,
      openDialog: false,
    },
    loaders: {
      create: false,
    },
  },
  registerLegalEntity: {
    // aqui esta en el reducer
    form: {
      name: '',
      rut: '',
      email: '',
      phone: '',
      address: '',
      password: '',
      confirmPassword: '',
    },
    formErrors: {
      rut: {
        isValid: true,
        errorMessage: '',
      },
      email: {
        isValid: true,
        errorMessage: '',
      },
    },
    controls: {
      openDialog: false,
    },
    legalPowersControls: {
      openDialog: false,
    },
    representativesControls: {
      openDialog: false,
    },
    loaders: {
      create: false,
    },
  },
  registerNaturalEntity: {
    form: {
      names: '',
      surnames: '',
      rut: '',
      email: '',
      phone: '',
      password: '',
      confirmPassword: '',
    },
    formErrors: {
      rut: {
        isValid: true,
        errorMessage: '',
      },
      email: {
        isValid: true,
        errorMessage: '',
      },
    },
    controls: {
      openDialog: false,
    },
    loaders: {
      create: false,
    },
  },
  registerDiscrepancyMessage: {
    form: {
      title: '',
      message: '',
      noTitle: false,
      enabled: true,
    },
    controls: {
      openDialog: false,
    },
    loaders: {
      create: false,
    },
  },
  editNaturalEntity: {
    form: {
      names: '',
      surnames: '',
      rut: '',
      email: '',
      phone: '',
      password: '',
      confirmPassword: '',
    },
    controls: {
      openDialog: false,
    },
    loaders: {
      create: false,
      loading: true,
    },
  },
  editLegalEntity: {
    form: {
      name: '',
      rut: '',
      email: '',
      phone: '',
      address: '',
      password: '',
      confirmPassword: '',
    },
    controls: {
      openDialog: false,
    },
    legalPowersControls: {
      openDialog: false,
      openConfirmDialog: {
        isOpen: false,
        powerLegalFileId: 0,
      },
    },
    representativesControls: {
      openDialog: false,
    },
    representatives: {},
    loaders: {
      create: false,
      legalPowers: {
        delete: false,
        create: false,
      },
    },
    tutorialLink: '',
  },
  generalAlert: {
    status: '',
    message: '',
    title: '',
    open: false,
  },
};

const fetchUserUpdate = (state) => ({
  ...state,
  loadingUser: true,
});

const toggleUpdateDiscrepancyLoader = (state, value) => ({
  ...state,
  updateDiscrepancyLoader: value,
});

const app = newReducer(initialState, {
  [LoginTypes.LOGIN_USER_SUCCESS]: (state, action) => ({
    ...state,
    user: action.payload.user,
    loadingUser: false,
  }),
  [FETCH_INITIAL_STATE_SUCCESS]: (state, action) =>
    mergeObjects(state, action, (newState) => ({
      ...newState,
      user: action.payload.user,
      editNaturalEntity: {
        ...state.editNaturalEntity,
        ...action.payload.editNaturalEntity,
      },
      editLegalEntity: {
        ...state.editLegalEntity,
        ...action.payload.editLegalEntity,
      },
      tutorialLink: action.payload.tutorialLink,
      loadingUser: false,
    })),
  [FETCH_INITIAL_STATE]: fetchUserUpdate,
  [LoginTypes.LOGIN_USER]: fetchUserUpdate,
  [FETCH_INITIAL_STATE_FAIL]: (state) => ({
    ...state,
    user: null,
    loadingUser: false,
  }),
  [TOGGLE_DRAWER]: (state) => ({
    ...state,
    drawerIsOpen: !state.drawerIsOpen,
  }),
  [TOGGLE_ERROR]: (state) => ({
    ...state,
    error: false,
    errorMsg: !state.errorMsg,
  }),
  [TOGGLE_SUCCESS]: (state) => ({
    ...state,
    success: false,
    successMsg: !state.successMsg,
  }),
  [SET_ERROR]: (state, action) => ({
    ...state,
    error: true,
    errorMsg: action.payload.msg,
  }),
  [SET_SUCCESS]: (state, action) => ({
    ...state,
    success: true,
    successMsg: action.payload.msg,
  }),
  [SET_LOADER]: (state, action) => ({
    ...state,
    loaders: {
      ...state.loaders,
      [action.id]: action.state,
    },
  }),
  [commonsTypes.CHANGE_DISCREPANCY_CREATION_CONTROLS]: (state, action) => ({
    ...state,
    discrepancyStepper: {
      ...state.discrepancyStepper,
      controls: {
        ...state.discrepancyStepper.controls,
        [action.payload.name]: action.payload.value,
      },
    },
  }),
  [commonsTypes.CHANGE_DISCREPANCY_FORM]: (state, action) => ({
    ...state,
    discrepancyStepper: {
      ...state.discrepancyStepper,
      form: {
        ...state.discrepancyStepper.form,
        [action.payload.name]: action.payload.value,
      },
    },
  }),
  [commonsTypes.CREATE_DISCREPANCY]: (state) => ({
    ...state,
    discrepancyStepper: {
      ...state.discrepancyStepper,
      loaders: {
        ...state.discrepancyStepper.form,
        create: true,
      },
    },
  }),
  [commonsTypes.CREATE_DISCREPANCY_FAIL]: (state) => ({
    ...state,
    discrepancyStepper: {
      ...state.discrepancyStepper,
      loaders: {
        ...state.discrepancyStepper.form,
        create: false,
      },
    },
  }),
  [commonsTypes.CREATE_DISCREPANCY_SUCCESS]: (state, action) =>
    mergeObjects(state, action, (newState) => ({
      ...newState,
      discrepancyStepper: {
        ...initialState.discrepancyStepper,
      },
    })),

  [commonsTypes.CHANGE_LEGAL_ENTITY_CREATION_CONTROLS]: (state, action) => ({
    ...state,
    registerLegalEntity: {
      ...state.registerLegalEntity,
      controls: {
        ...state.registerLegalEntity.controls,
        [action.payload.name]: action.payload.value,
      },
    },
  }),
  [commonsTypes.CHANGE_LEGAL_ENTITY_FORM]: (state, action) => ({
    ...state,
    registerLegalEntity: {
      ...state.registerLegalEntity,
      form: {
        ...state.registerLegalEntity.form,
        [action.payload.name]: action.payload.value,
      },
    },
  }),
  [commonsTypes.CLEAR_LEGAL_ENTITY_FORM]: (state) => ({
    ...state,
    registerLegalEntity: {
      ...initialState.registerLegalEntity,
    },
  }),
  [commonsTypes.CREATE_LEGAL_ENTITY]: (state) => ({
    ...state,
    registerLegalEntity: {
      ...state.registerLegalEntity,
      loaders: {
        ...state.registerLegalEntity.loaders,
        create: true,
      },
    },
  }),
  [commonsTypes.CREATE_LEGAL_ENTITY_FAIL]: (state) => ({
    ...state,
    registerLegalEntity: {
      ...state.registerLegalEntity,
      loaders: {
        ...state.registerLegalEntity.loaders,
        create: false,
      },
    },
    editLegalEntity: {
      ...state.editLegalEntity,
      loaders: {
        ...state.editLegalEntity.loaders,
        legalPowers: {
          ...state.editLegalEntity.loaders.legalPowers,
          create: false,
        },
      },
    },
  }),
  [commonsTypes.CREATE_LEGAL_ENTITY_SUCCESS]: (state, action) =>
    mergeObjects(state, action, (newState) => ({
      ...newState,
      registerLegalEntity: {
        ...initialState.registerLegalEntity,
        formErrors: {
          ...initialState.registerLegalEntity.formErrors,
        },
      },
    })),

  [commonsTypes.CHANGE_NATURAL_ENTITY_CREATION_CONTROLS]: (state, action) => ({
    ...state,
    registerNaturalEntity: {
      ...state.registerNaturalEntity,
      controls: {
        ...state.registerNaturalEntity.controls,
        [action.payload.name]: action.payload.value,
      },
    },
  }),
  [commonsTypes.CHANGE_DISCREPANCY_MESSAGE_CONTROLS]: (state, action) => ({
    ...state,
    registerDiscrepancyMessage: {
      ...state.registerDiscrepancyMessage,
      form: {
        ...state.registerDiscrepancyMessage.form,
        [action.payload.name]: action.payload.value,
      },
    },
  }),
  [commonsTypes.OPEN_DISCREPANCY_MESSAGE_DIALOG]: (state, action) => ({
    ...state,
    registerDiscrepancyMessage: {
      ...state.registerDiscrepancyMessage,
      controls: {
        ...state.registerDiscrepancyMessage.controls,
        [action.payload.name]: action.payload.value,
      },
    },
  }),
  [commonsTypes.UPDATE_DISCREPANCY_MESSAGE]: (state, action) => ({
    ...state,
    registerDiscrepancyMessage: {
      ...state.registerDiscrepancyMessage,
      loaders: {
        ...state.registerDiscrepancyMessage.loaders,
        create: true,
      },
    },
  }),
  [commonsTypes.UPDATE_DISCREPANCY_MESSAGE_SUCCESS]: (state, action) =>
    mergeObjects(state, action, (newState) => ({
      ...newState,
      registerDiscrepancyMessage: {
        ...state.registerDiscrepancyMessage,
        loaders: {
          ...state.registerDiscrepancyMessage.loaders,
          create: false,
        },
        controls: {
          openDialog: false,
        },
      },
    })),
  [commonsTypes.UPDATE_DISCREPANCY_MESSAGE_FAIL]: (state, action) => ({
    ...state,
    registerDiscrepancyMessage: {
      ...state.registerDiscrepancyMessage,
      loaders: {
        ...state.registerDiscrepancyMessage.loaders,
        create: false,
      },
    },
  }),
  [commonsTypes.LOAD_DISCREPANCY_MESSAGE]: (state, action) => ({
    ...state,
    registerDiscrepancyMessage: {
      ...state.registerDiscrepancyMessage,
      form: {
        title: action.payload.title,
        message: action.payload.message,
        noTitle: action.payload.noTitle,
        enabled: action.payload.enabled,
      },
    },
  }),
  [commonsTypes.CHANGE_NATURAL_ENTITY_FORM]: (state, action) => ({
    ...state,
    registerNaturalEntity: {
      ...state.registerNaturalEntity,
      form: {
        ...state.registerNaturalEntity.form,
        [action.payload.name]: action.payload.value,
      },
    },
  }),
  [commonsTypes.CREATE_NATURAL_ENTITY]: (state) => ({
    ...state,
    registerNaturalEntity: {
      ...state.registerNaturalEntity,
      loaders: {
        ...state.registerNaturalEntity.form,
        create: true,
      },
    },
  }),
  [commonsTypes.CREATE_NATURAL_ENTITY_FAIL]: (state) => ({
    ...state,
    registerNaturalEntity: {
      ...state.registerNaturalEntity,
      loaders: {
        ...state.registerNaturalEntity.form,
        create: false,
      },
    },
  }),
  [commonsTypes.CREATE_NATURAL_ENTITY_SUCCESS]: (state, action) =>
    mergeObjects(state, action, (newState) => ({
      ...newState,
      registerNaturalEntity: {
        ...initialState.registerNaturalEntity,
      },
    })),

  [commonsTypes.CHANGE_LEGAL_POWERS_MODAL_CONTROLS]: (state, action) => ({
    ...state,
    registerLegalEntity: {
      ...state.registerLegalEntity,
      legalPowersControls: {
        ...state.registerLegalEntity.legalPowersControls,
        [action.payload.name]: action.payload.value,
      },
    },
  }),

  [commonsTypes.CHANGE_REPRESENTATIVES_MODAL_CONTROLS]: (state, action) => ({
    ...state,
    registerLegalEntity: {
      ...state.registerLegalEntity,
      representativesControls: {
        ...state.registerLegalEntity.representativesControls,
        [action.payload.name]: action.payload.value,
      },
    },
  }),

  [commonsTypes.EDIT_NATURAL_ENTITY]: (state) => ({
    ...state,
    editNaturalEntity: {
      ...state.editNaturalEntity,
      loaders: {
        ...state.editNaturalEntity.form,
        create: true,
      },
    },
  }),
  [commonsTypes.EDIT_NATURAL_ENTITY_FAIL]: (state) => ({
    ...state,
    editNaturalEntity: {
      ...state.editNaturalEntity,
      loaders: {
        ...state.editNaturalEntity.form,
        create: false,
      },
    },
  }),
  [commonsTypes.EDIT_NATURAL_ENTITY_SUCCESS]: (state, action) =>
    mergeObjects(state, action, (newState) => ({
      ...newState,
      editNaturalEntity: {
        ...initialState.editNaturalEntity,
        ...action.payload.editNaturalEntity,
      },
    })),

  [commonsTypes.CHANGE_EDIT_NATURAL_ENTITY_CONTROLS]: (state, action) => ({
    ...state,
    editNaturalEntity: {
      ...state.editNaturalEntity,
      controls: {
        ...state.editNaturalEntity.controls,
        [action.payload.name]: action.payload.value,
      },
    },
  }),

  [commonsTypes.CHANGE_EDIT_NATURAL_ENTITY_FORM]: (state, action) => ({
    ...state,
    editNaturalEntity: {
      ...state.editNaturalEntity,
      form: {
        ...state.editNaturalEntity.form,
        [action.payload.name]: action.payload.value,
      },
    },
  }),

  [commonsTypes.EDIT_LEGAL_ENTITY]: (state) => ({
    ...state,
    editLegalEntity: {
      ...state.editLegalEntity,
      loaders: {
        ...state.editLegalEntity.form,
        create: true,
      },
    },
  }),
  [commonsTypes.EDIT_LEGAL_ENTITY_FAIL]: (state) => ({
    ...state,
    editLegalEntity: {
      ...state.editLegalEntity,
      loaders: {
        ...state.editLegalEntity.form,
        create: false,
      },
    },
  }),
  [commonsTypes.EDIT_LEGAL_ENTITY_SUCCESS]: (state, action) =>
    mergeObjects(state, action, (newState) => ({
      ...newState,
      editLegalEntity: {
        ...initialState.editLegalEntity,
        ...action.payload.editLegalEntity,
      },
    })),

  [commonsTypes.CHANGE_EDIT_LEGAL_ENTITY_CONTROLS]: (state, action) => ({
    ...state,
    editLegalEntity: {
      ...state.editLegalEntity,
      controls: {
        ...state.editLegalEntity.controls,
        [action.payload.name]: action.payload.value,
      },
    },
  }),

  [commonsTypes.CHANGE_EDIT_LEGAL_ENTITY_FORM]: (state, action) => ({
    ...state,
    editLegalEntity: {
      ...state.editLegalEntity,
      form: {
        ...state.editLegalEntity.form,
        [action.payload.name]: action.payload.value,
      },
    },
  }),

  [commonsTypes.CHANGE_EDIT_REPRESENTATIVES_MODAL_CONTROLS]: (state, action) => ({
    ...state,
    editLegalEntity: {
      ...state.editLegalEntity,
      representativesControls: {
        ...state.editLegalEntity.representativesControls,
        [action.payload.name]: action.payload.value,
      },
    },
  }),

  [commonsTypes.CHANGE_EDIT_LEGAL_POWERS_MODAL_CONTROLS]: (state, action) => ({
    ...state,
    editLegalEntity: {
      ...state.editLegalEntity,
      legalPowersControls: {
        ...state.editLegalEntity.legalPowersControls,
        [action.payload.name]: action.payload.value,
      },
    },
  }),

  [HomeTypes.GET_DISCREPANCIES_SUCCESS]: mergeObjects,
  [LastDocumentsTypes.GET_DOCUMENTS_SUCCESS]: mergeObjects,
  [DiscrepancyDetailsTypes.GET_DISCREPANCY_SUCCESS]: mergeObjects,
  [DiscrepancyDetailsTypes.GET_HEARING_PARTICIPATIONS_FILE_SUCCESS]: mergeObjects,
  [DiscrepancyDetailsTypes.DOWNLOAD_ALL_FILES_REQUEST]: mergeObjects,
  [DiscrepancyDetailsTypes.MAKE_HEARING_PARTICIPATIONS_FILE_SUCCESS]: mergeObjects,
  [DiscrepancyDetailsTypes.CREATE_DOCUMENT_SUCCESS]: mergeObjects,
  [DiscrepancyDetailsTypes.COPY_FILES_TO_DISCREPANCIES_SUCCESS]: mergeObjects,
  [DiscrepancyDetailsTypes.UPDATE_DOCUMENTS_VALUES_SUCCESS]: mergeObjects,
  [GET_STATE_REPLACE_FROM_API_SUCCESS]: replaceObjects,
  [commonsTypes.LOGOUT]: () => ({
    ...initialState,
  }),
  [DiscrepancyDetailsTypes.DELETE_DOCUMENT_SUCCESS]: deleteObject,
  [DiscrepancyDetailsTypes.NOTIFICATE_DISCREPANCY_SUCCESS]: mergeObjects,
  [commonsTypes.TOGGLE_UPDATE_DISCREPANCY_MODAL]: (state, action) => ({
    ...state,
    openUpdateDiscrepancyDialog: action.payload.open,
  }),
  [commonsTypes.UPDATE_DISCREPANCY]: (state) => toggleUpdateDiscrepancyLoader(state, true),
  [commonsTypes.UPDATE_DISCREPANCY_FAIL]: (state) => toggleUpdateDiscrepancyLoader(state, false),
  [commonsTypes.UPDATE_DISCREPANCY_SUCCESS]: (state, action) =>
    mergeObjects(state, action, (newState) => ({
      ...newState,
      openUpdateDiscrepancyDialog: false,
      updateDiscrepancyLoader: false,
    })),
  [DiscrepancyDetailsTypes.UPDATE_ATTACHMENT_PART_SUCCESS]: mergeObjects,
  [DiscrepancyDetailsTypes.CREATE_ATTACHMENT_PART_SUCCESS]: mergeObjects,
  [DiscrepancyDetailsTypes.DELETE_ATTACHMENT_PART_SUCCESS]: deleteObject,
  [DiscrepancyDetailsTypes.UPDATE_ATTACHMENT_FILES_SUCCESS]: mergeObjects,
  [DiscrepancyDetailsTypes.CREATE_AUDIENCE_DOCUMENT_SUCCESS]: mergeObjects,
  [AudiencesCalendarTypes.FETCH_AUCIENCES_SUCCESS]: mergeObjects,
  [AudiencesCalendarTypes.SUSCRIBE_IN_HEARING_SUCCESS]: mergeObjects,
  [AudiencesCalendarTypes.UPDATE_SUSCRIBE_IN_HEARING_SUCCESS]: mergeObjects,
  [commonsTypes.SET_LEGAL_ENTITY_ERROR_FORMS]: (state, action) => ({
    ...state,
    registerLegalEntity: {
      ...state.registerLegalEntity,
      formErrors: {
        ...state.registerLegalEntity.formErrors,
        ...action.payload,
      },
    },
  }),
  [commonsTypes.SET_NATURAL_ENTITY_ERROR_FORMS]: (state, action) => ({
    ...state,
    registerNaturalEntity: {
      ...state.registerNaturalEntity,
      formErrors: {
        ...state.registerNaturalEntity.formErrors,
        ...action.payload,
      },
    },
  }),
  [commonsTypes.DELETE_DISCREPANCY]: (state) => ({
    ...state,
    loaders: {
      ...state.loaders,
      deleteDiscrepancy: true,
    },
  }),
  [commonsTypes.DELETE_DISCREPANCY_SUCCESS]: (state, action) =>
    deleteObjectAndMutateState(state, action, (newState) => ({
      ...newState,
      loaders: {
        ...state.loaders,
        deleteDiscrepancy: false,
      },
    })),
  [commonsTypes.DELETE_DISCREPANCY_FAIL]: (state) => ({
    ...state,
    loaders: {
      ...state.loaders,
      deleteDiscrepancy: false,
    },
  }),
  [DiscrepancyDetailsTypes.CREATE_HEARING_PRESENTATION_SUCCESS]: mergeObjects,
  [commonsTypes.OPEN_CONFIRM_DELETE_LEGAL_POWER_DIALOG]: (state, action) => ({
    ...state,
    editLegalEntity: {
      ...state.editLegalEntity,
      legalPowersControls: {
        ...state.editLegalEntity.legalPowersControls,
        openConfirmDialog: {
          ...state.editLegalEntity.legalPowersControls.openConfirmDialog,
          isOpen: true,
          ...action.payload,
        },
      },
    },
  }),
  [commonsTypes.CLOSE_CONFIRM_DELETE_LEGAL_POWER_DIALOG]: (state) => ({
    ...state,
    editLegalEntity: {
      ...state.editLegalEntity,
      legalPowersControls: {
        ...state.editLegalEntity.legalPowersControls,
        openConfirmDialog: {
          ...state.editLegalEntity.legalPowersControls.openConfirmDialog,
          ...initialState.editLegalEntity.legalPowersControls.openConfirmDialog,
        },
      },
    },
  }),
  [commonsTypes.DELETE_LEGAL_POWER]: (state) => ({
    ...state,
    editLegalEntity: {
      ...state.editLegalEntity,
      loaders: {
        ...state.editLegalEntity.loaders,
        legalPowers: {
          ...state.editLegalEntity.loaders.legalPowers,
          delete: true,
        },
      },
    },
  }),
  [commonsTypes.DELETE_LEGAL_POWER_SUCCESS]: (state, action) =>
    deleteObjectAndMutateState(state, action, (newState) => ({
      ...newState,
      editLegalEntity: {
        ...state.editLegalEntity,
        legalPowersControls: {
          ...state.editLegalEntity.legalPowersControls,
          openConfirmDialog: {
            ...state.editLegalEntity.legalPowersControls.openConfirmDialog,
            ...initialState.editLegalEntity.legalPowersControls.openConfirmDialog,
          },
        },
        loaders: {
          ...state.editLegalEntity.loaders,
          legalPowers: {
            ...state.editLegalEntity.loaders.legalPowers,
            ...initialState.editLegalEntity.loaders.legalPowers,
          },
        },
      },
    })),
  [commonsTypes.DELETE_LEGAL_POWER_FAIL]: (state) => ({
    ...state,
    editLegalEntity: {
      ...state.editLegalEntity,
      loaders: {
        ...state.editLegalEntity.loaders,
        legalPowers: {
          ...state.editLegalEntity.loaders.legalPowers,
          ...initialState.editLegalEntity.loaders.legalPowers,
        },
      },
    },
  }),
  [commonsTypes.CREATE_LEGAL_POWER]: (state) => ({
    ...state,
    editLegalEntity: {
      ...state.editLegalEntity,
      loaders: {
        ...state.editLegalEntity.loaders,
        legalPowers: {
          ...state.editLegalEntity.loaders.legalPowers,
          create: true,
        },
      },
    },
  }),

  [commonsTypes.CREATE_LEGAL_POWER_SUCCESS]: (state, action) =>
    mergeObjects(state, action, (newState) => ({
      ...newState,
      editLegalEntity: {
        ...state.editLegalEntity,
        loaders: {
          ...state.editLegalEntity.loaders,
          legalPowers: {
            ...state.editLegalEntity.loaders.legalPowers,
            ...initialState.editLegalEntity.loaders.legalPowers,
          },
        },
      },
    })),
  [commonsTypes.CREATE_LEGAL_POWER_FAIL]: (state) => ({
    ...state,
    editLegalEntity: {
      ...state.editLegalEntity,
      loaders: {
        ...state.editLegalEntity.loaders,
        legalPowers: {
          ...state.editLegalEntity.loaders.legalPowers,
          ...initialState.editLegalEntity.loaders.legalPowers,
        },
      },
    },
  }),
  [commonsTypes.OPEN_GENERAL_ALERT]: (state, action) => ({
    ...state,
    generalAlert: {
      ...state.generalAlert,
      open: true,
      ...action.payload,
    },
  }),
  [commonsTypes.CLOSE_GENERAL_ALERT]: (state, action) => ({
    ...state,
    generalAlert: {
      ...state.generalAlert,
      ...action.payload,
    },
  }),
  [VALIDATE_CAPTCHA_SUCCESS]: (state, action) => ({
    ...state,
    aprovedCaptcha: action.payload.aprovedCaptcha,
  }),
  [RESET_CAPTCHA]: (state) => ({
    ...state,
    aprovedCaptcha: false,
    showCaptchaError: false,
  }),
  [SHOW_CAPTCHA_ERROR]: (state, action) => ({
    ...state,
    showCaptchaError: action.payload,
  }),
});

export default app;
