import camelize from 'camelcase-keys-deep';

export default class HearingParticipation {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  entity() {
    return this.env().Entity.getById(this.entityId);
  }

  hearing() {
    return this.env().Hearing.getById(this.hearingId);
  }

  representative() {
    return this.env().Representative.getById(this.representativeId);
  }
}
