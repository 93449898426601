import color from 'utils/colors';

const styles = {
  title: {
    fontSize: 'h6.fontSize',
    fontWeight: 'bold',
    color: color.cooper,
    textTransform: 'uppercase',
  },
  dialogContent: {
    // maxWidth: '500px',
    '& .formControlsContainer': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      /* maxHeight: '500px', */
      height: 'auto',
      minHeight: '380px',
      p: 1,
    },
  },
};

export default styles;
