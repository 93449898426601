import color from 'utils/colors';

const lastDocStyles = () => ({
  typography: {
    fontSize: 'body2',
    fontWeight: 'medium',
    color: color.cooper,
    pt: 2,
  },
});

export default lastDocStyles;
