import React from 'react';

/* Material UI */
import { Chip, ListItemButton, ListItemText } from '@mui/material';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';

/* React Router */
import { useHistory } from 'react-router-dom';

/* Project */
import { useSelector } from 'react-redux';
import documentItemStyles from './DocumentItem.styles';
import color from '../../../utils/colors';

/* Extra */
const useStyles = makeStyles(documentItemStyles);

function DocumentItem({ document, legalEntity, discrepancy }) {
  const classes = useStyles();
  const history = useHistory();
  const { lastDocuments: lastDocumentsState } = useSelector((state) => state);
  const { chipsIds } = lastDocumentsState;
  const { index, interestedParty, title, documentType } = chipsIds;

  const documentStyle = {
    color: color.cooper,
    background: `linear-gradient(to right, ${color.cooperRgba} 1%, ${color.whiteRgba} 1%)`,
    boxShadow: 4,
    borderRadius: '4px',
    height: '50px',
  };

  const handleRedirection = (evt) => {
    evt.preventDefault();
    history.push(`discrepancies/${discrepancy.id}/expedient/${document.id}`);
  };

  const interestedPartyChip = interestedParty.filter((ip) => document.id === ip.id).length ? (
    <Chip label="Parte e Interesada" size="small" className={classes.chip} />
  ) : null;

  const indexChip = index.filter((idx) => document.id === idx.id).length ? (
    <Chip label="Indice Documento" size="small" className={classes.chip} />
  ) : null;

  const titleChip = title.filter((t) => document.id === t.id).length ? (
    <Chip label="Título Documento" size="small" className={classes.chip} />
  ) : null;

  const documentTypeChip = documentType.filter((dt) => document.id === dt.id).length ? (
    <Chip label="Tipo Documento" size="small" className={classes.chip} />
  ) : null;

  const itemLabel = `${discrepancy.number}-${new Date(discrepancy.createdAt).getFullYear()} ${
    discrepancy.cover
  } - ${legalEntity.names}`;

  return (
    <Grid item xs={12}>
      <ListItemButton
        id={document.id}
        className={`${classes.listItemButton} list-item-button`}
        component="a"
        onClick={handleRedirection}
        sx={documentStyle}>
        <ListItemText
          secondary={document.title}
          primary={itemLabel}
          primaryTypographyProps={{ className: classes.primaryTextStyle }}
          secondaryTypographyProps={{ className: classes.secondaryTextStyle }}
        />
        {interestedPartyChip}
        {indexChip}
        {titleChip}
        {documentTypeChip}
      </ListItemButton>
    </Grid>
  );
}

export default DocumentItem;
