// @ts-nocheck
import React, { useState, useEffect } from 'react';
// material
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
// utils
import moment from 'moment';
import { truthty } from 'utils/functions';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
function DatePicker(props) {
  const {
    value: propsValue,
    disableBox,
    fullWidth,
    inputId,
    label,
    error,
    helperText,
    onChange,
    onCheckboxChange,
    InputProps,
    variant,
  } = props;
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  const [active, setActive] = useState(false);

  useEffect(() => {
    // if (truthty(propsValue)) {
    //   setDate(moment(propsValue).format('YYYY-MM-DD'));
    // } else {
    //   setDate(moment().format('YYYY-MM-DD'));
    // }
    if (truthty(propsValue)) {
      setDate(moment(propsValue).format('YYYY-MM-DD'));
    } else {
      setDate('');
    }
  }, [propsValue]);

  const changeControls = (event) => {
    setDate(event.target.value);
    onChange(event, !active && disableBox ? null : moment(event.target.value).toISOString());
  };

  const changeControlsDesktopPicker = (event) => {
    const date = event ? moment(event._d).format('YYYY-MM-DD') : null;
    setDate(date);
    onChange(null, !active && disableBox ? null : date);
  };

  const disabledOnChange = (event) => {
    setActive(event.target.checked);
    onCheckboxChange(!event.target.checked && disableBox ? null : moment(date).toISOString());
  };

  const CalendarIcon = () => {
    return <CalendarMonthIcon sx={{ color: '#A68095' }} />;
  };

  return (
    <Grid item container>
      {disableBox && (
        <Grid item xs={2}>
          <Checkbox checked={active} onChange={disabledOnChange} />
        </Grid>
      )}
      <Grid item xs>
        {variant === 'standard' ? (
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
              inputFormat="DD/MM/YYYY"
              disabled={!active && disableBox}
              value={date || null}
              onChange={changeControlsDesktopPicker}
              id={inputId || 'date'}
              label={label || 'Fecha'}
              components={{
                OpenPickerIcon: CalendarIcon,
              }}
              renderInput={(params) => {
                const newParams = {
                  ...params,
                  variant: 'standard',
                  size: 'small',
                  fullWidth: true,
                  error: error,
                  helperText: helperText,
                };
                return (
                  <TextField
                    {...newParams}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                );
              }}
            />
          </LocalizationProvider>
        ) : (
          <TextField
            size="small"
            disabled={!active && disableBox}
            value={date}
            onChange={changeControls}
            fullWidth={fullWidth}
            id={inputId || 'date'}
            label={label || 'Fecha'}
            error={error}
            helperText={helperText}
            variant={variant || 'outlined'}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            color="secondary"
            InputProps={{ ...InputProps }}
          />
        )}
      </Grid>
    </Grid>
  );
}

export default DatePicker;
