import React, { useEffect, useMemo, useState } from 'react';

/* Material UI */
import { Grid } from '@mui/material';

/* React Redux */
import { useDispatch, useSelector } from 'react-redux';

/* React Router */
import { useHistory } from 'react-router-dom';

/* Moment */
import moment from 'moment';

/* Calendar */
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';

/* Project */
import PageLoader from 'commons/components/PageLoader';
import { fetchHearings } from 'screens/AudiencesCalendar/AudiencesCalendar.actions';
import AudienceDialog from 'screens/AudiencesCalendar/components/AudienceDialog';
import { reselectObjects } from 'utils/helper';

moment.locale('es-mx', {
  week: {
    dow: 1,
    doy: 1,
  },
});

const localizer = momentLocalizer(moment);

function AudiencesCalendar() {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(fetchHearings());
  }, []);

  const [hearing, setHearing] = useState(null);

  const { audiencesCalendar: audienceCalendarState, app: appState } = useSelector((state) => state);

  const {
    loaders: { hearings },
  } = audienceCalendarState;

  const { user: currentUser, objects: objectsState } = appState;

  const objects = useMemo(() => reselectObjects(objectsState), [objectsState]);
  const { hearingParticipations, representatives } = objects;

  if (hearings) {
    return <PageLoader />;
  }

  const userModel = currentUser ? objects.users[currentUser.id] : null;
  const currentEntity = userModel ? userModel.entity() : null;
  const currentRepresentatives = currentEntity
    ? Object.values(representatives).filter(
        (representItem) => representItem.naturalEntityId === currentEntity.id,
      )
    : [];

  const events = Object.values(objects.hearings).map((hearingItem) => ({
    model: hearingItem,
    start: moment(hearingItem.date).toDate(),
    end: moment(hearingItem.date).add(1, 'hour').toDate(),
    title: `Disc. N°${hearingItem?.discrepancy()?.number}-${new Date(
      hearingItem.discrepancy().createdAt,
    ).getFullYear()}`,
  }));

  const onSelectEvent = (hearingSelected) => {
    if (!currentUser) return history.push('/login');
    return setHearing(hearingSelected);
  };

  const closeAudienceDialog = () => setHearing(null);

  const { entities, legalEntities } = objects;

  const entity = userModel ? Object.values(entities).filter((e) => e.userId === userModel.id) : [];

  const legalEntity = entity.length
    ? Object.values(legalEntities).filter((le) => le.entityId === entity[0].id)
    : [];

  const shouldShowButtonsToRegister = legalEntity.length === 0;

  return (
    <Grid container>
      <Calendar
        messages={{
          date: 'Día',
          time: 'Hora',
          event: 'Audiencia',
          next: 'Siguiente',
          previous: 'Anterior',
          today: 'Hoy',
          month: 'Mes',
          week: 'Semana',
          day: 'Día',
          work_week: 'Semana laboral',
          allDay: 'Todo el día',
          yesterday: 'Ayer',
          tomorrow: 'Mañana',
          noEventsInRange: 'No hay eventos en el rango seleccionado',
          showMore: function showMore(total) {
            return `+${total} audiencias`;
          },
        }}
        onSelectEvent={onSelectEvent}
        localizer={localizer}
        defaultDate={new Date()}
        defaultView="month"
        events={events}
        style={{ height: '90vh', width: '90vw', margin: 'auto', fontFamily: 'Roboto' }}
      />
      {hearing ? (
        <AudienceDialog
          open
          onClose={closeAudienceDialog}
          hearing={hearing}
          hearingParticipations={hearingParticipations}
          shouldShowButtonsToRegister={shouldShowButtonsToRegister}
          currentEntity={currentEntity}
          currentRepresentatives={currentRepresentatives}
        />
      ) : null}
    </Grid>
  );
}

export default AudiencesCalendar;
