// @ts-nocheck
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import { TextField, Typography } from '@mui/material';
import color from 'utils/colors';
import PrimaryButton from 'commons/components/PrimaryButton/PrimaryButton';
import SecondaryButton from 'commons/components/SecondaryButton/SecondaryButton';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import {
  changeNaturalEntityControls,
  changeNaturalEntityForm,
  createNaturalEntity,
  resetCaptcha,
  setNaturalEntityErrorForms,
  showCaptchaError,
} from 'commons/commons.actions';
import { validateEmail, validateRut } from 'utils/functions';
import CaptchaDialog from '../CaptchaDialog/CaptchaDialog';
import { VALIDATE_CAPTCHA } from 'commons/commons.reducer';

function RegisterLegalEntity() {
  const { registerNaturalEntity, aprovedCaptcha } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const { controls, form, loaders, formErrors } = registerNaturalEntity;

  const handleClose = () => {
    dispatch(changeNaturalEntityControls({ name: 'openDialog', value: false }));
    dispatch(resetCaptcha());
  };

  const handleChange = ({ target: { name, value } }) => {
    dispatch(changeNaturalEntityForm({ name, value }));
  };

  const handleRutChange = ({ target: { name, value } }) => {
    dispatch(changeNaturalEntityForm({ name, value }));
    const validRut = validateRut(value);
    if (!validRut) {
      dispatch(
        setNaturalEntityErrorForms({
          rut: {
            isValid: false,
            errorMessage: 'RUT inválido',
          },
        }),
      );
    } else {
      dispatch(
        setNaturalEntityErrorForms({
          rut: {
            isValid: true,
            errorMessage: '',
          },
        }),
      );
    }
  };

  const handleEmailChange = ({ target: { name, value } }) => {
    dispatch(changeNaturalEntityForm({ name, value }));
    const validEmail = validateEmail(value);
    if (!validEmail) {
      dispatch(
        setNaturalEntityErrorForms({
          email: {
            isValid: false,
            errorMessage: 'email inválido',
          },
        }),
      );
    } else {
      dispatch(
        setNaturalEntityErrorForms({
          email: {
            isValid: true,
            errorMessage: '',
          },
        }),
      );
    }
  };

  const submitCaptcha = () => {
    const siteKey = process.env.REACT_APP_CAPTCHA_SITE_KEY;
    const secretKey = process.env.REACT_APP_CAPTCHA_SECRET_KEY;
    try {
      grecaptcha.ready(function () {
        grecaptcha.execute(siteKey, { action: 'submit' }).then(function (token) {
          dispatch({ type: VALIDATE_CAPTCHA, payload: { secretKey, token } });
        });
      });
    } catch (error) {
      dispatch({ type: SET_ERROR, msg: 'Error al obtener captcha' });
    }
  };

  useEffect(() => {
    if (controls.openDialog) {
      submitCaptcha();
    }

    return () => {
      dispatch(resetCaptcha());
    };
    // eslint-disable-next-line
  }, [controls.openDialog]);

  const submitForm = () => {
    if (aprovedCaptcha) {
      return dispatch(
        createNaturalEntity({
          names: form.names,
          surnames: form.surnames,
          rut: form.rut,
          email: form.email,
          phone: form.phone,
          password: form.password,
          confirmPassword: form.confirmPassword,
        }),
      );
    } else {
      return dispatch(showCaptchaError({ show: !aprovedCaptcha }));
    }
  };

  const title = (
    <Typography
      sx={{
        fontWeight: 'regular',
        color: color.cooper,
        fontSize: 20,
      }}>
      Registro nueva persona natural
    </Typography>
  );

  const namesTextField = (
    <TextField
      fullWidth
      size="small"
      variant="outlined"
      label="Nombres"
      name="names"
      onChange={handleChange}
      color="secondary"
      value={form.names}
    />
  );

  const surnamesTextField = (
    <TextField
      fullWidth
      size="small"
      variant="outlined"
      label="Apellidos"
      name="surnames"
      onChange={handleChange}
      color="secondary"
      value={form.surnames}
    />
  );

  const rutTextField = (
    <TextField
      fullWidth
      size="small"
      variant="outlined"
      label="RUT"
      name="rut"
      onChange={handleRutChange}
      color="secondary"
      value={form.rut}
      error={!formErrors.rut.isValid}
      helperText={formErrors.rut.errorMessage}
    />
  );

  const emailTextField = (
    <TextField
      fullWidth
      size="small"
      variant="outlined"
      label="Correo"
      name="email"
      onChange={handleEmailChange}
      color="secondary"
      value={form.email}
      error={!formErrors.email.isValid}
      helperText={formErrors.email.errorMessage}
    />
  );

  const phoneTextField = (
    <TextField
      fullWidth
      size="small"
      variant="outlined"
      label="Teléfono"
      name="phone"
      onChange={handleChange}
      color="secondary"
      value={form.phone}
    />
  );

  const passwordTextField = (
    <TextField
      inputProps={{ autoComplete: 'off' }}
      fullWidth
      size="small"
      type="password"
      variant="outlined"
      label="Contraseña"
      name="password"
      onChange={handleChange}
      color="secondary"
      value={form.password}
    />
  );

  const confirmPasswordTextField = (
    <TextField
      inputProps={{ autoComplete: 'off' }}
      fullWidth
      size="small"
      type="password"
      variant="outlined"
      label="Confirmar contraseña"
      name="confirmPassword"
      onChange={handleChange}
      color="secondary"
      value={form.confirmPassword}
    />
  );

  const disableSave =
    !form.names ||
    !form.surnames ||
    !form.rut ||
    !form.email ||
    !form.phone ||
    !form.password ||
    !form.phone ||
    !form.confirmPassword ||
    !formErrors.rut.isValid ||
    !formErrors.email.isValid;

  return (
    <Dialog
      open={controls.openDialog}
      onClose={handleClose}
      PaperProps={{ sx: { minWidth: { lg: 700 }, minHeight: 200 } }}>
      <Box sx={{ m: 3 }}>
        <Grid container justifyContent="center" alignItems="center" sx={{ maxHeight: '80vh' }}>
          <Grid item container xs={12}>
            <form onSubmit={() => {}}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {title}
                </Grid>
                <Grid item xs={12}>
                  {namesTextField}
                </Grid>
                <Grid item xs={12}>
                  {surnamesTextField}
                </Grid>
                <Grid item xs={12}>
                  {rutTextField}
                </Grid>
                <Grid item xs={12}>
                  {emailTextField}
                </Grid>
                <Grid item xs={12}>
                  {phoneTextField}
                </Grid>
                <Grid item xs={6}>
                  {passwordTextField}
                </Grid>
                <Grid item xs={6}>
                  {confirmPasswordTextField}
                </Grid>
                <Grid item xs={12}>
                  <CaptchaDialog />
                </Grid>
                <Grid item xs={6} textAlign="start" sx={{ mt: 2 }}>
                  <SecondaryButton text="Cancelar" onClick={handleClose} />
                </Grid>
                <Grid item xs={6} textAlign="end" sx={{ mt: 2 }}>
                  <PrimaryButton
                    loader={loaders.create}
                    onClick={submitForm}
                    text="Aceptar"
                    disabled={disableSave}
                  />
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
}

export default RegisterLegalEntity;
