import { takeLatest, spawn, put } from 'redux-saga/effects';

/* Project */
import apiRequest, { apiSuccess, update } from 'utils/api';
import { SET_ERROR, SET_SUCCESS } from 'commons/commons.reducer';
import AudiencesCalendarTypes from 'screens/AudiencesCalendar/AudiencesCalendar.types';

function* fetchHearings() {
  yield takeLatest(AudiencesCalendarTypes.FETCH_AUCIENCES, function* deleteAttachmentPart() {
    const response = yield apiRequest(`api/v1/hearings`, { method: 'get' }, true, true);

    if (response.error) {
      yield put(apiSuccess(AudiencesCalendarTypes.FETCH_AUCIENCES_FAIL));
      yield put({ type: SET_ERROR, payload: { msg: response.errorMsg } });
    } else {
      yield put(apiSuccess(AudiencesCalendarTypes.FETCH_AUCIENCES_SUCCESS, response));
    }
  });
}

function* inscribeInHearing() {
  yield takeLatest(
    AudiencesCalendarTypes.SUSCRIBE_IN_HEARING,
    function* deleteAttachmentPart(action) {
      const body = JSON.stringify({ ...action.payload });

      const response = yield apiRequest(
        `api/v1/hearingParticipations`,
        { method: 'post', body },
        true,
        true,
      );

      if (response.error) {
        yield put(apiSuccess(AudiencesCalendarTypes.SUSCRIBE_IN_HEARING_FAIL));
        yield put({ type: SET_ERROR, payload: { msg: response.errorMsg } });
      } else {
        yield put(apiSuccess(AudiencesCalendarTypes.SUSCRIBE_IN_HEARING_SUCCESS, response));
        yield put({
          type: SET_SUCCESS,
          payload: { msg: 'Inscripción en audiencia exitosa.' },
        });
      }
    },
  );
}

function* updateHearingParticipation() {
  yield takeLatest(
    AudiencesCalendarTypes.UPDATE_SUSCRIBE_IN_HEARING,
    function* updateHearingP(action) {
      const { id } = action.payload;
      const body = { ...action.payload };

      const response = yield update(`api/v1/hearingParticipations/${id}`, body);

      if (response.error) {
        yield put(apiSuccess(AudiencesCalendarTypes.UPDATE_SUSCRIBE_IN_HEARING_FAIL));
        yield put({ type: SET_ERROR, payload: { msg: response.errorMsg } });
      } else {
        yield put(apiSuccess(AudiencesCalendarTypes.UPDATE_SUSCRIBE_IN_HEARING_SUCCESS, response));
        yield put({
          type: SET_SUCCESS,
          payload: { msg: 'Modificacion de existosa de Inscripcion' },
        });
      }
    },
  );
}

function* fetchUserHearing() {
  yield takeLatest(
    AudiencesCalendarTypes.FETCH_USER_HEARING,
    function* deleteAttachmentPart(action) {
      const { hearingId, entityId } = action.payload;
      const response = yield apiRequest(
        `api/v1/hearings/user-hearing?hearingId=${hearingId}&entityId=${entityId}`,
        { method: 'get' },
        true,
        true,
      );

      if (response.error) {
        yield put(apiSuccess(AudiencesCalendarTypes.FETCH_USER_HEARING_FAIL));
        yield put({ type: SET_ERROR, payload: { msg: response.errorMsg } });
      } else {
        yield put(apiSuccess(AudiencesCalendarTypes.FETCH_USER_HEARING_SUCCESS, response));
      }
    },
  );
}

export default function* AudiencesCalendarSaga() {
  yield spawn(fetchHearings);
  yield spawn(inscribeInHearing);
  yield spawn(updateHearingParticipation);
  yield spawn(fetchUserHearing);
}
