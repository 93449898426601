/* REACT */
import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

/* Material UI */
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  InputAdornment,
  IconButton,
  Grid,
  Alert,
  Typography,
} from '@mui/material';
import { FileUpload } from '@mui/icons-material';
import WarningIcon from '@mui/icons-material/Warning';

/* REACT-REDUX */
import { useDispatch } from 'react-redux';

/* PROJECT */
import { changeForm } from 'screens/DiscrepancyDetail/DiscrepancyDetails.actions';
import CustomDateTimePicker from 'commons/components/CustomDateTimePicker/CustomDateTimePicker';
import CustomTextField from 'commons/components/CustomTextField/CustomTextField';
import color from 'utils/colors';
import { appSetError } from 'commons/commons.actions';
import { isZippedFile } from 'utils/functions';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';

function DocumentStepOne(props) {
  const {
    form,
    objects,
    interestedPartyId,
    currentUser,
    hearingFile,
    setHearingFile,
    documentCode = 'default',
    discrepancyHearing,
    dateValidations,
  } = props;

  // 4 = Comunicados, 5 = audencias
  // const { documentTypeId } = form;

  const dispatch = useDispatch();

  const publicDocumentCodes = [
    'presentations',
    'interested_presentation',
    'observations',
    'hearing_presentation',
    'withdrawal',
    'response_to_queries',
    'others',
  ];

  const handleChange = ({ target: { name, value } }) => {
    dispatch(changeForm({ name, value }));
  };

  const { representatives, users, documentTypes } = objects;

  const currentUserModel = users[currentUser?.id];
  const currentEntityModel = currentUserModel?.entity();
  const currentUserType = currentUser?.type;

  const avaliableRepresentatives = currentEntityModel
    ? Object.values(representatives).filter((r) => r.naturalEntityId === currentEntityModel.id)
    : [];

  const legalEntities = avaliableRepresentatives.map((representative) =>
    representative.legalEntity(),
  );

  const legalOptions = legalEntities
    .filter((legalEntity) => legalEntity) // I have no idea why this comes with nulls
    .map((legalEntity) => (
      <MenuItem key={legalEntity.id} value={legalEntity.id}>
        {legalEntity.entity().names}
      </MenuItem>
    ));

  const handleHearingFileChange = ({ target }) => {
    const currentFile = target.files[0];
    if (!currentFile) {
      dispatch(appSetError({ msg: 'No se encuentra un archivo.' }));
      return;
    }
    if (!isZippedFile(currentFile.name)) {
      setHearingFile(currentFile);
    } else {
      setHearingFile(null);
      dispatch(appSetError({ msg: 'El documento no puede ser un archivo comprimido' }));
    }
  };

  const handleHourChange = (dateVal) => {
    handleChange({
      target: {
        name: 'dateHourAudience',
        value: dateVal,
      },
    });
  };

  const handleTimeHourChange = (name, dateVal) => {
    handleChange({
      target: {
        name: name,
        value: dateVal,
      },
    });
  };

  const isAdmin = currentUserType === 'admin' ? false : interestedPartyId;

  useEffect(() => {
    if (!isAdmin) {
      dispatch(changeForm({ name: 'position', value: 'panel de expertos' }));
    }
  }, []);

  const { id: discrepancyId } = useParams();
  const currentDiscrepancy = discrepancyId ? objects.discrepancies[discrepancyId] : null;
  const documentTypePresentationUserId = useMemo(() => {
    const documents = currentDiscrepancy.documents();
    const documentTypePresentation = documents.filter(
      (document) => document?.documentType()?.name === 'Presentación de discrepancia',
    );

    const firstCreatedDocument = documentTypePresentation.sort(function (a, b) {
      return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
    })?.[0];

    const user = firstCreatedDocument
      ? firstCreatedDocument.interestedParty().legalEntity().userId
      : false;

    return user;
  }, [currentDiscrepancy]);

  const documentTypesValidation = useMemo(() => {
    if (currentUser && currentUser.id === documentTypePresentationUserId) {
      return Object.values(documentTypes);
    } else {
      return Object.values(documentTypes).filter(
        (item) => item.name !== 'Presentación de discrepancia',
      );
    }
  }, [currentUser, currentDiscrepancy]);

  const guidelinesDocument = useMemo(() => {
    const documents = currentDiscrepancy.documents();
    const exist = documents.find((item) => {
      const documentType = item.documentType();
      return documentType.code === 'announcements_and_guidelines';
    });
    return exist;
  }, [currentDiscrepancy]);

  const presentationDocument = useMemo(() => {
    const documents = currentDiscrepancy.documents();
    const exist = documents.find((item) => {
      const documentType = item.documentType();
      return documentType.code === 'presentations';
    });
    return exist;
  }, [currentDiscrepancy]);

  // Deberia filtrarse en el backend ?????
  const documentTypeOptions =
    currentUserType === 'user'
      ? documentTypesValidation
          .filter((el) => publicDocumentCodes.includes(el.code))
          .map((documentType) => (
            <MenuItem key={documentType.id} value={documentType.id}>
              {documentType.name}
            </MenuItem>
          ))
      : Object.values(documentTypes)
          .filter((dt) => {
            if (dt.code === 'audiences' && discrepancyHearing?.url) {
              return false;
            }
            return dt;
          })
          .filter((dt) => (guidelinesDocument ? dt.id !== guidelinesDocument.documentTypeId : true))
          .filter((dt) =>
            presentationDocument ? dt.id !== presentationDocument.documentTypeId : true,
          )
          .map((documentType) => (
            <MenuItem key={documentType.id} value={documentType.id}>
              {documentType.name}
            </MenuItem>
          ));

  const documentTypeField = (
    <FormControl fullWidth size="small" variant="standard">
      <InputLabel id="documentTypeId">Tipo documento</InputLabel>
      <Select
        labelId="documentTypeId"
        name="documentTypeId"
        value={form.documentTypeId}
        label="Tipo documento"
        onChange={handleChange}>
        <MenuItem disabled value="default">
          Selecciona una opción
        </MenuItem>
        {documentTypeOptions}
      </Select>
    </FormControl>
  );

  const titleField = (
    <TextField
      fullWidth
      size="small"
      variant="standard"
      label="Título"
      name="title"
      onChange={handleChange}
      color="secondary"
      value={form.title}
    />
  );

  const MinWidth = (props) => (
    <Grid
      xs={12}
      sm={6}
      container
      direction={'row'}
      justifyContent={'center'}
      sx={{ padding: '10px' }}>
      {props.component}
    </Grid>
  );

  const indexField = (
    <TextField
      multiline
      rows={6}
      fullWidth
      size="small"
      variant="standard"
      label="Índice del documento"
      name="index"
      onChange={handleChange}
      color="secondary"
      value={form.index}
    />
  );

  const positionField = !isAdmin ? null : (
    <TextField
      fullWidth
      size="small"
      variant="standard"
      label="Cargo en la empresa"
      name="position"
      onChange={handleChange}
      color="secondary"
      value={form.position}
    />
  );

  const legalEntityField = (
    <TextField
      size="small"
      id="outlined-select-legal-entities"
      select
      fullWidth
      label="En representación de"
      name="legalEntityId"
      onChange={handleChange}
      color="secondary"
      variant="standard"
      value={form.legalEntityId}>
      {legalOptions}
    </TextField>
  );

  const dateHourAudienceField = (
    <CustomDateTimePicker
      label="Fecha audiencia"
      value={form.dateHourAudience}
      onChange={handleHourChange}
      alternativeStyles
      sx={{
        width: '100%',
      }}
    />
  );

  const dateAudienceField = (
    <CustomDateTimePicker
      label="Fecha audiencia"
      value={form.dateHourAudience}
      onChange={handleHourChange}
      alternativeStyles
      sx={{
        width: '100%',
      }}
    />
  );

  const dateRegistrationDeadline = (
    <CustomDateTimePicker
      label="Fecha Tope Inscripción"
      value={form.dateHourRegistrationDeadline}
      onChange={(value) => handleTimeHourChange('dateHourRegistrationDeadline', value)}
      alternativeStyles
      error={dateValidations.error}
      errorMsg={dateValidations.errorMsg}
      sx={{
        width: '100%',
      }}
    />
  );

  const datePresentationInterestedParty = (
    <CustomDateTimePicker
      label="Fecha Presentación Interesados"
      value={form.dateHourPresentationInterestedParty}
      onChange={(value) => handleTimeHourChange('dateHourPresentationInterestedParty', value)}
      alternativeStyles
      sx={{
        width: '100%',
      }}
    />
  );

  const dateObservationsDeadline = (
    <CustomDateTimePicker
      label="Fecha Tope Observaciones Complementarias"
      value={form.dateHourObservationsDeadline}
      onChange={(value) => handleTimeHourChange('dateHourObservationsDeadline', value)}
      alternativeStyles
      sx={{
        width: '100%',
      }}
    />
  );

  const audienceUrlField = (
    <TextField
      fullWidth
      size="small"
      variant="outlined"
      label="URL Audiencia"
      name="audienceUrl"
      onChange={handleChange}
      color="secondary"
      value={form.audienceUrl}
    />
  );

  const hearingDocumentField = (
    <CustomTextField
      name="hearingFile"
      variant="standard"
      label="Archivo Audiencia"
      value={hearingFile?.name || ''}
      inputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <label htmlFor="hearingFile">
              <input
                accept=".pdf, .pptx, .ppt"
                id="hearingFile"
                name="hearingFile"
                type="file"
                style={{ display: 'none' }}
                onChange={handleHearingFileChange}
              />
              <IconButton sx={{ color: color.blue }} aria-label="hearingFile" component="span">
                <FileUpload />
              </IconButton>
            </label>
          </InputAdornment>
        ),
      }}
    />
  );

  const dialogValidation =
    currentDiscrepancy.presentationDate !== null &&
    currentDiscrepancy.observationsDeadline !== null;

  // eslint-disable-next-line no-shadow
  const formFields = (documentCode) => {
    switch (documentCode) {
      case 'hearing_presentation':
        return hearingDocumentField;
      case 'audiences':
        return audienceUrlField;
      case 'announcements':
        return (
          <>
            {titleField}
            {positionField}
          </>
        );
      case 'reports':
      case 'proceedings':
        return (
          <>
            {titleField}
            {positionField}
          </>
        );
      case 'announcements_and_guidelines':
        return (
          <Grid container direction={'row'} justifyContent={'space-between'}>
            <Grid
              xs={12}
              sm={6}
              container
              direction={'row'}
              justifyContent={'center'}
              sx={{ padding: '10px' }}>
              {documentTypeField}
            </Grid>
            <Grid
              xs={12}
              sm={6}
              container
              direction={'row'}
              justifyContent={'center'}
              sx={{ padding: '10px' }}>
              {titleField}
            </Grid>
            <Grid
              xs={12}
              sm={6}
              container
              direction={'row'}
              justifyContent={'center'}
              sx={{ padding: '10px' }}>
              {dateAudienceField}
            </Grid>
            <Grid
              xs={12}
              sm={6}
              container
              direction={'row'}
              justifyContent={'center'}
              sx={{ padding: '10px' }}>
              {dateRegistrationDeadline}
            </Grid>
            <Grid
              xs={12}
              sm={6}
              container
              direction={'row'}
              justifyContent={'center'}
              sx={{ padding: '10px' }}>
              {datePresentationInterestedParty}
            </Grid>
            <Grid
              xs={12}
              sm={6}
              container
              direction={'row'}
              justifyContent={'center'}
              sx={{ padding: '10px' }}>
              {dateObservationsDeadline}
            </Grid>
          </Grid>
        );
      case 'interested_presentation':
      case 'observations':
      case 'others':
        return (
          <Grid container>
            <Grid
              xs={dialogValidation ? 6 : 12}
              sx={
                dialogValidation
                  ? {
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                    }
                  : null
              }>
              <Grid xs={12} sx={{ mb: '10px' }}>
                {documentTypeField}
              </Grid>
              <Grid xs={12} sx={{ mb: '10px' }}>
                {legalEntityField}
              </Grid>
              <Grid xs={12} sx={{ mb: '10px' }}>
                {titleField}
              </Grid>
              {positionField && (
                <Grid xs={12} sx={{ mb: '10px' }}>
                  {positionField}
                </Grid>
              )}
            </Grid>
            {dialogValidation && (
              <Grid xs={6} sx={{ padding: '10px' }}>
                <Alert icon={false} severity="error">
                  <Grid sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                    <WarningIcon fontSize={'medium'} sx={{ pr: '10px' }} />
                    <Typography sx={{ fontSize: '13px', fontWeight: '600' }}>
                      {`Recuerde que la fechas de tope de Presentación de Interesados es el ${moment(
                        currentDiscrepancy.presentationDate,
                      ).format('DD/MM/YYYY HH:mm')}, luego de ello sólo pueden ingresarse como
                        Otros.`}
                    </Typography>
                  </Grid>
                  <Grid sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                    <WarningIcon fontSize={'medium'} sx={{ pr: '10px' }} />
                    <Typography sx={{ fontSize: '13px', fontWeight: '600' }}>
                      {`Recuerde que la fechas de tope de Observaciones
                        complementarias es el ${moment(
                          currentDiscrepancy.observationsDeadline,
                        ).format(
                          'DD/MM/YYYY HH:mm',
                        )}, luego de ello sólo pueden ingresarse como Otros.`}
                    </Typography>
                  </Grid>
                </Alert>
              </Grid>
            )}
            <Grid xs={12} sx={{ mb: '10px' }}>
              {indexField}
            </Grid>
          </Grid>
        );
      default:
        return (
          <>
            {titleField}
            {positionField}
            {indexField}
          </>
        );
    }
  };

  const omitDefaultFields =
    documentCode !== 'announcements_and_guidelines' &&
    documentCode !== 'interested_presentation' &&
    documentCode !== 'observations' &&
    documentCode !== 'others';

  return (
    <Stack alignItems="center" spacing={2}>
      {omitDefaultFields && documentTypeField}
      {omitDefaultFields && legalEntityField}
      {formFields(documentCode)}
    </Stack>
  );
}

export default DocumentStepOne;
