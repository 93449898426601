import React, { useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material';

import { composedComponent } from 'utils/functions';

// commons
import { appActions } from 'commons/commons.reducer';
import saga from 'commons/commons.saga';

// Routes
import Routes from './components/Routes';

const theme = createTheme({
  palette: {
    primary: {
      main: '#343A40',
      dark: '#5c6166',
      light: '#24282c',
      contrastText: 'white',
    },
    secondary: {
      main: '#B15410',
      dark: '#7b3a0b',
      light: '#c0763f',
      contrastText: 'white',
    },
  },
  typography: {
    fontFamily: 'Roboto',
  },
});

function App(props) {
  const { actions, loadingUser, user } = props;

  const toggleCaptchaBadge = (show) => {
    const badge = document.getElementsByClassName('grecaptcha-badge')[0];
    if (badge && badge instanceof HTMLElement) {
      badge.style.visibility = show ? 'visible' : 'hidden';
    }
  };

  useEffect(() => {
    toggleCaptchaBadge(false);
    actions.fetchInitialState();
  }, []);

  return loadingUser ? (
    <div />
  ) : (
    <ThemeProvider theme={theme}>
      <Routes user={user} loading={loadingUser} />
    </ThemeProvider>
  );
}

export default composedComponent(App, saga, {
  saga: 'sagaApp',
  states: [
    'app.drawerIsOpen',
    'app.errorMsg',
    'app.error',
    'app.successMsg',
    'app.success',
    'app.user',
    'app.loadingUser',
  ],
  actions: [appActions],
});
