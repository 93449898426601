import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import { Environment } from 'model-environment';
import * as Models from '../models';
import { clone } from './functions';

export const actionProps = (actionsObj) => (dispatch) => ({
  actions: bindActionCreators(actionsObj, dispatch),
  dispatch,
});

const getState = (statePortion) => {
  const splitedState = statePortion.split('.');

  return (state) => {
    let tempState = state;

    if (/\./.test(statePortion)) {
      splitedState.forEach((value) => {
        tempState = tempState[value];
      });
    } else {
      tempState = tempState[statePortion];
    }

    return tempState;
  };
};

export const cachedEnvironment = {};
const getSelectors = (key, valueState, state) => ({
  [key]: createSelector([getState(valueState)], (reselectState) => {
    if (key === 'objects') {
      if (!cachedEnvironment.value) {
        cachedEnvironment.value = new Environment(
          { objects: reselectState },
          Models,
        ).parseDB().objects;
      }
      return clone(clone.OBJECT, reselectState, cachedEnvironment.value);
    }
    return reselectState;
  })(state),
});

export const selectState =
  (...statePortion) =>
  (state) =>
    statePortion.reduce((prev, current) => {
      const stateName = current.split('.').pop();
      const a = clone(clone.OBJECT, prev, getSelectors(stateName, current, state));
      return a;
    }, {});
