import React, { useState } from 'react';
import PropTypes from 'prop-types';

/* Project */
import DiscrepancyItem from 'commons/components/DiscrepancyItem/DiscrepancyItem';
import {
  Grid,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  tableRowClasses,
} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { discrepancyFullNumber } from 'utils/functions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';
import { changePagination } from 'screens/Home/Home.actions';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import { changeControls } from 'commons/commons.actions';
import moment from 'moment';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#A6A6A6',
    color: theme.palette.common.white,
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  [`&.${tableRowClasses.root}:hover`]: {
    backgroundColor: '#E0E0E0',
  },
  [`&.${tableRowClasses.hover}`]: {
    backgroundColor: theme.palette.action.hover,
    cursor: 'pointer',
  },
}));

const DiscrepanciesList = (props) => {
  const { discrepanciesList, filters, discrepanciesIds, loading, pagination, objects: { users} } = props;
  const { user: currentUser } = useSelector((state) => state.app);
  const currentUserModel = users[currentUser?.id];
  const { page, count } = pagination;
  const history = useHistory();
  const dispatch = useDispatch();
  const byNumberAsc = (a, b) => (a.number > b.number ? 1 : -1);
  const byNumberDes = (a, b) => (a.number < b.number ? 1 : -1);
  const byDateAsc = (a, b) => (new Date(a.createdAt) >= new Date(b.createdAt) ? 1 : -1);
  const byDateDes = (a, b) => (new Date(a.createdAt) <= new Date(b.createdAt) ? 1 : -1);
  //sort by dateDesc and numberDesc
  const actualYear = new Date().getFullYear();
  const byDefault = (a, b) =>
    a.number < b.number &&
    actualYear === new Date(a.createdAt).getFullYear() &&
    actualYear === new Date(b.createdAt).getFullYear()
      ? 1
      : -1;


  const compareDiscrepancies = (a, b) => {
    const concatenacionA = a.number < 10 ? `${new Date(a.createdAt).getFullYear()}0${a.number}` : `${new Date(a.createdAt).getFullYear()}${a.number}`
    const concatenacionB = b.number < 10 ? `${new Date(b.createdAt).getFullYear()}0${b.number}` : `${new Date(b.createdAt).getFullYear()}${b.number}`;

    // Ordenar de forma descendente
    return concatenacionB.localeCompare(concatenacionA);
  };

  const orderFunction = () => {
    switch (filters.order) {
      case 1:
        return byNumberAsc;
      case 2:
        return byNumberDes;
      case 3:
        return byDateAsc;
      case 4:
        return byDateDes;
      default:
        return compareDiscrepancies;
    }
  };

  const sortedDiscrepancies = Object.values(discrepanciesList)
    .filter((d) => discrepanciesIds.includes(d.id))
    .sort(orderFunction());

  /* return sortedDiscrepancies.map((discrepancy) => (
    <DiscrepancyItem key={discrepancy.id} discrepancy={discrepancy} />
  )); */

  const handleRedirection = (evt, discrepancy) => {
    evt.preventDefault();
    const lastdocumentId = Math.min(...discrepancy.documents().map((d) => d.id));

    history.push(`discrepancies/${discrepancy.id}/expedient/${lastdocumentId}`);
  };

  const onChangePagination = (event, value) => {
    dispatch(changePagination({ value }));
  };

  const toggleStepper = () => dispatch(changeControls({ name: 'openDialog', value: true }));

  return (
    <>
      <TableContainer component={Paper}>
        <Table
          stickyHeader={true}
          sx={{ minWidth: 650, borderRadius: 0, height: '100px' }}
          size="small"
          aria-label="a dense table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Nro. Discrepancia</StyledTableCell>
              <StyledTableCell align="left">Nombre</StyledTableCell>
              <StyledTableCell align="left">Fecha presentación</StyledTableCell>
              <StyledTableCell align="left">Materia</StyledTableCell>
              <StyledTableCell align="left">Submateria</StyledTableCell>
              <StyledTableCell align="left">Estado</StyledTableCell>
              <StyledTableCell align="left">Fecha Audiencia</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {sortedDiscrepancies.length ? (
              sortedDiscrepancies.map((discrepancy) => (
                <StyledTableRow
                  hover={true}
                  key={discrepancy.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  onClick={(e) => handleRedirection(e, discrepancy)}>
                  <StyledTableCell>
                    {discrepancyFullNumber(discrepancy.number, discrepancy.createdAt)}
                  </StyledTableCell>
                  <StyledTableCell align="left">{discrepancy.cover}</StyledTableCell>
                  <StyledTableCell align="left">
                    {moment(discrepancy.createdAt).format('DD/MM/YYYY')}
                  </StyledTableCell>
                  <StyledTableCell align="left">{discrepancy.matter()?.name}</StyledTableCell>
                  <StyledTableCell align="left">
                    {discrepancy.legalSubMatter().name}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {discrepancy.endedAt ? 'Terminada' : 'En Tramitación'}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {discrepancy.hearing().length
                      ? new Date(discrepancy.hearing()[0].date).toLocaleDateString()
                      : 'Pendiente'}
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <StyledTableCell align="center" colSpan={7}>
                No se encontraron resultados
              </StyledTableCell>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container flexDirection="row" justifyContent={'center'} sx={{ mt: 3 }}>
        <Pagination
          count={count}
          page={page}
          variant="outlined"
          shape="rounded"
          disabled={loading}
          onChange={onChangePagination}
        />
      </Grid>
      {
        !currentUserModel?.entity()?.legalEntity()
         ? <Grid container flexDirection="row" justifyContent={'flex-start'} sx={{ mt: 2 }}>
            <PrimaryButton
              text="Nueva discrepancia"
              onClick={toggleStepper}
              secondaryStyles
              startIcon={<DescriptionOutlinedIcon />}
            />
          </Grid>
         : null
      }
    </>
  );
};

export default DiscrepanciesList;
