import React, { useEffect, useMemo } from 'react';

/* Material */
import { CircularProgress, Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';

/* React Redux */
import { useDispatch, useSelector } from 'react-redux';

/* Project */
import TitleField from 'commons/components/TitleField/TitleField';
import { reselectObjects } from 'utils/helper';
import LastDocFilters from 'screens/LastDocuments/components/Filters';
import DocumentsList from 'screens/LastDocuments/components/DocumentsList';

/* Styles */
import { getDocuments } from 'screens/LastDocuments/LastDocuments.actions';
import lastDocStyles from './LastDocuments.styles';

const useStyles = makeStyles(lastDocStyles);

function LastDocuments() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { lastDocuments: lastDocumentsState, app: appState } = useSelector((state) => state);
  const { objects: objectsState } = appState;

  const objects = useMemo(() => reselectObjects(objectsState), [objectsState]);

  const { loaders, filters, documentsIds } = lastDocumentsState;

  const filteredDocuments = Object.values(objects.documents).filter((doc) =>
    documentsIds.includes(doc.id),
  );

  useEffect(() => {
    dispatch(getDocuments({ ...filters }));
  }, []);

  return (
    <Container maxWidth={false} sx={{ m: 0 }}>
      <TitleField title="ÚLTIMOS DOCUMENTOS INGRESADOS" />
      <LastDocFilters docTypes={Object.values(objects.documentTypes)} filters={filters} />

      <Grid container direction="column">
        <Grid container rowSpacing={2} sx={{ overflow: 'auto', pb: 3, maxHeight: '65vh' }}>
          {loaders.list ? (
            <CircularProgress size={30} sx={{ margin: 'auto', marginTop: '1rem' }} />
          ) : (
            <DocumentsList
              documentsList={objects.documents}
              entitiesList={objects.entities}
              interestedPartiesList={objects.interestedParties}
              discrepanciesList={objects.discrepancies}
              documentsIds={documentsIds}
            />
          )}
        </Grid>
        {!loaders.list ? (
          <Grid container rowSpacing={2} sx={{ flexGrow: 1, marginTop: '.7rem' }}>
            <Typography variant="overline" className={classes.typography}>
              Total: {filteredDocuments.length || 0} documentos
            </Typography>
          </Grid>
        ) : null}
      </Grid>
    </Container>
  );
}

export default LastDocuments;
