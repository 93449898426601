import camelize from 'camelcase-keys-deep';

export default class Alert {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  user() {
    return this.env().User.getById(this.userId);
  }

  discrepancy() {
    return this.env().Discrepancy.getById(this.discrepancyId);
  }
}
