import { newReducer } from 'utils/reducer';
import AudiencesCalendarTypes from 'screens/AudiencesCalendar/AudiencesCalendar.types';

const initialState = {
  controls: {
    hearingDate: null,
    representativeId: null,
    inscriptionType: 1,
  },
  loaders: {
    hearings: true,
  },
};

const audiencesCalendar = newReducer(initialState, {
  [`${AudiencesCalendarTypes.FETCH_AUCIENCES}`]: (state) => ({
    ...state,
    loaders: {
      ...state.loaders,
      hearings: true,
    },
  }),
  [AudiencesCalendarTypes.FETCH_AUCIENCES_SUCCESS]: (state) => ({
    ...state,
    loaders: {
      ...state.loaders,
      hearings: false,
    },
  }),
  [AudiencesCalendarTypes.FETCH_AUCIENCES_FAIL]: (state) => ({
    ...state,
    discrepancies: null,
    loaders: {
      ...state.loaders,
      hearings: true,
    },
  }),
  [AudiencesCalendarTypes.HANDLE_CHANGE]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      [action.payload.name]: action.payload.value,
    },
  }),
  [AudiencesCalendarTypes.FILL_HEARING]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      hearingDate: action.payload.hearingDate,
    },
  }),
  [AudiencesCalendarTypes.FETCH_USER_HEARING_SUCCESS]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      representativeId: action.payload.representativeId,
      inscriptionType: action.payload.inscriptionType,
    },
  }),
});

export default audiencesCalendar;
