import { takeLatest, spawn, put, call, delay } from 'redux-saga/effects';

/* Project */
import apiRequest, { apiSuccess, update } from 'utils/api';
import DiscrepancyDetailsTypes from 'screens/DiscrepancyDetail/DiscrepancyDetails.types';
import { v4 as uuidv4 } from 'uuid';
import * as APP from 'commons/commons.reducer';
import { SET_ERROR, DELETE_DOCUMENT_SUCCESS } from 'commons/commons.reducer';
import controlledCall from 'utils/services/controlledSaga';
import { selectState } from 'utils/reduxActions';
import { pairFiles, downloadPdf } from 'utils/functions';
import { formatDistanceToNow } from 'date-fns';

function* getDiscrepancy() {
  yield takeLatest(DiscrepancyDetailsTypes.GET_DISCREPANCY, function* getDiscrepancyApi(action) {
    const { id, history } = action.payload;

    const response = yield apiRequest(`api/v1/discrepancies/${id}`, { method: 'get' }, true, true);

    if (!response.error) {
      yield put(apiSuccess(DiscrepancyDetailsTypes.GET_DISCREPANCY_SUCCESS, response));
    } else {
      yield put(apiSuccess(DiscrepancyDetailsTypes.GET_DISCREPANCY_FAIL));
      history.replace('/');
    }
  });
}

function* getHearingParticipationsFile() {
  yield takeLatest(
    DiscrepancyDetailsTypes.GET_HEARING_PARTICIPATIONS_FILE,
    function* getDiscrepancyApi(action) {
      const { id } = action.payload;

      const response = yield apiRequest(
        `api/v1/discrepancies/xlsx/${id}`,
        { method: 'get' },
        true,
        true,
      );

      if (!response.error) {
        yield put(
          apiSuccess(DiscrepancyDetailsTypes.GET_HEARING_PARTICIPATIONS_FILE_SUCCESS, response),
        );
      } else {
        yield put(apiSuccess(DiscrepancyDetailsTypes.GET_HEARING_PARTICIPATIONS_FILE_FAIL));
      }
    },
  );
}

function* downloadAllFilesSaga() {
  yield takeLatest(
    DiscrepancyDetailsTypes.DOWNLOAD_ALL_FILES_REQUEST,
    function* blabla(action) {
      const { data } = action.payload.data;

      for (const file of data) {
        yield call(downloadPdf, file.url, file.content);
        yield delay(300);
      }
    }
  )
}

function* makeHearingParticipationsFile() {
  yield takeLatest(
    DiscrepancyDetailsTypes.MAKE_HEARING_PARTICIPATIONS_FILE,
    function* getDiscrepancyApi(action) {
      const { id } = action.payload;

      const response = yield apiRequest(
        `api/v1/discrepancies/xlsx/make/${id}`,
        { method: 'get' },
        true,
        true,
      );

      if (!response.error) {
        yield put(
          apiSuccess(DiscrepancyDetailsTypes.MAKE_HEARING_PARTICIPATIONS_FILE_SUCCESS, response),
        );
      } else {
        yield put(apiSuccess(DiscrepancyDetailsTypes.MAKE_HEARING_PARTICIPATIONS_FILE_FAIL));
      }
    },
  );
}

function* postDocument() {
  yield takeLatest(DiscrepancyDetailsTypes.CREATE_DOCUMENT, function* postDocumentApi(action) {
    const { history, ...payload } = action.payload;
    const payloadCopy = { ...payload };
    const body = new FormData();

    const { files, attachments } = payloadCopy;

    delete payloadCopy.files;
    delete payloadCopy.attachments;

    const stringifyPayload = JSON.stringify(payloadCopy);

    const mainRandom = uuidv4().split('-')[0];
    const mainRandomWord = uuidv4().split('-')[0];
    const mainRandomConfidential = uuidv4().split('-')[0];

    const [publicFile, wordFile, confidentialFile] = files;
    const testTotalFiles = [publicFile, wordFile, confidentialFile];

    body.append('files[]', publicFile, `${mainRandom}_${publicFile?.name?.replace(/[\/\\:*?<>|()\[\]{}"]/g, '')}`);
    body.append(
      'files[]',
      wordFile || new Blob(),
      wordFile ? `${mainRandomWord}_${wordFile.name?.replace(/[\/\\:*?<>|()\[\]{}"]/g, '')}` : undefined,
    );
    body.append(
      'files[]',
      confidentialFile || new Blob(),
      confidentialFile ? `${mainRandomConfidential}_${confidentialFile.name?.replace(/[\/\\:*?<>|()\[\]{}"]/g, '')}` : undefined,
    );

    attachments.forEach((attachment) => {
      if (attachment) {
        const random = uuidv4().split('-')[0];
        const randomPdf = uuidv4().split('-')[0];
        // const randomWord = uuidv4().split('-')[0];
        const randomConfidential = uuidv4().split('-')[0];
        const { privateAtt, publicAtt } = attachment;
        if (privateAtt) {
          testTotalFiles.push(attachment.privateAtt);
        }
        if (publicAtt) {
          testTotalFiles.push(attachment.publicAtt);
        }
        body.append(
          `attachments[${random}]`,
          publicAtt,
          publicAtt ? `${randomPdf}_${publicAtt.name?.replace(/[\/\\:*?<>|()\[\]{}"]/g, '')}` : undefined,
        );
        body.append(`attachments[${random}]`, new Blob(), null);
        body.append(
          `attachments[${random}]`,
          privateAtt || new Blob(),
          privateAtt ? `${randomConfidential}_${privateAtt.name?.replace(/[\/\\:*?<>|()\[\]{}"]/g, '')}` : undefined,
        );
      }
    });

    body.append('jsonBody', stringifyPayload);

    const response = yield apiRequest('api/v1/documents', { method: 'post', body }, false);

    if (!response.error) {
      const attachmentsSaved = Object.values(response.objects.attachments).filter(
        (attachment) => attachment.id,
      );
      const pairedFiles = pairFiles(testTotalFiles, attachmentsSaved);
      for (const singleFile of pairedFiles) {
        const { contentType, id, content } = singleFile.savedInDb;
        const { file } = singleFile;
        let body = JSON.stringify({
          fileName: content,
          attachment: true,
          fileType: contentType,
          id,
        });
        try {
          const signedUrlResponse = yield call(
            apiRequest,
            `api/v1/s3/get_presigned_url`,
            { method: 'post', body },
            true,
            true,
          );
          if (signedUrlResponse?.error) {
            throw new Error('Error obteniendo signedUrl para guardar el archivo');
          }
          yield fetch(decodeURI(signedUrlResponse?.signedUrl), {
            method: 'put',
            headers: {
              'Content-Type': contentType,
              'Access-Control-Allow-Headers': 'Content-Type',
              'Access-Control-Allow-Methods': 'OPTIONS,POST,GET',
              'Access-Control-Allow-Origin': '*',
            },
            body: file,
          });
          body = JSON.stringify({
            id,
            fileName: content,
            contentType,
            attachment: true,
          });
          const viewFileSignedUrlResponse = yield apiRequest(
            `api/v1/s3/get_presigned_url_view`,
            {
              method: 'post',
              body,
            },
            true,
            true,
          );
          if (viewFileSignedUrlResponse?.error) {
            throw new Error('Error obteniendo signedUrl para guardar el archivo');
          }
          response.objects.attachments[id].url = viewFileSignedUrlResponse.signedUrl;
        } catch (error) {
          console.log(`ERROR SUBIENDO DOCUMENTO`, error);
          yield apiRequest(
            `api/v1/documents/${response.newDocumentId}`,
            { method: 'delete' },
            true,
            true,
          );
          yield put(apiSuccess(DiscrepancyDetailsTypes.CREATE_DOCUMENT_FAIL, response));
          yield put(
            apiSuccess(APP.SET_ERROR, { msg: 'Hubo un error subiendo el documento al sistema' }),
          );
          return;
        }
      }

      if (payloadCopy.copyToDiscrepancies.length) {
        try {
          const originalAttachments = Object.values(response.objects.attachments)
            .filter((item) => item.id)
            .map((item) => item.id);

          body.append('originalAttachments', originalAttachments);

          const copyToDiscrepanciesResponse = yield apiRequest(
            'api/v1/documents/copy-files',
            { method: 'post', body: body },
            false,
          );

          if (!copyToDiscrepanciesResponse.error) {
            yield put(apiSuccess(DiscrepancyDetailsTypes.CREATE_DOCUMENT_SUCCESS, response));
            yield put(
              apiSuccess(
                DiscrepancyDetailsTypes.COPY_FILES_TO_DISCREPANCIES_SUCCESS,
                copyToDiscrepanciesResponse,
              ),
            );

            // redirect to new document url
            history.push(
              `/discrepancies/${payload.discrepancyId}/expedient/${response.newDocumentId}`,
            );
          }
        } catch (error) {
          yield put(
            apiSuccess(APP.SET_ERROR, {
              msg: 'Hubo un error en la copia de archivos a multiples discrepancias',
            }),
          );
        }
      } else {
        yield put(apiSuccess(DiscrepancyDetailsTypes.CREATE_DOCUMENT_SUCCESS, response));
        // redirect to new document url
        history.push(`/discrepancies/${payload.discrepancyId}/expedient/${response.newDocumentId}`);
      }
    } else {
      yield put(apiSuccess(DiscrepancyDetailsTypes.CREATE_DOCUMENT_FAIL, response));
      yield put(apiSuccess(APP.SET_ERROR, { msg: response.errorMsg }));
    }
  });
}

function* deleteDocument() {
  yield takeLatest(
    DiscrepancyDetailsTypes.DELETE_DOCUMENT,
    function* deleteDiscrepancyDocument(action) {
      const { documentId, history, currentDiscrepancyId, firstDocumentId } = action.payload;

      const response = yield apiRequest(
        `api/v1/documents/${documentId}`,
        { method: 'delete' },
        true,
        true,
      );

      if (response.error) {
        yield put(apiSuccess(DiscrepancyDetailsTypes.DELETE_DOCUMENT_FAIL));
        return yield put({ type: SET_ERROR, payload: { msg: response.errorMsg } });
      }

      yield put({ type: DELETE_DOCUMENT_SUCCESS, state: selectState('app'), payload: response });
      history.push(`/discrepancies/${currentDiscrepancyId}/expedient/${firstDocumentId}`);
      return yield put(apiSuccess(DiscrepancyDetailsTypes.DELETE_DOCUMENT_SUCCESS, response));
    },
  );
}

function* notifyDiscrepancy() {
  yield takeLatest(
    DiscrepancyDetailsTypes.NOTIFICATE_DISCREPANCY,
    function* deleteDiscrepancyDocument(action) {
      const { discrepancyId, value } = action.payload;

      const successFunction = (response) => ({
        type: DiscrepancyDetailsTypes.NOTIFICATE_DISCREPANCY_SUCCESS,
        payload: { ...response },
      });

      const failFunction = (response) => ({
        type: DiscrepancyDetailsTypes.NOTIFICATE_DISCREPANCY_FAIL,
        payload: { ...response },
      });

      const response = yield controlledCall(
        update,
        `api/v1/discrepancies/${discrepancyId}/notificate`,
        { notificate: value },
        successFunction,
        failFunction,
      );

      if (response.error) {
        yield put({ type: SET_ERROR, payload: { msg: response.errorMsg } });
      }
    },
  );
}

function* updateDocumentStepOne() {
  yield takeLatest(
    DiscrepancyDetailsTypes.UPDATE_DOCUMENTS_VALUES,
    function* updateDocumentStepOneGenerator(action) {
      const {
        documentTypeId,
        id,
        index,
        title,
        documentDate,
        legalEntityId,
        dateHourAudience,
        dateHourRegistrationDeadline,
        dateHourPresentationInterestedParty,
        dateHourObservationsDeadline,
      } = action.payload;

      const successFunction = (response) => ({
        type: DiscrepancyDetailsTypes.UPDATE_DOCUMENTS_VALUES_SUCCESS,
        payload: { ...response },
      });

      const failFunction = (response) => ({
        type: DiscrepancyDetailsTypes.UPDATE_DOCUMENTS_VALUES_FAIL,
        payload: { ...response },
      });

      const response = yield controlledCall(
        update,
        `api/v1/documents/${id}`,
        {
          documentTypeId,
          index,
          title,
          documentDate,
          legalEntityId,
          dateHourAudience,
          dateHourRegistrationDeadline,
          dateHourPresentationInterestedParty,
          dateHourObservationsDeadline,
        },
        successFunction,
        failFunction,
      );

      if (response.error) {
        yield put({ type: SET_ERROR, payload: { msg: response.errorMsg } });
      }
    },
  );
}

function* updateAttachmentFiles() {
  yield takeLatest(
    DiscrepancyDetailsTypes.UPDATE_ATTACHMENT_FILES,
    function* postDocumentApi(action) {
      const payloadCopy = { ...action.payload };
      const body = new FormData();

      const { files, documentId, oldFileNames } = payloadCopy;

      delete payloadCopy.files;

      const newPayload = {
        ...payloadCopy,
        deleteConfidential: files[2].deleteConfidential,
      };

      const stringifyPayload = JSON.stringify(newPayload);

      const mainRandom = uuidv4().split('-')[0];
      body.append(
        'files[]',
        files[0]?.size ? files[0] : new Blob(),
        files[0]?.size ? `${mainRandom}_${files[0]?.name?.replace(/[\/\\:*?<>|()\[\]{}"]/g, '')}` : oldFileNames[0],
      );

      if (files[1]?.size) {
        const mainRandomWord = uuidv4().split('-')[0];
        body.append('files[]', files[1], `${mainRandomWord}_${files[1]?.name?.replace(/[\/\\:*?<>|()\[\]{}"]/g, '')}` || oldFileNames[1]);
      } else {
        body.append('files[]', new Blob(), null);
      }

      if (files[2].file?.size) {
        const mainRandomConfidential = uuidv4().split('-')[0];
        body.append(
          'files[]',
          files[2].file,
          `${mainRandomConfidential}_${files[2].file.name.replace(/[\/\\:*?<>|()\[\]{}"]/g, '')}` || oldFileNames[2],
        );
      } else {
        body.append('files[]', new Blob(), null);
      }

      body.append('jsonBody', stringifyPayload);

      const response = yield apiRequest(
        `api/v1/documents/${documentId}/attachments`,
        { method: 'put', body },
        false,
      );

      if (!response.error) {
        const filesUpdated = Object.values(response.objects.attachments).filter(
          (attachment) => attachment.id,
        );
        const pairedFiles = pairFiles(files, filesUpdated);
        for (const singleFile of pairedFiles) {
          const { contentType, id, content } = singleFile.savedInDb;
          const { file } = singleFile;
          let body = JSON.stringify({
            fileName: content,
            attachment: true,
            fileType: contentType,
            id,
          });
          try {
            const signedUrlResponse = yield call(
              apiRequest,
              `api/v1/s3/get_presigned_url`,
              { method: 'post', body },
              true,
              true,
            );
            if (signedUrlResponse?.error) {
              throw new Error('Error obteniendo signedUrl para guardar el archivo');
            }
            yield fetch(decodeURI(signedUrlResponse?.signedUrl), {
              method: 'put',
              headers: {
                'Content-Type': contentType,
                'Access-Control-Allow-Headers': 'Content-Type',
                'Access-Control-Allow-Methods': 'OPTIONS,POST,GET',
                'Access-Control-Allow-Origin': '*',
              },
              body: file,
            });
            body = JSON.stringify({
              id,
              fileName: content,
              contentType,
              attachment: true,
            });
            const viewFileSignedUrlResponse = yield apiRequest(
              `api/v1/s3/get_presigned_url_view`,
              {
                method: 'post',
                body,
              },
              true,
              true,
            );
            if (viewFileSignedUrlResponse?.error) {
              throw new Error('Error obteniendo signedUrl para guardar el archivo');
            }
            response.objects.attachments[id].url = viewFileSignedUrlResponse.signedUrl;
          } catch (error) {
            const body = new FormData();
            const stringifyPayload = JSON.stringify({
              ...newPayload,
              pageNumberMainpdf: newPayload.oldPageNumberMainPdf,
            });
            oldFileNames.forEach((oldFile) => {
              if (oldFile) {
                body.append('files[]', new Blob(), oldFile);
              } else {
                body.append('files[]', new Blob(), null);
              }
            });
            body.append('jsonBody', stringifyPayload);
            const responseTest = yield apiRequest(
              `api/v1/documents/${documentId}/attachments`,
              { method: 'put', body },
              false,
            );

            console.log(`ERROR SUBIENDO ARCHIVO`, error);
            yield put(apiSuccess(DiscrepancyDetailsTypes.UPDATE_ATTACHMENT_FILES_FAIL, response));
            yield put(apiSuccess(APP.SET_ERROR, { msg: 'Falló la carga del documento' }));
            return;
          }
        }
        const filesUnchanged = [];
        const attachmentsSaved = Object.values(response.objects.attachments);
        attachmentsSaved.forEach((attachmentSaved) => {
          const oldFileNamesCopy = [...oldFileNames];
          // We remove the first file name of the array (mainPdfFile), because we will always update it if the user only updates the mainPdfFilePageNumber but (...)
          // (...) not the mainPdfFile
          oldFileNamesCopy.shift();
          if (oldFileNamesCopy.includes(attachmentSaved.content)) {
            delete response.objects.attachments[`${attachmentSaved.id}`];
          }
        });
        yield put(apiSuccess(DiscrepancyDetailsTypes.UPDATE_ATTACHMENT_FILES_SUCCESS, response));
        yield put(
          apiSuccess(APP.SET_SUCCESS, {
            msg: 'Documentos principales actualizados correctamente',
          }),
        );
      } else {
        yield put(apiSuccess(DiscrepancyDetailsTypes.UPDATE_ATTACHMENT_FILES_FAIL, response));
        yield put(apiSuccess(APP.SET_ERROR, { msg: 'Falló la carga del documento' }));
      }
    },
  );
}

function* fetchDeleteAttachmentPart() {
  yield takeLatest(
    DiscrepancyDetailsTypes.DELETE_ATTACHMENT_PART,
    function* deleteAttachmentPart(action) {
      const { id } = action.payload;

      const response = yield apiRequest(
        `api/v1/attachmentParts/${id}`,
        { method: 'delete' },
        true,
        true,
      );

      if (response.error) {
        yield put(apiSuccess(DiscrepancyDetailsTypes.DELETE_ATTACHMENT_PART_FAIL));
        yield put({ type: SET_ERROR, payload: { msg: response.errorMsg } });
      } else {
        yield put(
          apiSuccess(APP.SET_SUCCESS, {
            msg: 'Adjunto eliminado correctamente.',
          }),
        );
        yield put(apiSuccess(DiscrepancyDetailsTypes.DELETE_ATTACHMENT_PART_SUCCESS, response));
      }
    },
  );
}

function* updateOrCreateAttachmentPart(action, updateMethod = false) {
  const payloadCopy = { ...action.payload };

  const body = new FormData();

  const {
    publicFile,
    privateFile,
    attachmentPartId,
    defaultPrivateAttachment,
    defaultPublicAttachment,
  } = payloadCopy;
  delete payloadCopy.publicFile;
  delete payloadCopy.privateFile;

  if (!updateMethod) {
    delete payloadCopy.attachmentPartId;
    delete payloadCopy.removeConfidential;
  }

  const successType = updateMethod
    ? DiscrepancyDetailsTypes.UPDATE_ATTACHMENT_PART_SUCCESS
    : DiscrepancyDetailsTypes.CREATE_ATTACHMENT_PART_SUCCESS;

  const failType = updateMethod
    ? DiscrepancyDetailsTypes.UPDATE_ATTACHMENT_PART_FAIL
    : DiscrepancyDetailsTypes.CREATE_ATTACHMENT_PART_FAIL;

  const files = [publicFile, privateFile];

  const stringifyPayload = JSON.stringify(payloadCopy);

  files.forEach((file) => {
    if (file?.size) {
      const random = uuidv4().split('-')[0];
      body.append('files[]', file, `${random}_${file.name.replace(/[\/\\:*?<>|()\[\]{}"]/g, '')}`);
    } else {
      body.append(`files[]`, new Blob(), null);
    }
  });

  body.append('jsonBody', stringifyPayload);

  const response = yield apiRequest(
    updateMethod ? `api/v1/attachmentParts/${attachmentPartId}` : 'api/v1/attachmentParts',
    { method: updateMethod ? 'put' : 'post', body },
    false,
  );

  if (!response.error) {
    const filesUpdated = Object.values(response.objects.attachments);
    const pairedFiles = pairFiles(files, filesUpdated);
    for (const singleFile of pairedFiles) {
      const { contentType, id, content } = singleFile.savedInDb;
      const { file } = singleFile;
      let body = JSON.stringify({
        fileName: content,
        attachment: true,
        fileType: contentType,
        id,
      });
      try {
        const signedUrlResponse = yield call(
          apiRequest,
          `api/v1/s3/get_presigned_url`,
          { method: 'post', body },
          true,
          true,
        );
        if (signedUrlResponse?.error) {
          throw new Error('Error obteniendo signedUrl para guardar el archivo');
        }
        yield fetch(decodeURI(signedUrlResponse?.signedUrl), {
          method: 'put',
          headers: {
            'Content-Type': contentType,
            'Access-Control-Allow-Headers': 'Content-Type',
            'Access-Control-Allow-Methods': 'OPTIONS,POST,GET',
            'Access-Control-Allow-Origin': '*',
          },
          body: file,
        });
        body = JSON.stringify({
          id,
          fileName: content,
          contentType,
          attachment: true,
        });
        const viewFileSignedUrlResponse = yield apiRequest(
          `api/v1/s3/get_presigned_url_view`,
          {
            method: 'post',
            body,
          },
          true,
          true,
        );
        if (viewFileSignedUrlResponse?.error) {
          throw new Error('Error obteniendo signedUrl para guardar el archivo');
        }
        response.objects.attachments[id].url = viewFileSignedUrlResponse.signedUrl;
      } catch (error) {
        const stringifyPayload = JSON.stringify({
          ...payloadCopy,
          removeConfidential: false,
        });
        console.log(`ERROR SUBIENDO ARCHIVO`, error);
        if (updateMethod) {
          const body = new FormData();
          defaultPublicAttachment?.content
            ? body.append('files[]', new Blob(), defaultPublicAttachment?.content)
            : body.append('files[]', new Blob(), null);
          defaultPrivateAttachment?.content
            ? body.append('files[]', new Blob(), defaultPrivateAttachment?.content)
            : body.append('files[]', new Blob(), null);

          body.append('jsonBody', stringifyPayload);
          const response = yield apiRequest(
            `api/v1/attachmentParts/${attachmentPartId}`,
            { method: 'put', body },
            false,
          );
        } else {
          const attachmentPartIdCreated = Object.values(response.objects.attachmentParts)[0].id;
          const responseTest = yield apiRequest(
            `api/v1/attachmentParts/${attachmentPartIdCreated}`,
            { method: 'delete' },
            false,
          );
        }

        yield put(apiSuccess(failType, response));
        yield put(apiSuccess(APP.SET_ERROR, { msg: 'Error al subir el archivo a la plataforma' }));
        return;
      }
    }
    yield put(apiSuccess(DiscrepancyDetailsTypes.UPDATE_ATTACHMENT_FILES_SUCCESS, response));
    yield put(
      apiSuccess(APP.SET_SUCCESS, {
        msg: 'Documentos principales actualizados correctamente',
      }),
    );
    yield put(apiSuccess(successType, response));
    yield put(
      apiSuccess(APP.SET_SUCCESS, {
        msg: updateMethod ? 'Adjunto actualizado correctamente.' : 'Adjunto creado correctamente.',
      }),
    );
  } else {
    yield put(apiSuccess(failType, response));
    yield put(apiSuccess(APP.SET_ERROR, { msg: response.errorMsg }));
  }
}

function* fetchUpdateAttachmentPart() {
  yield takeLatest(
    DiscrepancyDetailsTypes.UPDATE_ATTACHMENT_PART,
    function* updateAttachmentPart(action) {
      return yield updateOrCreateAttachmentPart(action, true);
    },
  );
}

function* fetchCreateAttachmentPart() {
  yield takeLatest(
    DiscrepancyDetailsTypes.CREATE_ATTACHMENT_PART,
    function* updateAttachmentPart(action) {
      return yield updateOrCreateAttachmentPart(action);
    },
  );
}

function* fetchCreateAudienceDocument() {
  yield takeLatest(
    DiscrepancyDetailsTypes.CREATE_AUDIENCE_DOCUMENT,
    function* createAudienceDocument(action) {
      const { history, ...payload } = action.payload;
      const body = new FormData();
      const stringifyPayload = JSON.stringify(payload);

      body.append('jsonBody', stringifyPayload);

      const response = yield apiRequest('api/v1/hearings', { method: 'post', body }, false);
      if (!response.error) {
        yield put(apiSuccess(DiscrepancyDetailsTypes.CREATE_AUDIENCE_DOCUMENT_SUCCESS, response));
        yield put(
          apiSuccess(APP.SET_SUCCESS, {
            msg: 'Audiencia creada correctamente.',
          }),
        );
        history.push(`/discrepancies/${payload.discrepancyId}/expedient/${response.newDocumentId}`);
      } else {
        yield put(apiSuccess(DiscrepancyDetailsTypes.CREATE_AUDIENCE_DOCUMENT_FAIL, response));
        yield put(apiSuccess(APP.SET_ERROR, { msg: response.errorMsg }));
      }
    },
  );
}

function* createHearingPresentation() {
  yield takeLatest(
    DiscrepancyDetailsTypes.CREATE_HEARING_PRESENTATION,
    function* createHearingPresentationGenerator(action) {
      const { hearingFile, history, ...payload } = action.payload;
      const stringifyPayload = JSON.stringify(payload);

      const payloadCopy = { ...payload };
      const body = new FormData();
      const random = uuidv4().split('-')[0];

      body.append('jsonBody', stringifyPayload);
      body.append(`files[]`, hearingFile, `${random}_${hearingFile.name?.replace(/[\/\\:*?<>|()\[\]{}"]/g, '')}`);
      const copyBody = body;

      const response = yield apiRequest(
        'api/v1/hearings/hearing_presentation',
        { method: 'post', body },
        false,
      );

      if (!response.error) {
        const fileSavedInDb = Object.values(response.objects.attachments)[0];
        const { contentType, id, content } = fileSavedInDb;
        let body = JSON.stringify({
          fileName: content,
          attachment: true,
          fileType: contentType,
          id,
        });
        try {
          const signedUrlResponse = yield call(
            apiRequest,
            `api/v1/s3/get_presigned_url`,
            { method: 'post', body },
            true,
            true,
          );
          if (signedUrlResponse?.error) {
            throw new Error('Error obteniendo signedUrl para guardar el archivo');
          }
          yield fetch(decodeURI(signedUrlResponse?.signedUrl), {
            method: 'put',
            headers: {
              'Content-Type': contentType,
              'Access-Control-Allow-Headers': 'Content-Type',
              'Access-Control-Allow-Methods': 'OPTIONS,POST,GET',
              'Access-Control-Allow-Origin': '*',
            },
            body: hearingFile,
          });
          body = JSON.stringify({
            id,
            fileName: content,
            contentType,
            attachment: true,
          });
          const viewFileSignedUrlResponse = yield apiRequest(
            `api/v1/s3/get_presigned_url_view`,
            {
              method: 'post',
              body,
            },
            true,
            true,
          );
          if (viewFileSignedUrlResponse?.error) {
            throw new Error('Error obteniendo signedUrl para guardar el archivo');
          }
          response.objects.attachments[id].url = viewFileSignedUrlResponse.signedUrl;
        } catch (error) {
          console.log(error);
          yield apiRequest(
            `api/v1/documents/${response.newDocumentId}`,
            { method: 'delete' },
            true,
            true,
          );
          yield put(apiSuccess(DiscrepancyDetailsTypes.CREATE_HEARING_PRESENTATION_FAIL, response));
          yield put(
            apiSuccess(APP.SET_ERROR, { msg: 'Hubo un error al subir el archivo a la plataforma' }),
          );
          return;
        }

        if (payloadCopy.copyToDiscrepancies.length) {
          try {
            const originalAttachments = Object.values(response.objects.attachments)
              .filter((item) => item.id)
              .map((item) => item.id);

            copyBody.append('originalAttachments', originalAttachments);

            const copyToDiscrepanciesResponse = yield apiRequest(
              'api/v1/documents/copy-files',
              { method: 'post', body: copyBody },
              false,
            );

            if (!copyToDiscrepanciesResponse.error) {
              yield put(
                apiSuccess(DiscrepancyDetailsTypes.CREATE_HEARING_PRESENTATION_SUCCESS, response),
              );
              yield put(
                apiSuccess(APP.SET_SUCCESS, {
                  msg: 'Audiencia creada correctamente.',
                }),
              );
              yield put(
                apiSuccess(
                  DiscrepancyDetailsTypes.COPY_FILES_TO_DISCREPANCIES_SUCCESS,
                  copyToDiscrepanciesResponse,
                ),
              );
              history.push(
                `/discrepancies/${payload.discrepancyId}/expedient/${response.newDocumentId}`,
              );
            }
          } catch (error) {
            yield put(
              apiSuccess(APP.SET_ERROR, {
                msg: 'Hubo un error en la copia de archivos a multiples discrepancias',
              }),
            );
          }
        } else {
          yield put(
            apiSuccess(DiscrepancyDetailsTypes.CREATE_HEARING_PRESENTATION_SUCCESS, response),
          );
          yield put(
            apiSuccess(APP.SET_SUCCESS, {
              msg: 'Audiencia creada correctamente.',
            }),
          );
          history.push(
            `/discrepancies/${payload.discrepancyId}/expedient/${response.newDocumentId}`,
          );
        }
      } else {
        yield put(apiSuccess(DiscrepancyDetailsTypes.CREATE_HEARING_PRESENTATION_FAIL, response));
        yield put(apiSuccess(APP.SET_ERROR, { msg: response.errorMsg }));
      }
    },
  );
}

export default function* DiscrepancyDetailsSaga() {
  yield spawn(getDiscrepancy);
  yield spawn(getHearingParticipationsFile);
  yield spawn(makeHearingParticipationsFile);
  yield spawn(postDocument);
  yield spawn(deleteDocument);
  yield spawn(notifyDiscrepancy);
  yield spawn(updateDocumentStepOne);
  yield spawn(updateAttachmentFiles);
  yield spawn(fetchDeleteAttachmentPart);
  yield spawn(fetchUpdateAttachmentPart);
  yield spawn(fetchCreateAttachmentPart);
  yield spawn(fetchCreateAudienceDocument);
  yield spawn(createHearingPresentation);
  yield spawn(downloadAllFilesSaga);
}
