import React from 'react';

/* Material UI */
import { ListItemButton, ListItemText, Tooltip, List, Typography } from '@mui/material';
import { AccessTime } from '@mui/icons-material';

/* React Router */
import { useHistory, useParams } from 'react-router-dom';

/* Project */
import color from 'utils/colors';
import { hoursToString, shortFormatDate } from '../../../utils/functions';
import { useDispatch } from 'react-redux';
import { selectViewDocument } from '../DiscrepancyDetails.actions';

function DocumentItem(props) {
  const { document, legalEntity } = props;
  const { title, id, createdAt } = document;

  const history = useHistory();
  const dispatch = useDispatch();
  const { id: discrepancyID, documentId } = useParams();

  const creationDate = new Date(createdAt);
  const creationTime = hoursToString(creationDate);

  const discrepancyStyle = {
    boxShadow: 0,
    p: 0,
    borderRadius: '10px',
    /* border: '2px solid #DBDBDB', */
    // height: '50px',
    mb: 1,
  };

  const currentDocument = id === Number(documentId);

  const discrepancyUpperStyle = {
    background: currentDocument ? color.secondaryMain : color.secondaryBackground,
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    margin: '0px',
    padding: '.4rem',
    width: 'inherit',
    borderTop: '2px solid #DBDBDB',
    borderLeft: '2px solid #DBDBDB',
    borderRight: '2px solid #DBDBDB',
  };

  const discrepancyLowerStyle = {
    color: 'rgba(48, 55, 64, 0.95)',
    padding: '.4rem',
    width: 'inherit',
    borderRadius: '0px 0px 10px 10px',
    borderBottom: '2px solid #DBDBDB',
    borderLeft: '2px solid #DBDBDB',
    borderRight: '2px solid #DBDBDB',
    marginTop: 0,
  };

  const handleDocumentClick = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();

    history.push(`/discrepancies/${discrepancyID}/expedient/${id}`);
  };

  return (
    <ListItemButton id={id} component="a" sx={discrepancyStyle} onClick={handleDocumentClick}>
      <List sx={{ padding: '0', margin: '0', width: '-webkit-fill-available' }}>
        <ListItemText
          sx={discrepancyUpperStyle}
          primary={
            <>
              <Typography
                sx={{
                  fontSize: '12px',
                  color: currentDocument ? '#fff' : color.purple,
                }}>{`${legalEntity?.names.toUpperCase() || ''}`}</Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                  color: currentDocument ? '#fff' : color.purple,
                }}>
                {`${shortFormatDate(creationDate)} ${creationTime}`}
              </Typography>

              {/* <Tooltip title={`Hora de Creación: ${creationTime}`} placement="right" arrow>
                <AccessTime color="inherit" fontSize="inherit" />
              </Tooltip> */}
            </>
          }
          primaryTypographyProps={{
            color: color.white,
            fontSize: '0.8rem',
            fontWeight: 'bold',
            maxHeight: 'fit-content',
            paddingLeft: '.4rem',
          }}
        />
        <ListItemText
          sx={discrepancyLowerStyle}
          primary={document.documentType()?.name || title}
          primaryTypographyProps={{
            // maxHeight: '23px',
            fontSize: '0.9rem',
            maxHeight: 'fit-content',
            paddingLeft: '.4rem',
          }}
        />
      </List>
    </ListItemButton>
  );
}

export default DocumentItem;
