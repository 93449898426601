import AudiencesCalendarTypes from 'screens/AudiencesCalendar/AudiencesCalendar.types';

export const fetchHearings = () => ({
  type: AudiencesCalendarTypes.FETCH_AUCIENCES,
  payload: {},
});

export const suscribeInHearing = ({ hearingId, representativeId, isPublic }) => ({
  type: AudiencesCalendarTypes.SUSCRIBE_IN_HEARING,
  payload: { hearingId, representativeId, isPublic },
});

export const updateHearingParticipation = ({ id, hearingId, representativeId, isPublic }) => ({
  type: AudiencesCalendarTypes.UPDATE_SUSCRIBE_IN_HEARING,
  payload: { id, hearingId, representativeId, isPublic },
});

export const handleChange = ({ name, value }) => ({
  type: AudiencesCalendarTypes.HANDLE_CHANGE,
  payload: { name, value },
});

export const fillHearing = ({ hearingDate, representativeId, inscriptionType }) => ({
  type: AudiencesCalendarTypes.FILL_HEARING,
  payload: { hearingDate, representativeId, inscriptionType },
});

export const fetchUserHearing = ({ hearingId, entityId }) => ({
  type: AudiencesCalendarTypes.FETCH_USER_HEARING,
  payload: { hearingId, entityId },
});
