import camelize from 'camelcase-keys-deep';

export default class Discrepancy {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  documents() {
    return this.env().Document.findAllBy('discrepancyId', this.id);
  }

  legalSubMatter() {
    return this.env().LegalSubMatter.getById(this.legalSubMatterId);
  }

  matter() {
    return this.legalSubMatter().legalMatter();
  }

  interestedParties() {
    return this.documents().map((document) => document.interestedParty());
  }

  hearing() {
    return this.env().Hearing.findAllBy('discrepancyId', this.id);
  }

  alerts() {
    return this.env().Alert.findAllBy(this.id);
  }

  presentationDocumentType() {
    return this.env().DocumentType.findBy('code', 'presentations');
  }

  presentation() {
    return this.documents().find(
      (document) => document.documentTypeId === this.presentationDocumentType().id,
    );
  }
}
