import React, { useState } from 'react';
import PropTypes from 'prop-types';

/* Material UI */
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';

/* React Redux */
import { useDispatch } from 'react-redux';

/* React Router */
import { useHistory, useParams } from 'react-router-dom';

import PrimaryButton from 'commons/components/PrimaryButton/PrimaryButton';
import SecondaryButton from 'commons/components/SecondaryButton/SecondaryButton';
import {
  appSetError,
  deleteDiscrepancyAction,
  toggleUpdateDiscrepancyModal,
  updateDiscrepancy,
} from 'commons/commons.actions';
import color from 'utils/colors';
import AddIcon from '@mui/icons-material/Add';
import GeneralAlert from 'commons/components/GeneralAlert/GeneralAlert';
import { isZippedFile } from 'utils/functions';

function DiscrepancyUpdateDialog(props) {
  const { openUpdateDiscrepancyDialog, objects, updateDiscrepancyLoader, deleteDiscrepancyLoader } =
    props;

  const { id: discrepancyId } = useParams();
  const currentDiscrepancy = objects.discrepancies[discrepancyId];

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [endedAt, setEndedAt] = useState(currentDiscrepancy.endedAt);

  const history = useHistory();

  const currentSubMatter = objects.legalSubMatters[currentDiscrepancy.legalSubMatterId];
  const currentMatter = currentSubMatter.legalMatter();
  const currentPresentation = currentDiscrepancy.presentation();
  const principalAttachmentPart = currentPresentation?.principalAttachmentPart();
  const principalAttachments = principalAttachmentPart?.attachments();
  const currentPdfPresentation = principalAttachments?.find((a) => a.contentType === 'pdf');
  const currentWordPresentation = principalAttachments?.find(
    (a) => a.contentType === 'doc' || a.contentType === 'docx' || a.contentType === 'word',
  );

  const [cover, setCover] = useState(currentDiscrepancy.cover || '');

  const [matterId, setMatterId] = useState(currentMatter.id);

  const [subMatterId, setSubMatterId] = useState(currentSubMatter.id);

  const [presentation, setPresentation] = useState('');

  const [wordPresentation, setWordPresentation] = useState('');

  const dispatch = useDispatch();

  const legalMatterList = Object.values(objects.legalMatters);
  const legalSubMatterList = Object.values(objects.legalSubMatters);

  const legalMatterOptions = legalMatterList.map((legalMatter) => (
    <MenuItem key={legalMatter.id} value={legalMatter.id}>
      {legalMatter.name}
    </MenuItem>
  ));

  const filteredSubMatters = !matterId
    ? []
    : legalSubMatterList.filter((subMatter) => subMatter.legalMatterId === Number(matterId));

  const legalSubMatterOptions = filteredSubMatters.map((legalSubMatter) => (
    <MenuItem key={legalSubMatter.id} value={legalSubMatter.id}>
      {legalSubMatter.name}
    </MenuItem>
  ));

  const handleClose = () => {
    dispatch(toggleUpdateDiscrepancyModal({ open: false }));
    // setCover('');
    // setMatterId('');
    // setSubMatterId('');
    setPresentation('');
    setWordPresentation('');
  };

  const handleSave = () => {
    dispatch(
      updateDiscrepancy({
        // aqui se envia para actualizar
        cover,
        discrepancyId,
        subMatterId,
        presentation,
        wordPresentation,
        endedAt,
        currentPdfPresentation,
        currentWordPresentation,
        oldValues: {
          cover: currentDiscrepancy.cover,
          discrepancyId,
          subMatterId: currentSubMatter.id,
          endedAt: currentDiscrepancy.endedAt,
        },
      }),
    );
  };

  const disableSave = !discrepancyId || !cover || !subMatterId;

  const handleFileChange = ({ target }) => {
    if (target.files[0] && !isZippedFile(target.files[0].name)) {
      setPresentation(target.files[0]);
    } else {
      dispatch(appSetError({ msg: 'El archivo no puede ser una carpeta comprimida' }));
    }
  };

  const handleWordFileChange = ({ target }) => {
    const currentFile = target.files[0];
    if (currentFile && !isZippedFile(currentFile.name)) {
      setWordPresentation(target.files[0]);
    } else {
      dispatch(appSetError({ msg: 'El archivo no puede ser una carpeta comprimida' }));
    }
  };

  const handleMatterChange = ({ target: { value } }) => {
    setSubMatterId('');
    setMatterId(value);
  };

  const closeConfirmation = () => setOpenConfirmation(false);

  const handleOnDeleteDiscrepancy = () => {
    setOpenConfirmation(true);
  };

  const dispatchDiscrepancyDeletion = () => {
    closeConfirmation();
    dispatch(deleteDiscrepancyAction({ discrepancyId, history }));
    dispatch(toggleUpdateDiscrepancyModal({ open: false }));
  };

  const toggleEnded = ({ target: { checked } }) => {
    if (currentDiscrepancy.endedAt) {
      setEndedAt(checked ? currentDiscrepancy.endedAt : null);
    } else {
      setEndedAt(checked ? new Date() : null);
    }
  };

  const coverTextField = (
    <TextField
      fullWidth
      size="small"
      variant="outlined"
      label="Nombre discrepancia"
      name="cover"
      onChange={({ target: { value } }) => setCover(value)}
      color="secondary"
      value={cover}
    />
  );

  const legalMatterField = (
    <TextField
      size="small"
      select
      fullWidth
      label="Materia"
      color="secondary"
      name="matterId"
      onChange={handleMatterChange}
      value={matterId}>
      {legalMatterOptions}
    </TextField>
  );

  const legalSubMatterField = (
    <TextField
      size="small"
      select
      fullWidth
      label="Sub-materia"
      color="secondary"
      name="subMatterId"
      onChange={({ target: { value } }) => setSubMatterId(value)}
      value={subMatterId}>
      {legalSubMatterOptions}
    </TextField>
  );

  const formContent = (
    <Stack alignItems="center" spacing={2}>
      {coverTextField}
      {legalMatterField}
      {legalSubMatterField}
    </Stack>
  );

  const presentationContent = (
    <>
      <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 2 }}>
        <Typography
          sx={{
            fontSize: 'h6.fontSize',
            fontWeight: 'bold',
            color: color.blue,
            textTransform: 'uppercase',
          }}>
          CARGAR
        </Typography>
        <Typography
          sx={{
            fontWeight: 'regular',
            color: color.blue,
            fontSize: 20,
          }}>
          Presentación de discrepancia
        </Typography>
        <label htmlFor="presentation-file">
          <input
            accept=".pdf"
            id="presentation-file"
            name="icon-button-file"
            type="file"
            style={{ display: 'none' }}
            onChange={handleFileChange}
            disabled
          />
          <IconButton color="primary" aria-label="upload picture" component="span" disabled>
            <AddIcon
              sx={{
                color: color.white,
                backgroundColor: color.darkGrey,
                borderRadius: 3,
              }}
            />
          </IconButton>
        </label>
      </Stack>
      <Typography>
        {presentation?.name || currentPdfPresentation?.content.split('_').pop()}
      </Typography>
    </>
  );

  const wordPresentationContent = (
    <>
      <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 2 }}>
        <Typography
          sx={{
            fontSize: 'h6.fontSize',
            fontWeight: 'bold',
            color: color.blue,
            textTransform: 'uppercase',
          }}>
          CARGAR
        </Typography>
        <Typography
          sx={{
            fontWeight: 'regular',
            color: color.blue,
            fontSize: 20,
          }}>
          Presentación word de discrepancia
        </Typography>
        <label htmlFor="presentation-word-file">
          <input
            accept=".doc, .docx"
            id="presentation-word-file"
            name="icon-button-file"
            type="file"
            style={{ display: 'none' }}
            onChange={handleWordFileChange}
            disabled
          />
          <IconButton color="primary" aria-label="upload picture" component="span" disabled>
            <AddIcon
              sx={{
                color: color.white,
                backgroundColor: color.darkGrey,
                borderRadius: 3,
              }}
            />
          </IconButton>
        </label>
      </Stack>
      <Typography>
        {wordPresentation?.name || currentWordPresentation?.content.split('_').pop()}
      </Typography>
    </>
  );

  const switchEndDiscrepancy = (
    <FormControlLabel
      control={<Switch checked={!!endedAt} onChange={toggleEnded} name="endedAt" color="primary" />}
      label="Terminada"
    />
  );

  return (
    <>
      <Dialog open={openUpdateDiscrepancyDialog}>
        <DialogTitle>Actualización de discrepancia</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ marginTop: '1rem' }}>
              {formContent}
            </Grid>
            <Grid item xs={12}>
              <Grid container direction="column">
                {presentationContent}
                {wordPresentationContent}
                {switchEndDiscrepancy}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mb: 2,
            ml: 2,
            mr: 2,
          }}>
          <SecondaryButton onClick={handleClose} disabled={updateDiscrepancyLoader}>
            Cerrar
          </SecondaryButton>
          <PrimaryButton loading={deleteDiscrepancyLoader} onClick={handleOnDeleteDiscrepancy}>
            Eliminar
          </PrimaryButton>
          <PrimaryButton
            onClick={handleSave}
            disabled={disableSave}
            loading={updateDiscrepancyLoader}>
            Guardar
          </PrimaryButton>
        </DialogActions>
      </Dialog>
      <GeneralAlert
        title="Advertencia"
        message="La eliminación de una discrepancia es irreversible."
        isOpen={openConfirmation}
        handleSubmit={dispatchDiscrepancyDeletion}
        handleOnClose={closeConfirmation}
        status="warning"
      />
    </>
  );
}

DiscrepancyUpdateDialog.propTypes = {
  objects: PropTypes.shape({}).isRequired,
  openUpdateDiscrepancyDialog: PropTypes.bool.isRequired,
  updateDiscrepancyLoader: PropTypes.bool.isRequired,
  deleteDiscrepancyLoader: PropTypes.bool.isRequired,
};

export default DiscrepancyUpdateDialog;
