const BASE = '[SEARCH_CONTENT]';

const searchContentTypes = {
  HANDLE_CHANGE: `${BASE} HANDLE_CHANGE`,
  RESET_CONTROLS: `${BASE} RESET_CONTROLS`,
  GET_DOCUMENTS: `${BASE} GET_DOCUMENTS`,
  GET_DOCUMENTS_SUCCESS: `${BASE} GET_DOCUMENTS_SUCCESS`,
  GET_DOCUMENTS_FAIL: `${BASE} GET_DOCUMENTS_FAIL`,
  CHANGE_PAGINATION: `${BASE} CHANGE_PAGINATION`,
  GET_FILE_LINK: `${BASE} GET_FILE_LINK`,
  GET_FILE_LINK_SUCCESS: `${BASE} GET_FILE_LINK_SUCCESS`,
  GET_FILE_LINK_FAIL: `${BASE} GET_FILE_LINK_FAIL`,
};

export default searchContentTypes;
