import camelize from 'camelcase-keys-deep';

export default class InterestedParty {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  naturalEntity() {
    return this.env().Entity.getById(this.naturalEntityId);
  }

  legalEntity() {
    return this.env().Entity.getById(this.legalEntityId);
  }
}
