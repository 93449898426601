import color from 'utils/colors';

const styles = {
  title: {
    fontSize: 'h6.fontSize',
    fontWeight: 'bold',
    color: color.cooper,
    textTransform: 'uppercase',
  },
  uploadIcon: {
    color: color.blue,
  },
  dialogContent: {
    width: '500px',
    '& .formControlsContainer': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '200px',
      padding: '10px',
    },
  },
};

export default styles;
