import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

/* Material UI */
import { Search } from '@mui/icons-material';

/* React Router */
import { useHistory } from 'react-router-dom';

/* React Redux */
import { useDispatch, useSelector } from 'react-redux';

import DocumentModal from 'screens/DiscrepancyDetail/components/DocumentModal';
import {
  Alert,
  AlertTitle,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import DocumentItem from 'screens/DiscrepancyDetail/components/DocumentItem';
import PrimaryButton from 'commons/components/PrimaryButton/PrimaryButton';
import {
  changeControls,
  controlsFiltersChanged,
  handleDocumentBanner,
  handleDownloadZipDialog,
  resetDocumentFilters,
} from 'screens/DiscrepancyDetail/DiscrepancyDetails.actions';
import color from 'utils/colors';
import SecondaryButton from 'commons/components/SecondaryButton/SecondaryButton';
import DownloadIcon from '@mui/icons-material/Download';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import CustomSelectField from 'commons/components/CustomSelectField/CustomSelectField';
import ClearIcon from '@mui/icons-material/Clear';
import DiscrepancyMessageBanner from './DiscrepancyMessageBanner';
import CustomSelectMultipleField from 'commons/components/CustomSelectMultipleField/CustomSelectMultipleField';
function DocumentList(props) {
  const { objects, currentUser, discrepancyID, updateHearingUrl, hearing, isSmallPage, checksum } =
    props;

  const history = useHistory();
  const dispatch = useDispatch();
  const { documentFilters, controls } = useSelector((state) => state.discrepancyDetails);
  const { entities, documentTypes } = objects;
  const { interested, documentType } = documentFilters;
  const { openDownloadDialog } = controls;

  const objectUser = currentUser ? objects.users[currentUser.id] : null;
  const currentDiscrepancy = objects.discrepancies[discrepancyID];
  const { discrepancyFileUrl, discrepancyMessage } = useSelector((state) => state.app.objects);
  const user = useSelector((state) => state.app.user);

  const documentFileUrl = discrepancyFileUrl[discrepancyID];
  const discrepancyMessageValue = useMemo(
    () => Object.values(discrepancyMessage).pop(),
    [discrepancyMessage],
  );
  const handleDocumentDownload = () => {
    if (documentFileUrl) {
      const url = documentFileUrl.url;
      const link = document.createElement('a');
      link.href = url;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }
    dispatch(handleDownloadZipDialog({ open: false }));
  };

  const openDownloadZipDialog = () => {
    dispatch(handleDownloadZipDialog({ open: true }));
  };

  const handleCloseZipDialog = () => {
    dispatch(handleDownloadZipDialog({ open: false }));
  };

  const interestedParties = currentDiscrepancy.interestedParties();

  const documents = currentDiscrepancy.documents();

  const currentEntity = objectUser?.entity();

  const [keyword, setKeyword] = useState('');
  const [documentsMatches, setDocumentsMatches] = useState([]);

  const naturalEntityParty = interestedParties.find((interestedParty) => {
    const entity = interestedParty.naturalEntity();
    return entity?.id === currentEntity?.id;
  });

  const legalEntityParty = interestedParties.find((interestedParty) => {
    const entity = interestedParty.legalEntity();
    return entity?.id === currentEntity?.id;
  });

  const interestedPartyId = naturalEntityParty?.id || legalEntityParty?.id;

  const disabledDocumentCreation = !!currentDiscrepancy.endedAt;

  const handleToggle = () => {
    if (!currentUser) {
      return history.push('/login');
    }

    if (discrepancyMessageValue?.enabled) {
      dispatch(handleDocumentBanner({ open: true }));
    }

    return dispatch(changeControls({ name: 'openDialog', value: true }));
  };

  const handleChange = ({ target: { value } }) => {
    const legalEntityMatch = documents
      .map((doc) => ({
        legalEntity: objects.documents[doc.id].interestedParty().legalEntity().names,
        docId: doc.id,
      }))
      .map((len) => {
        if (len.legalEntity.toLowerCase().includes(value.toLowerCase())) {
          return len.docId;
        }
        return null;
      })
      .filter((e) => e !== null);

    const documentTitleMatch = documents
      .map((doc) => ({
        title: objects.documents[doc.id].title,
        docId: doc.id,
      }))
      .map((ti) => {
        if (ti.title.toLowerCase().includes(value.toLowerCase())) {
          return ti.docId;
        }
        return null;
      })
      .filter((e) => e !== null);

    const documentIndexMatch = documents
      .map((doc) => ({
        title: objects.documents[doc.id].index,
        docId: doc.id,
      }))
      .map((ti) => {
        if (ti.title.toLowerCase().includes(value.toLowerCase())) {
          return ti.docId;
        }
        return null;
      })
      .filter((e) => e !== null);

    setDocumentsMatches([...legalEntityMatch, ...documentTitleMatch, ...documentIndexMatch]);
    setKeyword(value);
  };

  const typographyStyle = {
    fontSize: 'body2',
    fontWeight: 'medium',
    color: color.cooper,
    pt: 2,
  };

  const searchField = (
    <TextField
      size="small"
      fullWidth
      label="Buscar"
      variant="outlined"
      color="secondary"
      onChange={handleChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <IconButton>
              <Search />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );

  function AddClearButton(props) {
    const { name, handleChange } = props;
    return (
      <ClearIcon
        className="clear-icon"
        onClick={() => {
          handleChange({ target: { name, value: null } });
        }}
      />
    );
  }

  const interestedList = useMemo(() => {
    const data = [];
    const uniqueIds = [];

    interestedParties.forEach((party) => {
      if (party.naturalEntity()) {
        data.push(party.naturalEntity());
      }
      if (party.legalEntity()) {
        data.push(party.legalEntity());
      }
    });

    const unique = data.filter((element) => {
      const isDuplicate = uniqueIds.includes(element.id);
      if (!isDuplicate) {
        uniqueIds.push(element.id);
        return true;
      }
      return false;
    });
    return unique.length ? unique.map((item) => ({ ...item, label: item.names })) : [];
  }, [interestedParties]);

  const documentTypesList = useMemo(
    () => Object.values(documentTypes).map((item) => ({ ...item, label: item.name })),
    [documentTypes],
  );

  const handleFiltersChange = ({ target }) => {
    dispatch(controlsFiltersChanged({ name: target.name, value: target.value }));
  };

  useEffect(() => {
    return () => {
      dispatch(resetDocumentFilters());
    };
  }, []);

  const handleFiltersMultipleChange = ({ target }) => {
    const values = target.value;
    const currentValue = target.value.slice(-1)[0];
    if (!documentType.find((item) => item?.id === currentValue?.id)) {
      dispatch(controlsFiltersChanged({ name: target.name, value: [...values] }));
    } else {
      dispatch(controlsFiltersChanged({ name: target.name, value: target.value }));
    }
  };

  const handleFiltersInterestedMultipleChange = ({ target }) => {
    const values = target.value;
    const currentValue = target.value.slice(-1)[0];

    if (!interested.find((item) => item?.id === currentValue?.id)) {
      dispatch(controlsFiltersChanged({ name: target.name, value: [...values] }));
    } else {
      const filteredData = interested.filter((item) => item?.id !== currentValue?.id);
      dispatch(controlsFiltersChanged({ name: target.name, value: [...filteredData] }));
    }
  };

  const interestedField = (
    <Grid sx={{ mb: 2 }}>
      <CustomSelectMultipleField
        label="Interesado"
        name="interested"
        list={interestedList}
        onChange={handleFiltersInterestedMultipleChange}
        value={interested}
      />
    </Grid>
  );

  const documentTypeField = (
    <Grid sx={{ mb: 2 }}>
      <CustomSelectMultipleField
        label="Tipo documento"
        name="documentType"
        list={documentTypesList.filter(dt => dt.name != 'Grabación de audiencia pública')}
        onChange={handleFiltersMultipleChange}
        value={documentType}
      />
    </Grid>
  );

  const documentsList = useMemo(() => {
    let data;

    if (documentType.length && !interested.length) {
      const filteredData = [];
      documentType.forEach((dt) => {
        const fileredValues = Object.values(documents).filter(
          (item) => item.documentTypeId === dt.id,
        );
        if (fileredValues.length) {
          fileredValues.forEach((item) => {
            filteredData.push(item);
          });
        }
      });
      data = filteredData;
    } else if (!documentType.length && interested.length) {
      const filteredData = [];
      interested.forEach((interested) => {
        const filteredValues = Object.values(documents).filter((item) => {
          const interestedParty = item.interestedParty();
          if (
            interested.id === interestedParty.legalEntityId ||
            interested.id === interestedParty.naturalEntityId
          ) {
            return true;
          }
        });
        if (filteredValues.length) {
          filteredValues.forEach((item) => {
            filteredData.push(item);
          });
        }
      });

      data = filteredData;
    } else if (documentType.length && interested.length) {
      const filteredData = [];
      documentType.forEach((dt) => {
        const fileredValues = Object.values(documents).filter(
          (item) => item.documentTypeId === dt.id,
        );

        if (fileredValues.length) {
          fileredValues.forEach((item) => {
            filteredData.push(item);
          });
        }
      });

      const filteredInterested = [];
      interested.forEach((interested) => {
        const filteredValues = filteredData.filter((item) => {
          const interestedParty = item.interestedParty();
          if (
            interested.id === interestedParty.legalEntityId ||
            interested.id === interestedParty.naturalEntityId
          ) {
            return true;
          }
        });
        if (filteredValues.length) {
          filteredValues.forEach((item) => {
            filteredInterested.push(item);
          });
        }
      });

      data = filteredInterested;
    } else {
      data = Object.values(documents);
    }

    return data;
  }, [documents, interested, documentType]);

  const uniqueDocuments = documentsList.filter((document, index, self) =>
    index === self.findIndex((d) => d.id === document.id)
  );

  return (
    <Grid item xs={12} md={3} lg={3} sx={{ marginBottom: isSmallPage ? '1rem' : 0 }}>
      <DocumentModal objects={objects} interestedPartyId={interestedPartyId} />
      {interestedField}
      {documentTypeField}
      <Box sx={{ overflow: 'auto', mb: 3, maxHeight: '45vh' }}>
        {uniqueDocuments
          .sort((a, b) => (a.id > b.id ? 1 : -1))
          .filter((doc) => {
            if (keyword === '') {
              return true;
            }
            if (documentsMatches.length) {
              return documentsMatches.includes(doc.id);
            }
            return false;
          })
          .map((document) => (
            <DocumentItem
              key={document.id}
              document={document}
              legalEntity={entities[document?.interestedParty().legalEntityId]}
            />
          ))}
      </Box>
      {
        !currentEntity?.legalEntity()
          ? <Grid container justifyContent={'center'} sx={{ mb: '20px' }}>
              <PrimaryButton
                text="Agregar documento"
                secondaryStyles
                onClick={handleToggle}
                disabled={disabledDocumentCreation}
                startIcon={<DescriptionOutlinedIcon />}
              />
            </Grid>
          : null
      }
      {/* {hearing?.url ? (
        <SecondaryButton
          sx={{ marginTop: '10px' }}
          text="Editar enlace"
          fullWidth
          onClick={updateHearingUrl}
        />
      ) : null} */}
      {documentFileUrl && user?.id && (
        <Grid container justifyContent={'center'} sx={{ padding: 0, margin: 0 }}>
          <SecondaryButton
            sx={{ marginTop: '10px' }}
            text="Descargar Documentos"
            secondaryStyles
            fullWidth
            onClick={openDownloadZipDialog}
            startIcon={<DownloadIcon />}
          />
        </Grid>
      )}
      <DiscrepancyMessageBanner />
      <Dialog
        onClose={handleCloseZipDialog}
        open={openDownloadDialog}
        PaperProps={{ sx: { minWidth: { lg: 400 }, minHeight: 200 } }}>
        <DialogTitle>Descargar archivos</DialogTitle>
        <DialogContent>
          <Grid container>
            <Alert severity="info">
              <AlertTitle>Checksum</AlertTitle>
              {checksum}
            </Alert>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ padding: '12px 20px' }}>
          <Grid container direction="row" justifyContent={'space-between'}>
            <PrimaryButton text="Cerrar" onClick={handleCloseZipDialog} secondaryStyles />
            <PrimaryButton text="Descargar" onClick={handleDocumentDownload} secondaryStyles />
          </Grid>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

export default DocumentList;
