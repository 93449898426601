/* SSO index

import { truthty } from 'utils/functions';

// @ts-nocheck
export const apiSuccess = (entity, payload) => ({
  type: entity,
  payload,
});

const defaultHeader = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export default function* apiRequest(url, options = defaultHeader) {
  const res = yield fetch(`${window.location.origin}/${url}`, {
    ...defaultHeader,
    ...options,
  })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else if (response.status === 401) {
        localStorage.clear();
        window.location.replace(window.location.origin);
      } else {
        return response.text().then(errorMsg => ({ error: true, errorMsg }));
      }
    })
    .catch(response => {
      console.error(response);
    });
  return res;
}

export function objectToQueryString(obj) {
  return Object.keys(obj)
    .map(k => {
      if (truthty(obj[k])) {
        return Array.isArray(obj[k])
          ? obj[k].map(o => `${encodeURIComponent(k)}[]=${encodeURIComponent(o)}`).join('&')
          : `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`;
      } else {
        return '';
      }
    })
    .join('&');
}

export function* post(path, body) {
  const options = {
    method: 'post',
    body: JSON.stringify(body),
  };

  return yield apiRequest(path, options);
}

export function* update(path, body) {
  const options = {
    method: 'put',
    body: JSON.stringify(body),
  };

  return yield apiRequest(path, options);
}

export function* destroy(path, query) {
  const options = {
    method: 'delete',
  };
  return yield apiRequest(`${path}?${query}`, options);
}

export function* get(path, query) {
  const options = {
    method: 'get',
  };
  return yield apiRequest(`${path}?${query}`, options);
}
*/

import { getItemInStorage, truthty } from 'utils/functions';

// @ts-nocheck
export const apiSuccess = (entity, payload) => ({
  type: entity,
  payload,
});

export default function* apiRequest(url, options, useDefaultHeader = true, isLogin = false) {
  const session = getItemInStorage('user');
  const authorizationHeader = { Authorization: `Bearer ${session && session.token}` };
  const defaultHeaders = { 'Content-Type': 'application/json' };

  const content = options ?? {};
  if (useDefaultHeader) {
    content.headers = { ...content.headers, ...defaultHeaders };
  }
  content.headers = { ...content.headers, ...authorizationHeader };

  return yield fetch(`${window.location.origin}/${url}`, content)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      if (response.status === 401 && isLogin === false) {
        localStorage.clear();
        window.location.replace(window.location.origin);
      }
      return response.text().then((errorMsg) => ({ error: true, errorMsg }));
    })
    .catch((response) => {
      // eslint-disable-next-line no-console
      console.error(response);
    });
}

export function objectToQueryString(obj) {
  return Object.keys(obj)
    .filter((k) => truthty(obj[k]))
    .map((k) => {
      if (truthty(obj[k])) {
        return Array.isArray(obj[k])
          ? obj[k].map((o) => `${encodeURIComponent(k)}[]=${encodeURIComponent(o)}`).join('&')
          : `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`;
      }
      return '';
    })
    .join('&');
}

export function* post(path, body) {
  const options = {
    method: 'post',
    body: JSON.stringify(body),
  };

  return yield apiRequest(path, options);
}

export function* postForm(path, body) {
  const session = getItemInStorage('user');
  const options = {
    method: 'post',
    body,
    headers: {
      Authorization: `Bearer ${session && session.token}`,
    },
  };

  return yield apiRequest(path, options, false);
}

export function* update(path, body) {
  const options = {
    method: 'put',
    body: JSON.stringify(body),
  };

  return yield apiRequest(path, options);
}

export function* destroy(path, query) {
  const options = {
    method: 'delete',
  };
  return yield apiRequest(`${path}${query ? `?${query}` : ''}`, options);
}

export function* get(path, query) {
  const options = {
    method: 'get',
  };
  return yield apiRequest(`${path}${query ? `?${query}` : ''}`, options);
}
