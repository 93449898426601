import { Button, Grid } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeViewType } from '../DiscrepancyDetails.actions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const DiscrepancyTabs = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { view } = useSelector((state) => state.discrepancyDetails);
  const { discrepancy } = props;

  const btnStylesValidation = (type) => {
    const btnValidation = view === type;

    return {
      height: '50px',
      backgroundColor: btnValidation ? '#A6A6A6' : '#EDEDED',
      color: btnValidation ? '#fff' : '#000',
      fontSize: '16px',
      '&:hover': {
        backgroundColor: btnValidation ? '#959595' : '#E0E0E0',
      },
    };
  };

  const handleButtonChange = (e) => {
    if (e.target.name === 'secondary') {
      history.push(`/discrepancies/${discrepancy.id}/entities`);
    } else {
      const lastdocumentId = Math.min(...discrepancy.documents().map((d) => d.id));

      history.replace(`/discrepancies/${discrepancy.id}/expedient/${lastdocumentId}`);
    }
    dispatch(changeViewType({ view: e.target.name }));
  };

  return (
    <Grid container direction={'row'} justifyContent={'space-between'}>
      <Grid xs={6} sx={{ padding: '10px 10px 10px 0px' }}>
        <Button
          variant="contained"
          name="main"
          disableElevation
          fullWidth
          onClick={handleButtonChange}
          sx={btnStylesValidation('main')}>
          expediente
        </Button>
      </Grid>
      <Grid xs={6} sx={{ padding: '10px 0px 10px 10px' }}>
        <Button
          variant="contained"
          name="secondary"
          disableElevation
          fullWidth
          onClick={handleButtonChange}
          sx={btnStylesValidation('secondary')}>
          interesados
        </Button>
      </Grid>
    </Grid>
  );
};

export default DiscrepancyTabs;
