import { put, spawn, takeLatest } from 'redux-saga/effects';
import { onlyKeysWithValue } from 'utils/helper';
import apiRequest, { apiSuccess } from 'utils/api';
import LastDocumentsTypes from 'screens/LastDocuments/LastDocuments.types';

function* fetchDocuments() {
  yield takeLatest(LastDocumentsTypes.GET_DOCUMENTS, function* getDocumentsApi(action) {
    const urlParams = new URLSearchParams(onlyKeysWithValue(action.payload));

    const response = yield apiRequest(
      `api/v1/documents?${urlParams}`,
      { method: 'get' },
      true,
      true,
    );
    if (!response.error) {
      yield put(apiSuccess(LastDocumentsTypes.GET_DOCUMENTS_SUCCESS, response));
    } else {
      yield put(apiSuccess(LastDocumentsTypes.GET_DOCUMENTS_FAIL));
    }
  });
}

export default function* LastDocumentsSaga() {
  yield spawn(fetchDocuments);
}
