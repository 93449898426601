import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Alert,
} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import CloseIcon from '@mui/icons-material/Close';
import PrimaryButton from 'commons/components/PrimaryButton/PrimaryButton';
import SecondaryButton from 'commons/components/SecondaryButton/SecondaryButton';
import {
  fetchUserHearing,
  fillHearing,
  handleChange,
  suscribeInHearing,
  updateHearingParticipation,
} from 'screens/AudiencesCalendar/AudiencesCalendar.actions';
import { useDispatch, useSelector } from 'react-redux';

/* Moment */
import moment from 'moment';
import 'moment/locale/es';
import CustomDateTimePicker from 'commons/components/CustomDateTimePicker/CustomDateTimePicker';

function AudienceDialog(props) {
  const {
    hearing,
    open,
    onClose,
    hearingParticipations,
    currentEntity,
    currentRepresentatives,
    shouldShowButtonsToRegister = true,
  } = props;

  const dispatch = useDispatch();
  const { controls } = useSelector((state) => state.audiencesCalendar);
  // representativeId es el id de la tabla representación que se selecciona en el select
  const [representativeId, setRepresentativeId] = useState('default');
  // currentHP es el objeto que representa la inscripcion del usuario en la audiencia
  const [currentHP, setCurrentHP] = useState(null);
  // inscribed determina si el usuario ya se inscribio en la audiencia
  const [inscribed, setInscribed] = useState(false);
  // inscribedAs determina si el usuario se inscribio como publico o expositor
  const [inscribedAs, setInscribedAs] = useState(null);

  const getInscribedParticipations = () =>
    Object.values(hearingParticipations).filter((hp) => hp.hearingId === hearing.model.id);

  let inscribedParticipations = getInscribedParticipations();

  const getInscribedRepresentatives = () =>
    inscribedParticipations.map((item, index) => {
      const { representativeId } = item;
      if (!representativeId) return 'default';
      return representativeId;
    });

  let inscribedRepresentatives = getInscribedRepresentatives();

  const parsedOption = (option) => (option === 'default' ? null : option);

  const getCurrentHP = (optionValue) =>
    Object.values(hearingParticipations).find(
      (hp) => hp.entityId === currentEntity.id && hp.hearingId === hearing.model.id,
    );

  const initDialog = (selectedValue) => {
    const initCurrentHP = getCurrentHP(selectedValue);
    setCurrentHP(initCurrentHP);
    setRepresentativeId(selectedValue);
    setInscribed(inscribedRepresentatives.includes(selectedValue));
    setInscribedAs(initCurrentHP?.public);
  };

  const handleInscription = (isPublic) => {
    dispatch(
      suscribeInHearing({
        hearingId: hearing.model.id,
        representativeId: controls.representativeId,
        isPublic,
      }),
    );
    setInscribedAs(isPublic);
    setInscribed(true);
  };
  const formValidation =
    controls.inscriptionType === 0 &&
    (controls.representativeId === null || controls.representativeId === 'default');

  const handleSave = () => {
    if (formValidation) {
      return;
    }

    if (!currentHP) {
      handleInscription(controls.inscriptionType);
    } else {
      handleUpdateHP(controls.inscriptionType);
    }
    onClose();
  };

  const handleUpdateHP = (isPublic) => {
    dispatch(
      updateHearingParticipation({
        id: currentHP.id,
        hearingId: hearing.model.id,
        representativeId:
          controls.representativeId === 'default' ? null : controls.representativeId,
        isPublic,
      }),
    );
    setInscribedAs(isPublic);
  };

  const hearingModel = hearing.model;
  const discrepancy = useMemo(() => hearingModel.discrepancy(), [hearing]);
  const discrepancyDate = new Date(discrepancy.createdAt);

  const dialogTitle = `Audiencia pública discrepancia N°${
    discrepancy.number
  }-${discrepancyDate.getFullYear()}`;

  const hearingDeadline = useMemo(() => {
    const now = moment().toDate();
    const deadline = moment.utc(hearing.model.deadline).toDate();
    const day = moment(deadline).lang('es').format('dddd');

    return {
      pastDeadline: moment(now).isSameOrAfter(deadline),
      day: day[0].toUpperCase() + day.slice(1),
      hours: moment(deadline).format('HH:mm'),
      formatDate: moment(deadline).format('DD/MM/YYYY'),
    };
  }, [hearing]);

  useEffect(() => {
    initDialog('default');
  }, []);

  useEffect(() => {
    initDialog(representativeId);
  }, [hearingParticipations]);

  const handleChangeFields = (e) => {
    dispatch(
      handleChange({
        name: e.target.name,
        value: e.target.value,
      }),
    );
  };

  useEffect(() => {
    dispatch(
      fillHearing({
        hearingDate: hearing.start,
      }),
    );
  }, [hearing, currentHP]);

  useEffect(() => {
    dispatch(fetchUserHearing({ hearingId: hearing.model.id, entityId: currentEntity.id }));
  }, [hearing, currentEntity.id]);

  const representativeOptions =
    currentRepresentatives.length > 0
      ? Object.values(currentRepresentatives).map((item) => {
          const legalEntity = item.entityFromLegal();
          return (
            <MenuItem key={item.id} value={item.id}>
              {legalEntity.names}
            </MenuItem>
          );
        })
      : null;

  const expiredTimeAlert = (
    <Alert icon={<WarningIcon fontSize={'large'} />} severity="error">
      <Typography sx={{ fontSize: '17px', fontWeight: '600' }}>
        {`Recuerde que la fecha tope de inscripción a la audiencia es del ${hearingDeadline?.day}
        ${hearingDeadline?.formatDate} hasta las ${hearingDeadline.hours} hrs. luego de la cual, ya no podrá participar.`}
      </Typography>
    </Alert>
  );

  const endedAtAlert = (
    <Alert icon={<WarningIcon fontSize={'large'} />} severity="error">
      <Typography sx={{ fontSize: '17px', fontWeight: '600' }}>
        {`No es posible inscribirse a una audiencia cuando una discrepancia se encuentra terminada.`}
      </Typography>
    </Alert>
  );

  const dateAudienceField = (
    <CustomDateTimePicker
      label="Fecha audiencia"
      value={controls.hearingDate}
      onChange={handleChangeFields}
      alternativeStyles
      disabled={true}
      sx={{
        width: '100%',
      }}
    />
  );

  const inscriptionTypeField = (
    <FormControl fullWidth size="small" variant="standard">
      <InputLabel id="inscriptionType">Tipo de Inscripción</InputLabel>
      <Select
        labelId="inscriptionType"
        name="inscriptionType"
        value={controls.inscriptionType}
        label="Tipo documento"
        onChange={handleChangeFields}>
        <MenuItem key="public" value={1}>
          Público
        </MenuItem>
        <MenuItem key="exhibitor" value={0}>
          Expositor
        </MenuItem>
      </Select>
    </FormControl>
  );

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <Grid container spacing="1.5rem">
          <Grid item xs={12}>
            {hearing.model.deadline && expiredTimeAlert}
            <br />
            {discrepancy.endedAt !== null && endedAtAlert}
          </Grid>
          {inscribed && (
            <Grid item xs={12}>
              <Typography>{`Inscrito como: ${inscribedAs ? 'Público' : 'Expositor'}`}</Typography>
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            {dateAudienceField}
          </Grid>

          <Grid item xs={12} sm={6}>
            {currentRepresentatives.length > 0 ? (
              <FormControl fullWidth size="small" variant="standard">
                <InputLabel id="representativeId" shrink={true}>
                  Representación
                </InputLabel>
                <Select
                  labelId="representativeId"
                  name="representativeId"
                  label="En Representación de"
                  defaultValue="default"
                  value={controls.representativeId || 'default'}
                  onChange={handleChangeFields}>
                  <MenuItem value="default">Sin Representación</MenuItem>
                  {representativeOptions}
                </Select>
              </FormControl>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={6}>
            {inscriptionTypeField}
          </Grid>
        </Grid>
        {formValidation && currentRepresentatives == 0 ? (
          <Grid sx={{ mt: 2 }}>
            <Alert severity="error">
              Debe ser representante de una persona jurídica para ser expositor
            </Alert>
          </Grid>
        ) : (
          formValidation && (
            <Grid sx={{ mt: 2 }}>
              <Alert severity="error">
                Debe seleccionar un representante si quiere ser expositor
              </Alert>
            </Grid>
          )
        )}
      </DialogContent>
      <DialogActions>
        <Grid
          container
          direction={'row'}
          justifyContent={'space-between'}
          sx={{ padding: '5px 10px' }}>
          <PrimaryButton secondaryStyles onClick={onClose} startIcon={<CloseIcon />}>
            Cerrar
          </PrimaryButton>
          <PrimaryButton
            secondaryStyles
            onClick={handleSave}
            startIcon={<DoubleArrowIcon />}
            disabled={
              hearingDeadline?.pastDeadline ||
              formValidation ||
              moment(moment().toDate()).isAfter(controls.hearingDate) ||
              discrepancy.endedAt !== null
            }>
            Guardar
          </PrimaryButton>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

AudienceDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  hearing: PropTypes.shape({}),
  currentEntity: PropTypes.shape({}),
};
AudienceDialog.defaultProps = {
  hearing: null,
  currentEntity: null,
};

export default AudienceDialog;
