import React from 'react';
/* Material UI */
import { LinearProgress } from '@mui/material';

/* React Router */
import { Redirect, useLocation } from 'react-router-dom';

/* Project */
import AuthUserContext from 'commons/context/user';
import Layout from 'commons/containers/Layout/Layout';
import Login from 'screens/Login/Login';

function Auth({ user, loading, children }) {
  const location = useLocation();

  if (loading) {
    return <LinearProgress />;
  }

  if (user) {
    if (location.pathname === '/login') {
      return <Redirect to="/" />;
    }

    return (
      <Layout>
        <AuthUserContext.Provider value={user}>{children}</AuthUserContext.Provider>
      </Layout>
    );
  }

  return <Login />;
}
export default Auth;
