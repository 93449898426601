import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadIcon from '@mui/icons-material/Download';
import moment from 'moment';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { selectViewDocument } from '../DiscrepancyDetails.actions';
import colors from 'utils/colors';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const DocumentsViewList2 = (props) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const { formatedData, currentDocument } = props;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    return () => {
      setValue(0);
    };
  }, [currentDocument]);

  useEffect(() => {
    return () => {
      setPage(0);
      setRowsPerPage(5);
    };
  }, [formatedData]);

  const selectedData = useMemo(() => {
    const data = [];
    formatedData.forEach((element) => {
      data.push(...element.data);
    });
    return data;
  }, [value, formatedData]);

  const colorIconValidation = (type) => {
    switch (type) {
      case 'pdf':
        return '#F40F02';
      case 'doc':
        return '#103F91';
      case 'docx':
        return '#103F91';
      case 'xlsx':
        return '#1D6F42';
      default:
        return '#9e9e9e';
    }
  };

  const openDocument = (attachment) => {
    dispatch(selectViewDocument({ document: attachment }));
  };

  const downloadPdf = (filePath, fileName = 'Example-PDF-file.pdf') => {
    fetch(filePath, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));

        const link = document.createElement('a');
        link.href = url;
        link.download = fileName.split('_')[1];

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
      });
  };

  return (
    <Box sx={{ width: '100%', mt: 3, mb: 1 }}>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Nombre</TableCell>
              <TableCell align="left">Tipo</TableCell>
              <TableCell align="left">Tipo doc</TableCell>
              <TableCell align="left">Fecha subida</TableCell>
              <TableCell align="right">Ver</TableCell>
              <TableCell align="right">Descargar</TableCell>
            </TableRow>
          </TableHead>
          {selectedData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => (
            <TableRow hover>
              <TableCell align="left">{item?.content.split('_').pop()}</TableCell>
              <TableCell align="left" sx={{ width: '30px' }}>
                <Grid
                  sx={{
                    background:
                      item.attachmentPart().type === 'principal'
                        ? colors.purpleSecondary
                        : colors.grey,
                    textAlign: 'center',
                    paddingX: '7px',
                    borderRadius: '5px',
                    fontWeight: '500',
                    fontSize: '13px',
                    color: '#fff',
                  }}>
                  {item.attachmentPart().type === 'principal' ? 'Principal' : 'Adjunto'}
                </Grid>
              </TableCell>
              <TableCell align="left" sx={{ width: '60px' }}>
                <Grid
                  sx={{
                    background: colorIconValidation(item.contentType),
                    textAlign: 'center',
                    paddingX: '3px',
                    borderRadius: '5px',
                    fontWeight: '500',
                    fontSize: '13px',
                    color: '#fff',
                    width: 'auto',
                  }}>
                  {item.contentType.toUpperCase()}
                </Grid>
              </TableCell>
              <TableCell align="left" sx={{ width: '120px' }}>
                {moment(item?.uploadDate).format('DD-MM-YYYY HH:mm')}
              </TableCell>
              <TableCell align="right" sx={{ width: '5px' }}>
                {item.contentType === 'pdf' && (
                  <IconButton
                    aria-label="delete"
                    sx={{ padding: '1px', fontSize: '20px' }}
                    onClick={(e) => openDocument(item)}>
                    <VisibilityIcon fontSize="inherit" />
                  </IconButton>
                )}
              </TableCell>
              <TableCell align="right" sx={{ width: '5px' }}>
                <IconButton
                  aria-label="delete"
                  sx={{ padding: '1px', fontSize: '20px' }}
                  onClick={() => downloadPdf(item.url, item.content)}>
                  <DownloadIcon fontSize="inherit" />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={selectedData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={'Filas por página:'}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}–${to} de ${count !== -1 ? count : `mas que ${to}`}`
          }
        />
      </TableContainer>
    </Box>
  );
};

export default DocumentsViewList2;
