import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import {
  changeDocumentControls,
  deleteDocument,
} from 'screens/DiscrepancyDetail/DiscrepancyDetails.actions';
import SecondaryButton from 'commons/components/SecondaryButton/SecondaryButton';
import PrimaryButton from 'commons/components/PrimaryButton/PrimaryButton';
import { useParams } from 'react-router-dom';

function DeleteDocumentDialog(props) {
  const { objects, documentState, history, currentDiscrepancy, currentDiscrepancyDocuments } =
    props;
  const { controls, loaders } = documentState;
  const { openDeleteDialog } = controls;
  const currentDiscrepancyId = currentDiscrepancy.id;
  const firstDocumentId = currentDiscrepancyDocuments[0].id;

  const { documentId } = useParams();
  const dispatch = useDispatch();

  const currentDocument = objects.documents[documentId];

  const closeDialog = () => {
    dispatch(changeDocumentControls({ name: 'openDeleteDialog', value: false }));
  };

  const handleDeleteDocument = () => {
    dispatch(deleteDocument({ documentId, history, currentDiscrepancyId, firstDocumentId }));
  };

  return (
    <Dialog open={openDeleteDialog}>
      <DialogTitle>Advertencia </DialogTitle>
      <DialogContent>
        <Typography>
          ¿Está seguro(a) que desea eliminar el documento {currentDocument.title} de la
          discrepancia?
        </Typography>
      </DialogContent>
      <DialogActions>
        <SecondaryButton onClick={closeDialog} disabled={loaders.delete}>
          Cerrar
        </SecondaryButton>
        <PrimaryButton onClick={handleDeleteDocument} loading={loaders.delete}>
          Eliminar
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
}

DeleteDocumentDialog.propTypes = {
  objects: PropTypes.shape({}).isRequired,
  documentState: PropTypes.shape({}).isRequired,
};

export default DeleteDocumentDialog;
