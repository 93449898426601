import { Alert } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

const CaptchaDialog = () => {
  const { showCaptchaError } = useSelector((state) => state.app);
  return <>{showCaptchaError && <Alert severity="error">Lo sentimos, no pudimos validar el Captcha. Por favor, actualiza tu navegador o inténtalo otra vez.</Alert>}</>;
};

export default CaptchaDialog;
