import LoginTypes from 'screens/Login/Login.types';
import { createAction, newReducer } from 'utils/reducer';

// Local constants
const PATH = 'login/';
const DISABLE_ERRORS = `${PATH}DISABLE_ERRORS`;
const CONTROLS_CHANGED = `${PATH}CONTROLS_CHANGED`;

// actions
export const loginActions = {
  disableErrors: createAction(DISABLE_ERRORS),
  // api
  getUser: createAction(LoginTypes.GET_USER),
  login: createAction(LoginTypes.LOGIN_USER, 'controls', 'redirectUrl'),
  controlsChanged: createAction(CONTROLS_CHANGED, 'controls'),
};

const initialState = {
  controls: {
    loading: false,
    message: '',
    error: false,
  },
};

const login = newReducer(initialState, {
  [`${DISABLE_ERRORS}`]: (state) => ({
    ...state,
    error: false,
  }),
  // api
  [LoginTypes.LOGIN_USER]: (state) => ({
    ...state,
    controls: {
      ...state.controls,
      loading: false,
      error: false,
    },
  }),
  [LoginTypes.LOGIN_USER_SUCCESS]: (state) => ({
    ...state,
    controls: {
      ...state.controls,
      loading: false,
    },
  }),
  [LoginTypes.GET_USER_FAIL]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      loading: false,
      error: true,
      message: action.payload.errorMsg,
    },
  }),
  [LoginTypes.LOGIN_USER_FAIL]: (state) => ({
    ...state,
    controls: {
      ...state.controls,
      loading: false,
    },
  }),
  [CONTROLS_CHANGED]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      ...action.controls,
    },
  }),
});

export default login;
