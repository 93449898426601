// REACT
import React from 'react';

// MATERIAL-UI
import { Dialog } from '@mui/material';

// PROJECT
import UpdateDocumentsFormStepOne from './components/UpdateDocumentForms/UpdateDocumentsFormStepOne/UpdateDocumentsFormStepOne';
import UpdateDocumentsFormStepTwo from './components/UpdateDocumentForms/UpdateDocumentsFormStepTwo/UpdateDocumentsFormStepTwo';
import UpdateDocumentsFormStepThree from './components/UpdateDocumentForms/UpdateDocumentsFormStepThree/UpdateDocumentsFormStepThree';

function UpdateDocumentDialog(props) {
  const {
    documentState,
    documentTypes,
    attachmentsParts,
    objects,
    currentDocument,
    currentDiscrepancy,
  } = props;
  const { controls, loaders } = documentState;

  const principalAttachmentPart = attachmentsParts.find((ap) => ap.type === 'principal');
  const principalAttachments = principalAttachmentPart?.attachments() || [];
  const publicPdf = principalAttachments.find(
    (attachment) => attachment.confidential === false && attachment.contentType === 'pdf',
  );
  const publicWord = principalAttachments.find(
    (attachment) =>
      attachment.contentType === 'doc' ||
      attachment.contentType === 'docx' ||
      attachment.contentType === 'word',
  );
  const privatePdf = principalAttachments.find((attachment) => attachment.confidential === true);

  return (
    <Dialog open={controls.openEditDialog} fullWidth>
      {controls.editActiveStep === 0 && (
        <UpdateDocumentsFormStepOne
          currentDiscrepancy={currentDiscrepancy}
          currentDocument={currentDocument}
          editActiveStep={controls.editActiveStep}
          initFormValues={documentState.form}
          documentTypes={documentTypes}
          loading={loaders.edition}
          objects={objects}
        />
      )}
      {controls.editActiveStep === 1 && (
        <UpdateDocumentsFormStepTwo
          publicPdf={publicPdf}
          fileMainWordName={publicWord?.content}
          fileMainConfidentialName={privatePdf?.content}
          loading={loaders.edition}
        />
      )}
      {controls.editActiveStep === 2 && (
        <UpdateDocumentsFormStepThree loading={loaders.edition} objects={objects} />
      )}
    </Dialog>
  );
}

export default UpdateDocumentDialog;
