import React, { useState } from 'react';

/* Material UI */
import { Button, FormControlLabel, Grid, IconButton, InputAdornment } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import Switch from '@mui/material/Switch';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
/* React Redux */
import { useDispatch, useSelector } from 'react-redux';

/* Project */
import PrimaryButton from 'commons/components/PrimaryButton/PrimaryButton';
import DatePicker from 'commons/components/DatePicker/DatePicker';
import CustomTextField from 'commons/components/CustomTextField/CustomTextField';
import CustomSelectField from 'commons/components/CustomSelectField/CustomSelectField';
import { getDiscrepancies, setFilters, clearFilters } from 'screens/Home/Home.actions';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import filtersStyles from './Filters.styles';
import styled from '@emotion/styled';
import moment from 'moment';

function AddClearButton(props) {
  const { name, handleChange } = props;
  return (
    <ClearIcon
      className="clear-icon"
      onClick={() => {
        handleChange({ target: { name, value: '' } });
      }}
    />
  );
}

const CustomSwitch = styled(Switch)(({ theme }) => ({
  '& .Mui-checked': {
    color: '#641C34',
  },
  '& .MuiSwitch-track': {
    backgroundColor: '#641C34 !important',
  },
}));

function Filters(props) {
  const { legalMatterList, stateList, orderList, legalSubMatterList, orderOnChange, pagination } =
    props;

  const dispatch = useDispatch();
  const { filters } = useSelector((state) => state.home);
  const { user } = useSelector((state) => state.app);
  const [dateErrorMessage, setDateErrorMessage] = useState({startDate: '', endDate: ''});

  const handleChange = ({ target: { name, value } }) => {
    dispatch(setFilters({ name, value }));
  };

  const handleChangeSwitch = (e) => {
    const checked = e.target.checked ? true : null;
    dispatch(setFilters({ name: e.target.name, value: checked }));
  };

  const handleSearch = () => {
    // const startDate = moment(filters.startDate, 'YYYY-MM-DD HH:mm', true);
    // const endDate = moment(filters.endDate, 'YYYY-MM-DD HH:mm', true);

    const startDate = filters.startDate
    ? moment(filters.startDate, 'YYYY-MM-DD HH:mm', true)
    : null;
  const endDate = filters.endDate
    ? moment(filters.endDate, 'YYYY-MM-DD HH:mm', true)
    : null;

    let startDateErrorMessage = '';
    let endDateErrorMessage = '';

    if (startDate && !startDate.isValid()) {
      startDateErrorMessage = 'Formato de fecha inválido';
    }

    if (endDate && !endDate.isValid()) {
      endDateErrorMessage = 'Formato de fecha inválido';
    }

    if (startDate && endDate && startDate.isAfter(endDate)) {
      startDateErrorMessage = 'Fecha de inicio debe ser anterior a la fecha de fin';
      endDateErrorMessage = 'Fecha de fin debe ser posterior a la fecha de inicio';
    }

    setDateErrorMessage({
      startDate: startDateErrorMessage,
      endDate: endDateErrorMessage,
    });

    if (!startDateErrorMessage && !endDateErrorMessage) {
      dispatch(getDiscrepancies({ filters, pagination }));
    }
  };

  const handleChangeDate = (date, name) => {
    let formatedDate;
    if (name === 'endDate') {
      formatedDate = moment(date).endOf('day').format('YYYY-MM-DD HH:mm');
    } else {
      formatedDate = moment(date).startOf('day').format('YYYY-MM-DD HH:mm');
    }
    handleChange({ target: { name, value: formatedDate } });
  };

  const subMatterList = !filters.legalMatter
    ? []
    : legalSubMatterList.filter(
        (subMatter) => subMatter.legalMatterId === Number(filters.legalMatter),
      );

  const handleClearDate = (name) => {
    handleChangeDate(null, name);
  };

  const handleMouseDown = (event) => {
    event.preventDefault();
  };

  const handleClearFilters = () => {
    dispatch(clearFilters());
  };

  const endAdornment = (name) => (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={() => handleClearDate(name)}
        onMouseDown={handleMouseDown}>
        <ClearIcon />
      </IconButton>
    </InputAdornment>
  );

  const handleMatterChange = ({ target: { name, value } }) => {
    dispatch(setFilters({ name, value }));
    dispatch(setFilters({ name: 'legalSubMatter', value: '' }));
  };

  return (
    <Grid container rowSpacing={2} columnSpacing={6} sx={{ pb: 6, pt: 2 }}>
      <Grid item container xs={12} rowSpacing={2} columnSpacing={6}>
        <Grid item xs={12} md={6} lg={3}>
          <DatePicker
            value={filters.startDate}
            onChange={(e, date) => {
              handleChangeDate(date, 'startDate');
            }}
            fullWidth
            label="Desde"
            variant="standard"
            InputProps={{
              endAdornment: endAdornment('startDate'),
            }}
            error = {dateErrorMessage.startDate !== ''}
            helperText={dateErrorMessage.startDate}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <DatePicker
            value={filters.endDate}
            onChange={(e, date) => {
              handleChangeDate(date, 'endDate');
            }}
            fullWidth
            label="Hasta"
            variant="standard"
            InputProps={{
              endAdornment: endAdornment('endDate'),
            }}
            error = {dateErrorMessage.endDate !== ''}
            helperText={dateErrorMessage.endDate}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <CustomSelectField
            label="Orden"
            name="order"
            list={orderList}
            orderOnChange={orderOnChange}
            onChange={handleChange}
            value={filters.order}
            variant="standard"
            endAdornment={
              <AddClearButton name="order" value={filters.order} handleChange={handleChange} />
            }
            sx={{ ...filtersStyles.selectButton }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <CustomSelectField
            label="Estado"
            name="state"
            list={stateList}
            onChange={handleChange}
            value={filters.state}
            variant="standard"
            endAdornment={
              <AddClearButton name="state" value={filters.state} handleChange={handleChange} />
            }
            sx={{ ...filtersStyles.selectButton }}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={3}>
          <CustomTextField
            label="Nro. Discrepancia"
            name="number"
            value={filters.number}
            onChange={handleChange}
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <CustomTextField
            label="Palabra clave"
            name="keyword"
            value={filters.keyword}
            onChange={handleChange}
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <CustomSelectField
            label="Materia"
            name="legalMatter"
            list={legalMatterList}
            onChange={handleMatterChange}
            value={filters.legalMatter}
            mapKey="name"
            variant="standard"
            endAdornment={
              <AddClearButton
                name="legalMatter"
                value={filters.legalMatter}
                handleChange={handleChange}
              />
            }
            sx={{ ...filtersStyles.selectButton }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <CustomSelectField
            label="Submateria"
            name="legalSubMatter"
            list={subMatterList}
            onChange={handleChange}
            value={filters.legalSubMatter}
            mapKey="name"
            variant="standard"
            endAdornment={
              <AddClearButton
                name="legalSubMatter"
                value={filters.legalSubMatter}
                handleChange={handleChange}
              />
            }
            sx={{ ...filtersStyles.selectButton }}
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} justifyContent="space-between">
        <Grid item xs={2}>
          {user && (
            <FormControlLabel
              value="start"
              control={<CustomSwitch />}
              label="Sólo en las que soy parte"
              labelPlacement="start"
              name="onlyMyData"
              sx={{ marginLeft: 0 }}
              checked={filters.onlyMyData}
              onChange={handleChangeSwitch}
            />
          )}
        </Grid>
        <Grid item xs={10} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Grid sx={{ display: 'flex', fledDirection: 'row', alignItems: 'center' }}>
            <Grid sx={{ paddingRight: '20px' }}>
              <Button
                variant="text"
                sx={{ paddingLeft: '20px', paddingRight: '20px' }}
                startIcon={<DataUsageIcon sx={{ color: '#909498' }} />}
                onClick={() => handleClearFilters()}>
                Limpiar
              </Button>
            </Grid>
            <PrimaryButton
              fullWidth
              text="Aplicar filtro"
              onClick={handleSearch}
              secondaryStyles
              startIcon={<FilterAltIcon />}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Filters;
