import { takeLatest, spawn, put, call } from 'redux-saga/effects';
import apiRequest, { apiSuccess, update } from 'utils/api';
import searchContentTypes from './SearchContent.types';
import { onlyKeysWithValue } from 'utils/helper';

function* getAll() {
  yield takeLatest(searchContentTypes.GET_DOCUMENTS, function* getDiscrepancyApi(action) {
    const urlParams = new URLSearchParams(action.payload);

    const response = yield apiRequest(
      `api/v1/searchContent?${urlParams}`,
      { method: 'get' },
      true,
      true,
    );

    if (!response.error) {
      yield put(apiSuccess(searchContentTypes.GET_DOCUMENTS_SUCCESS, response));
    } else {
      yield put(apiSuccess(searchContentTypes.GET_DOCUMENTS_FAIL));
    }
  });
}
function* getFileLink() {
  yield takeLatest(searchContentTypes.GET_FILE_LINK, function* getDiscrepancyApi(action) {
    const urlParams = new URLSearchParams(action.payload);
    const response = yield apiRequest(
      `api/v1/searchContent/file-link?${urlParams}`,
      { method: 'get' },
      true,
      true,
    );

    if (!response.error) {
      yield put(apiSuccess(searchContentTypes.GET_FILE_LINK_SUCCESS, response));
    } else {
      yield put(apiSuccess(searchContentTypes.GET_FILE_LINK_FAIL));
    }
  });
}

export default function* SearchContentSaga() {
  yield spawn(getAll);
  yield spawn(getFileLink);
}
