import React from 'react';

/* Material UI */
import { CircularProgress, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

/* Project */
import { useForm } from 'utils/hooks/useForm';
import { validateEmail } from 'utils/functions';

const CustomField = TextField;

function RecoveryForm(props) {
  const {
    actions,
    classes,
    onChangeText,
    controls,
    history,
    email,
    loading,
    successChangePassword,
  } = props;

  const { onChange, onSubmit, errors } = useForm(controls, null, {
    onChange: onChangeText,
    validations: {
      email: [
        {
          check: (value) => !!value && value.trim().length > 0,
          message: 'Correo electrónico requerido',
        },
        {
          check: (value) => validateEmail(value || ''),
          message: 'Correo electrónico inválido',
        },
      ],
    },
  });

  const onClickVerifyEmail = (event) => {
    event.preventDefault();
    onSubmit(() => actions.verifyEmail(controls));
  };

  return (
    <form onSubmit={onClickVerifyEmail}>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={10} textAlign="center">
          <Typography variant="body1" component="div">
            <strong>Restablecer contraseña</strong>
          </Typography>
        </Grid>
        <Grid item xs={10} textAlign="justify">
          <Typography variant="body1" component="div">
            Ingresa tu correo electrónico y recibirás instrucciones para una nueva contraseña.
          </Typography>
        </Grid>

        <Grid item xs={10}>
          <CustomField
            className={classes.field}
            label="Correo electrónico"
            key="email"
            id="email"
            name="email"
            value={email}
            onChange={onChange}
            style={{ paddingBottom: '8px' }}
            fullWidth
            error={errors.email}
            helperText={errors.email && errors.email[0]}
          />
        </Grid>

        <Grid
          item
          xs={10}
          container
          justifyContent="space-between"
          alignItems="center"
          className={classes.textAlign}>
          <IconButton edge="start" onClick={() => history.goBack()}>
            <ArrowBackIcon />
          </IconButton>

          <Button
            disabled={successChangePassword}
            type="submit"
            variant="contained"
            color="primary"
            style={{ marginTop: '5px' }}
            className={classes.button}>
            {loading ? <CircularProgress className={classes.progress} /> : 'Continuar'}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default RecoveryForm;
